import { useSelector } from "react-redux";
import { useIntercom } from "react-use-intercom";
import { parseUserInfo } from ".";

export const IntercomUserInitializer = ({ children }) => {
    const userInfo = useSelector((state) => state.userinfo);

    const { boot } = useIntercom();

    if (userInfo) {
        const intercomUserData = parseUserInfo(userInfo);
        boot(intercomUserData);
    }

    return children;
};
