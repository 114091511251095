import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import i18n from 'util/i18n';
import { useFirebase} from 'react-redux-firebase';
import { loadBambooHRAddresses } from 'util/api/bamboohr';
import { useState } from 'react';
import LoadingIcon from 'components/util/LoadingIcon';
import { withToastNotification  } from 'components/hocs/withToastNotification';

const ImportAddressesBambooHR = ({companyInfo, data, toastNotification}) => {
    const firebase = useFirebase();
    const [loading, setLoading] = useState(false);

    return (
        (typeof companyInfo?.BambooHRIntegrationInfo?.CompanyDomain !== "undefined" && typeof companyInfo?.BambooHRIntegrationInfo?.APIKey !== "undefined") ? 
            loading? <LoadingIcon/> : <div className="text-base text-gray-600 flex flex-row justify-center items-center mt-3 rounded-lg hover:bg-gray-200 cursor-pointer"
                onClick={() => {
                    setLoading(true);
                    loadBambooHRAddresses(firebase, data, companyInfo)
                    setLoading(false);
                    toastNotification(i18n.successBambooHR, "success");
                }}
            >
                <IconButton> <AddCircleIcon/> </IconButton>
                {i18n.importAddressesBambooHR}
            </div>
        : null     
    );
}

export default withToastNotification(ImportAddressesBambooHR);