import React from 'react';
import {Field, reduxForm} from "redux-form";

import {css} from "@emotion/css";
import Grid from "@material-ui/core/Grid";
import FormSection from 'components/Forms/FormSection';
import LogoSelector from "./LogoSelector";
import {renderAddressAutocomplete, renderTextField} from "components/Forms";
import i18n from "util/i18n";

const CompanySettingsForm = reduxForm({form: "companySettingsForm"})(
    ({logo, timeCreated}) => {
        const formattedSinceCreated = new Date(timeCreated).toLocaleDateString(
            "en-CA",
            {
                month: "long",
                day: "numeric",
                year: "numeric",
            }
        );
        return (
            <form
                className={css`
            width: 100%;
            > * {
              margin-bottom: 18px;
            }
          `}
            >
                <Field
                    name="logo"
                    component={LogoSelector}
                    logo={logo}
                    directory="logos"
                />
                <FormSection label={i18n.generalInfo} columns={1}>
                    <Grid container justifyContent="space-between" alignItems="baseline">
                        <Field
                            name="name"
                            placeholder={i18n.companyName}
                            component={renderTextField}
                        />
                        <span>{i18n.joined} {formattedSinceCreated}</span>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Field
                                name="address"
                                autoComplete="address-line1"
                                placeholder={i18n.address}
                                component={renderAddressAutocomplete}
                                className={css`width: 100%;`}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Field
                                name="secondary_address"
                                autoComplete="address-line2"
                                placeholder={i18n.apartmentEtc}
                                component={renderTextField}
                                className={css`margin-top: 0.5em; width: 100%;`}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Field
                            name="phone"
                            placeholder={i18n.phoneNumber}
                            component={renderTextField}
                            className={css`width: 100%;`}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Field
                            name="domain"
                            placeholder={i18n.businessInternetDomainWithExample}
                            component={renderTextField}
                            className={css`width: 100%;`}
                        />
                    </Grid>

                    <Grid item xs={8}>
                        <Field
                            name="adminEmail"
                            placeholder={i18n.adminEmailAddress}
                            component={renderTextField}
                            className={css`width: 100%;`}
                        />
                    </Grid>
                </FormSection>
            </form>
        );
    }
);

export default CompanySettingsForm
