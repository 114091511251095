import Mixpanel from "util/analytics/mixpanel";

// Checks whether the given invitation code is valid.
// Returns false if invalid, otherwise returns related info from db
async function verifyCode(firestore, inviteCode) {
    if (inviteCode === '') {return false;}

    const doc = await firestore.collection('invites').doc(inviteCode).get();
    const data = doc.data();
    if (data === undefined) {return false;}

    return {inviteCode, ...data};
}

// Generates a new invite code with the given role.
// Requires superuser or sysadmin role.
// Superusers can only generate employee or superuser invites
// for their own company.
async function generateInvite(firestore, companyId, role) {
    const companyRef = firestore.collection('companies').doc(companyId);
    const invite = await firestore.collection('invites').add({
        company: companyRef,
        role,
        timeCreated: Date.now()
    });
    return invite.id;
}

// Initializes a new user with the given data.
async function initializeUser(firestore, user, data) {
    const {uid} = user;
  
    // Set userinfo
    await firestore.collection('userinfo').doc(uid).set({
        signupTime: Date.now(),
        ...data,
    });
    Mixpanel.trackSignUp(data);
}

async function getInviteLink(firestore, company) {
    const code = await generateInvite(firestore, company.id, 'employee');
    return `https://${window.location.host}/signup?code=${code}&company=${encodeURIComponent(company.name)}`;
}

export {
    generateInvite,
    verifyCode, initializeUser,
    getInviteLink,
};
