import React, { useState } from 'react';

import { useFirestore, useFirebase } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

import LoadingIcon from 'components/util/LoadingIcon';

import SummaryPage from 'components/Dashboard/Summary';
import { loadHistory, loadCompanyHistory } from 'util/database/history';


// companyInfo is used to target a specific company.
// Only valid in sysadmin view.
function Summary({companyInfo}) {
    const firebase = useFirebase();
    const firestore = useFirestore();
    const userInfo = useSelector(state => state.userinfo);

    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(true);

    if (userInfo?.company && !history.length && loading) {
        if (companyInfo) {
            loadCompanyHistory(firestore, companyInfo.id).then(setHistory).then(
                () => setLoading(false)
            );
        } else {
            loadHistory(firebase).then(setHistory).then(
                () => setLoading(false)
            );
        }
    }

    return loading ? <LoadingIcon/> : <SummaryPage history={history}/>;
}

export default Summary;
