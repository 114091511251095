const CollapsibleInstructions = () => {
    return (
        <div id="accordion-collapse" data-accordion="collapse">
            <h2 id="accordion-collapse-heading-2">
                <button type="button" className="flex items-center justify-between w-full p-5 font-medium text-left border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-800 bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
                    <span className="font-bold">How to setup Kudos</span>
                    <svg data-accordion-icon="" className="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
            </h2>
            <div id="accordion-collapse-body-2" className="" aria-labelledby="accordion-collapse-heading-2">
                <div className="p-5 font-light border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                    <p className="mb-2 text-gray-500 dark:text-gray-400">
                        To install the Kudos app, click the "Add to Slack" button and select the Slack workplace where you want to install the app.
                    </p>
                    <p className="mb-2 pl-5 text-gray-500 dark:text-gray-400 italic">
                        Note: if you already have a WFHomie company, please install the Kudos app from your <a href="https://app.wfhomie.com/dashboard/settings/company" className="text-blue-600 dark:text-blue-500 hover:underline">company settings page</a>. This way, your Kudos installation will be linked to your WFHomie company. If you don’t have a WFHomie account, we will create one for you during the installation process. You can log into your account using the Slack SSO option on the <a href="https://app.wfhomie.com/login" className="text-blue-600 dark:text-blue-500 hover:underline">login page</a>.
                    </p>
                    <p className="mb-2 text-gray-500 dark:text-gray-400">
                        Once the app is installed, you will be redirected to the <a href="https://app.wfhomie.com/dashboard/settings/company" className="text-blue-600 dark:text-blue-500 hover:underline">Kudos settings page</a>. What you can do on the settings page depends on your Kudos plan:
                    </p>
                    <ul className="pl-5 text-gray-500 list-disc dark:text-gray-400">
                        <li>On a free plan, you can select the Slack channel where the public Kudos announcement messages will be sent by the bot.</li>
                        <li>On a premium plan, you can:
                            <ul className="pl-5 text-gray-500 list-disc dark:text-gray-400">
                                <li>restrict the channels from which the /kudos command can be used;</li>
                                <li>select the Slack channel where the public Kudos announcement messages will be sent by the bot;</li>
                                <li>select who is allowed to send Kudos; and</li>
                                <li>set the maximum number of Kudos that can be received by the same person each month.</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>

            <h2 id="accordion-collapse-heading-1">
                <button type="button" className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                    <span className="font-bold">How to use Kudos on a free plan</span>
                    <svg data-accordion-icon="" className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
            </h2>
            <div id="accordion-collapse-body-1" className="hidden" aria-labelledby="accordion-collapse-heading-1">
                <div className="p-5 font-light border border-b-0 border-gray-200 dark:border-gray-700">
                    <ol className="pl-5 text-gray-500 list-decimal dark:text-gray-400 space-y-2">
                        <li>First, head to your team's designated Kudos Slack Channel.</li>
                        <li>Next, type in the following: <span className="italic">/kudos @recipient's name followed by a message to recognize your teammates' hard work!</span>
                            <ul className="pl-5 text-gray-500 list-disc dark:text-gray-400">
                                <li>Here's an example: <span className="italic">/kudos @Rachel Smith thanks so much for your help on this project! We were able to exceed all of our KPIs!!</span></li>
                                <li>You can send a Kudos to a group of people by chaining as many name tags as you want, for example: <span className="italic">/kudos @Bobby @Samuel For the amazing workshop this morning!</span></li>
                            </ul>
                        </li>
                        <li>{"Tada! The recipient then receives a public shoutout :)"}</li>
                    </ol>
                </div>
            </div>

            <h2 id="accordion-collapse-heading-3">
                <button type="button" className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" aria-controls="accordion-collapse-body-3">
                    <span className="font-bold">How to use Kudos on a premium plan</span>
                    <svg data-accordion-icon="" className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
            </h2>
            <div id="accordion-collapse-body-3" className="hidden" aria-labelledby="accordion-collapse-heading-3">
                <div className="p-5 font-light border border-t-0 border-gray-200 dark:border-gray-700">
                    <p className="mb-2 text-gray-500 dark:text-gray-400">On a premium plan, every Kudos sent comes with a gift card. Kudos senders have a limit of Kudos they can send every month, based on the pricing plan agreed upon.</p>
                    <ol className="pl-5 text-gray-500 list-decimal dark:text-gray-400 space-y-2">
                        <li>First, head to your team's designated Kudos Slack Channel.</li>
                        <li>Next, type in the following: <span className="italic">/kudos @recipient's name followed by a message to recognize your teammates' hard work!</span>
                            <ul className="pl-5 text-gray-500 list-disc dark:text-gray-400">
                                <li>Here's an example: <span className="italic">/kudos @Rachel Smith thanks so much for your help on this project! We were able to exceed all of our KPIs!!</span></li>
                                <li>You can send a Kudos to a group of people by chaining as many name tags as you want, for example: <span className="italic">/kudos @Bobby @Samuel For the amazing workshop this morning!</span></li>
                            </ul>
                        </li>
                        <li>Every premium Kudos sent uses one of your monthly gifting credits. We added a safeguard message to prevent accidental use of those credits; simply press “Yes” to proceed with sending the Kudos, or “No” to cancel.</li>
                        <li>{"Tada! The recipient then receives a public shoutout, as well as a DM with a link to claim their well-deserved gift card :)"}</li>
                    </ol>
                </div>
            </div>
        </div>
    );
}

export default CollapsibleInstructions
