// this code should not be duplicated
const PanelLeft = ({ title, subtitle, description, imageUrl }) => {
    return (
        <div className="grid grid-flow-row gap-y-16 lg:grid-cols-2 lg:gap-x-16">
            <div>
                <img src={imageUrl} alt="Slack Demo" />
            </div>
            <div className="flex flex-col space-y-8">
                {title && <span className="text-kudos-green uppercase font-bold">{title}</span>}

                <span className="text-kudos-dark-blue font-bold leading-relaxed text-xl lg:text-3xl">{subtitle}</span>

                <span className="text-kudos-dark-blue leading-relaxed whitespace-pre-wrap">{description}</span>
            </div>
        </div>
    );
};

const PanelRight = ({ title, subtitle, description, imageUrl }) => {
    return (
        <div className="grid grid-flow-row gap-y-16 lg:grid-cols-2 lg:gap-x-16">
            <div className="flex flex-col space-y-8">
                {title && <span className="text-kudos-green uppercase font-bold">{title}</span>}

                <span className="text-kudos-dark-blue font-bold leading-relaxed text-xl lg:text-3xl">{subtitle}</span>

                <span className="text-kudos-dark-blue leading-relaxed whitespace-pre-wrap">{description}</span>
            </div>

            <div>
                <img src={imageUrl} alt="Slack Demo" />
            </div>
        </div>
    );
};

const panels = [
    {
        title: "Let the entire company can know who the real losers are",
        subtitle: "Shame your co-workers directly in Slack",
        description:
            `
The ShameBot by WFHomie is the best way to publicly shame your co-workers over Slack.

Whenever a less-liked teammate makes a mistake, asks a dull question or makes a cringy comment, using ShameBot is the easiest way to make them question their self-worth.
`,
        imageUrl: "/static/images/Shame website_1.png",
    },
    {
        subtitle: "Highlight your teammates' mistakes and weaknesses",
        description:
            `
When an intellectually inferior teammate disappoints you over and over again, make sure to let them know exactly how little you think of them.

Every time you shame someone, they receive a gif to make others laugh harder. Let the entire company know who the real losers are!
`,
        imageUrl: "/static/images/Shame website_2.png",
    },
];

export const Panels = () => {
    return (
        <div className="flex flex-col p-8 lg:p-16 space-y-32">
            <PanelRight {...panels[0]} />
            <PanelLeft {...panels[1]} />
        </div>
    );
};
