import React, {useEffect, useState} from 'react';
import {useFirestore} from "react-redux-firebase";

import Modal from 'react-modal';

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Card, CardContent} from "@material-ui/core";

import LoadingIcon from "../../../components/util/LoadingIcon";
import Typography from "@material-ui/core/Typography";
import {defaultEmployeePermissions, defaultSuperuserPermissions, permissions} from 'util/permission/permissions';
import { withToastNotification  } from 'components/hocs/withToastNotification';
import i18n from 'util/i18n';

const FEATURES = Object.values(permissions);

const cardStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '20px'
};

function CompanyPermissionManager({companyInfo}) {
    const [companyData, setCompanyData] = useState(null);
    const initialState = {};
    FEATURES.forEach(f => {
        initialState[f] = !companyInfo.featureList || companyInfo.featureList.includes(f);
    });
    const [featureList, setFeatureList] = useState(initialState);

    const firestore = useFirestore();

    const company = firestore.collection('companies').doc(companyInfo.id);

    useEffect(() => {
        if (companyData === null) {
            company.get().then(async doc => {
                let data = doc.data();

                //if the company document inside firestore does not contain rolePermissions and featureList, we will
                //create them and populate them with default values
                if (!data.featureList) {
                    company.set(
                        {
                            featureList: defaultSuperuserPermissions,
                            rolePermissions: {
                                employee: defaultEmployeePermissions,
                                superuser: defaultSuperuserPermissions
                            }
                        },
                        {merge: true});
                    data = (await company.get()).data();
                }
                setCompanyData(data);
            });
        }
    }, [companyData, firestore, company]);

    if (companyData === null) {
        return <LoadingIcon/>;
    }

    const updateFeatureList = async (event) => {
        const {name: featureName, checked: featureAvailability} = event.target;
        setFeatureList({...featureList, [featureName]: featureAvailability});
        let newFeatureList = companyData.featureList;
        let permissions = companyData.rolePermissions;
        if (featureAvailability) {
            newFeatureList.push(featureName);
            permissions.superuser.push(featureName);
        } else {
            newFeatureList = newFeatureList.filter(i => i !== featureName);
            permissions.superuser = permissions.superuser.filter(i => i !== featureName);
        }

        //updating company featureList inside firebase
        await company.set({featureList: newFeatureList, rolePermissions: permissions}, {merge: true});
        setCompanyData((await company.get()).data());
    };

    return (<>
        <FeatureList featureList={featureList} updateFeatureList={updateFeatureList}/>

        <RolePermissionsTable firestore={firestore} company={company} companyData={companyData}/>
    </>);
}

function FeatureList({featureList, updateFeatureList}) {
    return (
        <Card style={cardStyle}>
            <CardContent>
                <h3>This company has access to these permissions:</h3>
                <FormGroup row>
                    {
                        FEATURES.map(f =>
                            <FormControlLabel
                                key={f}

                                control={
                                    <Checkbox
                                        checked={featureList[f]}
                                        onChange={updateFeatureList}
                                        value={f}
                                        name={f}
                                    />
                                }
                                label={f}/>
                        )
                    }
                </FormGroup>
            </CardContent>
        </Card>);
}

function RolePermissionsTable({firestore, company, companyData, toastNotification}) {

    const [newRoleName, setNewRoleName] = useState('');
    const [roleToDelete, setRoleToDelete] = useState('');

    const loadInitialPermissionState = () => {
        let initialPermissionState = {};
        if (companyData.rolePermissions) {
            Object.keys(companyData.rolePermissions).sort().forEach(role => {
                FEATURES.forEach(f => {
                    if (!initialPermissionState[role]) {
                        initialPermissionState[role] = {};
                    }
                    initialPermissionState[role][f] = companyData.rolePermissions[role].includes(f);
                });
            });
        }
        return initialPermissionState;
    };
    const [featureRoleList, setFeatureRoleList] = useState(loadInitialPermissionState());

    const addNewRole = () => {
        if (!newRoleName) { 
            toastNotification(i18n.roleName, 'error');
            return;
        }
        const newRole = newRoleName.toLowerCase();
        const rolePermissions = companyData.rolePermissions || {};
        rolePermissions[newRole] = [];

        //adding newRole to firestore
        company.set({rolePermissions: rolePermissions}, {merge: true});

        let newFeatureRoleList = featureRoleList;
        newFeatureRoleList[newRole] = {};
        FEATURES.forEach(f => {
            newFeatureRoleList[newRole][f] = false;
        });

        //updating the state
        setFeatureRoleList(newFeatureRoleList);
        setNewRoleName('');
        toastNotification(i18n.roleNameAdded, 'success');
    };
    const toggleFeatureOfARole = async (event) => {
        let roleFeatureArr = event.target.name.split('.');
        const role = roleFeatureArr[0];
        const feature = roleFeatureArr[1];
        setFeatureRoleList({...featureRoleList, role: {...featureRoleList[role], f: event.target.checked}});
        let newRolePermissions = companyData.rolePermissions;
        if (event.target.checked) {
            newRolePermissions[role].push(feature);
        } else {
            newRolePermissions[role] = newRolePermissions[role].filter(i => i !== feature);
        }

        //update company rolePermissions in firestore
        await company.set({rolePermissions: newRolePermissions}, {merge: true});
        featureRoleList[role][feature] = newRolePermissions[role].includes(feature);
        await setFeatureRoleList(featureRoleList);
    };

    const handleDeleteRole = async (role) => {
        let newRolePermissions = companyData.rolePermissions;
        delete newRolePermissions[role];

        //update company rolePermissions in firestore
        await company.set({...companyData, rolePermissions: newRolePermissions});
        let newFeatureRoleList = {...featureRoleList};
        delete newFeatureRoleList[role];
        await setFeatureRoleList(newFeatureRoleList);
    }
    const checkDisable = (role, feature) => role === 'superuser' || !companyData.featureList.includes(feature);

    return (
        <>
            <Card style={cardStyle}>
                <CardContent>
                    <h3>Define a New Role</h3>
                    <form noValidate autoComplete="off">
                        <TextField id="new-role" label="New Role" value={newRoleName}
                                   onChange={(event) => {
                                       setNewRoleName(event.target.value);
                                   }}/>
                        <Button variant="contained" color="primary" onClick={addNewRole}>
                            Add New Role
                        </Button>
                    </form>
                </CardContent>
            </Card>
            {
                Object.keys(companyData.rolePermissions).map(r => {
                    return (
                        <Card style={cardStyle} key={r}>
                            {roleToDelete === r ? <DeleteModal firestore={firestore}
                                                               currentCompany={company}
                                                               isOpen={roleToDelete === r}
                                                               onSubmit={() => {
                                                                   setRoleToDelete('');
                                                                   handleDeleteRole(r);
                                                               }} role={r}
                                                               onClose={() => setRoleToDelete('')}/> : <></>}
                            <CardContent>
                                <h3>Role Name: {r}</h3>
                                <Button color="default" variant="contained"
                                        disabled={r.toLowerCase() === "superuser" || r.toLowerCase() === "employee"}
                                        onClick={() => {
                                            setRoleToDelete(r)
                                        }}>Delete</Button>
                                <p>This role has access to:</p>
                                <FormGroup row>
                                    {
                                        FEATURES.map(f =>
                                            <FormControlLabel
                                                key={f}
                                                control={
                                                    <Checkbox
                                                        disabled={checkDisable(r, f)}
                                                        checked={featureRoleList[r][f]}
                                                        onChange={toggleFeatureOfARole}
                                                        value={f}
                                                        name={r + "." + f}
                                                    />
                                                }
                                                label={f}/>
                                        )
                                    }
                                </FormGroup>
                            </CardContent>
                        </Card>);
                })
            }

        </>

    );
}

function DeleteModal({firestore, isOpen, onSubmit, role, onClose, currentCompany}) {
    const [loading, setLoading] = useState(true);
    const [anyUserExists, setAnyUserExists] = useState(false);
    const checkAnyUserExists = async () => {
        const users = await firestore.collection('userinfo')
            .where("company", '==', currentCompany)
            .where('role', '==', role.toLowerCase()).get();
        setLoading(false);
        setAnyUserExists(!users.empty);
    };

    checkAnyUserExists();

    return <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
    >
        <Typography variant='h5'>Deleting Role</Typography>
        {loading ? <LoadingIcon/> : (
            anyUserExists ? <p>There are users with this role, before deleting, you should change their roles</p>
                :
                <>
                    <p>Are you sure you want to delete role "{role}"</p>
                    <Button onClick={onSubmit} variant={"contained"} color={"secondary"}>Delete</Button>
                </>
        )}
    </Modal>;
}

export default withToastNotification(CompanyPermissionManager);
