async function loadEventPolls(firestore) {
    console.log('Getting all event polls...');

    const collection = await firestore.collection('slackPolls')
        .orderBy("date", "desc")
        .get()   

    const slackPolls = {};

    for (let event of collection.docs) {
        slackPolls[event.id] = event.data();
    }
    return slackPolls
}

export { loadEventPolls }