import React, {useState} from "react";
import {css} from '@emotion/css';
import {Card, CardContent, Grid, Button} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import VisualQuestionCard from "./VisualQuestionCard";

function SysadminQuestionCard({question, editQuestion, deleteQuestion, openModal}) {
    if(question === undefined) {
        return <></>;
    }

    return <Grid item>
        <Card className={css`width: 300px;`}>
            <CardContent>
                <h3>{question.title} </h3>
                <h5>Description: {question.description}</h5>
                <h5>Label: {question.label}</h5>
                <p>Type: {question.type}</p>
                <p>Options: {question.options.join(",") || "N/A"}</p>
                <div className={css`display: flex; justify-content: space-evenly;`}>
                    <Button onClick={() => openModal(question)} color="primary">Preview</Button>
                    <Button onClick={() => editQuestion(question.id)} color="primary">Edit</Button>
                    <Button onClick={() => deleteQuestion(question)} variant="contained"
                            className={css`background-color: #bf2626;
                                        color: white;
                                        :hover {
                                            background-color: #861b1b;
                                        }
                                        `}>Delete</Button>
                </div>
            </CardContent>
        </Card>
    </Grid>;
}

function SysadminQuestions({questions, editQuestion, deleteQuestion}) {
    const [open, setOpen] = useState(false);
    const [activeQuestion, setActiveQuestion] = useState(null);

    const handleClickOpen = (question) => {
      setOpen(true);
      setActiveQuestion(question);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return <>
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={true}
                maxWidth={"sm"}>
            <DialogContent dividers>
                <VisualQuestionCard question={activeQuestion}/>
            </DialogContent>
        </Dialog>
        <Grid container spacing={5} alignContent="center"
            className={css`margin: 0 auto; margin-top: 50px;`}>
            {questions.map((question) =>
                <SysadminQuestionCard
                            key={question.id}
                            question={question}
                            editQuestion={editQuestion}
                            deleteQuestion={deleteQuestion}
                            openModal={handleClickOpen}
                            />
            )}
        </Grid>
    </>;
}

export default SysadminQuestions;
