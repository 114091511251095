import React from 'react';
import { css } from '@emotion/css';

import i18n from 'util/i18n';

import Paper from '@material-ui/core/Paper';
import ArrowIcon from '@material-ui/icons/ArrowForward';

import LoadingIcon from 'components/util/LoadingIcon';

import Avatar from "@material-ui/core/Avatar";


const fallbackUser = {
    profile: {
        real_name: i18n.unknownUser,
        image_32: 'https://i.imgur.com/aflwVYZ.png',
    }
}


function UserProfile({ profile }) {
    return (<div className={css`
        display: flex;
        flex-direction: row;
        align-items: center;
    `}>
        <Avatar src={profile?.image_32} alt={profile?.real_name} className={css`
           height: 32px;
           width: 32px;
           margin-right: 15px;
           object-fit: contain;
        `}>{profile?.real_name} </Avatar>
        <div>
            <div className={css`
                color: hsl(230, 10%, 15%);
            `}>{profile?.real_name}</div>
            <div className={css`
                color: hsl(230, 10%, 40%);
                font-size: 12px;
            `}>{profile?.email}</div>
        </div>
    </div>);
}


function KudosEntry({ sender, receiver, timestamp, message }) {
    return <Paper className={css`
        max-width:100%;
        min-width: 600px;
        padding-top: 16px;
        margin-top: 20px;
        @media (max-width: 480px) {
            width: 100%;
            min-width: auto;
        }
    `}>
        <div className={css`
            font-size: 12px;
            color: hsl(230, 10%, 40%);
            margin-bottom: 16px;
            margin-left: 16px;
        `}>{timestamp.toLocaleString()}</div>
        <div className={css`
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: 20px 24px;
            overflow: auto;
            @media (max-width: 480px) {
                flex-wrap: wrap;
                gap: 16px;
            }
        `}>
            <UserProfile profile={sender.profile} />
            <ArrowIcon className={css`
                color: hsl(230, 50%, 60%);
                margin: 0 20px;
            `} />
            <div className={css`
                display: flex;
                flex-direction: column;
                gap: 16px;
            `}>
                {
                    Array.isArray(receiver)
                        ? receiver.map(r => (<UserProfile key={r?.id} profile={r?.profile} />))
                        : <UserProfile profile={receiver?.profile} />
                }
            </div>
        </div>
        <div className={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 16px 24px;

            background-color: hsl(230, 50%, 95%);
        `}>
            <div className={css`
                font-size: 32px;
                margin-right: 20px;
            `}>
                🎉
            </div>
            <div className={css`
                line-height: 1.5;
                color: hsl(230, 10%, 25%);
                max-width: 500px;
            `}>{message}</div>
        </div>
    </Paper>;
}


function KudosTable({ history, team }) {

    if (!team) { return <LoadingIcon />; }
    const teamMap = {};
    for (let u of team) {
        teamMap[u.id] = u;
    }
    if (!history) {
        return <Paper className={css`
            margin: 20px auto;
            max-width: 400px;
            padding: 24px;
            color: hsl(230, 10%, 25%);

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        `}>
            <div className={css`
                font-size: 32px;
                margin-right: 20px;
            `}>
                🎉
            </div>
            <div>
                <div>{i18n.nobodySentKudos}</div>
                <div>{i18n.beFirstOne}</div>
            </div>
        </Paper>
    }


    return <div className={css`
        display: flex;
        flex-direction: column;
        align-items: center;
    `}>
        {Object.values(history).sort(
            (a, b) => b.timestamp - a.timestamp
        ).map((kudos) => <KudosEntry
            key={kudos.id}
            sender={teamMap[kudos.from] || fallbackUser}
            receiver={
                !kudos?.to
                    ? [fallbackUser]
                    : typeof kudos.to === "string" ? [teamMap[kudos.to]] : kudos.to.map(id => teamMap[id])
            }
            timestamp={new Date(kudos.timestamp)}
            message={kudos.message}
        />)}
    </div>;
}

export default KudosTable;
