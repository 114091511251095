import { css } from "@emotion/css";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Slider from "@material-ui/core/Slider";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Modal from "react-modal";
import { useState } from "react";
import i18n from "util/i18n";
import { eventTags } from "util/constants/events";

const MARKS = [
  { value: 0, label: 'Free' },
  { value: 50, label: '$50' },
  { value: 100, label: '$100' },
  { value: 150, label: '$150' },
  { value: 200, label: '$200' },
  { value: 250, label: '$250' },
]

const EventFilterModal = ({
  isOpen,
  onClose,
  filters,
  toggleTag,
  setPrice,
  clear,
}) => {
  const [priceSliderEnabled, setPriceSliderEnabled] = useState(!isNaN(filters.price));
  const [lastPrice, setLastPrice] = useState(250);
  const tags = Object.values(eventTags);

  const handleTagChange = (_event, _newValue, action, option) => {
    if (action === "clear") {
      clear();
    } else {
      const { option: tag } = option;
      toggleTag(tag)();
    }
  };

  const togglePriceSlider = (event) => {
    const { checked } = event.target;
    setPriceSliderEnabled(checked);
    if (checked) {
      setPrice(lastPrice);
    } else {
      setPrice(NaN);
    }
  };

  const handlePriceChange = (event, price) => {
    event.stopPropagation();
    setLastPrice(price);
  };

  const handlePriceCommit = (event, price) => {
    event.stopPropagation();
    setPrice(price);
  }

  const clearFilters = () => {
    clear();
    setPriceSliderEnabled(false);
    setPrice(NaN);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldReturnFocusAfterClose={false}
    >
      <Grid container justifyContent="center">
        <div
          className={css`
            width: 50vw;
            min-height: 50vh;
            z-index: 9;
            padding: 2em;

            > div:not(first-of-type):not(.MuiAutocomplete-popper) {
              margin-top: 35px;
            }
          `}
        >
          <h2>{i18n.filterByTag}</h2>
          <Autocomplete
            multiple
            autoComplete
            disableCloseOnSelect
            options={tags}
            filterSelectedOptions
            value={filters.tag}
            onChange={handleTagChange}
            disablePortal
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={i18n.selectTagToFilter}
              />
            )}
          />
          <Grid container item justifyContent="space-between">
            <div>
              <h2>{i18n.filterByPricePerPerson}</h2>
              <small
                className={css`
                  display: block;
                  font-size: 9pt;
                  font-style: italic;
                  color: rgba(150, 150, 150, 1);
                `}
              >
                  {i18n.youCanUseArrowKeyToSetPrice}
              </small>
            </div>
            <Checkbox
              checked={priceSliderEnabled}
              onClick={togglePriceSlider}
            />
          </Grid>
          <Grid container item>
            <Slider
              disabled={!priceSliderEnabled}
              value={lastPrice}
              min={0}
              max={250}
              step={10}
              marks={MARKS}
              valueLabelDisplay="on"
              onChange={handlePriceChange}
              onChangeCommitted={handlePriceCommit}
            />
          </Grid>
        </div>
        <Grid container item justifyContent="flex-end">
            <Button color="primary" onClick={clearFilters} className={css`margin-right: 10px;`}>
                {i18n.clearFilters}
            </Button>
            <Button variant="contained" color="primary" onClick={onClose}>
                {i18n.showResult}
            </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default EventFilterModal;
