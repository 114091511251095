import React, { useEffect, useState } from "react";
import { css } from "@emotion/css";

import { useFirestore } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";

import { mq } from "components/util/Breakpoints";
import { loadChannels, loadKudosHistory, loadTeam, saveSettings } from "util/database/kudos";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import FormSection from "components/Forms/FormSection";
import { SubmitButton } from "components/Forms";

import KudosHistory from "components/Dashboard/Table/KudosTable";
import LoadingIcon from "components/util/LoadingIcon";
import i18n from "util/i18n";

import { KudosSettingsForm } from "./KudosSettingsForm";
import { GiftCardUpsell } from "./KudosGiftCardUpsell";
import { InsightsUpsell } from "./KudosInsightsUpsell";
import { InsightsComingSoon } from "./KudosInsightsComingSoon";
import AddToSlackButton, { SlackIntegration } from "components/util/AddToSlackButton";
import { withToastNotification } from "components/hocs/withToastNotification";
import { change } from "redux-form";

const KudosSettings = ({ toastNotification }) => {
    const firestore = useFirestore();
    const userData = useSelector((state) => state.userinfo);
    const companyInfo = useSelector((state) => state.userinfo?.company);
    const company = companyInfo || userData?.company;

    const form = useSelector((state) => state.form.kudosSettingsForm);
    const dispatch = useDispatch();

    const integrationRef = company?.kudosSlackRef;
    const integration = company?.kudosIntegrationInfo;

    const [team, setTeam] = useState(null);
    const [channels, setChannels] = useState(null);
    const [history, setHistory] = useState(null);

    const [loadingTeam, setLoadingTeam] = useState(false);
    const [loadingChannels, setLoadingChannels] = useState(false);
    const [loadingHistory, setLoadingHistory] = useState(false);

    const [tab, setTab] = useState(0);

    const hasIntegration = integration && integrationRef;

    if (company && !team && !loadingTeam && hasIntegration) {
        setLoadingTeam(true);
        loadTeam(integrationRef).then((t) => {
            setTeam(t.filter(u => !u.is_bot).sort((a, b) => ("" + a.profile?.email).localeCompare(b.profile?.email)));
        });
    }

    if (company && !loadingChannels && hasIntegration) {
        setLoadingChannels(true);
        loadChannels(integrationRef).then(setChannels);
    }

    if (company && !loadingHistory && hasIntegration) {
        setLoadingHistory(true);
        loadKudosHistory(integrationRef).then(setHistory);
    }

    // Initialize form
    useEffect(() => {
        if (channels === null || !hasIntegration) {
            return;
        }

        const allowedUsers = integration.allowedUsers || [];
        const allowedChannels = integration.allowedChannels || [];

        const allowedChannelsMap = [];
        for (let channel of channels) {
            if (allowedChannels.includes(channel.id))
                allowedChannelsMap.push(channel);
        }

        dispatch(change('kudosSettingsForm', 'allowedChannels', allowedChannelsMap));

        const allowedUsersMap = [];
        if (team) {
            for (let u of team) {
                if (allowedUsers.includes(u.id))
                    allowedUsersMap.push(u);
            }
            dispatch(change('kudosSettingsForm', 'allowedUsers', allowedUsersMap));
        }
        const announcementChannel = integration.announcementChannel || 'same';
        dispatch(change('kudosSettingsForm', 'announcementChannel', announcementChannel));

        dispatch(change('kudosSettingsForm', 'kudosLimit', integration.kudosLimit));


    }, [integration, channels, team, dispatch, hasIntegration]);

    // Normalize form values and save to DB
    async function handleSubmit() {
        const allowedChannels = form.values.allowedChannels.map(ch => ch.id);
        const allowedUsers = form.values.allowedUsers.map(u => u.id);

        let announcementChannel = form.values.announcementChannel;
        if (announcementChannel === "same") {
            announcementChannel = null;
        }

        const kudosLimit = Number(form.values.kudosLimit);

        await saveSettings(firestore, company.id, {
            allowedChannels,
            allowedUsers,
            announcementChannel,
            kudosLimit,
        }).then(() => toastNotification(i18n.kudosSettingsSaved, "success"));
    }

    if (!userData) {
        return <LoadingIcon />;
    }

    const isPremiumPlan = company?.kudosIntegrationInfo?.plan?.type === "premium";

    return (
        <div
            className={css`
                max-width: 800px;
                margin: 0 auto;
            `}
        >
            <h1
                className={css`
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    justify-content: center;
                    margin: 0;

                    ${mq[2]} {
                        font-size: 20px;
                    }
                `}
            >
                <img
                    src="/static/svg/slack.svg"
                    className={css`
                        height: 80px;
                        width: 80px;

                        ${mq[2]} {
                            width: 64px;
                            height: 64px;
                        }
                    `}
                    alt="Slack logo"
                />
                Kudos Slack Bot
            </h1>

            {hasIntegration && (
                <Tabs value={tab} onChange={(e, v) => setTab(v)} centered>
                    <Tab label="Settings" />
                    <Tab label="Insights" />
                </Tabs>
            )}

            {hasIntegration && channels ? ( // Form initialization resets if component is unmounted, so keep it mounted with display: none
                <div className="space-y-4 mt-8">
                    <div
                        className={css`
                            display: ${tab === 0 ? "initial" : "none"};
                        `}
                    >
                        {!isPremiumPlan && <GiftCardUpsell />}
                        <KudosSettingsForm channels={channels} users={team} isPremium={isPremiumPlan} />
                        <SubmitButton onClick={handleSubmit} />
                    </div>
                </div>
            ) : tab === 0 && (
                <FormSection label={i18n.addKudosBot} columns={1} description={i18n.addKudosBotDescription}>
                    <div className="mx-auto">
                        <AddToSlackButton slackIntegration={SlackIntegration.KUDOS} />
                    </div>
                </FormSection>
            )}

            {tab === 1 ? (
                <>
                    {isPremiumPlan ? <InsightsComingSoon /> : <InsightsUpsell />}
                    <KudosHistory history={history} team={team} />{" "}
                </>
            ) : null}
        </div>
    );
};

export default withToastNotification(KudosSettings);

