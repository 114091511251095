import React, { useState, useEffect } from 'react';
import { reduxForm, Field } from 'redux-form';
import { useSelector } from "react-redux";
import { useFirestore } from 'react-redux-firebase';
import { addVotingForm } from 'util/database/votes';
import { css } from '@emotion/css';

import LoadingIcon from 'components/util/LoadingIcon';
import { SubmitButton, renderDropdown } from 'components/Forms';
import i18n from "util/i18n";


// Validators
const required = v => {
    if (!v || v === '') {
        return i18n.thisFieldRequired;
    }
}

// Form to create a new survey to send to the specified employees
const VoteInviteForm = ({ eventsToPoll, closeModal, handleSubmit, valid, pristine, submitting, initialize, toastNotification }) => {
    const firestore = useFirestore();
    const form = useSelector((state) => state.form.voteInviteForm);

    const userInfo = useSelector(state => state.userinfo);
    const user = useSelector(state => state.firebase.auth);
    const companyInfo = useSelector(state => state.userinfo?.company)

    const [success, setSuccess] = useState(false); // Form submitted successfully (stored in Firestore)
    const [slackChannels, setSlackChannels] = useState({});

    // Load Slack channels in which the bot has been integrated
    useEffect(() => {
        if (userInfo.company.slackTeamRef !== undefined) {
            userInfo.company.slackTeamRef.get().then(doc => {
                const webhooks = doc.data().incoming_webhooks;

                // Map channel id to channel name
                const hookMap = Object.entries(webhooks).reduce(function (acc, hookInfo) {
                    acc[hookInfo[1].channel_id] = hookInfo[1].channel;
                    return acc;
                }, {});

                setSlackChannels(hookMap)
            });
        }
        if (companyInfo?.subscription?.type === 'Premium') {
            // setIsSubscribed(true);
            initialize({
                budget: {
                    oneCredit: true,
                },
            })
        }
    }, [userInfo.company.slackTeamRef, initialize, companyInfo]);

    // Helper function to filter object values based on a predicate
    Object.filter = function (obj, predicate) {
        let result = {}, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key) && predicate(obj[key])) {
                result[key] = obj[key];
            }
        }
        return result;
    };

    const onSubmit = async () => {
        // Get themes that are set to true (temporary, while still using renderCheckboxArray)
        let values = { ...form.values, eventIds: eventsToPoll.map(event => event.id) };

        // Send Slack team reference along with form
        if (values.sendToSlack === true) {
            values.slackTeamRef = userInfo.company.slackTeamRef;
        }

        if (!values.sendToSlack) {
            delete values.slackChannelID
            delete values.sendToSlack
        }

        let inviterName = `${userInfo.firstName} ${userInfo.lastName}`;

        // Send to Firestore (triggers sending the poll to Slack)
        await addVotingForm(firestore, inviterName, user, userInfo.company.id, values)
            .then(() => {
                setSuccess(true)
                toastNotification(`${i18n.successGetYourTeamVoting}🎉`, 'success')
                closeModal(true)
            })
            .catch(error => {
                toastNotification(i18n.errorR, 'error')
            })
    }
    // Shouldn't forget to add withToastNotification instead of CloseModal that doesn't close itself and requires a button to close
    // After withToastNotification functionality gets to "dev"

    return (
        <form onSubmit={handleSubmit(() => { })}>
            {eventsToPoll.length === 0 && <strong>Please select events to add to this poll. <br /><br /></strong>}
            {/*Only display Slack options if the company has integrated the Kudos Bot for Slack to their workspace */}
            {
                userInfo.company.slackTeamRef !== undefined &&
                <div className={css`
                        padding: 0px 20px 20px;
                        height: 76px;
                        display: grid;
                        grid-template-columns: 50% 50%;
                    `}
                >
                    <Field
                        name='slackChannelID'
                        component={renderDropdown}
                        menuItems={slackChannels}
                        label={i18n.selectASlackChannel}
                        validate={form?.values?.sendToSlack ? [required] : undefined}
                        onChange={() => { form.values.sendToSlack = true }}
                    />
                </div>
            }

            <p className={css`
                font-size: 14px;
                color: hsl(230, 10%, 30%);
                margin: -5px 5px 15px;
            `}>
                {i18n.slackIntegrationInfoMsg}
            </p>

            {/* <p className={css`
                font-size: 14px;
                color: hsl(230, 10%, 30%);
                margin-top: -5px;
                margin-bottom: 15px;
            `}>
                Notice:<br />
                We’ll send you a preview of the vote on your email and<br />
                ask for your confirmation before it goes out to the voters.
             </p> */}

            {
                !success ? // Show submit button or success message based on current state
                    <>
                        {!submitting ?
                            <SubmitButton
                                onClick={onSubmit}
                                disabled={!valid || pristine || submitting || eventsToPoll.length === 0}
                                label={i18n.inviteTeamToVote}
                            ></SubmitButton>
                            : <LoadingIcon />
                        }
                    </>
                    : <></>
            }
        </form >
    )
}

export default reduxForm({
    form: 'voteInviteForm',
    initialValues: {
        budget: {
            type: 'total',
        },
    }
})(VoteInviteForm);
