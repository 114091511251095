import React, {useEffect, useState} from 'react'
import {css} from '@emotion/css';
import {withRouter} from "react-router";
import Header from 'components/Header';
import {useFirestore} from "react-redux-firebase";
import CenteredLoadingIcon from 'components/util/CenteredLoadingIcon';
import VisualQuestionCard from "../../components/Dashboard/Questions/VisualQuestionCard";
import {change, reduxForm} from "redux-form";
import {SubmitButton} from "../../components/Forms";
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from "react-router-dom";
import {loadQuestionsOfEvent, loadUserAnswers, saveAnswers} from "../../util/database/questions";
import {loadEvent} from "../../util/database/events";
import i18n from "util/i18n";

const QuestionsForm = reduxForm({form: 'questionsForm'})(
    ({onSubmit, questions, disableSubmit}) => {
        return <div className={css`max-width: 100%`}>
            <form>
                {questions && questions.map(q => <VisualQuestionCard key={q.id} question={q}/>)}
            </form>
            <SubmitButton onClick={onSubmit} disabled={disableSubmit}/>
        </div>;
    }
);

const QuestionPage = () => {
    const history = useHistory();
    const params = new URLSearchParams(window.location.search);
    const eventId = params.get("eventId");
    const justSignedUp = params.get("justSignedUp");
    const userData = useSelector((state) => state.userinfo);
    const firestore = useFirestore();
    const [loading, setLoading] = useState(true);
    const [questions, setQuestions] = useState(null);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [currentEvent, setCurrentEvent] = useState(null);
    const questionsForm = useSelector((state) => state.form.questionsForm);
    const dispatch = useDispatch();

    useEffect(() => {
        if (loading && userData) {
            setLoading(false);
            loadQuestionsOfEvent(firestore, eventId).then(questions => {
                loadUserAnswers(firestore, questions.map(q => q.id), userData.userId).then(answers => {
                    setQuestions(questions);
                    answers.forEach(ans => {
                        dispatch(change('questionsForm', ans.questionId, ans.answer));
                    });
                });
            });

            loadEvent(firestore, eventId).then(setCurrentEvent);
        }

        // eslint-disable-next-line
    }, [userData, loading, eventId, firestore, dispatch, history]);

    const submitAnswers = () => {
        setDisableSubmit(true);
        saveAnswers(firestore, questionsForm.values, userData.userId).then(() => {
                if (justSignedUp) {
                    return history.replace({pathname: "/dashboard/home", state: {justSignedUp: true}});
                } else {
                    return history.push('/dashboard/home');
                }
            }
        );
    };

    return loading ? <CenteredLoadingIcon/> : (<>
        <Header/>
        <main className={css`
            padding: 100px 30px 30px;
            box-sizing: border-box;
            min-height: 100vh;
        `}>
            <div className={css`text-align: center; margin-top: 20px;`}>
                <img src="https://c.tenor.com/QNMMnGyi8CkAAAAC/get-excited-excited.gif" alt="minions get excited"
                     className={css`display: block;
                                      margin-left: auto;
                                      margin-right: auto;
                                      width: 30%;`}/>
                <p><em>{i18n.aFantasticVitualExp}</em></p>
            </div>
            {currentEvent &&
            <div className={css`text-align: center;`}>
                <h1>{i18n.questionsFor} {currentEvent.title}</h1>
                <p>{i18n.questionsDescription}</p>
            </div>}
            <div className={css`width: 610px; max-width: 100%; margin: auto;`}>
                <QuestionsForm questions={questions} onSubmit={submitAnswers} disableSubmit={disableSubmit}/>
            </div>
        </main>
    </>);
}

export default withRouter(QuestionPage);
