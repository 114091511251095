import React, {useState} from 'react';
import {css} from '@emotion/css';

import {useFirestore} from 'react-redux-firebase';

import Modal from 'react-modal';

import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {changeUserTeam} from 'util/database/users';
import i18n from "util/i18n";


function ChangeUserTeam({isOpen, onClose, info}) {
    const [newTeam, setNewTeam] = useState(null);

    const firestore = useFirestore();
    if (info === null) {
        return null;
    }

    const availableTeams = (info.availableTeams && [...info.availableTeams, 'None']) || ['None'];

    async function handleClick() {
        await changeUserTeam(firestore, info.id, newTeam === 'None' ? null : newTeam);
        onClose();
        window.location.reload();
    }

    return <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
    >
        <h2 className={css`
            margin: 0;
            font-weight: 500;
            font-size: 18px;
        `}>{i18n.changeTeam}</h2>
        <p className={css`
            color: hsl(230, 10%, 25%);
            font-size: 14px;
            margin-bottom: 30px;
        `}>{i18n.formatString(i18n.selectNewTeam,info.name)}</p>
        <FormControl component="fieldset">
            <FormLabel component="legend">New Team</FormLabel>
            <RadioGroup name="newTeam" defaultValue={info.team || 'None'} onChange={(event) => setNewTeam(event.target.value)}>
                {availableTeams.map(team => {
                    return <FormControlLabel key={team} value={team} control={<Radio/>} label={team}/>
                })}
            </RadioGroup>
        </FormControl>
        <Button disabled={!newTeam}
                onClick={handleClick}
                style={{
                    backgroundColor: 'hsl(350, 100%, 40%)',
                    color: 'white',
                }}
        >{i18n.changeTeam}</Button>
    </Modal>;
}

export default ChangeUserTeam;
