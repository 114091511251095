import i18n from "util/i18n";
import { eventThemes } from 'util/constants/events';
import pluralize from 'pluralize';

const defaultMininumParticipantCount = 5;

function generateTimeString(duration) {
    // If the event has an average duration, display that on the time string
    if (duration.avg) {
        return `~${duration.avg} ${i18n.minutes}`;
    }

    let currentString;

    if (duration.min && duration.max) {
        if (duration.min === duration.max) {
            currentString = duration.min;
        } else {
            currentString = duration.min + "-" + duration.max;
        }
    } else if (duration.min) {
        currentString = `${i18n.atLeast} ${duration.min}`;
    } else if (duration.max) {
        currentString = `${i18n.upTo} ${duration.max}`;
    } else {
        return i18n.unlimited;
    }

    currentString += ` ${i18n.minutes}`;

    return currentString;
}

function generateShortAudienceSize(suggestedAudienceSize, themes) {
    let currentString = `${i18n.atLeast} ${defaultMininumParticipantCount}`;

    if (themes.includes(eventThemes.VIRTUAL_ACTIVITIES)) {
        return i18n.unlimited;
    }

    const minIsNumber = Number.isInteger(suggestedAudienceSize.min);
    const maxIsNumber = Number.isInteger(suggestedAudienceSize.max);

    if (minIsNumber && maxIsNumber) {
        if (suggestedAudienceSize.min === suggestedAudienceSize.max) {
            currentString = `${suggestedAudienceSize.min} ${i18n.people}`;
        } else {
            currentString = `${suggestedAudienceSize.min}-${suggestedAudienceSize.max} ${i18n.people}`;
        }
    } else if (minIsNumber) {
        currentString = `${i18n.atLeast} ${suggestedAudienceSize.min}`;
    } else if (maxIsNumber) {
        currentString = `${defaultMininumParticipantCount}-${suggestedAudienceSize.max} ${i18n.people}`;
    }

    return currentString;
}

function generateLongAudienceSize(suggestedAudienceSize, themes) {
    let currentString = `${i18n.atLeast} ${defaultMininumParticipantCount}`;
    let determiner = defaultMininumParticipantCount; // Used to pluralize "participants"

    if (themes.includes(eventThemes.VIRTUAL_ACTIVITIES)) {
        currentString = i18n.unlimited;
        determiner = 999;
    }

    const minIsNumber = Number.isInteger(suggestedAudienceSize.min);
    const maxIsNumber = Number.isInteger(suggestedAudienceSize.max);

    if (minIsNumber && maxIsNumber) {
        determiner = suggestedAudienceSize.max;
        if (suggestedAudienceSize.min === suggestedAudienceSize.max) {
            currentString = `${suggestedAudienceSize.min}`;
        } else {
            currentString = `${suggestedAudienceSize.min}-${suggestedAudienceSize.max}`;
        }
    } else if (minIsNumber) {
        determiner = suggestedAudienceSize.min;
        currentString = `${i18n.atLeast} ${suggestedAudienceSize.min}`;
    } else if (maxIsNumber) {
        determiner = suggestedAudienceSize.max;
        currentString = `${defaultMininumParticipantCount}-${suggestedAudienceSize.max}`;
    }

    currentString += ` ${pluralize(i18n.participants, determiner).toLowerCase()}`;

    return currentString;
}

// Return [min, max] participant count bound
const getParticipantCountBounds = suggestedAudienceSize => {
    const { min: eventMin, max: eventMax } = suggestedAudienceSize;
    const minCount = Number.isInteger(eventMin) ? eventMin : defaultMininumParticipantCount;

    // If no max set for the event return -1, else return event max
    const maxCount = !Number.isInteger(eventMax)
        ? Number.MAX_VALUE
        : eventMax < eventMin ? eventMin : eventMax; // making sure returned max is greater than or equal to min

    return {
        min: minCount,
        max: maxCount
    };
}

// Get participant selection description based on bounds
const getParticipantSelectionDescription = (participantCountBounds) => {
    const { min, max } = participantCountBounds;

    const minIsNumber = Number.isInteger(min);
    const maxIsNumber = Number.isInteger(max);
    const unlimitedMax = max === Number.MAX_VALUE;

    if (minIsNumber && unlimitedMax) {
        return i18n.formatString(i18n.selectParticipantsMinOnly, min, pluralize(i18n.employee, min));
    }

    if (minIsNumber && maxIsNumber) {
        if (min === max) {
            return i18n.formatString(i18n.selectParticipantsFixed, min, pluralize(i18n.employee, min));
        }
        return i18n.formatString(i18n.selectParticipantsRange, min, max, pluralize(i18n.employee, max));
    }

    if (maxIsNumber) {
        return i18n.formatString(i18n.selectParticipantsMaxOnly, max, pluralize(i18n.employee, max));
    }

    return "";
}

export {
    generateTimeString,
    generateShortAudienceSize,
    generateLongAudienceSize,
    getParticipantCountBounds,
    getParticipantSelectionDescription
};
