export function getLocalCurrency({companyInfo}){
    if(companyInfo.localization) {
        return companyInfo.localization.currency;
    } else {
        return "CAD";
    }
}

export function getReplaceClassWithHome(userInfo){
    if (!userInfo?.companyInfo) {return false;}
    if (userInfo.companyInfo.localization) {
        return userInfo.companyInfo.localization.replaceHomeWithClasses;
    } else {
        return false;
    }
}
