import React from 'react';
import {css} from '@emotion/css';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';

import InfoBadge from 'components/Header/InfoBadge';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import i18n from "util/i18n";

const id = {
    Header: i18n.id,
    Cell: ({value}) => <tt className={css`
        font-size: 12px;
        color: hsl(200, 10%, 40%);
    `}>
        {value}
    </tt>,
    accessor: 'id',
};

const profileRenderer = ({value: {firstName, lastName, email, image}}) => (
    <div className={css`
        display: flex;
        flex-direction: row;
        align-items: center;
    `}>
        <Avatar src={image} alt={firstName} className={css`
           height: 40px;
           width: 40px;
           margin-right: 15px;
           object-fit: contain;
        `}>{`${firstName?.charAt(0).toUpperCase()}${lastName?.charAt(0).toUpperCase()}`} </Avatar>
        <div>
            <div className={css`
                color: hsl(230, 10%, 15%);
            `}>{firstName} {lastName}</div>
            <div className={css`
                color: hsl(230, 10%, 40%);
                font-size: 12px;
            `}>{email}</div>
        </div>
    </div>
);

const name = {
    Header: i18n.name,
    Cell: profileRenderer,
    accessor: 'profile',
};

const inviteCode = {
    Header: i18n.signUpCode,
    Cell: ({value: {code, timestamp}}) => <div className={css`
        display: flex;
        flex-direction: column;
        align-items: center;
    `}>
        <tt className={css`
        font-size: 14px;
        color: hsl(200, 10%, 15%);
    `}>
            {code}
        </tt>
        <span className={css`
            font-size: 12px;
            color: hsl(200, 10%, 40%);
            margin-top: 3px;
        `}>{i18n.formatString(i18n.usedOn, new Date(timestamp).toLocaleString())}</span>
    </div>,
    accessor: 'inviteCode',
};

const del = onDelete => ({
    Header: i18n.delete,
    Cell: ({value}) => <IconButton onClick={() => onDelete(value)}><DeleteIcon/></IconButton>,
    accessor: 'deleteInfo',
});

const changeRole = onChangeRole => ({
    Header: i18n.role,
    Cell: ({value}) => <InfoBadge info={value.role} onClick={() => onChangeRole(value)}/>,
    accessor: 'changeRoleInfo',
});

const changeTeam = onChangeTeam => ({
    Header: i18n.team,
    Cell: ({value}) => <InfoBadge info={value.team} onClick={() => onChangeTeam(value)}/>,
    accessor: 'changeTeamInfo',
});

const changeCompany = onChangeCompany => ({
    Header: i18n.affiliation,
    Cell: ({value}) => (<div className={css`
            display: flex;
            flex-direction: column;
            align-items: center;

            color: hsl(230, 10%, 20%);
            font-size: 11px;
            font-weight: 300;
            letter-spacing: 0.03em;
        `}>
            {value.availableCompanies && <Select
                defaultValue={value.user.company.id}
                onChange={(event) => onChangeCompany(value.id, event.target.value)}>
                {value.availableCompanies && Object.keys(value.availableCompanies).map(companyId => {
                    return <MenuItem key={companyId}
                                     value={companyId}>{value.availableCompanies[companyId].name}</MenuItem>
                })}
            </Select>}
        </div>
    ),
    accessor: 'changeCompany',
});

export {
    id, name, inviteCode, del, changeRole, changeTeam, changeCompany,
    profileRenderer,
};
