import React, { useState } from 'react';
import { css } from '@emotion/css';
import pluralize from 'pluralize';
import { useFirestore } from 'react-redux-firebase';

import LoadingIcon from 'components/util/LoadingIcon';

import { loadUsersFromCompany,
         loadUsersSearch } from 'util/database/users';

import { loadCompanies } from 'util/database/companies';

import {makeStyles,
        Tabs,
        Tab } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import UserTable from 'components/Dashboard/UserTable';
import TextField from '@material-ui/core/TextField';
import i18n from 'util/i18n';


// Admin user view.
function Users() {
    const firestore = useFirestore();
    const [users, setUsers] = useState(null);
    const [companies, setCompanies] = useState(null);

    const [searchValue, setSearchValue] = useState(null);
    const [buttonStatus, setButtonStatus] = useState('See Users');
    const [tabValue, setTabValue] = React.useState(0);

    const BarStyling = {width:"20rem",background:"#F2F1F9", border:"none", marginTop: '5px'};
    const ButtonStyling = {width:"250px", display: 'flex', justifyContent: 'space-between', border:"outlined", padding:"0.5rem", marginTop: '5px'};

    if (companies === null) {
        loadCompanies(firestore).then(setCompanies);
    }

    const useStyles = makeStyles({
        tabStyle: {
          fontSize: "32px",
          fontWeight: 700,
          textTransform: "capitalize",
          borderBottom: "3px solid #C4C4C4",
        },
      });
    const classes = useStyles();

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const searchAction = (searchValueParam) => {

        const firebaseLoading = (entry) => {
            setButtonStatus('Loading... Please wait')
            setUsers(entry)
            setButtonStatus("See Users")
        }

        if (tabValue === 0 ){
            loadUsersFromCompany(firestore, companies, searchValueParam).then((entry) => {
                setButtonStatus('Loading... Please wait')
                firebaseLoading(entry)
                setButtonStatus("See Users")
            })
        } else if (tabValue === 1){
            loadUsersSearch(firestore, companies, searchValueParam).then((entry) => {
                firebaseLoading(entry)
            })
        }
    }

    if (users?.length === 0) {
        return <LoadingIcon />
    }

    return <>
        <Tabs
            value={tabValue}
            onChange={handleTabChange}
            className={css`
            margin-top: 30px;
            margin-bottom: 15px;
            font-size: 20px;
            line-height: 25px;
            text-align: center;
            `}
        >
            <Tab label={i18n.company} className={classes.tabStyle} />
            <Tab label={i18n.users} className={classes.tabStyle} />
        </Tabs>
        <h1 className={css`
            font-size: 24px;
            font-weight: 500;
            color: hsl(235, 10%, 15%);
            margin: 0;
        `}>Manage Users</h1>
        <div className={css`columns: 1`}>
                <TextField
                    fullWidth
                    id="recoveryEmail"
                    name="email"
                    style={BarStyling}
                    label="Search"
                    variant="outlined"
                    // value = {searchValue}
                    onChange={(event) => setSearchValue(event?.target?.value)}
                />
                {<div><Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    size="large"
                    style = {ButtonStyling}
                    type="submit"
                    onClick={() => {searchAction(searchValue)}}>
                    {buttonStatus}</Button>
                </div>}
            </div>
        {users && <p className={css`
            margin: 0;
            color: hsl(200, 10%, 30%);
            font-size: 16px;
            margin-top: 20px;
            margin-bottom: 50px;
        `}>{pluralize('user', Object.keys(users).length, true)} registered</p>}
        {users && <UserTable users={users}/>}
    </>;
}

export default Users;
