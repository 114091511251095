import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { mq } from "../util/Breakpoints";

import { css } from "@emotion/css";

import User from "./User";
import LanguageChanger from "./languageChanger";
import i18n from "util/i18n";

const SubscriptionType = () => {
    const companyInfo = useSelector((state) => state.userinfo?.company);

    let subscriptionName = "";

    if (companyInfo) {
        if (!companyInfo.subscription.name) {
            subscriptionName = i18n.notSpecified;
        } else {
            subscriptionName = companyInfo.subscription.name;
        }
    }

    return (
        <>
            {subscriptionName !== "" && (
                <div
                    className={css`
                        border-radius: 80px;
                        padding: 6px 15px;
                        background-color: hsl(235, 100%, 57%);
                        color: white;
                        font-size: 12px;
                        font-weight: 600;
                        text-transform: uppercase;
                        letter-spacing: 0.07em;

                        ${mq[0]} {
                            margin-left: 5px;
                        }

                        ${mq[1]} {
                            margin-right: 35px;
                        }
                    `}
                >
                    {subscriptionName}
                </div>
            )}
        </>
    );
};

function Header({ hideSignIn }) {
    const history = useHistory();
    return (
        <>
            <header
                id="site-header"
                className={css`
                    border-bottom: 12px solid #2636ff;
                    padding: 10px 40px;

                    min-height: ${hideSignIn ? "40px" : "80px"};
                    background-color: white;

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    position: fixed;

                    left: 0;
                    right: 0;
                    z-index: 1250;
                `}
            >
                <img
                    src="/static/images/logo.png"
                    className={css`
                        ${mq[0]} {
                            height: 16px;
                        }
                        ${mq[1]} {
                            height: 30px;                          
                        }
                    `}
                    style={{ cursor: "pointer" }}
                    alt="wfhomie logo"
                    onClick={() => history.push("/")}
                />
                <div
                    className={css`
                        flex-grow: 1;
                    `}
                />
                <div
                    className={css`
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    `}
                >
                    <LanguageChanger />
                    <SubscriptionType />
                    <User hideSignIn={hideSignIn} />
                </div>
            </header>
        </>
    );
}

export default Header;
