export const permissions = {
    HOME: 'home',
    CLASSES: 'classes',
    EVENTS: 'events',
    GIFTING: 'gifting',
    ACTIVITIES: 'activities',
    ANALYTICS: 'analytics',
    COMPANY_PROFILE: 'companyProfile',
    INVOICES: 'invoices',
    SETTINGS: 'settings',
    MANAGE_TEAM: 'manageTeam',
    BOOK_EVENT: 'bookEvent',
    KUDOS_BOT: 'kudosBot',
    USE_CREDIT: 'useCredit',
}

export const managerPermissions = {
    MANAGE_COMPANIES: 'manageCompanies',
    MANAGE_USERS: 'manageUsers',
    CLIENT_EVENT_DB: 'clientEventDB',
}

export const defaultSuperuserPermissions =[
    permissions.HOME,
    permissions.CLASSES,
    permissions.EVENTS,
    permissions.BOOK_EVENT,
    permissions.ACTIVITIES,
    permissions.ANALYTICS,
    permissions.COMPANY_PROFILE,
    permissions.INVOICES,
    permissions.SETTINGS,
    permissions.MANAGE_TEAM,
    permissions.USE_CREDIT,
];

export const defaultSuperuserPermissionsForKudos =[
    permissions.HOME,
    permissions.COMPANY_PROFILE,
    permissions.INVOICES,
    permissions.SETTINGS,
    permissions.MANAGE_TEAM,
    permissions.KUDOS_BOT
];

export const defaultEmployeePermissions =[
    permissions.HOME,
    permissions.CLASSES,
    permissions.EVENTS,
    permissions.ACTIVITIES,
    permissions.SETTINGS,
];

export const defaultEmployeePermissionsForKudos =[
    permissions.HOME,
    permissions.SETTINGS,
];

export const specialRoles = {
    PENDING: 'pending',
    SUPERUSER: 'superuser',
    SYSADMIN: 'sysadmin',
};
