import React from 'react';
import { css } from '@emotion/css';

import Button from '@material-ui/core/Button';

import CheckmarkIcon from '@material-ui/icons/Done';
import XIcon from '@material-ui/icons/Close';
import DownloadIcon from '@material-ui/icons/GetApp';
import PayIcon from '@material-ui/icons/Payment';
import i18n from "util/i18n";


function InvoiceLine({data, bold}) {
    const {amount, currency, description} = data;
    return <div className={css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        margin: 2px 0;

        color: hsl(200, 10%, 15%);
        font-weight: ${bold ? 700 : 500};
    `}>
        <span>{description}</span>
        <span className={css`
            color: hsl(200, 10%, 30%);
            font-size: 18px;
        `}>
            <span className={css`
                position: relative;
                bottom: 0.2em;
                margin-right: 0.1em;
                font-size: 0.8em;
            `}>$</span>
            <span>{(amount/100).toFixed(2)}</span>
            <span className={css`
                font-size: 0.8em;
                text-transform: uppercase;
                margin-left: 0.5em;
            `}>{currency}</span>
        </span>
    </div>;
}

function PaymentStatus({paid, dueDate}) {
    const dueDateStr = dueDate ? `Due on ${(new Date(dueDate * 1e3)).toLocaleDateString()}` : (
        paid ? 'Thanks!' : null
    );
    return <div className={css`
        display: flex;
        align-items: center;

        border-radius: 8px;
        background-color: hsl(${paid ? 100 : 0}, 70%, 90%);

        color: hsl(${paid ? 100 : 0}, 30%, 30%);
        font-size: 12px;
        padding: 8px 12px;

        margin-left: auto;
    `}>
        {paid ? <CheckmarkIcon/> : <XIcon/>}
        <div className={css`
            margin: 0 12px;
        `}>
            <div className={css`
                font-weight: 700;
            `}>{paid ? i18n.paymentReceived : i18n.paymentNotReceived}</div>
            <div className={css`
                font-size: 10px;
            `}>{dueDateStr}</div>
        </div>
    </div>;
}



function Invoice({invoice}) {
    return <div className={css`
        background-color: white;
        border-radius: 8px;
        box-shadow: 1px 1px 4px hsla(200, 10%, 10%, 0.1);
        padding: 40px;
    `}>
        <div className={css`
            display: flex;
            flex-direction: row;
            align-items: start;
            justify-content: space-between;

            margin-bottom: 32px;
        `}>
            <div className={css`
                margin: 0;
                font-size: 12px;
                color: hsl(235, 15%, 40%);
            `}>
                <h3 className={css`
                    margin: 0;

                    font-size: 14px;
                    color: hsl(235, 70%, 57%);
                    font-weight: 700;

                    letter-spacing: 0.07em;
                    text-transform: uppercase;
                `}>{i18n.recurringSubscription}</h3>
                <div>{i18n.formatString(i18n.invoiceNumber,invoice.number)}</div>
                <div>{i18n.formatString(i18n.sentToEmail, invoice.customer_email)}</div>
            </div>
            <PaymentStatus paid={invoice.paid} dueDate={invoice.due_date}/>
        </div>

        {invoice.lines.data.filter(
            line => line.object === 'line_item'
        ).map(
            line => <InvoiceLine key={line.id} data={line}/>
        )}
        <div className={css`
            border-top: 1px solid hsl(200, 10%, 70%);
            margin: 5px 0;
        `}/>
        <InvoiceLine data={{
            amount: invoice.subtotal,
            currency: invoice.currency,
            description: i18n.subtotal,
        }}/>
        <InvoiceLine data={{
            amount: invoice.total,
            currency: invoice.currency,
            description: i18n.total,
        }} bold/>
        <div className={css`
            display: flex;
            align-items: center;
            justify-content: end;
            margin-top: 32px;
        `}>
            <Button
                onClick={() => {window.open(invoice.invoice_pdf, '_blank');}}
                startIcon={<DownloadIcon/>}
            >{i18n.downloadPDF}</Button>
            {invoice.paid ? null : <Button
                onClick={() => {window.open(invoice.hosted_invoice_url, '_blank');}}
                startIcon={<PayIcon/>}
                variant='contained'
                color='primary'
                className={css`margin-left: 16px;`}
            >{i18n.payNow}</Button>}
        </div>
    </div>;
}


export default Invoice;
