import { Dialog } from "@headlessui/react";
import FilledButton from "components/util/FilledButton";
import { useState } from "react";
import mixpanelActions from "util/analytics/mixpanel";
import { eventProperties, mixpanelEvents } from "util/constants/mixpanel";
import i18n from "util/i18n";

// Adds a hidden modal next to the wrapped component and passes a modal trigger function to open the modal.
export const withGameModal = (
    WrappedComponent
) => (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [gameInfo, setGameInfo] = useState({});

    // The links to host and join games are sent as inputs to the trigger function
    const openModal = (gameInfo) => {
        setGameInfo(gameInfo);
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
        setGameInfo({});
    }

    const { eventId, gameTitle, gameDescription, gameInstructions, hostGameLink, joinGameLink } = gameInfo;

    return <>
        <WrappedComponent {...props} openGameModal={openModal} />
        <Dialog open={isOpen} onClose={closeModal} className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

                <div className="relative bg-white rounded max-w-md mx-auto p-10 relative">
                    <div className="space-y-6 flex flex-col">
                        {gameTitle &&
                            <Dialog.Title className="text-center font-bold text-lg">{gameTitle}</Dialog.Title>
                        }
                        {gameDescription &&
                            <Dialog.Description className="text-center">{gameDescription}</Dialog.Description>
                        }
                        {gameInstructions &&
                            <Dialog.Description>{gameInstructions}</Dialog.Description>
                        }
                        <div className="flex flex-row gap-3">
                            {hostGameLink &&
                                <FilledButton
                                    onClick={() => {
                                        // Track button click on Mixpanel
                                        mixpanelActions.track(mixpanelEvents.CROWDPARTY_HOST_GAME_CLICK, {
                                            [eventProperties.EVENT_ID]: eventId,
                                            [eventProperties.EVENT_TITLE]: gameTitle,
                                        });

                                        window.open(hostGameLink, "_blank", "noreferrer");
                                    }}
                                >
                                    {i18n.hostAGame}
                                </FilledButton>
                            }
                            {joinGameLink &&
                                <FilledButton
                                    onClick={() => {
                                        // Track button click on Mixpanel
                                        mixpanelActions.track(mixpanelEvents.CROWDPARTY_JOIN_GAME_CLICK, {
                                            [eventProperties.EVENT_ID]: eventId,
                                            [eventProperties.EVENT_TITLE]: gameTitle,
                                        });

                                        window.open(joinGameLink, "_blank", "noreferrer");
                                    }}
                                >
                                    {i18n.joinAGame}
                                </FilledButton>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    </>
}
