import React, {useEffect, useState} from 'react';
import {useFirestore} from "react-redux-firebase";
import {Card, CardContent} from "@material-ui/core";
import LoadingIcon from "../../../components/util/LoadingIcon";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { css } from '@emotion/css';
import Checkbox from '@material-ui/core/Checkbox';

const cardStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '20px',
    flex: 1,
};

function Localization({companyInfo}){
    const [companyLocalization, setCompanyLocalization] = useState(null);
    const [usedDefault, setUsedDefault] = useState(false);
    const firestore = useFirestore();

    const companyRef = firestore.collection('companies').doc(companyInfo.id);
    const currencies = ["CAD", "USD"];
    const defaultLocalization = {currency: "CAD", replaceHomeWithClasses: false};

    useEffect(() => {
        if(companyLocalization !== null) {
            (async () => {
                await companyRef.set({...companyInfo, localization: companyLocalization})}
            )();
        }
    }, [companyLocalization, companyRef, companyInfo])

    const loadCompanyLocalization = async () => {
        const loadedData = (await companyRef.get()).data().localization;
        if(loadedData === undefined || (Object.keys(loadedData).length === 0)) {
            setUsedDefault(true);
            setCompanyLocalization(defaultLocalization);
        } else {
            setUsedDefault(false);
            setCompanyLocalization(loadedData);   
        }
    };

    if (companyLocalization === null) {
        loadCompanyLocalization();
    }

    const onLocalizationChange = async (key, value) => {
        let newCompanyLocalization = {...companyLocalization}
        newCompanyLocalization[key] = value;
        setCompanyLocalization(newCompanyLocalization);
    }
  
    return !companyInfo ? <LoadingIcon/> : (
        <>
        {usedDefault && 
            <Card style={cardStyle}>
                <CardContent>
                <h3 className={css`color: red;`}>This company has not had localization set, so default settings were used and saved to the database. 
                    If the desired settings are different, please change them.</h3>
                </CardContent>
            </Card>
        }
        {companyLocalization !== null && 
            <div className={css`display: flex;`}>
                <Card style={cardStyle}>
                    <CardContent>
                        <h2>Display Values</h2>
                        
                        <div className={css`>div{margin-left: 10px;}`}>
                            Currency <Select labelId="dropdown-label" label={"Currency Dropdown"} 
                                    value={companyLocalization.currency} 
                                    onChange={(e) => onLocalizationChange("currency", e.target.value)}>
                            {currencies.map((currency) =>
                                <MenuItem key={"currency " + currency} value={currency}>{currency}</MenuItem>
                            )}
                            </Select>
                        </div>

                    </CardContent>
                </Card>
            <Card style={cardStyle}>
                <CardContent>
                    <h2>Page Customizations</h2>
                    Replace Home with Classes <Checkbox checked={companyLocalization.replaceHomeWithClasses} 
                              onChange={(e) => onLocalizationChange("replaceHomeWithClasses", e.target.checked)}/>
                </CardContent>
            </Card>
        </div>
    }
    </>);
}

export default Localization;
