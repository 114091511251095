import React from "react";
import { css } from "@emotion/css";
import EventCard from "./EventCard";
import i18n from "util/i18n";
import pluralize from "pluralize";

const FilteredEventList = ({exists, filters, events, currentThemes, addEventToPoll,
                            allowPolling, currentEventsToPoll}) => {

    const eventFilter = (filters) => (events, currentThemes) => {
      if (filters === {} || !events) {
          return events;
      }

      if (currentThemes.length > 0) {
          events = events.filter((ev) =>
              currentThemes.some(item => ev.themes.indexOf(item) >= 0)
          );
      }

      if (exists(filters.tag)) {
          events = events.filter((ev) =>
            filters.tag.some((tag) => ev.tags.includes(tag))
          );
      }

      if (exists(filters.price)) {
          events = events.filter((ev) =>
              ev.pricePerPerson <= filters.price
          );
      }

      if (filters.title && exists(filters.title)) {
          events = events.filter((ev) =>
              ev.title.toLowerCase().includes(filters.title.toLowerCase())
          );
    }

      return events;
  };

  const stateFilter = eventFilter(filters, currentThemes);
  const filteredEvents = stateFilter(events, currentThemes);

  return (
    <>
    {filteredEvents.length === 0 ?
        <h2>
            {i18n.noEventFoundMsg}
        </h2> :
        <h2 className={css`font-size: 20px; font-weight: 400; color: hsl(235, 10%, 15%); margin: 0;`}>
          {`${i18n.basedOnYourSearch} (${filteredEvents.length} ${pluralize(i18n.result, filteredEvents.length)})`}
        </h2>
      }
      <div className={css`display: grid; grid-template-columns: repeat(auto-fit, 280px); grid-gap: 20px; margin: 30px 0;`}>
        {filteredEvents.map((entry, i) => (
          <EventCard key={i} {...entry} addEventToPoll={addEventToPoll} allowPolling={allowPolling}
                      checked={currentEventsToPoll?.some(({id}) => id === entry.id)}/>
        ))}
      </div>
    </>
  );
};

export default FilteredEventList;
