import React, { useEffect, useState } from "react";
import { css } from "@emotion/css";
import { useHistory, withRouter } from "react-router-dom";

import Link from "@material-ui/core/Link";
import EventCard from "./EventCard";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { loadEventsByTheme } from "util/database/events";
import { useFirestore } from "react-redux-firebase";

import { mq } from "../../util/Breakpoints";

import i18n from "util/i18n";
import CenteredLoadingIcon from "../../util/CenteredLoadingIcon";
import { eventThemes } from "util/constants/events";

const changeTitleToExploreArr = [
    {
        givenName: eventThemes.MOST_POPULAR,
        newName: i18n.exploreEvents,
        routeName: "events",
    },
    {
        givenName: eventThemes.VIRTUAL_ACTIVITIES,
        newName: i18n.exploreActivities,
        routeName: "activities",
    },
    {
        givenName: eventThemes.CROWDPARTY_GAMES,
        newName: i18n.exploreActivities,
        routeName: "activities",
    },
];

function EventCategory({
    name,
    isHomePage,
    addEventToPoll,
    allowPolling,
    currentEventsToPoll,
    handleEventEdit,
    handleDeleteEvent,
    sysadmin,
}) {
    const [events, setEvents] = useState(null);

    const firestore = useFirestore();
    const history = useHistory();

    useEffect(() => {
        loadEventsByTheme(firestore, name, isHomePage ? 6 : -1).then(setEvents);
    }, [firestore, isHomePage, name]);

    const currentTitle = changeTitleToExploreArr.find((x) => x.givenName.toLowerCase() === name.toLowerCase());

    const onClick = () => {
        history.push(`/dashboard/${currentTitle.routeName}`);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {(events === null || events.length > 0) && <>
                <div
                    className={css`
                        display: flex;
                        align-items: flex-end;
                        justify-content: space-between;
                        width: 100%;
                    `}
                >
                    <h1
                        className={css`
                            color: hsl(235, 10%, 15%);
                            margin: 0;
                            margin-top: 48px;

                            &:nth-of-type(2) {
                                margin-top: 0;
                            }
                        `}
                    >
                        {isHomePage && currentTitle ? currentTitle.newName : name}
                    </h1>
                    {isHomePage && (
                        <Link
                            component="button"
                            variant="body2"
                            className={css`
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: grey;
                                font-weight: 400;
                            `}
                            onClick={onClick}
                        >
                            {i18n.seeMore}
                            <ArrowRightIcon />
                        </Link>
                    )}
                </div>
                {events && events.length > 0 ? (
                    <div
                        className={css`
                            display: grid;
                            grid-template-columns: repeat(auto-fit, 280px);
                            grid-gap: 20px;
                            margin: 30px 0;

                            ${mq[0]} {
                                justify-content: center;
                            }

                            ${isHomePage &&
                            `
                grid-template-rows: repeat(1, 1fr);
                grid-auto-rows: 0;
                column-gap: 20px;
                row-gap: 0px;
                `}
                        `}
                    >
                        {events.map((entry) => (
                            <EventCard
                                entry={entry}
                                id={entry.id}
                                key={entry.id}
                                {...entry}
                                addEventToPoll={addEventToPoll}
                                allowPolling={allowPolling}
                                checked={currentEventsToPoll?.some(({ id }) => id === entry.id)}
                                handleEventEdit={handleEventEdit}
                                handleDeleteEvent={handleDeleteEvent}
                                sysadmin={sysadmin}
                            />
                        ))}
                    </div>
                ) : (
                    <div
                        className={css`
                            display: block;
                            width: 100%;
                            height: 300px;
                            margin: 30px 0;
                        `}
                    >
                        <CenteredLoadingIcon />
                    </div>
                )}
            </>}
        </>
    );
}

function EventDashboard({
    isHomePage,
    currentThemes,
    addEventToPoll,
    allowPolling,
    currentEventsToPoll,
    handleEventEdit,
    handleDeleteEvent,
    sysadmin,
}) {
    return (
        <>
            {currentThemes.map((theme) => (
                <EventCategory
                    key={theme}
                    name={theme}
                    isHomePage={isHomePage}
                    addEventToPoll={addEventToPoll}
                    allowPolling={allowPolling}
                    currentEventsToPoll={currentEventsToPoll}
                    handleEventEdit={handleEventEdit}
                    handleDeleteEvent={handleDeleteEvent}
                    sysadmin={sysadmin}
                />
            ))}
        </>
    );
}

export default withRouter(EventDashboard);
