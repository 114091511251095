import React from "react";
import { Panels } from "./Panels";
import {ShameButtonDark} from "components/Forms/Auth/AuthButton";
// import { Pricing } from "./Pricing";
import { Testimonial } from "./Testimonial";

const testimonials = [
    {
        description:
            "Failure is growth. Failure is learning. But sometimes failure is just failure. Shamebot makes it easy to communicate how deeply they failed me.",
        name: "Gavin Belson",
        image: "/static/images/belson.png",
    },
    {
        description:
            "Sometimes I feel like everyone I work with is an idiot, and by sometimes I mean all times. Shame bot is there for me to instantly tell everyone how I feel. 100% recommend it if you work in an office full of idiots.",
        name: "Kevin Malone",
        image: "/static/images/kevin.jpeg",
    },
];

export const KudosLandingPageExtendedContent = () => {
    return (
        <>
            <div className="flex relative">
                <img className="w-full h-24 md:h-auto" src="/static/svg/green-wave.svg" alt="Decorative green wave" />
                <div className="absolute inset-x-0 top-0 z-0 -bottom-10 w-full">
                    <div className="flex flex-col items-center justify-center space-y-6 lg:p-20 p-4">
                        <span className="z-10 text-kudos-dark-blue font-bold text-md lg:text-3xl text-center">
                            40% of people put more energy into their work when someone points out their mistakes and shames them in front of others.
                        </span>
                        <span className="z-10 text-kudos-dark-blue font-bold text-3xl text-center hidden lg:block">
                            Use your team's insecurities to make them work harder! 😎
                        </span>
                    </div>
                </div>
            </div>

            <Panels />

            {/*<div className="flex flex-col items-center p-8 space-y-8">*/}
            {/*    <span className="text-primary font-bold leading-relaxed lg:leading-relaxed text-2xl lg:text-5xl">*/}
            {/*        The simplest way to boost team morale*/}
            {/*    </span>*/}

            {/*    <span>Build a culture of feedback and celebrate your team wins with a public praise.</span>*/}

            {/*    <SlackButtonDark />*/}
            {/*</div>*/}

            <div className="flex justify-center p-16 space-y-12 md:space-x-6 lg:space-y-0 bg-kudos-blue-bg flex-wrap items-center">
                {testimonials.map((t) => (
                    <Testimonial {...t} />
                ))}
            </div>

            {/*<Pricing />*/}


            <div className="flex flex-col items-center p-8 space-y-8">
               <span className="text-primary font-bold leading-relaxed lg:leading-relaxed text-2xl lg:text-5xl">
                   How it works
               </span>

               <iframe width="560" height="315" src="https://www.youtube.com/embed/5n5k9JQOnTg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            </div>

            <div className="flex flex-col items-center p-16 space-y-6 bg-kudos-green-bg">
                <span className="text-kudos-dark-blue font-bold leading-relaxed text-xl lg:text-3xl">
                    Bring shame to the workplace! 🤩
                </span>
            </div>


            <div className="flex w-full">
                <img className="" src="/static/images/shame-bottom.png" alt="People having fun on a virtual event" />
            </div>

            <div className="flex flex-col items-center p-16 space-y-6 bg-kudos-pink">
                <span className="text-white text-3xl">Start shaming your teammates for free!</span>

                <div className="flex md:space-x-8 md:space-y-0 flex-wrap justify-center space-y-4">
                    <ShameButtonDark />
                    <a
                        className="h-1"
                        href="https://www.producthunt.com/posts/shamebot-by-wfhomie-1?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-shamebot-by-wfhomie-1"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            className="h-12"
                            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=338658&theme=dark"
                            alt="ShameBot&#0032;by&#0032;WFHomie - Publicly&#0032;shame&#0032;your&#0032;teammates&#0032;on&#0032;Slack&#0032;like&#0032;the&#0032;deserve&#0033; | Product Hunt"
                            width="250"
                        />
                    </a>
                </div>
            </div>
        </>
    );
};
