import * as yup from "yup";
import {useFormik} from "formik";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import i18n from "../../../util/i18n";
import {Link} from "react-router-dom";

function AuthenticationForm({onSubmit, action, params}) {
    const redirectString = `/signup?${decodeURIComponent(params.toString())}`;
    const forgotString = `/forgot?${decodeURIComponent(params.toString())}`;;

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: yup.object().shape({
            email: yup.string().email().required(),
            password: yup.string().min(8).required(),
        }),
        onSubmit: onSubmit,
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
                fullWidth
                id="password"
                name="password"
                label="Password"
                type="password"
                variant="outlined"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
            />
            <Button
                color="primary"
                variant="contained"
                fullWidth
                size="large"
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
            >
                {i18n.signIn}
            </Button>
            <p>
                {i18n.dontHaveAnAccount} <Link to={redirectString}>{i18n.signUp}</Link>
            </p>
            <p>
                {i18n.forgotPassword} <Link to={forgotString}>{i18n.recoverPassword}</Link>
            </p>
        </form>
    );
}

export default AuthenticationForm;
