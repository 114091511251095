import { initializeUser } from 'util/database/invites';

async function createUser(
    formValues,
    inviteData,
    user,
    firebase,
    firestore
) {
    const {
        signInOption,
        password,
        firstName,
        lastName,
        email,
        profileImage,

    } = formValues;


    let userData = user;
    if (signInOption === 'password') {
        userData = await firebase.auth().createUserWithEmailAndPassword(email, password).then(res => res.user).catch(err => {
            if (err.code === 'auth/email-already-in-use') {
                return firebase.auth().signInWithEmailAndPassword(email, password).then(res => res.user).catch(err2 => {
                    throw new Error("This email address is already used");
                });
            } else {
                throw new Error(err.message);
            }
        });
    }
    if (!userData) return;

    let fn = firstName, ln = lastName;

    if (!fn || !ln) {
        // Attempt to get the display name from the user's auth information
        const displayName = userData?.displayName || userData?.providerData?.[0]?.displayName || userData?.email?.split('@')?.[0] || "User";

        // Extract first name and last name from display name
        let fullNameArr = displayName.split(' ')
        if (fullNameArr.length > 1) {
            fn = fullNameArr.slice(0, -1).join(' '); // All elements but last
            ln = fullNameArr.slice(-1).join(' '); // Last element
        } else {
            fn = displayName;
            ln = ''
        }
    }

    await initializeUser(firestore, userData, {
        firstName: fn,
        lastName: ln,
        email: email || userData.email,
        address: '',
        apartment: '',
        phone: '',
        image: user.photoURL || profileImage,
        dietaryRestrictions: {},
        ...inviteData,
    });
}

export { createUser };
