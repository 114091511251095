import React from 'react'
import { css } from '@emotion/css';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from "react-router";
import Header from 'components/Header';
import GiftOrderingResult from './GiftOrderingResult';

const GiftingPage = () => {
    return <>
        <Header />
        <main className={css`
            padding: 100px 30px 30px;
            box-sizing: border-box;
            min-height: 100vh;
        `}>
            <div className={css`width: 610px; margin: auto;`}>
                <Switch>
                    <Route path='/gifting/order/success'>
                        <GiftOrderingResult success={true}/>
                    </Route>
                    <Route path='/gifting/order/fail'>
                        <GiftOrderingResult success={false}/>
                    </Route>
                    <Route path='/gifting/order/credit_success'>
                        <GiftOrderingResult success={true}/>
                    </Route>
                </Switch>
            </div>
        </main>
    </>;
}

export default withRouter(GiftingPage);
