import React, {useEffect, useState} from "react";
import {css} from "@emotion/css";
import {Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@material-ui/core";
import GiftingCards from "./GiftingCards";
import MemberInvite from "../Events/MemberInvite";
import {useFirebase} from 'react-redux-firebase';
import {useStripe} from '@stripe/react-stripe-js';
import {handleGiftPackageOrder} from 'util/database/gifts';
import i18n from "../../../util/i18n";
import {useSelector} from "react-redux";

function getSteps() {
    return ['Package Selection', 'Customization', 'Select Giftees', 'Finalize Order'];
}

function GiftingPackageStepContent({giftPackages, currentTrigger, activeStep, disableNextStep}) {
    const [disable, handleChange] = useState(false);
    const [buttonText, setButtonText] = useState(i18n.purchase)
    const [currentPackage, setCurrentPackage] = useState(null);
    const [customizedItems, setCustomizedItems] = useState({});
    const [selectedGiftees, setSelectedGiftees] = useState([]);
    const firebase = useFirebase();
    const stripe = useStripe();
    const userData = useSelector((state) => state.userinfo);

    useEffect(() => {
        setCustomizedItems({});
    }, [currentPackage])

    const changeText = (text) => setButtonText(text);

    const handleSubmit = async(purchase) => {
        const info = {
            orderer: userData,
            package: currentPackage,
            items: customizedItems,
            giftees: selectedGiftees,
            pay: purchase
        };
        info.giftees.forEach(giftee => delete giftee.company);
        delete info.orderer.company;
        handleGiftPackageOrder(firebase, info,
            result => result.data.location ? window.location = result.data.location :
                stripe.redirectToCheckout({sessionId: result.data.stripeSessionId})
            , console.error);
    };

    if (!giftPackages) {
        return <></>;
    }

    switch (activeStep) {
        case 0:
            disableNextStep(currentPackage === null);
            return <>
                <GiftingCards giftingPacks={giftPackages} setCurrentPackage={setCurrentPackage}
                              selectedPackage={currentPackage}/>
            </>
        case 1:
            disableNextStep(customizedItems && currentPackage.packageContains.length !== Object.keys(customizedItems).length);

            const handleCustomizedChange = (event, key, autoAdd) => {
                if (autoAdd) {
                    setCustomizedItems(prevState => ({
                        ...prevState,
                        [key]: key
                    }));
                } else {
                    setCustomizedItems(prevState => ({
                        ...prevState,
                        [key]: event.target.value
                    }));
                }
            };

            return <>
                <div className={css`margin: 10px 60px 10px; display: flex; flex-direction: column;`}>
                    <em className={css`font-size: 18px;`}>You are currently purchasing the
                        <strong> {currentPackage.name}.</strong>
                    </em>

                    <div
                        className={css`display: flex; align-items: center; flex-direction: column; padding-bottom: 40px;`}>
                        {
                            currentPackage.packageContains.map((packageItem) => {
                                let isCustomizable = packageItem.customOptions && packageItem.customOptions.length > 1;

                                if (!isCustomizable && !(packageItem.name in customizedItems)) {
                                    (() => {
                                        handleCustomizedChange(undefined, packageItem.name, true)
                                    })()
                                }

                                return <div key={"container" + packageItem.name}
                                            className={css`display: flex; justify-content: space-between; width: 100%; margin: 20px 0px 10px 0px;`}>
                                    <div className={css`flex: 0.9; display: flex; align-items: center;`}>
                                        <img src={packageItem.image}
                                             className={css`display: block; margin: auto; width: 250px;`}
                                             alt={packageItem.name + " " + currentPackage.name}></img>
                                    </div>
                                    <div className={css`flex: 0.55; display: flex; align-items: center;`}>
                                        <FormControl component="fieldset">
                                            <FormLabel
                                                component="legend"><strong>{packageItem.quantity} x {packageItem.name}</strong></FormLabel>
                                            {isCustomizable ?
                                                <RadioGroup aria-label={packageItem.name} name={packageItem.name}
                                                            value={customizedItems[packageItem.name] ? customizedItems[packageItem.name] : ""}
                                                            onChange={(e) => handleCustomizedChange(e, packageItem.name)}>
                                                    {packageItem.customOptions.map((customOption) => {
                                                        return <FormControlLabel
                                                            value={`${packageItem.name} (${customOption})`}
                                                            control={<Radio/>} label={customOption}
                                                            key={"keyfcl" + customOption}/>
                                                    })}
                                                </RadioGroup> :
                                                <RadioGroup aria-label={packageItem.name} name={packageItem.name}
                                                            value={packageItem.name}>
                                                    <FormControlLabel value={`${packageItem.name}`} control={<Radio/>}
                                                                      label={"Selected"}/>
                                                </RadioGroup>
                                            }
                                        </FormControl>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </>;
        case 2:
            disableNextStep(selectedGiftees.length === 0);

            return <div className={css`display: flex; flex-direction: row;`}>
                <div>
                    {i18n.selectTeamMember}:
                    <MemberInvite invited={selectedGiftees} setInvited={setSelectedGiftees} allowCustomUsers/>
                </div>
                <div className={css`margin-left: 100px;`}>
                    <strong>{selectedGiftees.length} {i18n.gifteesSelected}</strong>
                    <ul>
                        {[...selectedGiftees].map((giftee => {
                            return <li key={"li1" + giftee.firstName + " " + giftee.lastName}>
                                {giftee.firstName} {giftee.lastName} - {giftee.email}
                            </li>
                        }))}
                    </ul>
                </div>
            </div>;
        case 3:
            let pretaxPrice = (selectedGiftees.length * currentPackage.price);
            let pretaxAfterServiceFeePrice = currentPackage.serviceFeeFixed && currentPackage.serviceFeePercentage ?
                (pretaxPrice * (1+(currentPackage.serviceFeePercentage/100))) + (currentPackage.serviceFeeFixed * selectedGiftees.length) :
                pretaxPrice;
            return <>
                <div className={css`display: flex; justify-content: space-between;`}>
                    <div className={css`padding: 20px 30px; width: 40%; flex: 1; word-break: break-word;`}>
                        <strong>{selectedGiftees.length} Giftees</strong>
                        <ul>
                            {[...selectedGiftees].map((giftee => {
                                return <li
                                    key={"finalGiftees" + giftee.email}>{giftee.firstName} {giftee.lastName} - {giftee.email}</li>
                            }))}
                        </ul>
                    </div>
                    <div className={css`border: 1px solid lightgrey; flex: 1; padding: 20px 30px; width: 40%;`}>
                        <h3 className={css`text-decoration: underline; text-align: center;`}>Order Overview</h3>

                        <div>
                            <div className={css`display: flex; justify-content: space-between; font-weight: bold;`}>
                                <div> {selectedGiftees.length} x {currentPackage.name} (${currentPackage.price} Each)</div>
                                <div> ${pretaxPrice.toFixed(2)} </div>
                            </div>
                            <div className={css`margin-left: 20px; justify-content: left;`}>
                                {
                                    Object.keys(customizedItems).sort().map(function (key) {
                                        return <p key={"pkey" + customizedItems[key]}>{customizedItems[key]}</p>;
                                    })
                                }
                            </div>
                            <hr/>
                            {currentPackage.shipping && (
                                <div className={css`display: flex; justify-content: space-between;`}>
                                    <div>{i18n.shipping}</div>
                                    <div> $9.99
                                        * {selectedGiftees.length} = {(9.99 * selectedGiftees.length).toFixed(2)}</div>
                                </div>)}
                            {(currentPackage.serviceFeeFixed || currentPackage.serviceFeePercentage) && (
                                <div className={css`display: flex; justify-content: space-between;`}>
                                    <div>{i18n.serviceFee}</div>
                                    <div> ${pretaxAfterServiceFeePrice - pretaxPrice} </div>
                                </div>)}
                            {currentPackage.tax && (
                                <div className={css`display: flex; justify-content: space-between;`}>
                                    <div>{i18n.taxes}</div>
                                    <div> ${((pretaxAfterServiceFeePrice +
                                        (currentPackage.shipping ? (9.99 * selectedGiftees.length) : 0)) * 0.13).toFixed(2)} </div>
                                </div>)}
                            <hr/>
                            <div
                                className={css`display: flex; justify-content: space-between; font-weight: bold; text-decoration: underline;`}>
                                <div>{i18n.grandTotalEstimate}</div>
                                <div> ${((pretaxAfterServiceFeePrice + (currentPackage.shipping ? (9.99 * selectedGiftees.length) : 0)) +
                                    (currentPackage.tax ?
                                        (pretaxAfterServiceFeePrice + (currentPackage.shipping ? (9.99 * selectedGiftees.length) : 0)) * 0.13 :
                                        0)).toFixed(2)} </div>
                            </div>
                        </div>
                        <div className={css`width: 100%; margin: 20px auto;`}>
                            <Button variant="contained" color="primary" className={css`width: 40%; margin: 10px;`}
                                    disabled = {disable}
                                    onClick={() => {handleSubmit(true); handleChange(true); changeText("Loading...");}}>
                                {buttonText}
                            </Button>
                            <Button variant="contained" color="default" className={css`width: 50%; margin: 10px;`}
                                    onClick={() => handleSubmit(false)
                                    }>
                                📞 {i18n.callUsToCompleteOrder}
                            </Button>

                        </div>
                    </div>
                </div>
            </>;
        default:
            return <span>Error</span>
    }
}

export {getSteps, GiftingPackageStepContent}
