export const Testimonial = ({name, image, description}) => {
    return <div className="flex flex-col w-96 shadow-md border border-gray-100 p-4 bg-white space-y-6 relative rounded-md">
                    <img
                        className="h-20 w-20 object-cover rounded-full border-4 border-kudos-blue-bg absolute -top-8 left-1/2 transform -translate-x-1/2"
                        src={image}
                        alt="Person headshot"
                    />

                    <div className="p-8 space-y-4">
                        <p className="text-kudos-dark-purple text-center">
                           {description}
                        </p>
                        <p className="text-kudos-orange text-center font-bold">{name}</p>
                    </div>
                </div>
}
