import React, {useState} from 'react';
import {css} from '@emotion/css';

import {useFirestore} from 'react-redux-firebase';

import Modal from 'react-modal';

import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {changeUserRole} from 'util/database/users';
import i18n from "util/i18n";
const UserIsSuperuserModal = ({isOpen,onClose,name}) =>{
    return <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
    >
        <p className={css`
                color: hsl(230, 10%, 25%);
                font-size: 14px;
                margin-bottom: 30px;
            `}>{i18n.formatString(i18n.userIsManager,name)}</p>
        <Button onClick={onClose}
                style={{
                    backgroundColor: 'hsl(230, 100%, 40%)',
                    color: 'white',
                }}>{i18n.close}</Button>
    </Modal>
}

function ChangeUserRole({isOpen, onClose, info, currentUserRole}) {
    const [newRole, setNewRole] = useState(null);

    const firestore = useFirestore();
    if (info === null) {
        return null;
    }
    const userRole = info.role.toLowerCase();

    if (userRole ==="sysadmin" || (currentUserRole!=='sysadmin' && userRole === "superuser")) {
        return <UserIsSuperuserModal name={info.name} onClose={onClose} isOpen={isOpen}/>;
    }

    async function handleClick() {
        await changeUserRole(firestore, info.id, newRole);
        onClose();
        window.location.reload();
    }

    return <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
    >
        <h2 className={css`
            margin: 0;
            font-weight: 500;
            font-size: 18px;
        `}>{i18n.changeRole}</h2>
        <p className={css`
            color: hsl(230, 10%, 25%);
            font-size: 14px;
            margin-bottom: 30px;
        `}>{i18n.formatString(i18n.selectNewRole,info.name)}</p>
        <FormControl component="fieldset">
            <FormLabel component="legend">{i18n.newRole}</FormLabel>
            <RadioGroup name="newRole" defaultValue={userRole} onChange={(event) => setNewRole(event.target.value)}>
                {info.availableRoles.map(role => {
                    return <FormControlLabel key={role} value={role.toLowerCase()} control={<Radio/>} label={role}/>
                })}
            </RadioGroup>
        </FormControl>
        <Button disabled={!newRole}
            onClick={handleClick}
                style={{
                    backgroundColor: 'hsl(350, 100%, 40%)',
                    color: 'white',
                }}
        >{i18n.changeRole}</Button>
    </Modal>;
}

export default ChangeUserRole;
