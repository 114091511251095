import React, { useState } from 'react';
import { css } from '@emotion/css';

import pluralize from 'pluralize';

import { useHistory } from 'react-router-dom';
import { useFirestore } from 'react-redux-firebase';

import Company from 'components/Dashboard/Company';
import { loadCompanies } from 'util/database/companies';
import { withToastNotification } from 'components/hocs/withToastNotification';
import i18n from 'util/i18n';

function AddMore({toastNotification}) {
    return <div className={css`
        border: 2px dashed hsl(235, 10%, 80%);

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        border-radius: 8px;

        overflow: hidden;

        min-height: 160px;
        cursor: pointer;
    `} onClick={() => toastNotification(i18n.notImplemented, "error")}>
        <div className={css`font-size: 80px; color: hsl(235, 10%, 70%);`}>+</div>
        <div className={css`font-size: 16px; color: hsl(235, 10%, 30%);`}>Add More</div>
    </div>;
}


function Companies() {
    const firestore = useFirestore();
    const history = useHistory();

    let [companies, setCompanies] = useState(null);

    if (companies === null) {
        loadCompanies(firestore).then(setCompanies);
    }
    return <>
        <h1 className={css`
            font-size: 24px;
            font-weight: 500;
            color: hsl(235, 10%, 15%);
            margin: 0;

        `}>Manage Companies</h1>
        {companies && <p className={css`
            margin: 0;
            color: hsl(200, 10%, 30%);
            font-size: 16px;
            margin-top: 20px;
            margin-bottom: 50px;
            
        `}>{pluralize('company', Object.keys(companies).length, true)} registered</p>}
        {companies === null ? 'Loading...' :
            <div className={css`
            display: grid;
            grid-template-columns: repeat(auto-fit, 200px);
            grid-gap: 25px;
        `}>
                {Object.entries(companies).sort(([, a], [, b]) => a.name.localeCompare(b.name)).map(([id, company]) =>
                    <Company
                        key={id}
                        id={id}
                        onClick={() => history.push(`/dashboard/companies/${id}`)}
                        {...company}
                    />
                )}
                <AddMore />
            </div>}
    </>;
}

export default withToastNotification(Companies);
