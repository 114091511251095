import React from 'react';
import { css } from '@emotion/css';

import { Field, reduxForm } from 'redux-form';
import {
    renderTextField,
    renderCheckbox,
    renderDateTimePicker,
    renderGenericAutocomplete,
    SubmitButton,
} from 'components/Forms';
import FormSection from 'components/Forms/FormSection';

const numericalFields = ['duration', "price"];
const validate = (values) => {
    const requiredFields = [
        'name',
        'host',
        'description',
        'dateTime',
        'duration',
    ];

    const errors = {};

    for (let field of requiredFields) {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    }

    for (let field of numericalFields) {
        if (isNaN(Number(values[field]))) {
            errors[field] = 'Must be a number';
        }
    }

    return errors;
}

const ClassAutofillForm = reduxForm({ form: 'autofillForm' })(
    ({invalid, onSubmit, classes}) => {
        return <><form className={css`width: 100%`}>
            <FormSection
                label='Autofill from Existing Class'
                columns={1}
            >
                <Field
                    name='class'
                    placeholder='Class'
                    options={classes}
                    getOptionLabel={({id, name}) => `${name} · ${id}`}
                    component={renderGenericAutocomplete}
                />
            </FormSection>
        </form>
        <SubmitButton onClick={onSubmit} label='Autofill'/>
        </>;
    }
);

const ClassEntryForm = reduxForm({ form: 'classDBForm', validate })(
    ({invalid, onSubmit, isEdit, generateLink, checkboxComponent}) => {
        return <><form className={css`width: 100%`}>
            <FormSection label={isEdit? 'Edit Class Entry' : 'Add New Class Entry'} columns={1}>
                {isEdit &&
                    <Field disabled={true} name='id' placeholder='id of Class' component={renderTextField}/>
                }

                <Field name='name' label='Name of Class' component={renderTextField}/>
                <Field name='host' label='Host' component={renderTextField}/>
                <Field name='description' label='Description' component={renderTextField}/>
                <Field name='dateTime' label='Date & Time' component={renderDateTimePicker}/>
                <Field name='duration' label='Duration (minutes)' component={renderTextField}/>
                <Field name='image' label='Image URL (Optional)' component={renderTextField}/>
                <Field name='tags' label='Tags (Optional) separated by ,' component={renderTextField}/>
                {checkboxComponent}
                <Field name='link' label='Link of Class' component={renderTextField} disabled={generateLink}/>
                <Field name='isWebinar' label='Webinar-Style Zoom Meeting' component={renderCheckbox} disabled={!generateLink}/>
            </FormSection>
        </form>
        <SubmitButton onClick={onSubmit} disabled={invalid}/>
        </>;
    }
);

export { ClassEntryForm, ClassAutofillForm }
