import React, { useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import {loadAnswers} from 'util/database/answers'
import Answer from 'components/Dashboard/Answer'

function AnswersDB() {
    const firestore = useFirestore();
    const [answers, setAnswers] = useState(null);

    if (answers === null) {
        loadAnswers(firestore).then(setAnswers)
    }

    return<>
        <h1 className="text-2xl font-medium	m-0">Manage Answers</h1>
        {answers === null ? 'Loading...' :
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-8">
            {Object.entries(answers).map(([id, ans]) =>
                <Answer
                    key={id}
                    id={id}
                    content = {ans.answer}
                    answer = {ans}
                />
            )}
        </div>
        }
    </>
}

export default AnswersDB;