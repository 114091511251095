import { css } from "@emotion/css";
import {Header} from "./PageHeader";
import { mq } from "../util/Breakpoints";

import { LandingPageMetaTags } from "routes/LandingPages/MetaTags";

const landingPages = {
    "fall-events":{
        image: "https://firebasestorage.googleapis.com/v0/b/wfhomie-85a56.appspot.com/o/landingPages%2Ffall-landing.jpg?alt=media",
        title: "Wholesome monthly virtual team events on autopilot",
        description: "200+ unique events bringing human connection to the virtual workspace",
        meta: {
            title: "Fall Events",
            description: "Wholesome monthly virtual team events on autopilot.",
            image: "https://firebasestorage.googleapis.com/v0/b/wfhomie-85a56.appspot.com/o/landingPages%2Ffall-meta-image.png?alt=media"
        },
        cta: {
            text: "Let's Chat"
        },
    }
}

export const getLandingPage = (type) => landingPages[type];

export const isLandingPage = (type) => !!landingPages[type];

function LandingPageHeader({type}) {
    const {image, title, meta, description, cta} = getLandingPage(type)

    const openHubSpotChat = () => {
        window.HubSpotConversations.widget.open();
    }

    return <>
        <LandingPageMetaTags title={meta.title} description={meta.description} image={meta.image} url={window.location.href}/>
        <div className={css`
        margin-bottom: 40px;
        height: 400px;
        width: 100%;

        position: relative;
        overflow: hidden;
        min-height: 400px;

        display:flex;
        align-items: center;
        justify-content: center;
            `}>

           <div>

            <img src={image}
            alt={title}
            className={css`
                height: 400px;
                width: 100%;
                object-fit: cover;
                position:absolute;
                top:0;
                left:0;
                z-index:-1;
            `}/>


            <div  className={css`
            display:flex;
            flex-direction:column;
                max-width:830px;
                margin: 0 auto;
                justify-content:center;
                align-items:center;
            `}>
                <span  className={css`
                display: inline-block;
                color:white;
                font-weight: 900;
                text-align:center;
                text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);



                font-size: 50px;

                ${mq[2]} {
                    font-size: 30px;
                }
            `}>{title}</span>

<span className={css`
            display: block;
            max-width:fit-content;
            padding: 10px 30px;
            font-weight:bold;
            border-radius: 28px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            background-color: #fff;
            color: #2636ff;
            margin-top:40px;
            cursor:pointer;
            text-decoration:none;
            font-size:25px;
            `} onClick={openHubSpotChat}>{cta.text}</span>

            <span className={css`
            color: white;
            margin-top:40px;
            font-size:20px;
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
            text-align: center;
            `}>{description}</span>

            <div>

            </div>
            </div>
           </div>

        </div>
        <Header title={meta.title}/>
    </>
}

export default LandingPageHeader;
