import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import { useFirebase, useFirestore } from "react-redux-firebase";
import {Redirect, useHistory} from "react-router-dom";
import EventSummary from "../../components/Booking/EventSummary";
import i18n from "../../util/i18n";
import { handleCheckout } from "../../util/api/stripe";
import { useStripe } from "@stripe/react-stripe-js";
import { SubmitButton } from "../../components/Forms";
import LoadingIcon from "../../components/util/LoadingIcon";
import {useSelector} from "react-redux";

// Get n's ordinal number (1st, 2nd, 3rd, 4th etc.)
function getOrdinal(n) {
    const s = ["th", "st", "nd", "rd"], v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

// formatter for date picker
const dateFormat = (dateStr) => new Date(dateStr).toLocaleString([], {
    weekday: 'short',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
});

const PreOrder = () => {
    const params = new URLSearchParams(window.location.search);
    const preOrderId = params.get("preOrderId");
    const firebase = useFirebase();
    const firestore = useFirestore();
    const [preOrderData, setPreOrderData] = useState(null);
    const userData = useSelector((state) => state.userinfo);
    const stripe = useStripe();
    const history = useHistory();
    const [submitting, setSubmitting] = useState(false);
    const authData = useSelector(state => state.firebase.auth);

    useEffect(() => {
        if (!preOrderData && userData) {
            firestore.collection('preOrders').doc(preOrderId).get().then((doc) => {
                setPreOrderData(doc.data());
            });
        }
    }, [firestore,userData, preOrderId, preOrderData]);

    if(authData === null) {
        return <Redirect to={`/login?redirect=${window.location.pathname}?preOrderId=${preOrderId}`} />;
    }
    const checkout = () => {
        setSubmitting(true);
        handleCheckout(
            {
                firestore,
                stripe,
                uid: userData.userId,
                email: userData.email,
                firebase,
            },
            {
                eventID: preOrderData.eventInfo.id,
                ...preOrderData,
            },
            history
        )
    };

    return (submitting
        ? <div className={css`
                width: fit-content;
                height: calc(100vh - 130px);
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 20px;
        `}>
            <LoadingIcon />
            {i18n.pleaseWaitWhileWeProcess}
        </div>
        : <div className={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
        `}>
            {preOrderData && <>
                <EventSummary eventInfo={preOrderData.eventInfo} />
                <div className={css`
                    display: flex;
                    border-radius: 8px;
                    border: 1px solid hsl(230, 10%, 80%);
                    background-color: white;
                    margin: 36px 0;
                    padding: 20px 24px;
                    width: auto;
                    overflow: hidden;
                    & div {
                        padding-top:20px;
                    };
                    & div:first-child {
                        padding-top:0px;
                    };
                    & div span:first-child {
                        color: hsl(0deg 0% 21%);
                    };
                    flex-direction: column;
                    justify-content: space-around;
                `}>
                    <div>
                        <span>{i18n.numberOfParticipants}:&nbsp;</span>
                        <span>{preOrderData.numParticipants}</span>
                    </div>
                    <div>
                        <span>{i18n.participants}:&nbsp;</span>
                        <ul className={css`margin-top: 10px`}>
                            {preOrderData.membersInvited.map(p =>
                                <li className={css`margin-top: 5px`}>&nbsp;&nbsp;&nbsp;&nbsp;{p}</li>
                            )}
                        </ul>
                    </div>
                    <div className={css`display: flex; flex-direction: column; grid-gap: 5px;`}>
                        <span className={css`margin-bottom: 5px`}>{i18n.dates}:</span>
                        {Object.entries(preOrderData.dates).map(([idx, dateStr]) =>
                            <span key={idx} className={css`display: flex;`}>
                                <span
                                    className={css`width: 118px`}> &nbsp;&nbsp;&nbsp;&nbsp;{`${getOrdinal(parseInt(idx) + 1)} ${i18n.choice}:`}</span>
                                <span> {dateFormat(dateStr)}</span>
                            </span>)
                        }
                    </div>
                    <div>
                        <span>{i18n.timeZone}:&nbsp;</span>
                        <span>{preOrderData.timezone}</span>
                    </div>
                    <div>
                        <span>{i18n.creditsUsed}:&nbsp;</span>
                        <span>{preOrderData.creditsUsed}</span>
                    </div>
                    <div>
                        <span>{i18n.finalCost}:&nbsp;</span>
                        <span>{i18n.formatString(i18n.priceString, preOrderData.finalCost)}</span>
                    </div>
                </div>
                <SubmitButton onClick={checkout} label={i18n.finishAndPay} />
            </>}
        </div>
    );
};

export default PreOrder;
