import { useState, useEffect} from 'react';
import { loadQuestionByID } from 'util/database/questions';
import { loadUser } from 'util/database/users';
import { useFirestore } from 'react-redux-firebase';

function Answer({content, answer}) {
    const firestore = useFirestore();
    const utcSeconds = answer.date?.seconds;
    const date = new Date(0); // The 0 there is the key, which sets the date to the epoch
    date.setUTCSeconds(utcSeconds)
    const simpleDate = date.toUTCString().split(' ').slice(0, 5).join(' ')
    const [user, setUser] = useState(null)
    const [question, setQuestion] = useState(null)
    const [company, setCompany] = useState(null)
    let isContentString = true

    if (typeof(content) !== "string"){
        isContentString = false
    }

    useEffect(() => {
        if (user === null) {
            loadUser(firestore, answer.userId).then(setUser)
        }
        if (question === null) {
            loadQuestionByID(firestore, answer.questionId).then(setQuestion)
        }
        if (company === null) {
            user?.company.get().then(companyData => {
                setCompany(companyData.data()?.name)
            })
        }   
        // eslint-disable-next-line     
    }, [user])    
    return <div className="bg-white border rounded justify-items-center">
        <div className="text-base font-light text-center">
            
            {isContentString && <p>{content.toString()}</p>}
            {!isContentString && JSON.stringify(content)}
            <p>{simpleDate || answer.date}</p>
            <p>{question?.title}</p>
            <p>{user?.email}</p>
            <p>{company}</p>
        </div>
</div>
}

export default Answer;