import React, { useState } from 'react';
import { css } from "@emotion/css";

import { useSelector } from 'react-redux';
import { useFirestore } from "react-redux-firebase";

import { Field, reduxForm } from 'redux-form';
import FormSection from 'components/Forms/FormSection';
import {
    renderDropdown,
    renderNumberField,
    SubmitButton
} from 'components/Forms';

import Grid from "@material-ui/core/Grid";
import i18n from "util/i18n";

import LoadingIcon from 'components/util/LoadingIcon';

import { loadSubscriptions } from 'util/database/companies';
import { withToastNotification } from 'components/hocs/withToastNotification';


const InfoField = ({ label, value }) => {
    return <div>
        <Grid container justifyContent="space-between" alignItems="baseline">
            <span className={css`font-size: 1rem;`}>{label}:</span>
            <span className={css`font-size: 1rem; color: hsl(230, 10%, 30%);`}>{value}</span>
        </Grid>
    </div>
}

// Sysadmin form for updating subscription plan
const SysadminForm = reduxForm({form: 'subscriptionPlan'})(
    ({invalid, onSubmit, companyInfo, subscriptionList}) => {
        return <div className={css`width: 100%`}>
            <FormSection
                label='Subscription Info (Sysadmin View)'
                columns={1}
            >
                <Field
                    name='subscriptionType'
                    placeholder='Subscription Plan'
                    component={renderDropdown}
                    menuItems={Object.fromEntries(
                        Object.entries(subscriptionList).map(
                            ([id, data]) => [id, `${data.name} (${data.type})`],
                        )
                    )}
                />
                <Field
                    name='credits'
                    component={renderNumberField}
                    label='Credits'
                />
                <SubmitButton
                    label='Submit Subscription Changes'
                    onClick={onSubmit}
                />
            </FormSection>
        </div>;
    }
);

// Displays an overview of the company's subscription plan
function SubscriptionSettings({companyInfo, sysadmin, toastNotification}) {
    const firestore = useFirestore();
    const sysadminForm = useSelector((state) => state.form.subscriptionPlan);

    const [subscriptionList, setSubscriptionList] = useState(null);
    const [loading, setLoading] = useState(true);

    if (!subscriptionList & loading) {
        setLoading(false);
        loadSubscriptions(firestore).then(setSubscriptionList);
    }

    if (!subscriptionList) {return <LoadingIcon/>;}


    const stripeInfo = companyInfo.stripeSubscription;
    if (stripeInfo) {
        //Get start and end date from  Unix epoch
        let startDate = new Date(0)
        let endDate = new Date(0)
        startDate.setUTCSeconds(stripeInfo.periodStart);
        endDate.setUTCSeconds(stripeInfo.periodEnd);

        let optStart = {
            day: 'numeric',
            month: 'long',
        }
        let optEnd = {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        }
        //Format date to string
        let startString = startDate.toLocaleString([], optStart);
        let endString = endDate.toLocaleString([], optEnd);

        var periodString = `${startString} – ${endString}`;
    }

    // Extract relevant info
    const credits = companyInfo.credits ? companyInfo.credits : 0;
    const subscriptionId = companyInfo?.subscription?.id;
    const subscription = subscriptionList[subscriptionId];


    // Sysadmin panel functionality
    async function handleSubscriptionUpdate() {
        const { values } = sysadminForm;
        const subscriptionPlan = firestore.collection('subscriptionPlans').doc(
            values.subscriptionType
        );
        const company = firestore.collection('companies').doc(companyInfo.id);

        console.log(values, subscriptionPlan);
        await company.update({
            credits: +values.credits,
            subscription: subscriptionPlan,
        });
        toastNotification(i18n.planSavedSuccessfully, 'success');
    }
    if (sysadmin) {
        return <SysadminForm
            initialValues={{
                subscriptionType: companyInfo?.subscription?.id,
                credits: credits,
            }}
            companyInfo={companyInfo}
            subscriptionList={subscriptionList}
            onSubmit={handleSubscriptionUpdate}
        />;
    }


    // Generate a subscription info dictionary from what is stored in the company document
    const subscriptionInfo = subscription?.type === 'Premium'
        ? stripeInfo
            ? {
                Type: subscription.type,
                Description: stripeInfo.description,
                'Current Period': periodString,
                [i18n.creditsInBank]: credits,
            } : {
                Type: subscription.type,
                Description: `${subscription.name} Subscription`,
                [i18n.creditsInBank]: credits,
            }
        : {
            Type: subscription.type,
        }


    return <div className={css`width: 100%`}>
        {companyInfo &&
            <FormSection
                label={i18n.subscriptionInfo}
                columns={1}
            >
                {/* Display subscrition info entries */}
                {Object.entries(subscriptionInfo).map(([key, value]) =>
                    <InfoField label={key} key={key} value={value} />)}
                <span className={css`
                    font-size: 16px;
                    color: hsl(230, 10%, 30%);
                `}>
                    <i>{i18n.contactUsToUpgradeSubscription}</i>
                </span>
            </FormSection>
        }
    </div>
}

export default withToastNotification(SubscriptionSettings);

