import React from "react";

import { SlackButtonDark } from "components/Forms/Auth/AuthButton";
import { Checkmarks } from "./checkmarks";
import { Logos } from "./logos";
import { TwoPanelLandingPage } from "./twoPanelLandingPage";
import { WFHomieLogo } from "./wfhomieLogo";
import { useHistory } from "react-router";
import { KudosLandingPageExtendedContent } from "./KudosLandingPageContent";

export const KudosSignupPage = () => {
    const history = useHistory();

    return (
        <div className="flex justify-center">
            <div className="max-w-screen-2xl w-full">
                <TwoPanelLandingPage
                    leftPanel={
                        <>
                            <WFHomieLogo />

                            <span className="text-kudos-green uppercase font-bold">Spread more joy at work</span>

                            <span className="text-primary font-bold leading-relaxed lg:leading-relaxed text-2xl lg:text-5xl">
                                Recognize your teammates and give Kudos in Slack! 🥳
                            </span>

                            <Checkmarks />

                            <div className="flex flex-wrap space-x-0 md:space-x-4 space-y-4 md:space-y-0">
                                <SlackButtonDark
                                    onClick={() => history.push("/signup?source=kudos&redirect=/redirectToAddKudos")}
                                />
                                <a className="h-12 w-content" href="https://www.producthunt.com/posts/kudos-by-wfhomie?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-kudos-by-wfhomie" target="_blank" rel="noreferrer"><img className="h-12" src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=319039&theme=dark" alt="Kudos by WFHomie - Give kudos and recognize your teammates on Slack | Product Hunt"   /></a>
                            </div>

                            <Logos />
                        </>
                    }
                    rightPanel={
                        <div>
                            <img src="/static/images/kudos_slack.png" alt="Kudos Slack" />
                        </div>
                    }
                />

                <KudosLandingPageExtendedContent />
            </div>
        </div>
    );
};
