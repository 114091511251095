import React from 'react';
import { css } from '@emotion/css';

import { handleFileUpload, deleteItem } from 'util/storage';

import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LoadingIcon from 'components/util/LoadingIcon';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { withToastNotification } from 'components/hocs/withToastNotification';
import i18n from 'util/i18n';

// Allows to upload new csv files and display/remove current ones
const CompanyMembersFileSelector = ({ companyFolder, storedFiles, setStoredFiles, toastNotification }) => {

    // Send file on Firebase Storage on upload
    const onChange = async (e) => {
        const {
            target: {
                files: [upload],
            },
        } = e;
        if (!upload) {
            return;
        }
        await handleFileUpload(upload, companyFolder, false).then(() => {toastNotification(i18n.fileUploadedSuccessfully, 'success')});
        setStoredFiles([...storedFiles, upload.name]);
    };
    return <>
        <input
            type="file"
            id="file-upload"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={onChange}
            className={css`display: none;`}
        />
        {/* Label is visible (our custom button); input has display none */}
        <label
            htmlFor="file-upload"
        >
            <div
                className={css`
                        color: hsl(230, 10%, 40%);
                        font-size: 16px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;

                        border-radius: 8px;
                        margin-top: 10px;
                        cursor: pointer;

                        :hover {
                            background-color: hsl(200, 10%, 90%);
                        }
                    `}
            >
                <IconButton> <CloudUploadIcon /> </IconButton>
                Upload company members information
            </div>
        </label>
        {/* Display list of current files under upload the button */}
        {
            storedFiles ? Object.entries(storedFiles).map(([idx, value]) => {
                return (
                    <div 
                        key={idx}
                        className={css`
                            display: flex; 
                            align-items: center;
                            margin: auto;
                            width: fit-content;
                        `}
                    >
                        <div className={css`color: hsl(230, 10%, 40%);`}>{value}</div>
                        <IconButton
                            onClick={() => {
                                // Send async request to delete file
                                deleteItem(`${companyFolder}/${value}`);
                                // Delete element from local array for fast update
                                const newArr = [...storedFiles];
                                newArr.splice(idx, 1);
                                setStoredFiles(newArr);
                            }}
                            className={css`
                            padding: 10px;
                        `}
                        >
                            <RemoveCircleOutlineIcon />
                        </IconButton>

                    </div>
                )
            }) : <div className={css`display: flex; justify-content: center;`}>
                <LoadingIcon size={50} thickness={4} />
            </div>
        }

    </>;
}

export default withToastNotification(CompanyMembersFileSelector);
