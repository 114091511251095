import { useEffect, useState } from "react";
import AuthenticationForm from "../../components/Forms/Auth/AuthenticationForm";
import { css } from "@emotion/css";
import i18n from "../../util/i18n";
import AuthPortalContainerV2 from "../../components/AuthPortal/AuthPortalContainerV2";
import { useHistory } from "react-router-dom";
import LoadingIcon from "../../components/util/LoadingIcon";
import { GoogleButton, MicrosoftButton, SlackButton } from "components/Forms/Auth/AuthButton";
import Mixpanel from "util/analytics/mixpanel";
import { eventProperties, mixpanelEvents } from "util/constants/mixpanel";
import { useFirebase } from "react-redux-firebase";



function LoginPage() {
    const history = useHistory();
    const [error, setError] = useState(null);
    const params = new URLSearchParams(window.location.search);
    const redirect = params.get("redirect");
    const jwtToken = params.get("token");
    const [loading, setLoading] = useState(jwtToken !== null);
    const firebase = useFirebase();
    const firebaseAuth = firebase.auth();
    // Track how long it takes to log in
    useEffect(() => {
        Mixpanel.timeEvent("Sign In");
    }, []);


    const loginWithEmail = async (email, password) => {
        return await firebaseAuth.signInWithEmailAndPassword(email, password).catch(err => {
            return { error: firebaseAuthErrorToErrorMessage(err) };
        });
    };

    const loginWithAuthProvider = async (type) => {
        try {
            const credentials = await firebaseAuth.signInWithPopup(type);
            return { user: credentials.user };
        } catch (error) {
            return { error: firebaseAuthErrorToErrorMessage(error) };
        }
    }

    const loginWithGoogle = () => loginWithAuthProvider(new firebase.auth.GoogleAuthProvider());
    const loginWithMicrosoft = () => loginWithAuthProvider(new firebase.auth.OAuthProvider('microsoft.com'));

    useEffect(() => {
        const loginWithCustomToken = async (token) => {
            try {
                const credentials = await firebaseAuth.signInWithCustomToken(token);
                return { user: credentials.user };
            } catch (error) {
                return { error: firebaseAuthErrorToErrorMessage(error) };
            }
        };
        async function handleCustomToken() {
            if (jwtToken) {
                const { user, error } = await loginWithCustomToken(jwtToken);
                if (user) {
                    Mixpanel.track(mixpanelEvents.signIn, { [eventProperties.type]: "custom_token" });
                    if (redirect) {
                        history.push(redirect);
                    }
                }
                if (error) {
                    setError(error);
                    setLoading(false);
                }
            }
        }

        handleCustomToken();
    }, [jwtToken, redirect, history, firebaseAuth]);

    const handleAuth = async (type, authFunc) => {
        setError(null);

        const { user, error } = await authFunc();
        if (user) {
            Mixpanel.track(mixpanelEvents.SIGN_IN, { [eventProperties.TYPE]: type });
            history.push(redirect || "/dashboard/home");
        }

        if (error) {
            setError(error);
        }
    }

    const handleGoogle = async () => await handleAuth("google", loginWithGoogle)

    const handleSubmit = async (formValues) => await handleAuth("password", () => loginWithEmail(formValues.email, formValues.password))

    const handleMicrosoft = async () => await handleAuth("microsoft.com", loginWithMicrosoft)

    return loading ? (
        <LoadingIcon />
    ) : (
        <AuthPortalContainerV2>
            <div className="flex flex-col items-center space-y-2">
                <GoogleButton onClick={handleGoogle} className="w-60" />
                <MicrosoftButton onClick={handleMicrosoft} className="w-60" />
                <SlackButton className="w-60" />
            </div>
            <Divider />
            {error && <b>{error}</b>}
            <AuthenticationForm onSubmit={handleSubmit} action={"Log in"} params={params} />
        </AuthPortalContainerV2>
    );
}

function Divider() {
    return <div
        className={css`
            margin: 20px 0;
            text-align: center;
            position: relative;
            color: #ccc;
            &::before,
            &::after {
              content: "";
              position: absolute;
              bottom: 50%;
              height: 1px;
              width: 43%;
              background: #ccc;
            }

            &::before {
              left: 0;
              padding-right: 10px;
            }

            &::after {
              right: 0;
              padding-left: 10px;
            }
          `}
    >
        {i18n.or}
    </div>;
}


function firebaseAuthErrorToErrorMessage(error) { // string
    switch (error.code) {
        case "auth/user-not-found":
            return i18n.incorrectEmailOrPass;
        case "auth/email-already-in-use":
            return i18n.emailAlreadyInUse;
        case "auth/wrong-password":
            return i18n.wrongPassword;
        case "auth/popup-blocked":
            return i18n.popupBlocked;
        case "auth/popup-closed-by-user":
            return error.message;
        case "auth/cancelled-popup-request":
            return error.message;
        default:
            window.newrelic.noticeError(`unknown authentication error: ${JSON.stringify(error)}`);
            console.error(`unknown authentication error: ${error}`);
            return i18n.wentWrongTryAgain;
    }
}
export default LoginPage;


