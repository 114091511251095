import { css } from "@emotion/css";
import Header from "components/Header";

export const LandingPage = ({children}) => {
    return <div className={css`
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
    `}>
        <Header hideSignIn />
        <div  className={css`
        margin-top: 70px;
    `}>
            {children}
        </div>
    </div>
}
