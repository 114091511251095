import { generateInvite } from 'util/database/invites';

async function loadBambooHRUsers(firestore, firebase, data, userInfo, companyInfo, addresses){

    let NewEmails = [];
    //let ExistedEmails = ['reza@wfhomie.com', 'Isa@wfhomie.com'];
    let ExistedEmails = [];
    const company = companyInfo || userInfo?.company;
    const companyDomain = companyInfo.BambooHRIntegrationInfo.CompanyDomain;
    const apiKey = companyInfo.BambooHRIntegrationInfo.APIKey;
    
    const code = await generateInvite(firestore, company.id, 'employee');
    const invitationLink = `${window.location.origin}/signup?code=${code}&company=${encodeURIComponent(company.name)}`;
    
    for (let i = 0; i < data.length; i++) {
        let user = data[i];
        if (user.profile.email) {
            ExistedEmails.push(user.profile.email);
        }else{
            console.log("No email found for user: " + user.id);
        }
    }
    await firebase.functions().httpsCallable('fetchBamboohrUsers')({
        companyDomain,
        apiKey
    }).then(res => {
        for (var key in res.data){
            if (!ExistedEmails.includes(res.data[key].email)){
                NewEmails.push(res.data[key].email);
                console.log("New user found: " + res.data[key].email);
            }
        }
    });
    await firebase.functions().httpsCallable('sendInvitationEmail')(
        {
            emails: addresses,
            companyName: company.name,
            invitationLink: invitationLink
        })
        .catch(() => {
            return false;
        });

    for (let address of addresses) {
        await firestore.collection('invitedUsers').add({
            company: company,
            email: address,
            code: code
        });
    }
}

async function loadBambooHRAddresses(firebase, data, companyInfo){
    let ExistedEmails = [];
    let UsersNeedUpdate = [];
    const companyDomain = companyInfo.BambooHRIntegrationInfo.CompanyDomain;
    const apiKey = companyInfo.BambooHRIntegrationInfo.APIKey;

    for (let i = 0; i < data.length; i++) {
        let user = data[i];
        if (user.profile.email) {
            ExistedEmails.push(user.profile.email);
        }else{
            console.log("No email found for user: " + user.id);
        }
    }

    // Get all the employeeIDs that have existed in the database
    await firebase.functions().httpsCallable('fetchBamboohrUsers')({
        companyDomain,
        apiKey,
    }).then(res => {
        for (var key in res.data){
            if (ExistedEmails.includes(res.data[key].email)){
                UsersNeedUpdate.push(res.data[key]);
            }
        }
    });

    await firebase.functions().httpsCallable('fetchBamboohrAddresses')({
        companyDomain,
        apiKey,
        UsersNeedUpdate
    })
}

export {loadBambooHRUsers, loadBambooHRAddresses}
