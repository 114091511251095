import React from 'react';
import { css } from '@emotion/css';

import { useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {
    renderTextField,
    renderGenericAutocomplete,
    renderDateTimePicker,
    SubmitButton
} from 'components/Forms';

import FormSection from 'components/Forms/FormSection';

import GiftList from '../Summary/GiftList';


const numericalFields = [
    'price',
];

const validate = (values) => {
    const requiredFields = [
        'gift',
        'image',
        'dateTime',
        'price',
        'recipient',
    ];

    const errors = {};

    for (let field of requiredFields) {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    }

    for (let field of numericalFields) {
        if (isNaN(Number(values[field]))) {
            errors[field] = 'Must be a number';
        }
    }

    return errors;
}

const EntryForm = reduxForm({ form: 'giftDBForm', validate })(
    ({ invalid, onSubmit, employees }) => {
        return <><form className={css`width: 100%`}>
            <FormSection
                label='Add New Entry'
                columns={1}
            >
                <Field name='gift' placeholder='Gift' component={renderTextField} />
                <Field name='image' placeholder='Image URL' component={renderTextField} />
                <Field name='price' placeholder='Price' component={renderTextField} />
                <Field name='dateTime' placeholder='Date & Time' component={renderDateTimePicker} />
                <Field
                    name='recipient'
                    placeholder='Recipient'
                    options={employees}
                    getOptionLabel={({ uid, name }) => `${name} · ${uid}`}
                    component={renderGenericAutocomplete}
                />
            </FormSection>
        </form>
            <SubmitButton onClick={onSubmit} disabled={invalid} />
        </>;
    }
);

function normalize(entries) {
    const output = { ...entries };
    for (let field of numericalFields) {
        output[field] = Number(output[field]);
    }
    delete output.company;
    return output;
}

function flatten(employeeList) {
    const output = [];
    for (let [id, employee] of Object.entries(employeeList)) {
        output.push({ uid: id, name: `${employee.firstName} ${employee.lastName}` });
    }
    return output;
}

function GiftDB({ companies, giftList, saveEntry, employeeList }) {
    const form = useSelector((state) => state.form.giftDBForm);

    async function handleSubmit() {
        const info = normalize(form.values);

        info.dateTime = new Date(info.dateTime.getTime() - (info.dateTime.getTimezoneOffset() * 60000)).toISOString().substring(0, 16);

        // TODO janky. Ideally we should return {id, name} object
        // from autocomplete field, but redux-form takes control of onChange
        // and throws the value away.
        info.recipient = info.recipient.split(' · ')[1];

        await saveEntry(info);
        window.location.reload();
    }

    return <div className={css`width: 480px; margin: auto`}>
        <EntryForm
            onSubmit={handleSubmit}
            employees={flatten(employeeList)}
        />
        <GiftList
            giftList={giftList}
            employeeList={employeeList}
        />
    </div>;
}

export default GiftDB;
