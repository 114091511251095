import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { Redirect } from "react-router-dom";

import { css } from "@emotion/css";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import { SubmitButton } from "components/Forms";
import SubscriptionSettings from "./SubscriptionSettings";
import SlackSettings from "./SlackSettings";
import CompanySettingsForm from "components/Forms/Settings/CompanySettingsForm";
import i18n from "util/i18n";

// The companyInfo param is supplied by the sysadmin
// management tab, and supercedes the info from the
// userinfo selector. When this param is selected, also format
// this page as an embed.
function CompanySettings({companyInfo, sysadmin}) {
  const userData = useSelector((state) => state.userinfo);
  const userCompany = useSelector((state) => state.userinfo?.company);
  const form = useSelector((state) => state.form.companySettingsForm);
  const dispatch = useDispatch();
  const firestore = useFirestore();

  // Allows managing specific company on sysadmin panel
  const company = companyInfo || userCompany;

  const [toastOpen, toggleToast] = useState(false);

  const handleSubmit = async () => {
    const ref = firestore.collection("companies").doc(company.id);
    if (!ref) {
      throw new Error(i18n.companySettingSubmissionFailed);
    }
    const info = {};

    // See comments in UserSettings
    Object.entries(form.values).forEach(([fld, val]) => {
      if (typeof val === "string") {
        info[fld] = val;
      } else if (fld === "address") {
        if (val.address) {
          info[fld] = val.address
        }
      } else {
        info[fld] = { ...company[fld], ...val };
      }
    });

    await ref.update(info);
    toggleToast(true);
    return true;
  };

  // See comments in UserSettings
  useEffect(
    () =>
      company &&
      form &&
      !form.values &&
      Object.keys(form.registeredFields).forEach((field) => {
        dispatch({
          type: "@@redux-form/CHANGE",
          meta: { form: "companySettingsForm", field },
          payload:
            field === "address" ? { address: company[field] } : company[field],
        });
      }),
    [company, form, dispatch]
  );

  // Only superusers (managers) can see/access this page,
  // or sysadmins (if companyInfo is provided)
  if (!companyInfo && userData && userData.role !== "superuser") {
    return <Redirect to="/dashboard/settings" />;
  }

  return <div
      className={companyInfo ? undefined : css`
        width: 653px;
        padding: 1em;
        margin: auto;
        margin-top: calc(85px - 1em);
      `}
  >
    {companyInfo ? undefined : <h1>{i18n.businessSetting}</h1>}
    {company && (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <CompanySettingsForm {...company} />
        <Grid
          container
          className={css`
              margin: 0.5em 0px 1em;
            `}
        >
          <SubmitButton onClick={() => handleSubmit()} />
        </Grid>
        <SubscriptionSettings companyInfo={company} sysadmin={sysadmin}/>
        {companyInfo ? null : <SlackSettings />}
      </Grid>
    )}
    <Snackbar
      open={toastOpen}
      autoHideDuration={4000}
      onClose={() => toggleToast(false)}
      message="Company data saved successfully."
    />
  </div>;
};

export default CompanySettings;
