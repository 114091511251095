import React, { useState, useEffect } from "react";
import { css } from "@emotion/css";
import { reduxForm, FieldArray } from "redux-form";
import { useHistory } from "react-router-dom";
import { loadEvent } from "util/database/events";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { useStripe } from "@stripe/react-stripe-js";
import { handleCheckout } from "util/api/stripe";
import FormSection from "components/Forms/FormSection";
import EventSummary from "components/Booking/EventSummary";
import PriceDetails from "components/Booking/Invoice";
import renderDateSelector from "components/Booking/DateSelector";
import LoadingIcon from "components/util/LoadingIcon";
import { renderNumberField, SubmitButton } from "components/Forms";
import { getCurrentSystemTimezone } from "util/timezone";
import i18n from "util/i18n";

import TimeZoneSelect from "components/Forms/sections/TimeZoneSelect";
import { hasAccess } from "../../util/permission/haspermissions";
import { managerPermissions } from "../../util/permission/permissions";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { useSelector } from "react-redux";
import { Field } from "redux-form";
import {
    getParticipantCountBounds,
    getParticipantSelectionDescription,
} from "components/Dashboard/Events/util/EventInfo";
import CustomRequests from "components/Forms/sections/CustomRequests";
import { CopiableText } from "components/util/CopiableText";
import AuthUserModal from "components/Dashboard/UserTable/modals/AuthUser.js";

// Section of the form to select a company and set a fixed final cost
const PrebookSettings = ({ companies, selectedCompanyId, setSelectedCompanyId, setSelectedCompany }) => (
    <FormSection columns={1} label={"Select target company"}>
        <FormControl
            variant="outlined"
            className={css`
                height: 56px;
                width: 100%;
            `}
        >
            <InputLabel id="dropdown-label">select company</InputLabel>
            <Select
                labelId="dropdown-label"
                label={"select company"}
                value={selectedCompanyId}
                onChange={(event) => {
                    if (event?.target?.value) {
                        const company = companies.filter((f) => f.id === event.target.value)[0].data;
                        setSelectedCompany(company);
                        setSelectedCompanyId(event?.target?.value);
                    }
                }}
            >
                {companies.map((c) => (
                    <MenuItem key={c.id} value={c.id}>
                        {c?.data?.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
        <FormControl
            variant="outlined"
            className={css`
                height: 56px;
                width: 100%;
            `}
        >
            <Field component={renderNumberField} label="Manual Final Cost" name="manualFinalCost" />
        </FormControl>
    </FormSection>
);

const CopiablePrebookingLink = ({ prebookingLink }) => (
    <div
        className={css`
            margin-bottom: 15px;
        `}
    >
        <span
            className={css`
                font-size: 20px;
            `}
        >
            Pre-booking link (click to copy):
        </span>
        <CopiableText text={prebookingLink} />
    </div>
);

const BookingForm = ({ handleSubmit, initialize, invalid }) => {
    const userData = useSelector((state) => state.userinfo);
    const companyInfo = useSelector((state) => state.userinfo?.company);

    const [eventInfo, setEventInfo] = useState(null);
    const [initialDate, setInitialDate] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [finalCost, setFinalCost] = useState(0);
    const [preCreditCost, setPreCreditCost] = useState(0);
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(companyInfo);
    const [selectedCompanyId, setSelectedCompanyId] = useState(-1);
    const [preBookLink, setPreBookLink] = useState(null);
    const [participantCountBounds, setParticipantCountBounds] = useState(null);

    const history = useHistory();
    const firestore = useFirestore();
    const firebase = useFirebase();
    const stripe = useStripe();

    const displayPrebookSettings = userData && hasAccess(managerPermissions.CLIENT_EVENT_DB, userData);
    const shouldGeneratePrebookLink = displayPrebookSettings && selectedCompany?.id !== companyInfo?.id;

    useEffect(() => {
        async function getEvent(eventID) {
            try {
                setEventInfo(await loadEvent(firestore, eventID));
            } catch (error) {
                history.push({
                    pathname: "/book/fail",
                    state: { errorMsg: "Invalid event ID" },
                });
            }
        }
        if (displayPrebookSettings) {
            if (companies.length === 0)
                firestore
                    .collection("companies")
                    .get()
                    .then((querySnapshot) => {
                        const comps = [{ data: companyInfo, id: "-1" }];
                        querySnapshot.forEach((doc) => {
                            comps.push({ data: doc.data(), id: doc.id });
                        });
                        setCompanies(comps);
                    });
        }
        const params = new URLSearchParams(window.location.search);
        const eventID = params.get("event");
        const weekOfMs = parseInt(params.get("week"));

        getEvent(eventID);
        const initialDate = !isNaN(new Date(weekOfMs)) ? new Date(weekOfMs) : new Date();
        setInitialDate(initialDate);

        initialize({
            creditsUsed: 0,
            dates: [initialDate],
        });
    }, [initialize, history, firestore, userData, companyInfo, companies, displayPrebookSettings]);

    // Get min and max number of participants when event info is loaded
    useEffect(() => {
        if (eventInfo) {
            setParticipantCountBounds(getParticipantCountBounds(eventInfo.suggestedAudienceSize));
        }
    }, [eventInfo]);

    // Initialize selected company to user's company when companyInfo is loaded
    useEffect(() => {
        if (companyInfo) {
            setSelectedCompany(companyInfo);
            setSelectedCompanyId(-1);
        }
    }, [companyInfo]);

    // Function called on form submission
    const onSubmit = async (values) => {
        values.dates = values.dates.map((date) =>
            new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().substring(0, 16)
        );
        setSubmitting(true);
        if (shouldGeneratePrebookLink) {
            preBook(values);
        } else {
            handleCheckout(
                {
                    firestore,
                    stripe,
                    uid: userData.userId,
                    email: userData.email,
                    firebase,
                },
                {
                    eventID: eventInfo.id,
                    numQuestions: eventInfo.questions ? eventInfo.questions.length : 0,
                    numParticipants: values.membersInvited.length,
                    ...values,
                    customRequests: values.customRequests?.trim()?.length > 0 ? values.customRequests.trim() : null,
                    timezone: values.timeZone?.value ?? getCurrentSystemTimezone(), // Default to system timezone
                    finalCost,
                    preCreditCost,
                },
                history
            );
        }
    };

    const preBook = async (values) => {
        const orderInfo = {
            eventInfo: eventInfo,
            numQuestions: eventInfo.questions ? eventInfo.questions.length : 0,
            numParticipants: values.membersInvited.length,
            ...values,
            timezone: values.timeZone?.value ? values.timeZone.value : i18n.localeTimeZone,
            finalCost: values.manualFinalCost ? values.manualFinalCost : finalCost,
            preCreditCost,
            origin: window.location.origin,
            companyId: selectedCompanyId,
        };
        const requestRef = await firestore.collection("preOrders").add(orderInfo);
        setPreBookLink(`${window.location.origin}/book/pre?preOrderId=${requestRef.id}`);
        setSubmitting(false);
    };

    const pageIsLoading = !eventInfo || !participantCountBounds;
    const notLoggedIn = !userData;

    return (
        <>
            {notLoggedIn ? (
                <>
                    {pageIsLoading ? (
                        <div
                            className={css`
                                width: fit-content;
                                margin: auto;
                            `}
                        >
                            <LoadingIcon />
                        </div>
                    ) : (
                        <>
                            <EventSummary eventInfo={eventInfo} />
                            <AuthUserModal />{" "}
                        </>
                    )}
                </>
            ) : submitting ? (
                <div
                    className={css`
                        width: fit-content;
                        height: calc(100vh - 130px);
                        margin: auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        gap: 20px;
                    `}
                >
                    <LoadingIcon />
                    {i18n.pleaseWaitWhileWeProcess}
                </div>
            ) : (
                <>
                    {preBookLink && <CopiablePrebookingLink prebookingLink={preBookLink} />}
                    <h1>{i18n.confirmAndPay}</h1>
                    {pageIsLoading ? (
                        <div
                            className={css`
                                width: fit-content;
                                margin: auto;
                            `}
                        >
                            <LoadingIcon />
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {displayPrebookSettings && (
                                <PrebookSettings
                                    companies={companies}
                                    selectedCompanyId={selectedCompanyId}
                                    setSelectedCompanyId={setSelectedCompanyId}
                                    setSelectedCompany={setSelectedCompany}
                                />
                            )}
                            <EventSummary eventInfo={eventInfo} />
                            <>
                                <FormSection
                                    label={i18n.dateAndTime}
                                    description={
                                        <>
                                            {i18n.selectUpTo3TimeSlots}
                                            <br />
                                            {i18n.weWillConfirmDateTime}
                                        </>
                                    }
                                    columns={1}
                                >
                                    <FieldArray name="dates" defaultDate={initialDate} component={renderDateSelector} />
                                    <TimeZoneSelect />
                                </FormSection>
                                <FormSection
                                    label={i18n.participants}
                                    description={getParticipantSelectionDescription(participantCountBounds)}
                                    columns={1}
                                >
                                    <PriceDetails
                                        eventInfo={eventInfo}
                                        setFinalCost={setFinalCost}
                                        setPreCreditCost={setPreCreditCost}
                                        companyInfo={selectedCompany}
                                        participantCountBounds={participantCountBounds}
                                    />
                                </FormSection>
                                <CustomRequests />
                                <SubmitButton
                                    label={shouldGeneratePrebookLink ? i18n.generatePrebookingLink : i18n.finishAndPay}
                                    disabled={invalid || preCreditCost === 0}
                                />
                            </>
                        </form>
                    )}
                </>
            )}
        </>
    );
};

export default reduxForm({ form: "eventBooking" })(BookingForm);
