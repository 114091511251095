import {
    loadMembers,
    loadUser
} from "./users";

// Load classes
async function loadClasses(firestore) {
    console.log('Loading classes...');
    const classes = await firestore.collection('classes').get();
    let result = [];
    for (const doc of classes.docs) {
        const data = await doc.data();
        const tags = data.tags ? data.tags.map(tag => tag.trim()) : [];
        console.log(data);
        result.push({
            ...data,
            id: doc.id,
            tags,
        })
    }
    return result;
}

// Write a new class entry (requires sysadmin auth)
async function addEntry(firestore, entry) {
    const newRef = await firestore.collection('classes').doc();
    return newRef.set({
        ...entry,
        id: newRef.id
    });
}

async function updateEntry(firestore, entry) {
    await firestore.collection('classes').doc(entry.id).update(entry);
}

// Register for a class
async function addRegistration(firestore, classId, uid) {
    await firestore.collection('class-registrations').add({
        classId,
        uid
    });
}

// Register a list of users to a class
async function addRegistrations(firestore, classId, userIds) {
    const registrationPromises = userIds.map(userId => {
        return addRegistration(firestore, classId, userId);
    });
    await Promise.all(registrationPromises);
}

// Get registrations for this user.
async function loadRegistrations(firestore, uid) {
    const registrations = await firestore.collection('class-registrations').where('uid', '==', uid).get();
    return registrations.docs.map(doc => doc.data().classId);
}

// Get registrations for every users in the same company as this user, for the given classes.
async function loadCompanyRegistrationsForClasses(firestore, classes, companyId) {
    const companyMembers = await loadMembers(firestore, companyId);
    const companyMembersIds = Object.keys(companyMembers);

    const allRegistrations = await firestore.collection('class-registrations').get();
    const companyRegistrations = allRegistrations.docs.filter(doc => {
        const {uid, classId} = doc.data();
        return companyMembersIds.includes(uid) && classes.map(c => c.id).includes(classId);
    });
    const companyRegistrationsData = companyRegistrations.map(doc => doc.data());

    // Map classes to list of user emails
    const companyRegistrationsPerClass = {};
    classes.forEach(c => companyRegistrationsPerClass[c.id] = []);
    companyRegistrationsData.forEach(reg => companyRegistrationsPerClass[reg.classId].push(companyMembers[reg.uid].email));

    // Map classes to list of registered users and boolean flag for whether all members are registered
    const classRegistrationsMap = {};
    classes.forEach(({ id: classId }) => {
        const uniqueUsers = [...new Set(companyRegistrationsPerClass[classId])];
        classRegistrationsMap[classId] = {
            registeredUsers: uniqueUsers,
            allUsersRegistered: uniqueUsers.length === companyMembersIds.length
        }
    });

    return {
        classRegistrations: classRegistrationsMap,
        numMembers: companyMembersIds.length
    }
}

async function loadClassRegistrations(firestore, classId) {
    const registrations = await firestore.collection('class-registrations').where('classId', '==', classId).get();
    const userIdsRegistered = registrations.docs.map(doc => doc.data());

    return await Promise.all(userIdsRegistered.map(async (data) => {

        let user = await loadUser(firestore, data.uid);
        return {
            "firstName": user.firstName,
            "lastName": user.lastName,
            "email": user.email,
            "referrer": data.referrer
        }
    }))
}

// Delete class (company manager auth)
// I'm pretty sure it would be better to store userIDs directly in the respective class so that we don't have to search
// for individual entries

async function deleteClass(firestore, classID) {
    await firestore.collection('classes').doc(classID.id).delete();
}

async function addReferral(firestore, classData, userData, referrer) {
    await firestore.collection('class-registrations').where("uid", "==", userData.userId)
        .where("classId", "==", classData.id).limit(1).get().then((query) => {
            const thing = query.docs[0];
            thing.ref.update({
                referrer: referrer
            })
        });
}

async function checkReferral(firestore, classData, userData) {
    let referral = null;

    await firestore.collection('class-registrations').where("uid", "==", userData.userId)
        .where("classId", "==", classData.id).limit(1).get().then((query) => {
            const thing = query.docs[0]?.data();
            if(thing?.referrer) {
                referral = thing.referrer;
            }
        });

    return referral;
}

export {
    loadClasses,
    addEntry,
    updateEntry,
    addRegistration,
    addRegistrations,
    loadRegistrations,
    loadClassRegistrations,
    loadCompanyRegistrationsForClasses,
    deleteClass,
    addReferral,
    checkReferral,
};
