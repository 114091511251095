import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import LoadingIcon from 'components/util/LoadingIcon';

import InvoicePage from 'components/Dashboard/Invoices';
import { getInvoices } from 'util/api/stripe';


function Invoices() {
    const userInfo = useSelector(state => state.userinfo);

    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(true);

    if (userInfo?.company && !invoices?.length && loading) {
        getInvoices(userInfo.company.subscriptionID).then(setInvoices).then(
            () => setLoading(false)
        );
    }

    return loading ? <LoadingIcon/> : <InvoicePage invoices={invoices}/>;
}

export default Invoices;
