import React, {useEffect, useState} from "react";

import {useFirestore} from "react-redux-firebase";
import {Redirect, withRouter} from "react-router-dom";
import {useSelector} from "react-redux";
import "url-search-params-polyfill";

import EventDashboard from "components/Dashboard/Events/EventDashboard";

import {loadMembers} from "util/database/users";
import {loadEvents} from "util/database/events";

import TeamWidget from "components/Dashboard/TeamWidget";

import {hasAccess} from 'util/permission/haspermissions';
import {permissions} from 'util/permission/permissions';
import PageHeader from "../../../components/Dashboard/PageHeader"
import {getReplaceClassWithHome} from "util/localization";
import i18n from "util/i18n";
import { eventThemes } from "util/constants/events";

function Home() {
    const firestore = useFirestore();
    const userinfo = useSelector((state) => state.userinfo);

    const [members, setMembers] = useState(null);
    const [events, setEvents] = useState(null);

    useEffect(() => {
        (() => {
            loadEvents(firestore).then(setEvents);
        })();

        return () => setEvents(null);
    }, [firestore]);

    const fillHomeCategories = (userinfo) => {
        const categories = [];
        if (userinfo && hasAccess(permissions.EVENTS, userinfo)) { // Categories to preview on home page. TODO -- Randomize
            categories.push(eventThemes.MOST_POPULAR);
        }
        categories.push(eventThemes.CROWDPARTY_GAMES);
        return categories;
    }

    if (userinfo === null) {
        return null;
    }

    if (members === null && userinfo?.company) {
        loadMembers(firestore, userinfo.company).then(setMembers);
    }

    if (getReplaceClassWithHome(userinfo)) {
        return <Redirect to="classes"/>;
    }

    const currentThemes = fillHomeCategories(userinfo);

    const showMembers = userinfo.role !== "employee" ? <TeamWidget team={members}/> : <></>;

    return (
        <>
            <PageHeader showWelcomePopUp={true} title={`${i18n.hello}, ${userinfo.firstName}!`}
                        leftChild={showMembers}/>
            <EventDashboard isHomePage={true} events={events} currentThemes={currentThemes}/>
        </>
    );
}

export default withRouter(Home);
