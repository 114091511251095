async function loadGiftPackages(firestore) {
    const giftPackages = await firestore.collection('giftPackages').get();
    return giftPackages.docs.map(doc => doc.data());
}

async function addGiftPackage(firestore, entry) {
    const newRef = await firestore.collection('giftPackages').doc();
    return newRef.set({...entry, id: newRef.id});
}

function handleGiftPackageOrder(firebase, orderInfo, onSuccess, onError) {
    return firebase.functions().httpsCallable('handleGiftingPurchase')(
        {
            data: orderInfo,
            origin: window.location.origin
        })
        .then(onSuccess)
        .catch(onError);
}

async function getGiftOrder(firestore, giftOrderId){
    const order = await firestore.collection('giftPackageOrders').doc(giftOrderId).get();
    const data = order.data();
    return {id: giftOrderId, ...data};
}

async function updateGiftPackage(firestore, entry) {
    await firestore.collection('giftPackages').doc(entry.id).update(entry);
}

async function deleteGiftPackage(firestore, entry) {
    await firestore.collection('giftPackages').doc(entry.id).delete(entry);
}

// Load gift history (Requires company manager auth)
async function loadGiftHistory(firestore, companyInfo) {
    console.log(`Loading gift history for ${companyInfo.name}.`);
    const history = await firestore.collection('companies').doc(companyInfo.id).collection('gift-history').get();
    return history.docs.map(doc => doc.data());
}

// Write a new gift history entry (requires sysadmin auth)
async function addGiftHistory(firestore, companyId, entry) {
    await firestore.collection('companies').doc(companyId).collection('gift-history').add(entry);
    await firestore.collection('companies').doc(companyId).update({active: true});
}

export {
    loadGiftPackages,
    loadGiftHistory,
    getGiftOrder,
    addGiftHistory,
    addGiftPackage,
    updateGiftPackage,
    deleteGiftPackage,
    handleGiftPackageOrder
};
