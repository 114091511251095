import { css } from "@emotion/css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ScheduleGameForm from "components/Forms/Game/ScheduleGameForm";
import { add, formatISO } from "date-fns";
import { useMemo, useState } from "react";
import { useFirebase } from "react-redux-firebase";
import i18n from "util/i18n";

const atcUtils = require("react-add-to-calendar-hoc/lib/utils");

// and for Add To Calendar
const atcformat = (dt) => formatISO(dt, { format: "basic" });

const getEvent = ({ startTime, game, timezone }) => ({
  startDatetime: atcformat(startTime),
  endDatetime: atcformat(add(startTime, { hours: 1 })),
  description: i18n.formatString(i18n.joinYourTeamForGame, startTime.toLocaleString(),game.name),
  duration: 1,
  location: "Online",
  title: i18n.formatString(i18n.titleJoinGame,game.name),
  timezone,
});

const ScheduleGameModal = ({ onSuccess, onClose, game = {} }) => {
  const firebase = useFirebase();
  const [event, setEvent] = useState(null);

  const { name: title, gpid } = game;

  // Believe it or not, this is the most efficient way to get the user's timezone.
  const timezone = useMemo(
    () =>
      Intl.DateTimeFormat(undefined, { timeZoneName: "long" })
        .formatToParts()
        .find(({ type }) => type === "timeZoneName")?.value,
    []
  );

  const onFormSubmit = ({ date, ...values }) => {
    const ev = getEvent({ startTime: new Date(date), game, timezone });
    return firebase
      .functions()
      .httpsCallable("schedule")({
        gpid,
        starts_on: date,
        event: decodeURIComponent(atcUtils
          .buildShareUrl(ev))
          .replace("METHOD:PUBLISH", "METHOD:REQUEST"),
        ...values,
      })
      .then(() => {
        onSuccess();
        setEvent(ev);
      })
      .catch(console.error);
  };

  const isOpen = !!Object.keys(game).length;
  if (!isOpen) return null;

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      {isOpen && (
        <>
          <DialogTitle
            className={css`
              padding-bottom: 0;
            `}
          >
            Schedule <b>{title}</b> for your team
          </DialogTitle>
          <DialogContent>
            <ScheduleGameForm
              game={game}
              onSubmit={onFormSubmit}
              event={event}
            />
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default ScheduleGameModal;
