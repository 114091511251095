// Load event history (Requires company manager auth)
async function loadHistory(firebase) {
    const { data } = await firebase.functions().httpsCallable('loadBookings').call();
    return data;
}

// Load history for each company (requires sysadmin auth)
async function loadCompanyHistory(firestore, companyID) {
    const history = {};
    const snapshot = await firestore.collection('orders').where('companyID', '==', companyID).get();

    for (let doc of snapshot.docs) {
        history[doc.id] = doc.data();
    }

    return history;
}

// Write a new event history entry (requires sysadmin auth)
async function addEntry(firestore, companyId, entry) {
    await firestore.collection('companies').doc(companyId).collection('event-history').add(entry);
    await firestore.collection('companies').doc(companyId).update({active: true});
}

export { loadHistory, loadCompanyHistory, addEntry };
