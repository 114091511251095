import React, {useEffect, useState} from "react";
import { css } from "@emotion/css";
import LongGiftingWidget from "../../../components/Dashboard/Gifting/LongGiftingWidget";
import GiftingOrderWidgetRow from "../../../components/Dashboard/Gifting/GiftingOrderWidgetRow";
import GiftingTriggers from "../../../components/Dashboard/Gifting/GiftingTriggers";
import {
  makeStyles,
  Box,
  Typography,
  Tabs,
  Tab,
} from "@material-ui/core";
import {dummyStats, exampleData} from "./GiftingData";
import { useFirestore } from "react-redux-firebase";
import {loadGiftPackages} from "../../../util/database/gifts";
import { Helmet } from "react-helmet";
import i18n from "util/i18n";

const useStyles = makeStyles({
  tabStyle: {
    fontSize: "32px",
    fontWeight: 700,
    textTransform: "capitalize",
    borderBottom: "3px solid #C4C4C4",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Gifting() {
  const [tabValue, setTabValue] = React.useState(0);
  const [giftPackages, setGiftPackages] = useState([]);
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  const firestore = useFirestore();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (loading) {
        setLoading(false);
        loadGiftPackages(firestore).then(setGiftPackages);
    }
}, [firestore, loading]);

  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>WFHomie | Gifting </title>
      </Helmet>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        className={css`
          margin-top: 30px;
          font-family: Seravek;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 25px;
          text-align: center;
          color: #2300F8;
        `}
      >
        <Tab  label={i18n.gifting} className={classes.tabStyle} />
        <Tab label={i18n.orderHistory} className={classes.tabStyle} />
      </Tabs>

      <TabPanel
        value={tabValue}
        index={0}
        className={css`
          > div {
            padding-left: 22px;
          }
        `}
      >
        <GiftingTriggers giftPackages={giftPackages}/>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        {/* if no orders have been made, show blank page */}
        {exampleData.length === 0 ? (
          <div
            className={css`
              display: flex;
              width: 100%;
              text-align: center;
              justify-content: center;
              flex-direction: column;
              color: #909090;
            `}
          >
            <div>
              <img
                alt="empty gifting"
                src="/static/svg/undrawGift.svg"
                className={css`
                  width: 300px;
                  height: auto;
                  margin-bottom: 24px;
                `}
              ></img>
            </div>
            <div>
                {i18n.youHaventOrderAnyGiftYetWhyNot}
            </div>
            <div>
              <span
                className={css`
                  text-decoration: underline;
                  cursor: pointer;
                  color: #0022cc;
                `}
                onClick={() => setTabValue(0)}
              >
                  {i18n.browse}
              </span>
                {i18n.ourCollectionOf}
            </div>
          </div>
        ) : (
          <>
            <LongGiftingWidget stats={dummyStats} />
            <GiftingOrderWidgetRow exampleData={exampleData} />
          </>
        )}
      </TabPanel>
    </>
  );
}

export default Gifting;
