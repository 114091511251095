import React from 'react';
import { css } from '@emotion/css';

export const FormLabel = ({ label }) => (
    <h2 className={css`
        color: hsl(230, 20%, 15%);
        font-weight: 700;
        font-size: 14px;

        text-transform: uppercase;
        letter-spacing: 0.09em;

        margin: 0;
        margin-bottom: 20px;
    `}>{label}</h2>
);

const FormSection = ({ label, description, children, columns }) => {
    columns = columns === undefined ? 2 : columns;

    return <div className={css`
             background-color: white;
             border-radius: 8px;
             padding: 20px;
             margin: 20px 0;
             border: 1px solid hsl(230, 10%, 80%);
        `}>
        <FormLabel label={label} />
        {description && <p className={css`
            font-size: 14px;
            color: hsl(230, 10%, 30%);
            margin-top: -5px;
            margin-bottom: 15px;
            white-space: pre-wrap;
        `}>{description}</p>}
        <div className={css`
            display: grid;
            grid-gap: 20px;
            grid-template-columns: repeat(${columns}, 1fr);
        `}>{children}</div>
    </div>;
}

export default FormSection;
