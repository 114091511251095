import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";

export const withToastNotification = (WrappedComponent) => (props) => {
    const [toastState, setToastState] = useState({ message: "", severity: "", isOpen: false });

    const toastNotification = (message, severity) => {
        setToastState({ message, severity, isOpen: true });
    };
    return (
        <>
            <WrappedComponent {...props} toastNotification={toastNotification} />

            <Snackbar
                open={toastState.isOpen}
                autoHideDuration={8000}
                onClose={() => setToastState({ ...toastState, isOpen: false })}
            >
                <Alert
                    variant="filled"
                    severity={toastState.severity}
                    onClose={() => setToastState({ ...toastState, isOpen: false })}
                    sx={[
                        {
                            position: "fixed",
                            bottom: "20px",
                            left: "20px",
                            borderRadius: "20px",
                            borderWidth: "0px",
                        },
                    ]}
                    elevation={4}
                >
                    {toastState.message}
                </Alert>
            </Snackbar>
        </>
    );
};
