import React from "react";
import {css} from '@emotion/css';
import {Field, reduxForm} from 'redux-form';
import {renderTextField, SubmitButton, renderNumberField} from 'components/Forms';
import {DynamicTextField, DynamicDropdown} from "components/Forms/DynamicInputs";
import FormSection from 'components/Forms/FormSection';
import { eventTags, eventThemes } from "util/constants/events";


// TODO These should be moved to the firestore for localization
const themes = Object.values(eventThemes);
const tags = Object.values(eventTags);

// typescript would be great right about now - for reference
// const allFields = [
//     "coverImageUrl",
//     "description",
//     "duration",
//     "emote",
//     "external_link",
//     "id",
//     "images",
//     "physicalPackage",
//     "pricePerPerson",
//     "reviews",
//     "seoHeader",
//     "suggestedAudienceSize",
//     "tags",
//     "themes",
//     "title",
//     "whatDo",
//     "whatNeed"
// ]

const validate = (values) => {
    const requiredFields = [
        'title',
        'description',
        'suggestedAudienceSize',
        'pricePerPerson',
        'duration',
        'coverImageUrl'
    ];

    const errors = {};

    for (let field of requiredFields) {
        if (!values[field] || values[field].length === 0) {
            errors[field] = 'Required';
        }
    }
    return errors;
}

const EventEntryForm = reduxForm({ form: 'eventDBForm', validate})(
    ({invalid, onSubmit, isEdit, questions, fromAutofill}) => {
        return <div className={css`width: 80%; margin: 0 auto;`}>
            {isEdit && <h2 className={css`color: red; text-align: center;`}>
                        You are currently editing this event. Your changes here will override the previous version.
                    </h2>}
            <form>
                <FormSection label={isEdit? 'Edit New Event' : 'Add New Event'} columns={5}>
                    <Field name='themes' placeholder='Select Theme(s) (Required)' component={DynamicDropdown}
                                        fromAutofill={fromAutofill}
                                        menuItems={themes} className={css`grid-column: 2 / span 4;`}/>
                    <Field name='emote' label='Emote' component={renderTextField} className={css`grid-column: 1 / span 1;`}/>
                    <Field name='title' label='Title *' component={renderTextField} className={css`grid-column: 2 / span 4;`}/>
                    <Field name='seoHeader' label='Header (SEO)' component={renderTextField} className={css`grid-column: 1 / span 2;`}/>
                    <Field name='description' label='Description *' component={renderTextField} className={css`grid-column: 3 / span 3;`}/>
                    <Field name='physicalPackage' label='Physical Package' component={renderTextField} className={css`grid-column: 1 / span 2;`}/>
                    <Field name='external_link' label='Link' component={renderTextField} className={css`grid-column: 3 / span 3;`}/>
                    <Field name='suggestedAudienceSizeMin' label='Min Audience Size *' component={renderNumberField} className={css`grid-column: 1 / span 1;`}/>
                    <Field name='suggestedAudienceSizeMax' label='Max Audience Size *' component={renderNumberField} className={css`grid-column: 1 / span 2;`}/>
                    <Field name='durationMin' label='Min Duration (min) *' component={renderNumberField} className={css`grid-column: 1 / span 1;`}/>
                    <Field name='durationMax' label='Max Duration (min) *' component={renderNumberField} className={css`grid-column: 3 / span 1;`}/>
                    <Field name='pricePerPerson' label='Price Per Person ($) *' component={renderNumberField}/>

                    <Field name='whatDo' label='What Do' component={renderTextField} multiline={true} rows={1} className={css`grid-column: 1 / span 5;`}/>
                    <Field name='whatNeed' component={DynamicTextField} descriptor={"What Need "} minimum={1} fromAutofill={fromAutofill} className={css`grid-column: 1 / span 2;`}/>
                    <Field name='coverImageUrl' label='Cover Image (Thumbnail in card) *' component={renderTextField} className={css`grid-column: 1 / span 2;`}/>
                    <Field name='imageUrls' descriptor='Image ' limit={3} minimum={3} component={DynamicTextField} fromAutofill={fromAutofill}/>

                    {/* These still have to be comma seperated because i'm too lazy to make a dual component */}
                    {/* (Format: Rating, Review - e.g. 5, Great!) */}
                    <Field name='reviews' descriptor={"Review "} component={DynamicTextField} fromAutofill={fromAutofill}
                            className={css`grid-column: 1 / span 2;`}/>

                    <Field name='tags' placeholder='Select Tag(s)' component={DynamicDropdown} fromAutofill={fromAutofill} menuItems={tags} className={css`grid-column: 1 / span 5;`}/>
                    <Field name='questions' placeholder='Select Questions(s)' component={DynamicDropdown} fromAutofill={fromAutofill}
                            menuItems={questions} className={css`grid-column: 1 / span 5;`}/>

                </FormSection>
            </form>
            <SubmitButton onClick={onSubmit} disabled={invalid}/>
        </div>;
    }
);

export default EventEntryForm;
