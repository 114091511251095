import React from "react";
import { Panels } from "./Panels";
import { SlackButtonDark } from "components/Forms/Auth/AuthButton";
import { Pricing } from "./Pricing";
import { Testimonial } from "./Testimonial";
import { Link } from "react-router-dom";
import CollapsibleInstructions from "./CollapsibleInstructions";
import 'flowbite';

const testimonials = [
    {
        description:
            "It went so smoothly and our team loved it. The event was also a breeze to set up with WFHomie’s support. It made my life as an HR team of one, MUCH easier.",
        name: "Courtney - Format",
        image: "/static/images/courtney-format.jpeg",
    },
    {
        description:
            "Right from the get go in terms of organizing the event to the delivery of the session, they delivered top notch service and were very personable throughout the process/session!",
        name: "Dionne - Google",
        image: "/static/images/dionne-google.jpeg",
    },
];

export const KudosLandingPageExtendedContent = () => {
    return (
        <>
            <div className="flex relative">
                <img className="w-full h-24 md:h-auto" src="/static/svg/green-wave.svg" alt="Decorative green wave" />
                <div className="absolute inset-x-0 top-0 z-0 -bottom-10 w-full">
                    <div className="flex flex-col items-center justify-center space-y-6 lg:p-20 p-4">
                        <span className="z-10 text-kudos-dark-blue font-bold text-md lg:text-3xl text-center">
                            40% of people put more energy into their work when they feel recognized.
                        </span>
                        <span className="z-10 text-kudos-dark-blue font-bold text-3xl text-center hidden lg:block">
                            Create a culture of team appreciation! 🙌
                        </span>
                    </div>
                </div>
            </div>

            <Panels />

            <div className="flex flex-col items-center p-8 space-y-8">
                <span className="text-primary font-bold leading-relaxed lg:leading-relaxed text-2xl lg:text-5xl">
                    The simplest way to boost team morale
                </span>

                <span>Build a culture of feedback and celebrate your team wins with a public praise.</span>

                <SlackButtonDark />
            </div>

            <div className="flex justify-center p-16 space-y-12 md:space-x-6 lg:space-y-0 bg-kudos-blue-bg flex-wrap items-center">
                {testimonials.map((t) => (
                    <Testimonial {...t} />
                ))}
            </div>

            <Pricing />


            <div className="flex flex-col items-center p-8 space-y-8">
                <span className="text-primary font-bold leading-relaxed lg:leading-relaxed text-2xl lg:text-5xl">
                    How it works
                </span>

                <iframe width="640" height="360" src="https://www.youtube.com/embed/Kur6vYEWTCI" title="WFHomie Kudos Bot for Slack" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;" allowfullscreen></iframe>

            </div>

            <div className="mb-20 mt-8 max-w-6xl mx-auto">
                <CollapsibleInstructions />
            </div>

            <div className="flex flex-col items-center p-16 space-y-6 bg-kudos-green-bg">
                <span className="text-kudos-dark-blue font-bold leading-relaxed text-xl lg:text-3xl">
                    WFHomie brings joy to the workplace 🥳
                </span>
            </div>


            <div className="flex w-full">
                <img className="" src="/static/images/kudos-zoom.png" alt="People having fun on a virtual event" />
            </div>

            <div className="flex flex-col items-center p-16 space-y-6 bg-kudos-pink">
                <span className="text-white text-3xl">Start appreciating your peers for free!</span>

                <div className="flex md:space-x-8 md:space-y-0 flex-wrap justify-center space-y-4">
                    <SlackButtonDark />
                    <a
                        className="h-1"
                        href="https://www.producthunt.com/posts/kudos-by-wfhomie?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-kudos-by-wfhomie"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            className="h-12"
                            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=319039&theme=dark"
                            alt="Kudos by WFHomie - Give kudos and recognize your teammates on Slack | Product Hunt"
                        />
                    </a>
                </div>
                <div>
                    <Link to="/privacy-center" className="text-white text-lg underline">
                        Privacy Policy
                    </Link>
                </div>
            </div>
        </>
    );
};
