import React from 'react';

import { Field } from 'redux-form';

import { renderDatePicker } from 'components/Forms';
import FormSection from 'components/Forms/FormSection';
import i18n from "../../../util/i18n";

const Birthday = () => (
    <FormSection
        label={i18n.birthDay}
        description={i18n.birthDayMsg}
    >
        <Field
            label={i18n.birthLabel} 
            name='birthday'
            component={renderDatePicker}
        />
    </FormSection>
);

export default Birthday;
