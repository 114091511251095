import React from "react";
import {css} from '@emotion/css';
import {Field, reduxForm} from 'redux-form';
import {renderTextField, SubmitButton, renderValueDropdown} from 'components/Forms';
import FormSection from 'components/Forms/FormSection';

const totalTypes = ["text", "number", "calendar", "select", "checkmark"];

const validate = (values) => {
    const requiredFields = [
        'title',
        'label',
        'type',
    ];

    const errors = {};

    for (let field of requiredFields) {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    }
    
    return errors;
}

const QuestionEntryForm = reduxForm({ form: 'questionsDBForm', validate})(
    ({invalid, onSubmit, isEdit, enableOptions}) => {
        return <div className={css`width: 60%; margin: 0 auto;`}>
            {isEdit && <h2 className={css`color: red; text-align: center;`}>
                        You are currently editing this question. Your changes here will override the previous version.
                    </h2>}
            <form>
                <FormSection label={isEdit? 'Edit New Question' : 'Add New Question'} columns={2}>
                    <Field name='title' label='Question Text' component={renderTextField} className={css`grid-column: 1 / span 2;`}/>
                    <Field name='description' label='Question Description' component={renderTextField} className={css`grid-column: 1 / span 2;`}/>
                    <Field name='label' label='Question Placeholder/Label' component={renderTextField} className={css`grid-column: 1 / span 2; margin-bottom: 20px;`}/>
                    <Field name='type' label='Question Type' component={renderValueDropdown} menuItems={totalTypes}/>
                    <Field name='options' label='Options (comma seperated)' component={renderTextField} disabled={enableOptions}/>
                </FormSection>
            </form>
            <SubmitButton onClick={onSubmit} disabled={invalid}/>
        </div>;
    }
);

export default QuestionEntryForm;
