import React from "react";
import { css, keyframes } from "@emotion/css";

const animation = keyframes`
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}`;

const style = (size, width, color) => css`
    display: block;
    position: relative;
    width: ${size}px;
    height: ${size}px;

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: ${size - 8}px;
        height: ${size - 8}px;
        margin: 4px;
        border: ${width}px solid white;
        border-radius: 50%;
        animation: ${animation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: ${color} transparent transparent transparent;
    }
    div:nth-child(1) {
        animation-delay: -0.45s;
    }
    div:nth-child(2) {
        animation-delay: -0.3s;
    }
    div:nth-child(3) {
        animation-delay: -0.15s;
    }
`;

function CenteredLoadingIcon({ size, thickness, color }) {
    if (size === undefined) {
        size = 80;
    }
    if (thickness === undefined) {
        thickness = 8;
    }
    if (color === undefined) {
        color = "hsl(230, 20%, 80%)";
    }
    return (
        <div
            className={css`
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
            `}
        >
            <div className={style(size, thickness, color)}>
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    );
}

export default CenteredLoadingIcon;
