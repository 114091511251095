import React from "react";
import CardPage from "../../../components/Dashboard/CardPage"

import {useSelector} from "react-redux";

function Events({type = "events", showVoteButton}) {
    const userData = useSelector((state) => state.userinfo);
    const loggedIn = Boolean(userData);

    return <>
        <CardPage   type={type}
                    showFilters={true}
                    showCustomGames={false}
                    pageTitle={"Events"}
                    hasPageImage={false}
                    showVoteButton={showVoteButton !== undefined ? showVoteButton : loggedIn}/>
    </>;
}

export default Events;
