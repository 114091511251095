import {css} from '@emotion/css';

/**
 * Take the role name and generate a consistent color for it
 * @param string role name
 * @returns {number} color number between 0 to 360
 */
function stringToColorCode(string) {
    let hash = 30;
    if (!string || string.length === 0) {
        return hash;
    }
    string = string.toLowerCase();
    for (let i = 0; i < string.length; i++) {
        const char = string.charCodeAt(i);
        hash = ((hash << 4) - hash) + char;
        hash = hash & hash;
    }
    // hash += 200; //this could be used to change the pallet
    return hash % 360;
}

function InfoBadge({info, onClick}) {
    return <div className={css`
            border-radius: 80px;
            padding: 6px 15px;
            background-color: hsl(${stringToColorCode(info)}, 100%, 37%);
            margin-right: 0px;
            color: white;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.07em;
            text-align: center;

            transition: background-color 0.15s ease-in-out;
            ${onClick && 'cursor: pointer;'}

            :hover {
                ${onClick && `background-color: hsl(${stringToColorCode(info)}, 100%, 30%);`}
            }
        `} onClick={onClick}>
        {info || 'None'}
    </div>;
}

export default InfoBadge;
