import React from 'react';
import { css } from '@emotion/css';

import Chip from '@material-ui/core/Chip';


const FilterItem = ({label, selected, onClick}) => <Chip
    label={label}
    onClick={onClick}
    onDelete={selected ? onClick : undefined }
    color={selected ? 'primary' : undefined}
    size='small'
/>;

const toggleInclusion = (filters, tag) => {
    if (filters.includes(tag)) {
        return filters.filter(x => x !== tag);
    } else {
        return [...filters, tag];
    }
}

const FilterSelect = ({filters, setFilters, allTags}) => {
    console.log(allTags);
    return <div className={css`
        display: inline-flex;
        gap: 8px;
        align-items: center;
        padding: 8px 24px;
        max-width: 100%;

        color: hsl(235, 10%, 40%);
        border-radius: 8px;

        position: sticky;
        top: 100px;

        z-index: 300;
    `}>
        Filter:
        <div className={css`
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 8px;
        `}>
        {allTags.map(tag => <FilterItem
            label={tag}
            selected={filters.includes(tag)}
            onClick={() => setFilters(
                toggleInclusion(filters, tag)
            )}
        />)}
        </div>
    </div>;
}

export { toggleInclusion };
export default FilterSelect;
