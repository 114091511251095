import { useState } from 'react';
import { css } from "@emotion/css";
import { connect } from 'react-redux';
import FormSection from 'components/Forms/FormSection';
import i18n from "util/i18n";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddToSlackButton, { SlackIntegration } from 'components/util/AddToSlackButton';
import OutlinedButton from 'components/util/OutlinedButton';
import { Dialog } from '@headlessui/react';
import CenteredLoadingIcon from 'components/util/CenteredLoadingIcon';
import { useFirebase } from 'react-redux-firebase';
import { withToastNotification } from 'components/hocs/withToastNotification';

const ConfirmUninstallationModal = ({ isOpen, onClose, onProceed, loading }) => (
    <Dialog open={isOpen} onClose={() => loading ? {} : onClose()} className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            <div className="relative bg-white rounded max-w-md mx-auto p-10">
                <div className="flex flex-col gap-6">
                    <Dialog.Title className="text-center font-bold text-lg">{i18n.confirmKudosUninstallation.title}</Dialog.Title>
                    <Dialog.Description className="">{i18n.confirmKudosUninstallation.message}</Dialog.Description>
                    <div className="h-8">
                        {loading ?
                            <CenteredLoadingIcon size={40} thickness={4} />
                            : <div className="flex flex-row gap-5 justify-center">
                                <OutlinedButton onClick={onClose} >{i18n.cancel}</OutlinedButton>
                                <OutlinedButton onClick={onProceed} >{i18n.uninstall}</OutlinedButton>
                            </div>}
                    </div>

                </div>
            </div>
        </div>
    </Dialog>
);


const SlackSettings = ({ hasKudosIntegration, companyId, toastNotification }) => {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [uninstallingKudos, setUninstallingKudos] = useState(false);
    const [displayAddKudosButton, setDisplayAddKudosButton] = useState(!hasKudosIntegration);

    const firebase = useFirebase();

    const uninstallKudos = () => {
        setUninstallingKudos(true);

        firebase.functions().httpsCallable('uninstallKudos')({ companyId })
            .then(() => {
                setUninstallingKudos(false);
                setDisplayAddKudosButton(true);
                setShowConfirmationModal(false);
                toastNotification("Kudos has been uninstalled from your Slack workspace", 'success');
            })
            .catch(() => {
                setUninstallingKudos(false);
                setShowConfirmationModal(false);
                toastNotification("There was an error uninstalling Kudos from your Slack workspace. Please contact us if the problem persists.", 'error');
            });
    }

    return <>
        {showConfirmationModal && <ConfirmUninstallationModal
            isOpen
            onClose={() => setShowConfirmationModal(false)}
            onProceed={uninstallKudos}
            loading={uninstallingKudos}
        />}
        <FormSection
            label={i18n.slackIntegrations}
            columns={1}
        >
            <div className={css`
                display: grid;
                grid-template-columns: 1fr auto;
                grid-gap: 12px;
                align-items: center;
            `}>
                <p className={css`
                    font-size: 14px;
                    color: hsl(230, 10%, 30%);
                    max-width: 650px;
                    margin: 0;
                `}>
                    {i18n.formatString(i18n.addPollSlackBotMsg, <b>{i18n.wfhomiePoll}</b>)}
                    {/* Go to the events page and select "Invite team to vote" to create a poll. */}
                </p>
                <AddToSlackButton slackIntegration={SlackIntegration.POLL} />

                <div className={css`
                    grid-column: span 2;
                    border-top: 1px solid hsl(235, 30%, 80%);
                    margin: 4px 16px;
                `} />

                <p className={css`
                    font-size: 14px;
                    color: hsl(230, 10%, 30%);
                    max-width: 650px;
                    margin: 0;
                `}>
                    {i18n.formatString(i18n.slackKudosBotMsg, <b>{i18n.wfhomieKudos}</b>)}
                </p>
                {displayAddKudosButton
                    ? <AddToSlackButton slackIntegration={SlackIntegration.KUDOS} />
                    : <div className="flex flex-col items-center gap-2">
                        <div className="flex justify-center gap-1 pr-0.5 py-1">
                            <CheckCircleIcon color="success" fontSize="small" />
                            <span>{i18n.installed}</span>
                        </div>
                        <OutlinedButton onClick={() => setShowConfirmationModal(true)}>
                            <span>{i18n.uninstall}</span>
                        </OutlinedButton>
                    </div>
                }
            </div>
        </FormSection>
    </>
}

const mapStateToSlackSettingsProps = state => (
    {
        hasKudosIntegration: !!state.userinfo?.company?.kudosSlackRef,
        companyId: state.userinfo?.company?.id,
    }
);

export default connect(mapStateToSlackSettingsProps)(withToastNotification(SlackSettings));

