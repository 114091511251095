import FormSection from "../../Forms/FormSection";
import React from "react";
import {
    renderCheckboxArray,
    renderDatePicker,
    renderNumberField,
    renderRadiobuttonArray,
    renderTextField
} from "../../Forms";
import {Field} from "redux-form";

function VisualQuestionCard({question}) {
    return <FormSection label={question.title} description={question.description} columns={1}>
        {VisualQuestionInput(question)}
    </FormSection>
}

function VisualQuestionInput(question) {
    // These components are purely visual and not meant to be functional
    switch (question.type) {
        case "text":
            return <Field component={renderTextField} label={question.label} name={question.id}/>;
        case "number":
            return <Field component={renderNumberField} label={question.label} name={question.id}/>;
        case "select":
            return <Field component={renderRadiobuttonArray} labels={question.options} label={question.label}
                          name={question.id}/>;
        case "calendar":
            return <Field component={renderDatePicker} label={question.label} name={question.id}/>;
        case "checkmark":
            return <Field component={renderCheckboxArray} label={question.label} name={question.id}
                          labels={question.options}/>;
        default:
            throw new Error("Invalid input type");
    }
}

export default VisualQuestionCard;
