
import { LandingPage } from "./LandingPage"

import Events from "../Dashboard/SidebarLinks/Events"

export const FallEventsPage = () => {
    return <LandingPage>
    <Events type="fall-events" showVoteButton={false}/>
</LandingPage>
}
