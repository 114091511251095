import { stage } from "util/stage";

const production = {
    apiKey: "AIzaSyBiFG-XKGTMTtWVu4jm3zNINurjj-nEJK0",
    authDomain: "wfhomie-85a56.firebaseapp.com",
    projectId: "wfhomie-85a56",
    storageBucket: "wfhomie-85a56.appspot.com",
    messagingSenderId: "174859611904",
    appId: "1:174859611904:web:f8db6b649226fc67908de5",
    measurementId: "G-WKY1P1BW2B"
};

const dev = {
    apiKey: "AIzaSyBwUFH_esoUKKO2wkfbyGo9y12sxkISYow",
    authDomain: "wfhomietest.firebaseapp.com",
    projectId: "wfhomietest",
    storageBucket: "wfhomietest.appspot.com",
    messagingSenderId: "947399336078",
    appId: "1:947399336078:web:df83f33bb364d39c3ae9df",
    measurementId: "G-5982TWX9G5"
};

export default stage.isProduction() ? production : dev;
