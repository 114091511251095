import React from "react";

import { ShameButtonDark } from "components/Forms/Auth/AuthButton";
import { Checkmarks } from "./checkmarks";
import { Logos } from "./logos";
import { TwoPanelLandingPage } from "./twoPanelLandingPage";
import { WFHomieLogo } from "./wfhomieLogo";
import { useHistory } from "react-router";
import { KudosLandingPageExtendedContent } from "./KudosLandingPageContent";

export const ShameSignupPage = () => {
    const history = useHistory();

    return (
        <div className="flex justify-center">
            <div className="max-w-screen-2xl w-full">
                <TwoPanelLandingPage
                    leftPanel={
                        <>
                            <WFHomieLogo />

                            <span className="text-kudos-green uppercase font-bold">Do you hate your co-workers?</span>

                            <span className="text-primary font-bold leading-relaxed lg:leading-relaxed text-2xl lg:text-5xl">
                                Shame your teammates and highlight their weaknesses on Slack 😏
                            </span>

                            <Checkmarks />

                            <div className="flex flex-wrap space-x-0 md:space-x-4 space-y-4 md:space-y-0">
                                <ShameButtonDark
                                    onClick={() => history.push("/signup?source=shame&redirect=/redirectToAddShame")}
                                />
                                <a
                                    className="h-12 w-content"
                                    href="https://www.producthunt.com/posts/shamebot-by-wfhomie-1?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-shamebot&#0045;by&#0045;wfhomie&#0045;1"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        className="h-12"
                                        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=338658&theme=dark"
                                        alt="ShameBot&#0032;by&#0032;WFHomie - Publicly&#0032;shame&#0032;your&#0032;teammates&#0032;on&#0032;Slack&#0032;like&#0032;the&#0032;deserve&#0033; | Product Hunt"
                                    />
                                </a>
                            </div>
                        </>
                    }
                    rightPanel={
                        <div>
                            <img src="/static/images/shame.png" alt="Shame Slack" />
                        </div>
                    }
                />
                <div style={{paddingLeft: 30, paddingRight: 30 }}> <Logos /> </div>

                <KudosLandingPageExtendedContent />
            </div>
        </div>
    );
};
