import React, { useState, useEffect } from "react";
import { css } from "@emotion/css";

import { useFirebase, useFirestore } from "react-redux-firebase";

import Modal from "react-modal";

import Button from "@material-ui/core/Button";

import i18n from "util/i18n";
import { useSelector } from "react-redux";
import { withToastNotification } from "components/hocs/withToastNotification";

function MigrationModal({toastNotification}) {
    const userData = useSelector((state) => state.userinfo);
    const firebase = useFirebase();
    const firestore = useFirestore();
    const [open, setOpen] = useState(false);
    const [migrating, setMigrating] = useState(false);

    const domain = userData ? userData.email.split("@")[1] : "";
    const disableModal = Boolean(userData?.hideMigrationModal);

    useEffect(() => {
        (async () => {
            if (!firebase) {
                return;
            }
            if (!userData || disableModal) {
                return;
            }

            console.log("Checking for available migration...");
            let migrationAvailable = false;
            try {
                migrationAvailable = (await firebase.functions().httpsCallable("checkMigration")()).data;
            } catch (e) {
                console.error("Checking migration failed:", e);
                return;
            }

            console.log("Migration available:", migrationAvailable);
            setOpen(migrationAvailable);
        })();
    }, [firebase, userData, disableModal]);

    const dontAskAgain = async () => {
        await firestore.collection("userinfo").doc(userData.userId).update({ hideMigrationModal: true });
        setOpen(false);
    };

    const performMigration = async () => {
        setMigrating(true);
        const success = await firebase.functions().httpsCallable("performMigration")();
        if (!success) {
            toastNotification(i18n.migrationFailed, "error");
        }
        window.location.assign("/dashboard/events");
    };

    return (
        <Modal isOpen={open} onRequestClose={() => setOpen(false)}>
            <h1
                className={css`
                    margin: 0;
                    font-size: 20px;
                    font-weight: 500;
                    margin-bottom: 24px;
                `}
            >
                {i18n.joinExistingCompany}
            </h1>

            <div>
                {i18n.alreadyCompanyUsingEmail}
                <b
                    className={css`
                        font-weight: 700;
                    `}
                >
                    @{domain}
                </b>
                .
            </div>
            <div>{i18n.joinThemInstead}</div>

            <div
                className={css`
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 8px;
                    margin-top: 32px;
                `}
            >
                <Button variant="outlined" color="secondary" onClick={dontAskAgain}>
                    {i18n.dontAskAgain}
                </Button>
                <Button variant="contained" color="primary" onClick={performMigration} disabled={migrating}>
                    {migrating ? i18n.processing : i18n.join}
                </Button>
            </div>
        </Modal>
    );
}

export default withToastNotification(MigrationModal);
