import React from 'react'
import { css } from '@emotion/css';

import { mq } from "../util/Breakpoints";


function Announcement({children}) {
    return <div className={css`
        display: flex;
        justify-content: center;
        align-items: center;

        position: sticky;
        top: 80px;
        z-index: 100;

        background-color: hsl(40, 80%, 70%);

        padding: 8px 20px;
        min-height: 48px;

        color: hsl(40, 50%, 30%);
        font-size: 16px;
        box-shadow: 1px 1px 4px hsl(40, 10%, 10%, 0.1);

        ${mq[2]} {
            font-size: 14px;
        }
    `}>
        {children}
    </div>;
}

export default Announcement;
