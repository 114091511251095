import React, { useState } from 'react';
import { css } from '@emotion/css';
import VoteInviteModal from './VoteInviteModal';
import i18n from "util/i18n";
import { withToastNotification } from 'components/hocs/withToastNotification';

const VoteButton = ({text, backgroundColor, hoverColor, onClickFunction}) => {
    //toastNotification(`${i18n.successGetYourTeamVoting}🎉`, 'success')
    return <div className={css`
                background-color: ${backgroundColor};
                color: white;
                font-size: 16px;
                font-weight: bold;
                border-radius: 20px;
                box-shadow: 1px 1px 4px hsla(230, 10%, 10%, 0.3);
                display: flex;
                flex-direction: column;
                text-align: center;
                justify-content: center;
                cursor: pointer;
                padding: 12px 14px;
                margin-bottom: 16px;
                width: 300px;
                &:hover {
                    background-color: ${hoverColor};
                }
            `}
            onClick={onClickFunction}>
            {text}
        </div>
}

// Button to open a vote invite form modal
const VoteInviteButton = ({setPolling, eventsToPoll, setEventsToPoll, toastNotification}) => {
    const [votingEnabled, setVotingEnabled] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);

    function handleEnableVoting(){
        setVotingEnabled(true);
        setPolling(true);
    }

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function handleStopVoting() {
        setVotingEnabled(false);
        setPolling(false);
        setEventsToPoll([]);
    }

    return <>
        {
            votingEnabled
            ?
            <>
            <VoteButton text={`${i18n.createPollWithSelectedEvents} (${eventsToPoll.length})`} backgroundColor={"#5da3ad"}
            hoverColor={"#457880"} onClickFunction={openModal}/>

            <VoteButton text={i18n.exitPolling} backgroundColor={"#ba1818"}
            hoverColor={"#780e0e"} onClickFunction={handleStopVoting}/>

            <VoteInviteModal eventsToPoll={eventsToPoll} modalIsOpen={modalIsOpen} closeModal={closeModal} toastNotification={toastNotification}/>
            </>
            :
            <VoteButton text={i18n.pollYourTeam} backgroundColor={"#5da3ad"}
                        hoverColor={"#457880"} onClickFunction={handleEnableVoting}/>
    }
    </>;
}

export default withToastNotification(VoteInviteButton);
