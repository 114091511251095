import React, { useState }  from 'react';

import { useFirestore } from 'react-redux-firebase';
import { loadGiftHistory, addGiftHistory } from 'util/database/gifts';
import { loadMembers } from 'util/database/users';

import LoadingIcon from 'components/util/LoadingIcon';
import DBPage from 'components/Dashboard/Sysadmin/GiftDB';

function GiftDB({companyInfo}) {
    const firestore = useFirestore();
    const [giftList, setGiftList] = useState([]);
    const [employees, setEmployees] = useState({});

    const [loadingEmployees, setLoadingEmployees] = useState(true);
    const [loadingGifts, setLoadingGifts] = useState(true);

    if (!employees.length && loadingEmployees) {
        setLoadingEmployees(false);
        loadMembers(firestore, companyInfo).then(setEmployees);
    }

    if (!giftList.length && loadingGifts) {
        setLoadingGifts(false);
        loadGiftHistory(firestore, companyInfo).then(setGiftList);
    }

    const saveEntry = (entry) => addGiftHistory(firestore, companyInfo.id, entry);

    const loading = loadingEmployees || loadingGifts;

    return loading ? <LoadingIcon/> : <DBPage
        giftList={giftList}
        saveEntry={saveEntry}
        employeeList={employees}
    />;
}

export default GiftDB;
