import { React } from "react";
import i18n from "util/i18n";
import { Button, Grid } from "@mui/material";
import { TextField } from "@material-ui/core";
import FormSection from "components/Forms/FormSection";
import { css } from "@emotion/css";
import { useDispatch } from "react-redux";
import { useFirebase } from "react-redux-firebase";
import { withToastNotification } from "components/hocs/withToastNotification";
import * as yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { GoogleButton, MicrosoftButton } from "components/Forms/Auth/AuthButton";

const AuthUserModal = ({ toastNotification }) => {
    const dispatch = useDispatch();
    const firebase = useFirebase();
    const history = useHistory();

    const formikLogin = useFormik({
        initialValues: {
            emailLogin: "",
            passwordLogin: "",
        },
        validationSchema: yup.object().shape({
            emailLogin: yup.string().email(i18n.properEmailRequired).required(i18n.emailRequired),
            passwordLogin: yup.string().min(8, i18n.passwordLength).required(i18n.passwordRequired),
        }),
        onSubmit: async (values) => {
            try {
                await firebase
                    .login({
                        email: formikLogin.values.emailLogin,
                        password: formikLogin.values.passwordLogin,
                    })
                    .then(() => toastNotification(i18n.loginSuccess, "success"));
            } catch (error) {
                toastNotification(i18n.loginFailed, "error");
            }
        },
    });

    const signInWithOAuthProvider = (provider) => {
        dispatch({ type: "userinfo/unload" });
        firebase
            .login({ provider, type: "popup" })
            .then(() => {
                toastNotification(i18n.loginSuccess, "success");
            })
            .catch(() => {
                toastNotification(i18n.loginFailed, "error");
            });
    };

    const signInWithGoogle = () => signInWithOAuthProvider("google");
    const signInWithMicrosoft = () => signInWithOAuthProvider("microsoft.com");

    const handleSignUp = () => {
        history.replace({ pathname: "/signup" });
    };

    return (
        <>
            <FormSection
                label={<>{i18n.authentication}</>}
                description={<>{i18n.pleaseAuth}</>}
                columns={1}
            >
                <Grid container direction="column">
                    <div className="flex space-x-4 self-center">
                        <GoogleButton className="w-60" onClick={signInWithGoogle}></GoogleButton>
                        <MicrosoftButton className="w-60" onClick={signInWithMicrosoft}></MicrosoftButton>
                    </div>
                </Grid>
                <Grid>
                    <TextField
                        fullWidth
                        id="emailLogin"
                        name="emailLogin"
                        label={i18n.email}
                        variant="outlined"
                        className={css`
                            height: 100px;
                        `}
                        value={formikLogin.values.emailLogin}
                        onChange={formikLogin.handleChange}
                        error={formikLogin.touched.emailLogin && Boolean(formikLogin.errors.emailLogin)}
                        helperText={formikLogin.touched.emailLogin && formikLogin.errors.emailLogin}
                    />
                    <TextField
                        fullWidth
                        id="passwordLogin"
                        name="passwordLogin"
                        label={i18n.password}
                        type="password"
                        variant="outlined"
                        className={css`
                            height: 100px;
                        `}
                        value={formikLogin.values.passwordLogin}
                        onChange={formikLogin.handleChange}
                        error={formikLogin.touched.passwordLogin && Boolean(formikLogin.errors.passwordLogin)}
                        helperText={formikLogin.touched.passwordLogin && formikLogin.errors.passwordLogin}
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        className={css`
                            height: 42px;
                        `}
                        size="large"
                        type="submit"
                        disabled={!formikLogin.isValid || formikLogin.isSubmitting}
                        onClick={formikLogin.handleSubmit}
                    >
                        {i18n.signIn}
                    </Button>
                </Grid>

                <Grid
                    item
                    xs
                    className={css`
                        margin: 20px 0px;
                    `}
                >
                    <div
                        className={css`
                            text-align: center;
                            position: relative;
                            color: #ccc;
                            &::before,
                            &::after {
                                content: "";
                                position: absolute;
                                bottom: 50%;
                                height: 1px;
                                width: 43%;
                                background: #ccc;
                            }
                            &::before {
                                left: 0;
                                padding-right: 10px;
                            }
                            &::after {
                                right: 0;
                                padding-left: 10px;
                            }
                        `}
                    >
                        {i18n.or}
                    </div>
                </Grid>
                <Button
                    color="primary"
                    variant="contained"
                    className={css`
                        height: 42px;
                    `}
                    size="large"
                    onClick={() => handleSignUp()}
                >
                    {i18n.signUp}
                </Button>
            </FormSection>
        </>
    );
};

export default withToastNotification(AuthUserModal);
