
import Modal from 'react-modal';

export const initializeModals = () => {
    Modal.setAppElement('#root');

    Modal.defaultStyles.overlay = {
        ...Modal.defaultStyles.overlay,
        backgroundColor: 'hsl(230, 20%, 10%, 0.8)',
        zIndex: 50000,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    };

    Modal.defaultStyles.content = {
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
    };
}
