import React, {useEffect, useState} from "react";
import LoadingIcon from 'components/util/LoadingIcon';
import QuestionEntryForm from "components/Dashboard/Sysadmin/Forms/QuestionEntryForm";
import {addQuestion, updateQuestion, loadQuestions, deleteQuestion} from "util/database/questions";
import {useFirestore} from 'react-redux-firebase';
import {change} from 'redux-form';
import {useDispatch, useSelector} from 'react-redux';
import SysadminQuestions from "components/Dashboard/Questions/SysadminQuestions";

const typeWithOptions = ["select", "checkmark"];

function QuestionsDB() {
    const firestore = useFirestore();
    const dispatch = useDispatch();
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [enableOptions, setEnableOptions] = useState(false);

    const questionsForm = useSelector((state) => state.form.questionsDBForm);

    if (!questions.length && loading) {
        setLoading(false);
        loadQuestions(firestore).then((data) => {
            data.sort((a, b) => a.title.localeCompare(b.title));
            setQuestions(data);
        });
    }

    const normalize = (entries) => {
        if(entries["options"] && entries["options"].length > 0 && typeWithOptions.includes(entries["type"])) {
            if(typeof entries["options"] === "string") {
                entries["options"] = entries["options"].split(",");
            }
        } else {
            entries["options"] = [];
        }

        if(!entries["description"]) {
            entries["description"] = "";
        }

        return entries;
    }

    const questionsAutofill = (id) => {
        const info = questions.find(c => (c.id === id));

        for (let [key, value] of Object.entries(info)) {
            dispatch(change('questionsDBForm', key, value));
        }
    };

    useEffect(() => {
        setEnableOptions(!(questionsForm && questionsForm.values &&
                            typeWithOptions.includes(questionsForm.values.type)));
    }, [questionsForm])

    const handleSubmit = async () => {
        const info = normalize(questionsForm.values);

        if (isEdit) {
            await updateQuestion(firestore, info);
        } else {
            await addQuestion(firestore, info);
        }

        window.location.reload();
    };

    const handleQuestionEdit = async (entryID) => {
        setIsEdit(true);
        window.scrollTo(0, 0);
        await questionsAutofill(entryID);
    }

    const handleDeleteQuestion = async (entry) => {
        await deleteQuestion(firestore, entry);
        window.location.reload();
    }

    return loading ? <LoadingIcon/> :
        <>
            <QuestionEntryForm isEdit={isEdit} onSubmit={handleSubmit} enableOptions={enableOptions}/>
            <SysadminQuestions questions={questions}
                       editQuestion={handleQuestionEdit}
                       deleteQuestion={handleDeleteQuestion} />
        </>;
}

export default QuestionsDB;
