import {css} from "@emotion/css";
import React from "react";

function Tags({extraTags, tags, filled}) {
    return <div className={css`
        display: flex;
        flex-direction: row;
        justify-content: start;
        width: 100%;
        align-items: center;
        white-space: nowrap;
    `}>
        {extraTags && extraTags.map((eTag => eTag))}
        {tags.map(tag => 
        <div id={tag} className={css`
            margin-right: 8px;
            background-color: hsl(100, 40%, 100%);
            border: ${filled ? 'none' : '2px solid #1A38D1'};
            font-weight: ${filled ? 'initial' : 600};

            color: ${filled ? 'white' : '#1A38D1'};
            background-color: ${filled ? '#1A38D1' : 'none'};

            padding: 4px 12px;
            font-size: 14px;
            border-radius: 15px;

        `} key={"tag for " + tag}>
            {tag.toLowerCase()}
        </div>)}
    </div>;
}

export default Tags;
