import React from 'react';

import { Field } from 'redux-form';

import { renderCheckboxArray } from 'components/Forms';
import FormSection from 'components/Forms/FormSection';
import i18n from "util/i18n";

const dietaryRestrictions = {
    vegan: i18n.drVegan,
    vegetarian: i18n.drVegetarian,
    lactose: i18n.drLactose,
    nut: i18n.drNut,
    soy: i18n.drSoy,
    gluten: i18n.drGluten,
    kosher: i18n.drKosher,
    halal: i18n.drHalal,
};

const DietaryRestrictions = () => (
    <FormSection
        label={i18n.dietaryRestrictions}
        description={i18n.dietaryRestrictionsDesc}
    >
        <Field
            name='dietaryRestrictions'
            component={renderCheckboxArray}
            labels={dietaryRestrictions}
        />
    </FormSection>
);

export default DietaryRestrictions;
export {dietaryRestrictions};
