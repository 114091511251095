import { css } from "@emotion/css";
import { Field } from "redux-form";
import i18n from "util/i18n";
import FormSection from "../FormSection";

// Text area to add a custom request
const CustomRequests = ({ label }) => (
    <FormSection
        label={label ?? i18n.customRequests}
        columns={1}
    >
        <Field
            name="customRequests"
            component="textarea"
            className={css`
                width: 100%;
                height: 100px;
                resize: none;
                border: 1px solid #ccc;
                border-radius: 4px;
                padding: 10px;
                font-size: 16px;
                line-height: 1.5;
                color: #333;
                background-color: #fff;
                box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
                transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
            `}
        />
    </FormSection>
);

export default CustomRequests
