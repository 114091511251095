import React, {useEffect, useState } from 'react';
import { css } from '@emotion/css';

import pluralize from 'pluralize';

import { useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

import LoadingIcon from 'components/util/LoadingIcon';
import UserTable from 'components/Dashboard/UserTable';

import { loadMembers } from 'util/database/users';
import { getFileNamesAt} from 'util/storage';

import i18n from 'util/i18n';

// Company user view.
// If companyInfo is passed (e.g. sysadmin panel),
// it supercedes company info from userinfo.
function CompanyMembers({companyInfo}) {
    const firestore = useFirestore();
    const userInfo = useSelector(state => state.userinfo);
    const company = companyInfo || userInfo?.company;
    const companyFolder = `teamFiles/${company?.id}`; //TODO: the path for company folder should be changed to membersFiles/...

    const userRole = userInfo ? userInfo.role : "employee";
    const [members, setMembers] = useState(null);
    const [storedFiles, setStoredFiles] = useState(null);
    const [loadingMembers, setLoadingMembers] = useState(false);

    const dataLoaded = members && storedFiles && loadingMembers;

    useEffect(() => {
        getFileNamesAt(companyFolder).then(setStoredFiles);
    }, [companyFolder]);

    if (company && !members && !loadingMembers) {
        setLoadingMembers(true);
        loadMembers(firestore, company).then(setMembers);
    }
    
    // Load members info files currently stored in Firebase Storage


    return <div
        className={companyInfo ? css`
        display: flex;
        flex-direction: column;
        align-items: center;
    ` : css`
        padding: 1em;
        width: 480px;
        margin: calc(85px - 1em) auto 0;
    `}
    >

        {companyInfo ? null : <h1>{i18n.yourColleaguesAt}{" "}{company && company.name}</h1>}
        {dataLoaded && <p className={css`
            margin: 0;
            color: hsl(200, 10%, 30%);
            font-size: 16px;

            margin-top: 20px;

            margin-bottom: 50px;
        `}>{pluralize(i18n.member, Object.keys(members).length, true)}{" "}{i18n.registered}</p>}
        {dataLoaded ?
        <UserTable
            users={members}
            fil
            variant='superuser'
            companyFolder={companyFolder}
            storedFiles={storedFiles}
            setStoredFiles={setStoredFiles}
            companyInfo={companyInfo}
            userRole={userRole}
        /> : <div className={css`
            width: 100%;
            height: 500px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        `}><LoadingIcon /></div>}
    </div>;
}

export default CompanyMembers;
