import React, { useState, useEffect } from 'react';
import { css } from '@emotion/css';
import { mq } from "components/util/Breakpoints";

import i18n from "util/i18n";

import Button from '@material-ui/core/Button';
import DeleteModal from "../DeleteModal"
import LoadingIcon from 'components/util/LoadingIcon';
import Tags from 'components/util/Tags';


import { isToday, isTomorrow } from 'components/util/DateUtils';

import { loadClassRegistrations, deleteClass } from "util/database/classes"



const DurationBar = ({duration}) => <div className={css`
    grid-column: span 3;
`}>
    <div className={css`
    color: hsl(235, 20%, 40%);
    font-size: 12px;
    margin-bottom: 8px;
    font-weight: 700;
`}>{i18n.classLength}</div>
    <div className={css`
    height: 24px;
    background-color: hsl(235, 30%, 90%);
    width: 100%;
    max-width: 400px;
    border-radius: 8px;
`}>
        <div className={css`
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: hsl(235, 100%, 50%);
        width: ${Math.min(100 * duration/60, 100)}%;
        border-radius: 8px;

        font-size: 14px;
        color: hsl(235, 10%, 100%);
        padding: 0 12px;
        height: inherit;
    `}>{duration} minutes</div>
    </div>
</div>;


function ClassEntry({
    entry,
    classId,
    name,
    description,
    dateTime,
    host,
    duration,
    link,
    image,
    tags,
    timeZone,

    registered,
    onRegister,
    openConfirm,
    onEdit,
    openInvite,
    allUsersRegistered,

    isManager,
    sysadmin,
    firestore,
}) {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [registrationsList, setRegistrationsList] = useState([]);

    // Parse timestamp from event data
    dateTime = Date.parse(dateTime);

    const startTimestamp = new Date(dateTime).toLocaleString(i18n.localeString, {
        timeZone,
        hour: 'numeric',
        minute: 'numeric',
    });
    const endTimestamp = new Date(dateTime + duration * 60 * 1000).toLocaleString(i18n.localeString, {
        timeZone,
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName:'short',
    });

    // Allow access 15 minutes before event, until end
    const minutesLeft = (dateTime - Date.now()) / (60 * 1000);
    const ended = (minutesLeft + 15 < 0);

    useEffect(() => {
        if (sysadmin && classId) {
            loadClassRegistrations(firestore, classId).then(setRegistrationsList);
        }
        return () => { setRegistrationsList([]) }
    }, [firestore, classId, sysadmin]);

    // Save your spot by default
    let button = <Button fullWidth color='primary' variant='contained'
        onClick={() => {
            setLoading(true);
            (async () => {
                await onRegister();
                await openConfirm();
                setLoading(false);
            })();
        }}
    >{i18n.saveYourSpot}</Button>;

    if(ended && !sysadmin) {
        // if event has ended show it has ended
        button = <Button fullWidth color='primary' variant='contained' disabled={true}>{i18n.classHasEnded}</Button>;
    } else {
        if(loading && !registered) {
            // if registering is still in processs
            button = <LoadingIcon/>;
        } else if((minutesLeft < 15 && registered) || sysadmin){
            // if the event is soon and the user has registered for the event or is sysadmin
            button = <Button fullWidth color='primary' variant='contained'
                className={css`background-color: #EF8F71; :hover{background-color: #db633f;}`}
                onClick={() => window.open(link, '_blank')}>{i18n.joinClass}</Button>;
        } else if(registered){
            // if the user has registered
            button = <Button fullWidth color='primary' variant='contained'
                onClick={() => openConfirm()}>{i18n.openConfirmation}</Button>;
        }
    }

    let extraClassTags = [];
    let classTags = tags;

    // 15 minute interval on joining
    if (minutesLeft < 15 && !ended) {
        extraClassTags.push(<div
            key={'isNow'}
            id="isNow"
            className={css`margin-right: 8px;
                                background-color: #EF8F71;
                                border: none;
                                color: white;
                                padding: 4px 18px;
                                border-radius: 15px;
                                font-size: 14px;
                            `}>
            {i18n.happeningNow}
        </div>)
    } else if (isToday(dateTime)) {
        extraClassTags.push(<div
            key={'isToday'}
            id="isToday"
            className={css` margin-right: 8px;
                                border: none;
                                color: white;
                                padding: 4px 18px;
                                border-radius: 15px;
                                background-color: #18BD8C;
                                font-size: 14px;
                            `}>
            {i18n.happeningToday}
        </div>)
    } else if (isTomorrow(dateTime)) {
        extraClassTags.push(<div key={'isTom'} id="isTom" className={
            css` margin-right: 8px;
                border: none;
                color: white;
                padding: 4px 18px;
                border-radius: 15px;
                background-color: #915FD0;
                font-size: 14px;
            `}>
            {i18n.happeningTomorrow}
        </div>)
    }

    return <div key={`class card for ${name} ${classId}`}
        className={css`
        border-radius: 8px;
        background-color: white;
        box-shadow: 1px 1px 4px hsla(235, 10%, 10%, 0.1);
        word-wrap: break-word;
        display: flex;

        margin-top: 36px;
        margin-bottom: 48px;

        flex-direction: column;

        ${mq[6]} {
            max-width: 480px;
            margin: 36px auto;
        }
    `}>
        <div className={css`
            display: flex;

            ${mq[6]} {
                flex-direction: column;
            }
        `}>
            <div className={css`
                height: 320px;

                ${mq[10]} {
                    height: initial;
                }
            `}>
                <img src={image || '/static/images/classesDef.jpg'} className={css`
                    height: 100%;
                    width: 280px;
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: cover;
                    border-radius: 8px 0px 0px 8px;

                    ${mq[10]} {
                        width: 450px;
                        height: 100%;
                        max-height: initial;
                    }

                    ${mq[6]} {
                        width: 100%;
                        height: 320px;
                        border-radius: 8px 8px 0 0;
                    }
                `}
                    alt={`${name} class (id ${classId})`}/>
            </div>
            <div className={css`
                padding: 36px 48px;
                display: flex;
                gap: 60px;
                justify-content: space-evenly;
                flex: 1;

                ${mq[10]} {
                    flex-direction: column;
                    gap: 24px;
                }
            `}>
                <div className={css`
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                `}>
                    {(classTags.length + extraClassTags.length) > 0 ?
                        <div className={css`
                        margin-bottom: 24px;
                        display: none;
                        max-width: 100%;
                        overflow-x: scroll;

                        ${mq[10]} {
                            display: initial;
                        }
                    `}>
                            <Tags extraTags={extraClassTags} tags={classTags} filled/>
                        </div> : null}
                    <h2 className={css`
                        font-size: 18px;
                        color: hsl(235, 30%, 15%); margin: 0; font-weight: 700;`}>
                        {name}
                    </h2>
                    <p className={css`
                        font-size: 12px;
                        margin: 0;
                        margin-bottom: 24px;
                        color: hsl(235, 10%, 40%);
                    `}>{i18n.hostedBy} <span className={css`font-weight: 700`}>{host}</span></p>
                    <div className={css`
                        line-spacing: 1.5;
                        font-size: 14px;
                        color: hsl(235, 10%, 40%);
                        max-width: 400px;
                        text-overflow: ellipsis;

                        ${mq[6]} {
                            width: auto;
                        }
                    `}>
                        {description}
                    </div>

                    {(classTags.length + extraClassTags.length) > 0 ?
                        <div className={css`
                        margin-top: auto;

                        ${mq[10]} {
                            display: none;
                        }
                    `}>
                            <Tags extraTags={extraClassTags} tags={classTags} filled/>
                        </div> : null}
                </div>

                <div className={css`
                    height: inherit;
                    width: 280px;
                    margin: 0 auto;
                    display: grid;
                    grid-template-columns: 1fr 20px 1fr;
                    grid-gap: 24px 8px;
                    align-content: center;
                `}>
                    <div>
                        <div className={css`
                            color: hsl(235, 20%, 40%);
                            font-weight: 700;
                            font-size: 12px;
                        `}>{i18n.start}</div>
                        <span className={css`
                            font-size: 24px;
                            color: hsl(235, 30%, 20%);
                            white-space: nowrap;
                        `}>{startTimestamp}</span>
                    </div>
                    <div className={css`
                        display: flex;
                        flex-direction: column;
                        justify-content: end;
                       padding-bottom: 6px;
                    `}>—</div>
                    <div>
                        <div className={css`
                            color: hsl(235, 20%, 40%);
                            font-weight: 700;
                            font-size: 12px;
                        `}>{i18n.end}</div>
                        <span className={css`
                            font-size: 24px;
                            color: hsl(235, 30%, 20%);
                            white-space: nowrap;
                        `}>{endTimestamp}</span>
                    </div>
                    <DurationBar duration={duration}/>
                </div>

                <div className={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 24px;
                `}>
                    {/* TODO test this*/}
                    <div className={css`display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        flex: 1.2;`}>
                        {button}
                        {isManager && !ended && <>
                            {allUsersRegistered === false ?
                                <Button variant='outlined' className={css`
                                color: hsl(235, 10%, 40%);
                                    width: 100%;
                                    margin-top: 15px;
                                    :hover {
                                            background-color: hsl(235, 10%, 90%);
                                    }`}
                                    onClick={openInvite}>{i18n.inviteTeamMembers}</Button>
                                    : <Button
                                        className={css`width: 100%; margin-top: 15px;`}
                                        variant='contained' disabled
                                    >
                                        {allUsersRegistered === true ?
                                                i18n.allMembersRegistered
                                                : <LoadingIcon size={36.5} thickness={4} />}
                                    </Button>}
                        </>}
                        {sysadmin &&
                            <>
                                <Button className={css`background-color: navy;
                                                    color: white;
                                                    width: 100%;
                                                    margin-top: 15px;
                                                    :hover {
                                                            background-color: black;
                                                    }`}
                                    variant='contained' onClick={() => onEdit(classId)}>Edit</Button>
                                <Button className={css`background-color: rgb(186, 24, 24);
                                                    color: white;
                                                    width: 100%;
                                                    margin-top: 15px;
                                                    :hover {
                                                            background-color: rgb(120, 14, 14);
                                                    }`}
                                    variant='contained' onClick={() => setDeleteModalOpen(true)}>{i18n.delete}</Button>
                                <DeleteModal
                                    type={"Class"}
                                    isOpen={deleteModalOpen}
                                    info={entry}
                                    onClose={() => setDeleteModalOpen(false) && window.location.reload()}
                                    deleteFunction={deleteClass}
                                />
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>

        {
            sysadmin && registrationsList && registrationsList.length !== 0 &&
                <div className={css`background-color: #EAEAEA; padding: 0px 20px 20px 20px; border-radius: 10px;`}
                    key={"sysadmin class card for " + classId}>
                    <h4>Registrants: </h4>
                    {registrationsList.map((registree) => {
                        return <li key={"reg-" + registree.firstName + " " + registree.lastName}>
                            {registree.firstName} {registree.lastName} ||  {registree.email}.
                            ||&nbsp;
                            {registree.referrer ? "Referred by: " + registree.referrer : "No Referrer"}
                        </li>
                    })
                    }
                </div>
        }
    </div>;
}


export default ClassEntry;
