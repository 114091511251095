import React, {useState} from "react";

import {useFirestore} from 'react-redux-firebase';
import {change} from 'redux-form';
import {useDispatch, useSelector} from 'react-redux';
import {addGiftPackage, deleteGiftPackage, loadGiftPackages, updateGiftPackage} from "util/database/gifts";
import LoadingIcon from 'components/util/LoadingIcon';

import GiftingCards from 'components/Dashboard/Gifting/GiftingCards';
import GiftPackageEntryForm from 'components/Dashboard/Sysadmin/Forms/GiftPackageEntryForm';

const numericalFields = ["quantity", "price",'serviceFeeFixed', 'serviceFeePercentage'];
const normalize = (entries) => {
    const output = {...entries};
    for (let field of numericalFields) {
        if (output[field]) {
            output[field] = Number(output[field]);
        }
    }

    const labels = ["item_name", "item_image", "item_quantity", "item_customOptions", "item_API_campaignID", "item_API_denomination", "item_API_currency"];
    let counter = 1;

    output["packageContains"] = [];

    while (output["item_name" + counter]) {

        let temp = {
            "name": output[labels[0] + counter],
            "image": output[labels[1] + counter] || "",
            "quantity": Number(output[labels[2] + counter]) || 1,
            "customOptions": output[labels[3] + counter] && !Array.isArray(output[labels[3] + counter])
                ? output[labels[3] + counter].split(",") : [],
            "API_campaignID": output[labels[4] + counter],
            "API_denomination": Number(output[labels[5] + counter]) || 0,
            "API_currency": output[labels[6] + counter],
        }

        output["packageContains"].push(temp);

        for (let label of labels) {
            delete output[label + counter];
        }

        counter += 1;
    }
    return output;
}

function GiftPackagesDBPage() {
    const firestore = useFirestore();
    const dispatch = useDispatch();
    const [giftPackages, setGiftingPackages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isEdit, setIsEdit] = React.useState(false);
    const giftPackagesForm = useSelector((state) => state.form.giftPackageDBForm);

    if (!giftPackages.length && loading) {
        setLoading(false);
        loadGiftPackages(firestore).then(setGiftingPackages);
    }

    const giftPackageAutoFill = (enteredName) => {
        const info = giftPackages.find(c => (c.name === enteredName));

        for (let [key, value] of Object.entries(info)) {
            if (key === "packageContains") {
                for (let packageItem in value) {
                    for (let packageItemDesc in value[packageItem]) {
                        let dataValue = value[packageItem][packageItemDesc];

                        if (Array.isArray(dataValue)) {
                            dataValue.join(",");
                        }

                        if (dataValue === null) {
                            dataValue = "";
                        }

                        dispatch(change('giftPackageDBForm', "item_" + packageItemDesc + (parseInt(packageItem) + 1).toString(), dataValue));
                    }
                }
            } else {
                dispatch(change('giftPackageDBForm', key, value));
            }
        }
    };

    const handleSubmit = async () => {
        const info = normalize(giftPackagesForm.values);

        if (isEdit) {
            await updateGiftPackage(firestore, info);
        } else {
            await addGiftPackage(firestore, info);
        }
        window.location.reload();
    };

    const handleGiftPackageEdit = async (entryID) => {
        setIsEdit(true);
        window.scrollTo(0, 0);
        await giftPackageAutoFill(entryID);
    }


    return loading ? <LoadingIcon/> :
        <>
            <GiftPackageEntryForm isEdit={isEdit} onSubmit={handleSubmit}/>
            <GiftingCards giftingPacks={giftPackages} onEdit={handleGiftPackageEdit} onDelete={deleteGiftPackage}
                          sysadmin/>
        </>;
}

export default GiftPackagesDBPage;
