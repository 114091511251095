import React from 'react';
import { css } from '@emotion/css';

const Separator = ({marginTop, marginBottom}) => (
    <div className={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: ${marginTop}px;
            margin-bottom: ${marginBottom}px;
        `}>
        <div className={css`
                flex-grow: 1;
                border-top: 1px solid hsl(230, 10%, 80%);
            `}/>
        <p className={css`
                text-align: center;
                color: hsl(230, 10%, 60%);
                font-size: 14px;
                margin: 0 10px;
            `}>or</p>
        <div className={css`
                flex-grow: 1;
                border-top: 1px solid hsl(230, 10%, 80%);
            `}/>
    </div>
);
export default Separator;
