import React, { useState } from "react";
import { css } from "@emotion/css";
import { Button} from "@material-ui/core";
import DeleteModal from "../DeleteModal";
import i18n from "../../../util/i18n";

function GiftingCard({giftingPack, onEdit, onDelete, setCurrentPackage, sysadmin, highlight}) {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return <div
            key={"div" + giftingPack.name}
            className={css`
              background-color: white;
              border-radius: 8px;
              overflow: hidden;
              box-shadow: 1px 1px 4px hsla(230, 10%, 10%, 0.3);
              display: flex;
              flex-direction: column;
              margin-right: 30px;
              width: 100%;
              text-align: center;
              ${highlight && "border: 3px solid #008000;"}
            `}
          >
            <img
              src={giftingPack.image}
              alt={giftingPack.name}
              className={css`
                height: 200px;
                width: 100%;
                object-fit: contain;
              `}
            />
            <span
              className={css`
                font-size: 20px;
                font-weight: bold;
                color: #333333;
                overflow: hidden;
                text-overflow: ellipsis;
              `}
            >
              {giftingPack.name}
            </span>
            <span
              className={css`
                display: flex;
                justify-content: space-around;
                width: 100%;
                font-size: 16px;
                color: #909090;
                font-weight: 700;
              `}
            >
              ${giftingPack.price}
            </span>

            <div
              className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-grow: 1;
                color: #909090;
                padding: 20px;
              `}
            >
              <div
                className={css`
                  flex: 1;
                  text-align: left;
                `}
              >
                  {i18n.contains}:
                <ul className={css` list-style-position: outside; margin: 0;`}>
                  {giftingPack.packageContains.map((giftContent) => {
                      return <li key={"li1" + giftingPack.name + giftContent.name}>
                         {giftContent.quantity} x {giftContent.name}
                      </li>;
                  })}
                </ul>
              </div>
              {!sysadmin &&
              <div
                className={css`
                  flex: 0.8;
                  justify-content: center;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                `}
              >
                <Button
                  className={css`
                    background-color: ${highlight ? "#008000" : "#0022cc"};
                    color: white;
                    padding: 8px;
                    width: 80%;
                    border-radius: 10px;
                    font-weight: bold;
                    cursor: pointer;
                    :hover {
                      background-color: ${highlight ? "#003300" : "#001685"};
                    }
                  `}
                  onClick={() => setCurrentPackage(giftingPack)}>
                  {highlight ? "Selected ✅" : "Select"}
                </Button>
                </div>
                }
            </div>
            {sysadmin &&
                            <>
                      <Button className={css`background-color: navy;
                                              color: white;
                                              width: 100%;
                                              margin-top: 20px;
                                              :hover {
                                                      background-color: rgb(120, 14, 14);
                                              }`}
                          variant='contained' onClick={() => onEdit(giftingPack.name)}>Edit</Button>
                      <Button className={css`background-color: rgb(186, 24, 24);
                                              color: white;
                                              width: 100%;
                                              margin-top: 20px;
                                              :hover {
                                                      background-color: rgb(120, 14, 14);
                                              }`}
                              variant='contained' onClick={() => setDeleteModalOpen(true)}>Delete</Button>
                      <DeleteModal type={"Gift Package"}
                                  isOpen={deleteModalOpen}
                                  info={giftingPack}
                                  onClose={() => setDeleteModalOpen(false) && window.location.reload()}
                                  deleteFunction={onDelete}
                              />
                      </>}
          </div>
}

function GiftingCards({setCurrentPackage, selectedPackage, giftingPacks, onEdit, onDelete, sysadmin}) {
  return (
    <div
      className={css`
        display: grid;
        grid-template-columns: repeat(auto-fit, 375px);
        grid-gap: 20px;
        margin: 30px auto;
        justify-content: center;
      `}
    >
      {giftingPacks.map((item) => {
          return <GiftingCard key={"card" + item.name} giftingPack={item} setCurrentPackage={setCurrentPackage}
                    onEdit={onEdit} onDelete={onDelete} sysadmin={sysadmin} highlight={item === selectedPackage}/>
      })}
    </div>
  );
}

export default GiftingCards;
