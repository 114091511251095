import React from 'react';
import { css } from '@emotion/css';

import { Field } from 'redux-form';
import {
    renderTextField,
    renderAddressAutocomplete,
    renderPhoneField
} from 'components/Forms';

import FormSection from 'components/Forms/FormSection';
import i18n from "../../../util/i18n";

const ShippingInfo = () => <FormSection
    label={i18n.shippingInfo}
    description={i18n.shippingInfoMsg}
>
    <Field
        name='address'
        component={renderAddressAutocomplete}
        label={i18n.shippingAddress}
        className={css`
            grid-column: 1 / span 2;
        `}
    />

    <Field
        name='apartment'
        component={renderTextField}
        label={i18n.unitNumber}
    />

    <Field
        name='postalCode'
        component={renderTextField}
        label={i18n.postalCode}
    />

    <Field
        name='phone'
        component={renderPhoneField}
        label={i18n.phoneNumber}
        className={css`
            grid-column: 1 / span 2;
        `}
    />

    <Field
        name='deliveryInstructions'
        component={renderTextField}
        label={i18n.deliveryInstructions}
        className={css`
            grid-column: 1 / span 2;
        `}
    />
</FormSection>;

export default ShippingInfo;

