import Mixpanel from 'util/analytics/mixpanel';

export default function userInfo(state, action) {
    switch (action.type) {
        case 'userinfo/load':
            Mixpanel.setUserProperties(action.payload);
            return {
                ...state,
                ...action.payload
            }

        case 'userinfo/unload':
            return null;

        default:
            return state || null;
    }
}
