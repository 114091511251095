import MButton from "@material-ui/core/Button";
import MSelect from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withAddToCalendar from "react-add-to-calendar-hoc";
import { css } from "@emotion/css";

const Button = (props) => (
  <MButton
    disableElevation
    variant="contained"
    color="default"
    className={css`
    width: 100%;
    `}
    {...props}
  />
);

const Select = ({ children, onRequestClose, ...props }) => (
  <MSelect className={css`
    width: 0;
    height: 0;
    margin-left: -1px; /*removes hz scrollbar in modal contexts*/
  `} open value={''} onClose={onRequestClose} {...props}>
    {children.map((item, i) => (
      <MenuItem key={item + '-' + i} value={item}>{item}</MenuItem>
    ))}
  </MSelect>
);

const AddToCalendarButton = withAddToCalendar(Button, Select);

export default AddToCalendarButton;
