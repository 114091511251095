import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/css';
import pluralize from 'pluralize';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import i18n from "util/i18n";
import { computeTotalPrice, generatePriceStrings } from "util/pricing/events";

import { getParticipantCountBounds } from './EventInfo';

const conversionURL = "https://calendly.com/reza_/30-min";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      height: "68vh",
      maxHeight: "70vh",
      overflowY: "auto",
      marginRight: "auto",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    }
  }));


const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];

const weekdays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
]

function Time({hour}) {
    const displayHour = (hour - 1) % 12 + 1;
    const indicator = (hour % 24) > 11 ? 'PM' : 'AM';
    return <span>
        {displayHour}
        {' '}
        {indicator}
    </span>
}

function TotalPrice({
    cashPrice,
    creditPrice
}) {
    const { numerator: totalPrice, denominator: priceDenominator } = generatePriceStrings({
        cashPrice: cashPrice,
        creditPrice: creditPrice,
        priceDenominator: i18n.group,
        hideReducedPriceDenominator: true
    });
    return <>
        <span className={css`color: #333333; font-weight: bold;`}>
            {totalPrice}
        </span>
        <span>{priceDenominator}</span>
    </>;
}

function AvailableTime({
    time,
    cashPrice,
    creditPrice,
    onClick
}) {
    const [start, comment] = time;

    return <div className={css`
        background-color: hsl(230, 30%, 97%);
        border-radius: 8px;
        margin-top: 10px;
        padding: 15px 25px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    `}>
        <Grid item xs={6}>
            {/* <p>{weekdays[start.getDay()]}</p> */}
            <p>{i18n.weekOf} <br/>
                {months[start?.getMonth()]}
                {' '}
                {start?.getDate()}
                {' ' + comment}
            </p>
        </Grid>
        <Grid item xs={6}>
            <TotalPrice
                creditPrice={creditPrice}
                cashPrice={cashPrice}
            />
            <Button style={{
                    maxWidth: '100%',
                    backgroundColor: 'hsl(230, 100%, 50%)',
                    color: 'white',
                    margin: '10px'
                }} onClick={() => onClick({chosenWeek: start.getTime()})}>{i18n.bookNow}
            </Button>
        </Grid>
    </div>;
}


function BookCall({
    onClick
}) {
    return <div className={css`
        background-color: hsl(230, 30%, 97%);
        border-radius: 8px;
        margin-top: 10px;
        padding: 15px 25px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    `}>
        <Grid item xs={12}>
            <span>
            <Button style={{
                        maxWidth: '100%',
                        backgroundColor: 'hsl(230, 100%, 50%)',
                        color: 'white',
                        margin: '10px'
                    }}
                    onClick={() => onClick()}>{i18n.bookACall}
            </Button>
            </span>
        </Grid>
    </div>;
}


// Get array with the next three Mondays after the current date
function times(physicalPackage) {
    let numMondays = 3;
    let nextMonday = new Date();

    // if there's a physical package, the soonest booking week should be 3 weeks from now.
    let multiplier = physicalPackage ? 3 : 0;

    // Add number of days remaining until next Monday to the current date
    nextMonday.setDate((nextMonday.getDate() + (8 - nextMonday.getDay()) % 7) + (7 * multiplier));

    let nextMondays = [[new Date(nextMonday + (7*multiplier)), '']]
    for (let i = 0; i<numMondays-1; i++) {
        nextMonday.setDate(nextMonday.getDate() + 7); // Increase by 7 days
        nextMonday.setHours(8,0,0,0) // Set time to 8:00 AM
        nextMondays.push([new Date(nextMonday), i===numMondays-2 ? 'or later' : '']);
    }
    return nextMondays;
};

function BookingInfo({data}) {
    const history = useHistory();
    const companyInfo = useSelector(state => state.userinfo?.company)

    const { min: minParticipantCount } = getParticipantCountBounds(data.suggestedAudienceSize);

    const [groupSize, setGroupSize] = useState(5);
    const [text, setText] = useState(minParticipantCount);
    const [error, setError] = useState(false);
    const notEvent = (data.themes.includes("Perks") || data.themes.includes("Care packages") || data.themes.includes("Team Parties"));

    // total cash and credit price given the number of participants and available credits
    const { cashPrice, creditPrice } = computeTotalPrice({
        eventTags: data.tags,
        availableCredits: companyInfo?.credits,
        useMaxCredits: true,
        pricePerPerson: data.pricePerPerson,
        numParticipants: groupSize
    });

    // Starting price string to display ($ per person)
    const { numerator: startingPrice, denominator: startingPriceDenominator } = generatePriceStrings({
        cashPrice: data.pricePerPerson,
        creditPrice: 0,
        priceDenominator: i18n.person,
        hideReducedPriceDenominator: true
    });


    const conversionAction = () => {window.open(conversionURL, '_blank');};
    const bookEvent = (chosenWeek) => {
        history.push(`/book?event=${data.id}&week=${chosenWeek}&participants=${text}`);
    }

    const bookCall = () => {
        return conversionAction()
    };

    const bookOrCall = ({chosenWeek}) => {
        if (data.themes.length === 1 && data.themes.includes("Team Parties")){
            return conversionAction()
        } else {
            return bookEvent(chosenWeek)
        }
    }

    const classes = useStyles();

    return <>
        <div className={classes.root}>
            <Grid container spacing={3} className={css`width: calc(100% + 6px); margin: -12px;`}>
                <Grid item xs={12}>
                    {data.pricePerPerson && <Paper className={classes.paper}>
                        <h2 className={css`
                            margin: 0;
                            color: #333333;
                            font-weight: 400;
                            font-size: 24px;
                        `}>
                            {i18n.formatString(i18n.startingAtPerPerson,
                                <span className={css`
                                font-weight: bold
                            `}>{startingPrice}</span>)}

                            <span className={css`
                                color: hsl(230, 10%, 40%);
                                font-size: 18px;
                                margin-left: 2px;
                            `}>{startingPriceDenominator}</span>
                        </h2>
                        <div className={css`
                            font-size: 14px;
                            color: hsl(230, 10%, 40%);
                            margin-top: 10px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                        `}>
                            {i18n.showingPriceFor}
                            <input
                                type="number"
                                min={minParticipantCount}
                                className={css`
                                    margin: 0 8px;
                                    width: 43px;
                                    font-size: 14px;
                                    background-color: ${error ? 'hsl(0, 100%, 90%)' : 'hsl(230, 10%, 90%)'};
                                    border-radius: 8px;
                                    border: none;
                                    padding: 4px 6px;
                                `}
                                value={text}
                                onChange={e => {
                                    setText(e.target.value);
                                    const size = parseInt(e.target.value);
                                    if (isNaN(size) || size < 1) {
                                        setError(true);
                                    } else {
                                        setGroupSize(size);
                                        setError(false);
                                    }
                                }}
                            />
                            {pluralize('person', groupSize)}
                        </div>

                        {creditPrice > 0 &&
                            <Grid item xs={12} className={css`margin-top: 10px;`}>
                                {i18n.formatString(
                                    cashPrice === 0 ? i18n.useCredits.free : i18n.useCredits.reducedPrice,
                                    creditPrice,
                                    pluralize(i18n.credit, creditPrice),
                                    cashPrice
                                )}
                            </Grid>
                        }
                    </Paper>}
                </Grid>
                    {(notEvent) ? <BookCall eventid={data.id} onClick={bookCall}/>:
                    times(data.physicalPackage).slice(1).map((time, i) =>
                        <Grid item xs={12} key={"grid for time " + i}>
                            <AvailableTime
                                key={i}
                                time={time}
                                cashPrice={cashPrice}
                                creditPrice={creditPrice}
                                onClick={bookOrCall}
                            />
                        </Grid>
                        )
                    }
            </Grid>
        </div>
    </>
}

export default BookingInfo;
export { Time, weekdays };
