import React from 'react';
import { css } from '@emotion/css';

import { useFirestore } from 'react-redux-firebase';

import Modal from 'react-modal';

import Button from '@material-ui/core/Button';
import i18n from "../../util/i18n";

function DeleteModal({type, isOpen, onClose, info, deleteFunction}) {
    const firestore = useFirestore();

    if (info === null) {return null;}

    async function handleClick() {
        await deleteFunction(firestore, info);
        onClose();
        window.location.reload();
    }

    return <Modal isOpen={isOpen} onRequestClose={onClose}>
        <h2 className={css`
            margin: 0;
            font-weight: 500;
            font-size: 18px;
        `}>{i18n.confirmDelete}</h2>
        <p className={css`
            color: hsl(230, 10%, 25%);
            font-size: 14px;
            margin-bottom: 30px;
        `}>{i18n.formatString(i18n.confirmDeleteMsg,type)} {info.name}.</p>
        <Button onClick={handleClick}
            className={css`
            background-color: hsl(350, 100%, 40%);
            color: white;
            :hover {
                background-color: rgb(120, 14, 14);
            }
            `}
        >{i18n.delete} {type}</Button>
    </Modal>;
}

export default DeleteModal;
