
export const mergeClasses = (...classes) => classes.filter(c => c).join(' ');

// Append Eastern Time timezone offset to dateTime
const classTimeWithTimezoneOffset = (dateTime) => {
    const date = new Date(dateTime);

    const jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
    const jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
    const isDST = Math.max(jan, jul) !== date.getTimezoneOffset();

    // -4 if daylight savings time, else -5
    return isDST ? `${dateTime}-0400` : `${dateTime}-0500`;
}

export const classTimesWithTimezoneOffset = (classes) => {
    return classes.map(c => ({
        ...c,
        dateTime: classTimeWithTimezoneOffset(c.dateTime) // dateTimes from DB are in eastern time
    }))
}
