import React, {useEffect, useRef, useState} from 'react';
import {css} from '@emotion/css';

import {useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';

import ArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import Button from '@material-ui/core/Button';
import MenuList from '@material-ui/core/MenuList';
// https://stackoverflow.com/questions/61220424/material-ui-drawer-finddomnode-is-deprecated-in-strictmode

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import BusinessIcon from '@material-ui/icons/Business';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccountOutlined';
import UserIcon from '@material-ui/icons/AccountCircle';
import ClassIcon from '@material-ui/icons/School';
import SettingsIcon from '@material-ui/icons/Settings';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';

import SignIn from './SignIn';
import SignOut from './SignOut';
import InfoGetter from './InfoGetter';
import {Avatar} from '@material-ui/core';
import {hasAccess} from 'util/permission/haspermissions';
import {permissions} from 'util/permission/permissions';
import StarsIcon from '@material-ui/icons/Stars';
import FlareIcon from '@material-ui/icons/Flare';
import i18n from 'util/i18n';

function User({hideSignIn}) {
    const user = useSelector(state => state.firebase.auth);
    const userInfo = useSelector(state => state.userinfo);

    return <div>
        {user.isEmpty ? (hideSignIn ? null : <SignIn/>) : (
            userInfo ?
                <UserInfo
                    userInfo={userInfo}
                /> : <InfoGetter loadingIcon />
        )}
    </div>;
}

const roleMap = (role) => {
    if (role === 'superuser') {
        return 'Manager';
    }
    return role.charAt(0).toUpperCase() + role.substr(1);
};

const entries = (userInfo) => {
    if (userInfo.role === 'sysadmin') {
        return [
            ['Manage Companies', <BusinessIcon fontSize='inherit'/>, 'companies'],
            ['See Event Polling', <AssessmentIcon fontSize='inherit'/>, 'event-polling'],
            ['Manage Users', <UserIcon fontSize='inherit'/>, 'users'],
            ['Questions DB', <ContactSupportIcon fontSize='inherit' />, 'questions-database'],
            ['Answers DB', <ContactSupportIcon fontSize='inherit' />, 'answers-database'],
            ['Events DB', <FlareIcon fontSize='inherit' />, 'event-database'],
            ['Gifting Packages DB', <ShoppingBasketIcon fontSize='inherit' />, 'gift-pack-database'],
            ['Class DB', <ClassIcon fontSize='inherit'/>, 'class-database'],
            ['Settings', <SettingsIcon fontSize='inherit'/>, 'settings'],
        ];
    }
    const result = [];
    if (hasAccess(permissions.MANAGE_TEAM, userInfo)) {
        result.push([i18n.manageCompanyMembers, <SupervisorAccountIcon fontSize='inherit'/>, 'members']);
    }
    if (hasAccess(permissions.KUDOS_BOT, userInfo)) {
        result.push([i18n.kudos, <StarsIcon fontSize='inherit'/>, 'kudos-settings']);
    }
    if (hasAccess(permissions.COMPANY_PROFILE, userInfo)) {
        result.push([i18n.companyProfile, <BusinessIcon fontSize='inherit'/>, 'settings/company']);
    }
    if (hasAccess(permissions.INVOICES, userInfo)) {
        result.push([i18n.receipts, <ReceiptIcon fontSize='inherit'/>, 'receipts']);
    }
    if (hasAccess(permissions.SETTINGS, userInfo)) {
        result.push([i18n.settings, <SettingsIcon fontSize='inherit'/>, 'settings']);
    }
    return result;
}

function MenuListItem({name, icon, url, selected, closeFunction}) {
    const to = url.startsWith('/') ? url : `/dashboard/${url}`;
    return <Link to={to} className={css`
          text-decoration: none;
          color: Gray;
      `}>
        <MenuItem onClick={closeFunction} button key={name} selected={selected}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={name}/>
        </MenuItem>
    </Link>;
}

function UserInfo({userInfo}) {
    // const history = useHistory();

    const loc = useLocation();
    const part = loc.pathname.split('/').pop();
    const items = entries(userInfo);

    // https://material-ui.com/components/menus/
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return <>
        <Button className={css`
                display: flex;
                flex-direction: row;
                align-items: center;
        `}
                onClick={handleToggle}
                ref={anchorRef}
        >
            <Avatar
                src={userInfo.image}
                alt={userInfo.firstName}
                className={css`
                margin-right: 15px;
            `}/>
            {/* align-items: start; */}
            <div
                className={css`
                    display: flex;
                    align-items: start;
                    flex-direction: column;
                    line-height: 1.5;
                `}
            >
                <span className={css`
                        font-size: 16px;
                        color: hsl(200, 10%, 15%);
                        text-transform: none;
                    `}
                > {userInfo.firstName}</span>

                <span className={css`
                        font-size: 12px;
                        color: hsl(200, 10%, 25%);
                        text-transform: none;
                    `}
                > {roleMap(userInfo.role)} </span>

            </div>

            <ArrowIcon className={css`
            margin-left: 5px;
            margin-bottom: 2px;
            color: hsl(200, 10%, 70%);
        `}/>
        </Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({TransitionProps, placement}) => (
                <Grow
                    {...TransitionProps}
                    style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList autoFocusItem={open}>
                                {items.map(([name, icon, url]) =>
                                    <MenuListItem
                                        key={name}
                                        name={name}
                                        icon={icon}
                                        url={url}
                                        selected={part === url || (part === "company" && url === "settings/company")} // TODO: check router renames
                                        closeFunction={handleClose}
                                    />
                                )}
                                <SignOut/>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </>;
}

export default User;
