import i18n from "util/i18n";

const demoLink = "https://www.wfhomie.com/schedule-demo";

export const UpgradeToPremium = () => {
    return (
        <div className="flex-auto flex flex-col items-center space-y-6">
            <div className="flex flex-col items-center space-y-1">
                <a
                    className="border rounded-full border-box px-4 py-4 text-white bg-purple text-sm font-bold border-primary"
                    href={demoLink}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {i18n.bookACall}
                </a>
                <br></br>
                <span className="text-base">$2.99/user/month</span>
            </div>
        </div>
    );
};
