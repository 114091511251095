import React, {useEffect, useState} from "react";
import {css} from "@emotion/css";
import "url-search-params-polyfill";
import Grid from "@material-ui/core/Grid";
import {Field, reduxForm} from "redux-form";
import {useDispatch, useSelector} from "react-redux";
import {renderTextField, SubmitButton} from "components/Forms";
import {useFirebase, useFirestore} from "react-redux-firebase";
import {verifyCode} from "util/database/invites";
import {useHistory} from "react-router-dom";
import {createUser} from "util/signup";
import i18n from "util/i18n";
import {GoogleButton, MicrosoftButton} from "./AuthButton";
import CenteredLoadingIcon from "components/util/CenteredLoadingIcon";
import { withToastNotification  } from "components/hocs/withToastNotification";

//TODO: handling code verification better.
// currently, garbage codes cause infinite load.

const validate = (values) => {
    if (values === {}) {
        return;
    }

    const errors = {};
    const requiredFields = [];

    if ("emailSignIn" in values) {
        requiredFields.push([
            "emailSignIn",
            ["email", "password", "confirmPassword"],
        ]);
        const {password, confirmPassword} = values.emailSignIn;
        if (password && password !== confirmPassword) {
            errors.emailSignIn = {};
            errors.emailSignIn.confirmPassword = i18n.passwordsDontMatch;
        }
    }

    requiredFields.forEach(([subform, fields]) => {
        fields.forEach((field) => {
            if (!values?.[subform]?.[field]) {
                if (!(subform in errors)) {
                    errors[subform] = {};
                }
                errors[subform][field] = i18n.required;
            }
        });
    });

    return errors;
};

const EmployeeSignupForm = ({reset, invalid, handleSubmit, redirect, toastNotification}) => {
    const user = useSelector((state) => state.firebase.auth);
    const form = useSelector((state) => state.form.signup);
    const firebase = useFirebase();
    const firestore = useFirestore();
    const dispatch = useDispatch();
    const history = useHistory();
    const [signInOption, setSignInOption] = useState("password");
    const [submitting, setSubmitting] = useState(false);
    const oAuthSupplied = signInOption !== "password" && !!user;
    const emailAuthSupplied =
        signInOption === "password" && form?.values?.emailSignIn;
    const anyAuth = oAuthSupplied || emailAuthSupplied;

    const params = new URLSearchParams(window.location.search);

    const [inviteData, setInviteData] = useState(null);
    const inviteCode = params.get("code");
    const companyName = params.get("company");

    useEffect(() => {
        inviteData === null &&
        (async () => {
            try {
                const valid = await verifyCode(firestore, inviteCode);
                if (valid) {
                    setInviteData({...valid, companyName});
                    return;
                }
            } catch (e) {
                return;
            }
            setInviteData(null);
        })();
    }, [inviteData, companyName, firestore, inviteCode]);

    useEffect(() => {
        dispatch({type: "@@reactReduxFirebase/SET_PROFILE", payload: {}});
    }, [dispatch]);


    const signInWithOAuthProvider = (provider) => {
        dispatch({type: "userinfo/unload"});
        firebase
            .login({provider, type: "popup"})
            .then((res) => {
                reset();
                setSignInOption(provider);
                handleSubmit(onSubmit);
            })
            .catch((res) => {
                console.log(res)
                setSignInOption("password");
            });
    };

    const signInWithGoogle = () => signInWithOAuthProvider("google");

    const signInWithMicrosoft = () => signInWithOAuthProvider("microsoft.com");

    const onSubmit = () => {
        setSubmitting(true);
        createUser(
            {
                signInOption,
                profileImage: "https://i.imgur.com/aflwVYZ.png",
                ...form.values?.emailSignIn,
            },
            inviteData,
            user,
            firebase,
            firestore
        ).then(() => {
            if (redirect) {
                return history.push(redirect + '&justSignedUp=true');
            } else {
                return history.replace({pathname: "/dashboard/home", state: {justSignedUp: true}});
            }
        }).catch(err =>{
            toastNotification(i18n.errorR, "error")
            setSubmitting(false);
        });
    };

    if (!user.isLoaded || !inviteData?.company || submitting) {
        return <CenteredLoadingIcon/>;
    }

    return (
        <>
            <h1
                className={css`
          font-size: 24px;
          color: #ffbc3b;
          margin: 20px 0;
          font-family: "Roboto", serif;
          font-weight: 600;
        `}
            >
                {i18n.getStartedWith}
            </h1>
            <p
                className={css`
          color: hsl(235, 10%, 15%);
          font-size: 18px;
          margin-bottom: 30px;
        `}
            >
                {i18n.formatString(i18n.fewClicksAway, <b>{companyName}</b>)}
            </p>
            <form
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container direction="column">
                    <div className="flex space-x-4">
                        <GoogleButton
                            onClick={signInWithGoogle}
                        />
                        <MicrosoftButton
                            onClick={signInWithMicrosoft}
                        />
                    </div>
                    {!oAuthSupplied &&
                    <>
                        <Grid
                            item
                            xs
                            className={css`
              margin: 20px 0px;
            `}
                        >
                            <div
                                className={css`
                text-align: center;
                position: relative;
                color: #ccc;
                &::before,
                &::after {
                  content: "";
                  position: absolute;
                  bottom: 50%;
                  height: 1px;
                  width: 43%;
                  background: #ccc;
                }

                &::before {
                  left: 0;
                  padding-right: 10px;
                }

                &::after {
                  right: 0;
                  padding-left: 10px;
                }
              `}
                            >
                                {i18n.or}
                            </div>
                        </Grid>
                        <Grid container direction="column">
                            <Grid
                                container
                                direction="row"
                                className={css`
                gap: 10px;
              `}
                            >
                                <Grid item xs>
                                    <Field
                                        name="emailSignIn.firstName"
                                        placeholder={i18n.firstName}
                                        disabled={signInOption === "google"}
                                        component={renderTextField}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Field
                                        name="emailSignIn.lastName"
                                        placeholder={i18n.lastName}
                                        disabled={signInOption === "google"}
                                        component={renderTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Field
                                name="emailSignIn.email"
                                placeholder={i18n.workEmailStar}
                                disabled={signInOption === "google"}
                                component={renderTextField}
                            />
                            <Field
                                name="emailSignIn.password"
                                placeholder={i18n.passwordStar}
                                type="password"
                                disabled={signInOption === "google"}
                                component={renderTextField}
                            />
                            <Field
                                name="emailSignIn.confirmPassword"
                                placeholder={i18n.confirmPasswordStar}
                                type="password"
                                disabled={signInOption === "google"}
                                component={renderTextField}
                            />
                        </Grid>
                    </>
                    }
                    <Grid item xs={12} sm={6} className={css`margin-top: 20px`}>
                        <SubmitButton
                            onClick={onSubmit}
                            disabled={invalid || !anyAuth}
                            label={i18n.signUp}
                        />
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default reduxForm({form: "signup", validate})(withToastNotification(EmployeeSignupForm));
