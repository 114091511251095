import React, { useState, useEffect } from "react";

import { useFirebase, useFirestore } from 'react-redux-firebase';

import { loadClasses, loadRegistrations, addRegistrations } from 'util/database/classes';
import { sendCalendarInvite } from 'util/email/classes';

import LoadingIcon from 'components/util/LoadingIcon';
import Classes from 'components/Dashboard/Classes';
import PageHeader from "components/Dashboard/PageHeader";
import i18n from "util/i18n";
import { classTimesWithTimezoneOffset } from "util/classes";
import {useSelector} from "react-redux";

//const iframe = '<iframe id="sutraWidgetIframe" src="https://sutrapro.com/iframe/wfhomie/schedule" height="100%" width="100%" frameBorder="0" allowfullscreen></iframe> <script src="https://sutrapro.com/scripts/embed.js"></script>'

function ClassPage() {
    const firebase = useFirebase();
    const firestore = useFirestore();
    const userData = useSelector((state) => state.userinfo);

    const [classes, setClasses] = useState(undefined);
    const [userRegistrations, setUserRegistrations] = useState(undefined);

    const loading = !classes && !userRegistrations;

    useEffect(() => {
        if (userData?.userId) {
            loadClasses(firestore).then(setClasses);
            loadRegistrations(firestore, userData.userId).then(setUserRegistrations);
        }
    }, [firestore, userData]);

    const registerClass = async (classId, userIds, userEmails) => {
        const classInfo = classes.find(c => c.id === classId);
        await sendCalendarInvite(firebase, classInfo, userEmails);
        addRegistrations(firestore, classId, userIds);

        if (userEmails.includes(userData.email)) {
            setUserRegistrations([...userRegistrations, classId]);
        }
    }

    return loading ? <LoadingIcon /> :
        <>
            <PageHeader title={i18n.classes}
                description={i18n.dropInToTheseClassToGrowth} showWelcomePopUp={true}></PageHeader>
            <Classes
                classes={classTimesWithTimezoneOffset(classes)}
                userRegistrations={userRegistrations}
                onRegister={registerClass}
                userData={userData}
            />
        </>
}

export default ClassPage;
