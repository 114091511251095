import React from 'react';
import { css } from '@emotion/css';

import TextField from '@material-ui/core/TextField';

import PlacesAutocomplete from 'react-places-autocomplete';

function AddressAutocomplete({
    label, error, helperText,
    value, onChange,
    className,
    ...custom
}) {
    return <PlacesAutocomplete
        value={value.address || ''}
        onChange={text => onChange({address: text})}
        onSelect={(address, placeId, suggestion)=> onChange({address, suggestion})}
    >{
        ({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
            <div className={className} style={{position: 'relative'}}>
                <TextField
                    className={css`width: 100%;`}

                    label={label}

                    error={error}
                    helperText={helperText}
                    {...custom}
                    {...getInputProps({})}/>
                {suggestions.length > 0 ? 
                <div className={css`
                    position: absolute;
                    top: 50px;
                    width: 100%;
                    border: 1px solid hsl(230, 10%, 80%);

                    z-index: 100;
                `}>
                    {suggestions.map(suggestion => {
                        return (
                            <div className={css`
                                color: hsl(230, 10%, 15%);
                                font-size: 14px;
                                padding: 8px 15px;
                                background-color: white;

                                cursor: pointer;

                                :hover {
                                    background-color: hsl(230, 10%, 97%);
                                }
                            `} {...getSuggestionItemProps(suggestion, {})}>
                                {suggestion.description}
                            </div>
                        );
                    })}
                </div> : null}
            </div>
        )}
    </PlacesAutocomplete>;
}

export default AddressAutocomplete;
