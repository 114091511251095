// Create a new zoom meeting link for the given class.
async function createZoomMeeting(firebase, classInfo) {
    const response = await firebase.functions().httpsCallable('createZoomClass')({
        ...classInfo
    });
    if (!response.data.ok) {throw new Error(`Failed to create class: ${response.data.error}`);}
    return response.data.link;
}

export { createZoomMeeting };
