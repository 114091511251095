import {loadStripe} from '@stripe/stripe-js';

// These are publishable keys's it's perfectly safe
const PRODUCTION = process.env.REACT_APP_BUILD_MODE === 'production';

const publishableKey = PRODUCTION ?
    "pk_live_51HukxDJ2CkWL6pkHaCpgNl2kpvZSoSeFkMQhn0FdWMtaFoR3VSxbbB5FszyRYRceMfZP5FcuLPXndzpt3iH4iTqy00NU8jDc9v"
    : "pk_test_51HukxDJ2CkWL6pkHCtaxg7valrTWOr6rwAyVFyOj5RI9W0dWCVzpQSCjZDegMC1bRb3gqfcwaJMPMB0czP5xHRDY00adZrpq0I";

const stripePromise = loadStripe(publishableKey);

async function getProducts(firestore) {
    const ret = await firestore.collection('pricing').where('active', '==', true).get();
    const products = {};

    for (let entry of ret.docs) {
        const data = entry.data();
        data.prices = {};

        const prices = await entry.ref.collection('prices').get();
        for (let price of prices.docs) {
            data.prices[price.id] = price.data();
        }

        products[entry.id] = data;
    }

    console.log(products);

    return products;
}

async function handleCheckout(ctx, orderInfo, routerHistory) {
    const {firestore, stripe, uid, email,firebase} = ctx;

    const requestRef = await firestore.collection('orderRequests').add({
        uid: uid,
        email: email,
        origin: window.location.origin,
        ...orderInfo,
    });

    const orderRef = firestore.collection('orders').doc(requestRef.id);
    orderRef.onSnapshot((snap) => {
        const data = snap.data()
        if (data) {
            const {error, paymentId, finalCost, creditsUsed} = data;

            if (error) {
                routerHistory.push({
                    pathname: "/book/fail",
                    state: {errorMsg: error}
                })
                return;
            }

            // Paid with credit(s), and no additional cost
            if ((finalCost === 0) && (parseInt(creditsUsed) > 0)) {
                const orderSummary = {
                    name: data.eventName,
                    numParticipants: data.numParticipants,
                    dates: data.dates,
                    timezone: data.timezone,
                }
                routerHistory.push({
                    pathname: "/book/success",
                    state: {orderSummary: orderSummary}
                })
                return;
            }

            if (paymentId) {
                firebase.functions().httpsCallable('pay')(
                    {paymentId: paymentId,})
                    .then((res) => {
                        stripe.redirectToCheckout({sessionId: res.data.sessionId});
                    })
                    .catch((error) => {
                        routerHistory.push({
                            pathname: "/book/fail",
                            state: {errorMsg: error}
                        })
                    });
            }
        }
    });
}

async function getInvoices(subscriptionID) {
    const endpoint = PRODUCTION ? 'wfhomie-85a56' : 'wfhomietest';
    const response = await fetch(
        `https://us-central1-${endpoint}.cloudfunctions.net/getInvoice?subscriptionID=${subscriptionID}`
    );
    const payload = await response.json();
    return payload.data;
}

export {stripePromise, getProducts, handleCheckout, getInvoices};
