import React from "react"
import CardPage from "../../../components/Dashboard/CardPage"

function Activities() {
    // temporarily removing filters on this page
    return <>
        <CardPage type={"activities"}
            showFilters={false}
            showCustomGames={false}
            pageTitle={"Activities"}
            hasPageImage={false}
            showVoteButton={false}>
        </CardPage>
    </>;
}

export default Activities;
