import React from 'react';
import { css } from '@emotion/css';

import { useFirestore, useFirebase } from 'react-redux-firebase';

import Modal from 'react-modal';

import Button from '@material-ui/core/Button';

import { deleteUser } from 'util/database/users';
import i18n from "util/i18n";

function DeleteUserModal({isOpen, onClose, info}) {
    const firestore = useFirestore();
    const firebase = useFirebase();

    if (info === null) {return null;}

    async function handleClick() {
        if (info.id === firebase.auth().currentUser.uid) {
            await deleteUser(firestore, info.id).then(() => {firebase.auth().signOut()});
            onClose();
            window.location.reload();
        }
        else {
            await deleteUser(firestore, info.id);
            onClose();
        }
    }

    return <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
    >
        <h2 className={css`
            margin: 0;
            font-weight: 500;
            font-size: 18px;
        `}>{i18n.confirmDelete}</h2>
        <p className={css`
            color: hsl(230, 10%, 25%);
            font-size: 14px;
            margin-bottom: 30px;
        `}>{i18n.formatString(i18n.confirmDeleteUser,info.name)}</p>
        <Button onClick={handleClick}
            style={{
                backgroundColor: 'hsl(350, 100%, 40%)',
                color: 'white',
            }}
        >{i18n.deleteUser}</Button>
    </Modal>;
}

export default DeleteUserModal;
