import { connect } from 'react-redux';
import { stage } from 'util/stage';
import i18n from 'util/i18n';

const SlackIntegration = {
    KUDOS: "KUDOS",
    POLL: "POLL",
}

const SlackIntegrationConfig = {
    KUDOS: {
        prod: {
            installationURL: 'https://kudosbot-lkls3azcmq-uc.a.run.app/slack/install',
        },
        dev: {
            installationURL: 'https://kudosbot-vkmnp7z35a-uc.a.run.app/slack/install',
        },
        // LOCAL - TODO add "local" to stage getter
        // dev: {
        //     installationURL: 'https://4b90-2-56-188-43.ngrok.io/slack/install',
        // }
    },
    POLL: {
        prod: {
            clientId: '1430491033895.1817499675282',
            redirectURL: 'https://us-central1-wfhomie-85a56.cloudfunctions.net/slackOAuthRedirect',
            scope: 'incoming-webhook',
            userScope: ''
        },
        dev: {
            clientId: '1430491033895.1817499675282', // use prod bot; we don't have a functional dev poll bot (was '1430491033895.2358790352262')
            redirectURL: 'https://us-central1-wfhomietest.cloudfunctions.net/slackOAuthRedirect',
            scope: 'incoming-webhook',
            userScope: ''
        }
    }
}

const getInstallURL = (companyId, slackIntegration) => {
    switch (slackIntegration) {
        case SlackIntegration.KUDOS:
            const installationURL = SlackIntegrationConfig[slackIntegration][stage.isProduction() ? "prod" : "dev"].installationURL;
            return `${installationURL}?company_id=${companyId ? encodeURIComponent(companyId) : ''}`;
        case SlackIntegration.POLL:
            const { clientId, redirectURL, scope, userScope } = SlackIntegrationConfig[slackIntegration][stage.isProduction() ? "prod" : "dev"];
            const safeRedirectURL = encodeURIComponent(redirectURL);

            const state = companyId ? encodeURIComponent(companyId) : '';

            return `https://slack.com/oauth/v2/authorize?client_id=${clientId}&scope=${scope}&user_scope=${userScope}&state=${state}&redirect_uri=${safeRedirectURL}`;
        default:
            return '';
    }
}

const AddToSlackButton = ({ companyId, slackIntegration }) => {
    // Poll bot can only be installed by existing companies
    if (slackIntegration === SlackIntegration.POLL && !companyId) {
        return null;
    }

    const installURL = getInstallURL(companyId, slackIntegration);

    return <a
        href={installURL}
        className="items-center text-white bg-slack-purple rounded-md inline-flex text-sm font-semibold h-10 justify-center no-underline w-40 border-none font-sans m-1"
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{
                height: '20px',
                width: '20px',
                marginRight: '12px'
            }} q
            viewBox="0 0 122.8 122.8">
            <path
                d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                fill="#e01e5a">
            </path>
            <path
                d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                fill="#36c5f0">
            </path>
            <path
                d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                fill="#2eb67d">
            </path>
            <path
                d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                fill="#ecb22e">
            </path>
        </svg>
        {i18n.addToSlack}
    </a>
}

const mapStateToProps = state => (
    {
        companyId: state.userinfo?.company?.id,
    }
);

export default connect(mapStateToProps)(AddToSlackButton);
export { SlackIntegration };
