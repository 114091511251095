import React from 'react';

import { Field } from 'redux-form';

import { renderRadiobuttonArray } from 'components/Forms';
import FormSection from 'components/Forms/FormSection';
import i18n from "util/i18n";

const shirtSizes = {
    xs: i18n.shirtXs,
    s: i18n.shirtS,
    m: i18n.shirtM,
    l: i18n.shirtL,
    xl: i18n.shirtXl,
    xxl: i18n.shirtXxl,
};

const ShirtSize = () => (
    <FormSection
        label={i18n.shirtSize}
        description={i18n.shirtSizeDesc}
    >
        <Field
            name='shirtSize'
            component={renderRadiobuttonArray}
            labels={shirtSizes}
        />
    </FormSection>
);

export default ShirtSize;
export {shirtSizes};
