import React, {useState} from "react";
import LoadingIcon from 'components/util/LoadingIcon';
import EventEntryForm from "components/Dashboard/Sysadmin/Forms/EventEntryForm";
import {addEvent, updateEvent, loadEvents, deleteEvent} from "util/database/events";
import {useFirestore} from 'react-redux-firebase';
import {change} from 'redux-form';
import {useDispatch, useSelector} from 'react-redux';
import EventDashboard from "components/Dashboard/Events/EventDashboard";
import { loadQuestions } from "util/database/questions";
import { css } from '@emotion/css';
import { eventThemes } from "util/constants/events";

const themes = Object.values(eventThemes);

function EventDB() {
    const firestore = useFirestore();
    const userInfo = useSelector(state => state.userinfo);
    const dispatch = useDispatch();
    const [events, setEvents] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [questionsMap, setQuestionsMap] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [fromAutofill, setFromAutofill] = useState(false);
    const [loadingEvents, setLoadingEvents] = useState(true);
    const [loadingQuestions, setLoadingQuestions] = useState(true);

    const eventForm = useSelector((state) => state.form.eventDBForm);

    // there are random rerenders being triggered throughout the app. for example taking out userInfo
    // means like 10 extra loads...
    if(loadingEvents && loadingQuestions && firestore && userInfo) {
        (async () => {
            await loadQuestions(firestore).then((data) => {
                data.sort((a, b) => a.title.localeCompare(b.title));
                let sortedQ = data.map((question) => `${question.title} · ${question.id}`);
                setQuestions(sortedQ);
                setLoadingQuestions(false);
                let questionsMapTemp ={};
                data.forEach(q => {
                    questionsMapTemp[q.id] = q.title;
                });
                setQuestionsMap(questionsMapTemp);
            });
            await loadEvents(firestore).then((data) => {
                data.sort((a, b) => a.title.localeCompare(b.title));
                setEvents(data);
                setLoadingEvents(false);
            });
        })();
    }

    const normalize = (entries) => {
        let formattedReviews = [];
        if(entries.reviews) {
            formattedReviews = entries.reviews.map((review) => {
                if(review.text) return review;
                let firstCommaIndex = review.indexOf(",")
                let starRating = parseInt(review.substr(0, firstCommaIndex))
                let reviewText = review.substr(firstCommaIndex + 1)

                return {
                    rating: starRating,
                    text: reviewText
                }
            })
        }

        if(entries.questions) {
            let questionIDs = entries.questions.filter(q => q.indexOf(' · ') > 0).map((question) => question.split(' · ')[1] ?? question);
            entries.questions = questionIDs;
        }

        entries.pricePerPerson = parseInt(entries.pricePerPerson);
        entries.tags = entries.tags ?? [];
        entries.questions = entries.questions && !entries.questions.includes(undefined) ? entries.questions : [];
        entries.emote = entries.emote ?? "⭐";
        entries.seoHeader = entries.seoHeader ?? "";
        entries.external_link = entries.external_link ?? "";
        entries.imageUrls = entries.imageUrls ?? [];
        entries.whatDo = entries.whatDo ?? "";
        entries.whatNeed = entries.whatNeed ?? "";

        entries.suggestedAudienceSize = {
            min: parseInt(entries.suggestedAudienceSizeMin) ?? undefined,
            max: parseInt(entries.suggestedAudienceSizeMax) ?? undefined,
        }

        entries.duration = {
            min: parseInt(entries.durationMin) ?? undefined,
            max: parseInt(entries.durationMax) ?? undefined,
        }

        delete entries.suggestedAudienceSizeMin;
        delete entries.suggestedAudienceSizeMax;
        delete entries.durationMin;
        delete entries.durationMax;

        if(formattedReviews !== []) {
            entries.reviews = formattedReviews;
        }

        return entries;
    }

    const eventAutofill = (id) => {
        if(events !== []) {
            const info = events.find(c => (c.id === id));

            for (let [key, value] of Object.entries(info)) {
                if(key === "duration" || key === "suggestedAudienceSize") {
                    dispatch(change('eventDBForm', key + "Min", value.min));
                    dispatch(change('eventDBForm', key + "Max", value.max));
                } else if(key === "questions"){
                    const newVal = value.map(qId => `${questionsMap[qId]} · ${qId}`);
                    dispatch(change('eventDBForm',key, newVal));
                } else {
                    dispatch(change('eventDBForm', key, value));
                }
            }

            setFromAutofill(true);
        }
    };

    const handleSubmit = async () => {
        console.log("eventForm.values",eventForm.values);
        const info = normalize(eventForm.values);
        console.log("info",info);
        if (isEdit) {
            await updateEvent(firestore, info);
        } else {
            await addEvent(firestore, info);
        }

        window.location.reload();
    };

    const handleEventEdit = async (entry) => {
        setIsEdit(true);
        window.scrollTo(0, 0);
        // dispatch(reset('eventDBForm'))
        await eventAutofill(entry.id);
    }

    const handleDeleteEvent = async (entry) => {
        await deleteEvent(firestore, entry);
        window.location.reload();
    }

    if(!questions) return <LoadingIcon/>;

    return loadingEvents || loadingQuestions ? <LoadingIcon/> :
        <>
            <EventEntryForm isEdit={isEdit} onSubmit={handleSubmit} questions={questions} fromAutofill={fromAutofill}/>
            <div className={css`width: 1200px`}>
                <EventDashboard currentThemes={themes} isHomePage={false} events={events} allowPolling={false}
                                handleEventEdit={handleEventEdit} handleDeleteEvent={handleDeleteEvent} sysadmin={true}/>
            </div>
        </>;
}

export default EventDB;
