import "url-search-params-polyfill";
import firebaseConfig from 'config/firebase'

const FUNCTIONS_URL = `https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net/joinLobby`;

// This is the location where game invite links lead, just to have a familiar app.wfhomie in the URL.
// This redirects directly to the offsite platform where the game begins.
// See functions/gamePlatform.js for more info.
const VerifyGameSession = () => {
  const qs = new URLSearchParams(window.location.search);
  const lxid = qs.get("lxid");
  const token = qs.get("token");
  if (token && lxid) {
    try {
      const dest = FUNCTIONS_URL + "?" + qs.toString();
      window.location = dest;
      return "Success! Redirecting...";
    } catch (e) {
      console.error(e);
    }
  }
  return "Something went wrong.";
};

export { VerifyGameSession };
