import React, { useState } from "react";
import { css } from "@emotion/css";

import Modal from 'react-modal';

import { Button, DialogContent, IconButton,
        Stepper, Step, StepLabel, StepButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from 'util/api/stripe';

import {GiftingPackageStepContent, getSteps} from "./GiftingPackageStepContent";

import i18n from "../../../util/i18n";

function GiftingTrigger({giftingTrigger, onEdit, onDelete, openSetPurchaseModal, sysadmin}) {
  return <div
            key={"div" + giftingTrigger.name}
            className={css`
              background-color: white;
              border-radius: 25px;
              background: #FFFFFF;
              border: 3px solid #D8D5F0;
              overflow: hidden;
              box-shadow: 1px 1px 4px hsla(230, 10%, 10%, 0.3);
              display: flex;
              flex-direction: column;
              margin-right: 30px;
              width: 100%;
              text-align: center;
              padding: 20px 0px;
            `}
          >
              <img src={giftingTrigger.image} className={css`height: 90px; width: auto; margin: 20px auto;`} alt={giftingTrigger.name + " image"}/>
              <span className={css
              `font-size: 20px; 
              font-weight: bold; 
              color: blue; 
              text-transform: 
              uppercase; 
              font-family: Seravek;
              font-style: normal;
              font-weight: bold;
              `}>{giftingTrigger.name}</span>
              <span className = {css `color: #B3B3B3;`}>{giftingTrigger.hookDescription}</span>
              <span>
                <KeyboardArrowDownIcon 
                className = {css`color: #D8D5F0;`}/></span>
            <Button
                className={css`
                background-color: #0022cc;
                color: white;
                padding: 8px;
                width: 40%;
                border-radius: 20px;
                font-weight: bold;
                cursor: pointer;
                margin: 10px auto;
                :hover {
                    background-color: #001685;
                }
                `}
                onClick={(e) => {openSetPurchaseModal(e, giftingTrigger)}}>
                {i18n.startBuilding} 
            </Button>
            </div>
}

function NextStepButton({activeStep, steps, topTitle, handleNext, isNextStepDisabled}) {
  return <div className={css`width: 90%;
                                  margin: 0 auto;
                                  display: flex;
                                  justify-content: space-between;`}>
        {topTitle ? <h2>{steps[activeStep]}</h2> : <div></div>}
        {steps.length !== activeStep + 1 &&
            <Button color="primary" onClick={handleNext} disabled={isNextStepDisabled}>
              {/* step name: ({getSteps()[activeStep + 1]}) */}
              <strong>{i18n.next}</strong>
            <ArrowRightIcon/>
            </Button>
        }
      </div>
}

function GiftingTriggers({giftPackages, onEdit, onDelete, sysadmin}) {
  const [activeStep, setActiveStep] = useState(0);
  const [purchaseModalOpen, togglePurchaseModal] = useState(false);
  const [currentTrigger, setCurrentTrigger] = useState(null);
  const [isNextStepDisabled, setIsNextStepDisabled] = useState(true);
  const steps = getSteps();

  // i know this needs to be moved to the database
  const giftingTriggers = [
        {
          type: "Ongoing",
          triggers: [
          {
              name: "Onboarding Package",
              image: "/static/images/Box.png",
              hookDescription : "Welcome your new team",
              longDescription: "Send a package straight to your new team member's door!"
          },
          {
              name: "Work Anniversary",
              image: "/static/images/PartyHats.png",
              hookDescription: "Thank them for sticking around",
              longDescription: "Send a package to your long-time employee."
          },
          {
            name: "Birthdays",
            image: "/static/images/Balloons.png",
            hookDescription: "Show your employees you care",
            longDescription: "Send your employees a birthday gift. We have the dates, and we'll mark the calendar for you."
          },
          {
            name: "Farewell Package",
            image: "/static/images/Handshake.png",
            hookDescription: "Thank them for their work",
            longDescription: "Send them a gift and wish them the best for the future."
          },
          {
            name: "Miscallaneous (Ongoing)",
            image: "/static/images/GiftBox.png",
            hookDescription: "When you just want to give",
            longDescription: "[Placeholder]"
          }
          ]
        },
        {
          type: "One-Time",
          triggers: [
          {
              name: "Swag",
              image: "/static/images/HoodieBox.png",
              hookDescription : "Anything you want, we got it",
              longDescription: "Let your employees rep the team with some sweet company swag."
          },
          {
              name: "Rewards",
              image: "/static/images/Medal.png",
              hookDescription: "You're breathtaking!",
              longDescription: "Send a gift as a big thank-you!"
            },
          {
              name: "Team Wins",
              image: "/static/images/Trophy.png",
              hookDescription: "Sweet Victory",
              longDescription: "Celebrate that milestone together in style."
            },
          {
            name: "Seasonal Care Packages",
            image: "/static/images/RainCloud.png",
            hookDescription: "Whether the weather",
            longDescription: "Work can be stressful. Help your employees out by sending a package so they remember to take care of themselves."
          },
          {
            name: "Miscallaneous (One-Time)",
            image: "/static/images/GiftBox.png",
            hookDescription: "When you just want to give",
            longDescription: "[Placeholder]"
          }
          ]
        },
    ];

  const handleNext = (e) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const openSetPurchaseModal = (e, trigger) => {
    e.stopPropagation();
    setCurrentTrigger(trigger);
    togglePurchaseModal(true);
  };

  const closePurchaseModal = (e) => {
    e.stopPropagation();
    handleReset();
    togglePurchaseModal(false);
  };

  return (
    <div>
      <Modal
        isOpen={purchaseModalOpen}
        onRequestClose={closePurchaseModal}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogContent
          className={css`
            font-size: 16px;
            padding: 20px 40px;
            width: 70vw;
            height: 90vh;
            overflow: scroll;
          `}
        >
            <IconButton
              aria-label="close"
              onClick={closePurchaseModal}
              className={css`
                position: relative;
                margin-left: 95%;
              `}
            >
              <CloseIcon />
            </IconButton>
            <div className={css` margin-top: -40px;`}>
              <h1 className={css`text-align: center`}>{i18n.purchaseGiftingForm} 🎁</h1>
              {currentTrigger && <div className={css`text-align: center;`}>
                <h4>{i18n.trigger}: {currentTrigger.name}</h4>
                <em>{currentTrigger.longDescription}</em>
              </div>}

              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label} className={css`
                    > *:not(.Mui-disabled) {
                      text-decoration: underline;
                    }`}>
                    <StepButton onClick={() => setActiveStep(index)}>
                      <StepLabel>{label}</StepLabel>
                    </StepButton>
                  </Step>
                ))}
              </Stepper>

              <NextStepButton activeStep={activeStep} steps={steps} topTitle={true} handleNext={handleNext}
                              isNextStepDisabled={isNextStepDisabled}/>

              <div className={css`${activeStep !== 0 && "width: 90%; margin: 0 auto;"}`}>
                  <Elements stripe={stripePromise}>
                    <GiftingPackageStepContent disableNextStep={setIsNextStepDisabled} giftPackages={giftPackages}
                        activeStep={activeStep} currentTrigger={currentTrigger} closePurchaseModal={closePurchaseModal}/>
                  </Elements>
              </div>

              <NextStepButton activeStep={activeStep} steps={steps} topTitle={false} handleNext={handleNext}
                              isNextStepDisabled={isNextStepDisabled}/>
          </div>
        </DialogContent>
      </Modal>
      {giftingTriggers.map((triggerType) => {
        return <span className={css`text-align: left;`} key={triggerType.type}>
        <h1 className= {css`font-family: Seravek;
                font-style: normal;
                font-weight: bold;
                font-size: 28px;
                line-height: 22px;
                margin-top: 40px;
                margin-bottom: 40px;
                text-transform: uppercase; 
                        `}>{triggerType.type} Packages</h1>
        <div className={css`display: grid;
                grid-template-columns: repeat(auto-fit, 380px);
                grid-gap: 30px;
                margin-bottom: 110px;`}>
          {triggerType.triggers.map((item) => {
            return <GiftingTrigger key={"card" + item.name} giftingTrigger={item} onEdit={onEdit} onDelete={onDelete}
                                  openSetPurchaseModal={openSetPurchaseModal} sysadmin={sysadmin}/>
          })}
        </div>
        </span>
      })}
    </div>
  );
}

export default GiftingTriggers;
