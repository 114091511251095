// Loading users based on a first name Search
async function loadUsersSearch(firestore, companies, firstName) {
    let userinfo = ''
    if (firstName.toLowerCase() === 'all'){
        userinfo = await firestore.collection('userinfo').get()
    } else {
        userinfo = await firestore.collection('userinfo').where("firstName", '==', firstName).get()
    }

    const users = {};

        // Load 'public' data
    for (let user of userinfo.docs) {
        const userdata = {...user?.data()};
        if(!userdata?.company || !userdata?.company.id || !companies[userdata?.company?.id]){
            console.log("bad data, this user has no related company",userdata?.firstName,userdata?.lastName,userdata?.email);
        } else {
            const companyId = userdata.company.id;
            userdata.company = companies[userdata.company.id];
            userdata.company.id = companyId;
        }
        users[user.id] = userdata;

    }
    return users
}

// Initial user loading on Pagination
async function loadUsersPagination(firestore, companies) {
    const userinfo = await firestore
        .collection('userinfo')
        .orderBy("timeCreated", "asc")
        .limit(15)
        .get()
    const users = {};

        // Load 'public' data
    for (let user of userinfo.docs) {
        const userdata = {...user?.data()};
        if(userdata?.company || userdata?.company.id || companies[userdata?.company?.id]){
            console.log("bad data, this user has no related company",userdata?.firstName,userdata?.lastName,userdata?.email);
        } else {
            const companyId = userdata.company.id;
            userdata.company = companies[userdata.company.id];
            userdata.company.id = companyId;
        }
        users[user.id] = userdata;
    }

    const lastUserQuery = (await firestore.collection('userinfo')
    .orderBy("timeCreated", 'asc')
    .limit(15)
    .get())
    const lastUser = lastUserQuery.docs[lastUserQuery.docs.length - 1]

    return [users, lastUser]
}

// Function to load more users based on pagination
async function loadMoreUsersPagination(firestore, companies, lastUserDoc, usersList) {
    const userinfo = await firestore
        .collection('userinfo')
        .orderBy("timeCreated", "asc")
        .startAt(lastUserDoc)
        .limit(15)
        .get()

    const users = {};

    // Load 'public' data
    for (let user of userinfo.docs) {
        const userdata = {...user.data()};
        if(!userdata?.company || !userdata?.company.id || !companies[userdata.company.id]){
            console.log("bad data, this user has no related company",userdata.firstName,userdata.lastName,userdata.email);
        } else {
            const companyId = userdata.company.id;
            userdata.company = companies[userdata.company.id];
            userdata.company.id = companyId;
        }
        users[user.id] = userdata;
    }
    const newUsers = {...usersList, ...users}

    const lastUserQuery = (await firestore.collection('userinfo')
        .orderBy("timeCreated", 'asc')
        .startAt(lastUserDoc)
        .limit(15)
        .get())
    const lastUser = lastUserQuery.docs[lastUserQuery.docs.length - 1]

    return [newUsers, lastUser]
}

// Load users by searching for a company
async function loadUsersFromCompany(firestore, companies, searchCompany) {

    const searchedUsers = {};

    let userinfo = null
    if (searchCompany.toLowerCase() === "all"){
        userinfo = await firestore
        .collection('userinfo')
        .get()
    } else {
        userinfo = await firestore
        .collection('userinfo')
        .where('companyName', '==', searchCompany)
        .get()
    }

    for (let user of userinfo.docs) {
        const userdata = {...user?.data()}
        if(!userdata?.company || !userdata?.company.id || !companies[userdata?.company.id]){
            console.log("bad data, this user has no related company",userdata?.firstName,userdata?.lastName,userdata?.email);
        } else {
            const companyId = userdata.company.id;
            userdata.company = companies[userdata.company.id];
            userdata.company.id = companyId;
            searchedUsers[user.id] = userdata;
        }
    }
    return searchedUsers
}

// Load the info of a single user. (Requires same company affiliation)
async function loadUser(firestore, uid) {
    const doc = await firestore.collection('userinfo').doc(uid).get();
    return doc.data();
}

// Load user info for all employees (Requires company manager auth)
async function loadMembers(firestore, company) {
    const members = {}
    const companyRef = firestore.collection('companies').doc(company.id);
    (await firestore.collection('userinfo').where('company', '==', companyRef).get())
        .forEach(member => {
            members[member.id] = member.data();
            members[member.id].company = company;
            members[member.id].uid = member.id;
        });
    return members;
}

// Delete user (company manager auth)
async function deleteUser(firestore, uid) {
    const infoRef = firestore.collection('userinfo').doc(uid);
    const infoDoc = await infoRef.get();
    await firestore.collection('deletedUsers')
    .add({
        uid: infoRef.id,
        ...infoDoc.data()
    })
    .then(() => { infoRef.delete() });
}

// TODO: firestore transactions
async function changeUserRole(firestore, uid, newRole) {
    // Update userinfo role
    const infoDoc = firestore.collection('userinfo').doc(uid);
    await infoDoc.set({
        role: newRole,
    }, {merge: true});
}

async function changeUserTeam(firestore, uid, newTeam) {
    // Update userinfo team
    const infoDoc = firestore.collection('userinfo').doc(uid);
    await infoDoc.set({
        team: newTeam,
    }, {merge: true});
}

async function changeUserCompany(firestore, uid, newCompanyId) {
    const infoDoc = firestore.collection('userinfo').doc(uid);
    const newCompanyDoc = firestore.collection('companies').doc(newCompanyId);
    await infoDoc.set({
        company: newCompanyDoc,
        role: 'employee',
        team: null
    }, {merge: true});
}

function changeUserLang(firestore, uid, newLang) {
    const infoDoc = firestore.collection('userinfo').doc(uid);
    infoDoc.set({
        lang: newLang,
    }, {merge: true}).then(()=> window.location.reload());
}

export {loadUsersSearch, loadUsersPagination, loadMoreUsersPagination, loadUsersFromCompany, loadUser, loadMembers, deleteUser, changeUserRole, changeUserTeam, changeUserCompany, changeUserLang};
