const OutlinedButton = ({ disabled = false, children, onClick }) => (
    <div
        className="bg-transparent text-primary text-sm font-medium flex text-center justify-center border border-primary rounded-md cursor-pointer py-1 px-4 hover:bg-primary-hover hover:text-white"
        disabled={disabled}
        onClick={onClick}
    >
        {children}
    </div>
);

export default OutlinedButton
