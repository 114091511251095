import React, {useState} from 'react';
import {useFirestore} from "react-redux-firebase";
import LoadingIcon from "../../../components/util/LoadingIcon";
import {Card, CardContent} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Modal from "react-modal";
import Typography from "@material-ui/core/Typography";
import { withToastNotification  } from 'components/hocs/withToastNotification';
import i18n from 'util/i18n';

const cardStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '20px'
};

function TeamManagement({companyInfo, toastNotification}) {
    const [companyData, setCompanyData] = useState(null);
    const [newTeam, setNewTeam] = useState('');
    const [teamToDelete, setTeamToDelete] = useState('');

    const firestore = useFirestore();

    const companyRef = firestore.collection('companies').doc(companyInfo.id);
    const loadCompany = async () => {
        setCompanyData((await companyRef.get()).data());
    };
    if (!companyData) {
        loadCompany();
    }
    const addTeam = async () => {
        const teams = companyData.teams || [];
        if(!newTeam){
            toastNotification(i18n.teamNameRequired, "error");
        } else if(newTeam.toLowerCase()==='none'){
            toastNotification(i18n.teamNameCantBeNone, "error");
        } else if (teams.includes(newTeam.toLowerCase())) {
            toastNotification(i18n.teamNameAlreadyExists, "warning");
        } else {
            teams.push(newTeam.toLowerCase());
            companyRef.set({teams}, {merge: true}).then(loadCompany);
            toastNotification(i18n.teamNameAdded, "success");
        }
        setNewTeam('');
    };

    const removeTeam = async (teamName) => {
        let teams = companyData.teams || [];
        teams = teams.filter(t => t !== teamName);
        companyRef.set({teams}, {merge: true}).then(loadCompany);
    };

    return !companyData ? <LoadingIcon/> : (
        <>
            <Card style={cardStyle}>
                <CardContent>
                    <h3>Define a New Team</h3>
                    <form noValidate autoComplete="off">
                        <TextField id="new-team" label="New Team" value={newTeam}
                                   onChange={(event) => {
                                       setNewTeam(event.target.value);
                                   }}/>
                        <Button variant="contained" disabled={!newTeam} color="primary" onClick={addTeam}>
                            Add New Team
                        </Button>
                    </form>
                </CardContent>
            </Card>
            {companyData.teams && companyData.teams.map(team => {
                return (<Card style={cardStyle} key={team}>
                    {teamToDelete === team ? <DeleteModal key={team}
                                                          isOpen={teamToDelete === team}
                                                          onSubmit={() => {
                                                              setTeamToDelete('');
                                                              removeTeam(team);
                                                          }} team={team}
                                                          onClose={() => setTeamToDelete('')}
                                                          currentCompany={companyRef}
                                                          firestore={firestore}/> : <></>}
                    <CardContent>
                        <h3>{team} Team</h3>
                        <Button color="default" variant="contained"
                                onClick={() => {
                                    setTeamToDelete(team)
                                }}>Delete</Button>
                    </CardContent>
                </Card>);
            })}
        </>
    );
}

function DeleteModal({isOpen, onSubmit, team, onClose, firestore, currentCompany}) {
    const [loading, setLoading] = useState(true);
    const [anyUserExists, setAnyUserExists] = useState(false);

    const checkAnyUserExist = async () =>{
        const users = await firestore.collection('userinfo')
            .where("company", '==', currentCompany)
            .where('team', '==', team.toLowerCase()).get();

        setLoading(false);
        setAnyUserExists(!users.empty);
    }
    checkAnyUserExist();


    return <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
    >
        <Typography variant='h5'>Deleting Team</Typography>
        {loading ? <LoadingIcon/> : (
            anyUserExists ? <p>There are users with this team, before deleting, you should change their team</p>
                :
                <>
                    <p>Are you sure you want to delete "{team}" team</p>
                    <Button onClick={onSubmit} variant={"contained"} color={"secondary"}>Delete</Button>
                </>
        )}
    </Modal>;
}

export default withToastNotification(TeamManagement);
