import React, {useEffect, useState} from "react";
import {css} from "@emotion/css";

import Grid from "@material-ui/core/Grid";

import {Field, reduxForm, SubmissionError} from "redux-form";
import {useDispatch, useSelector} from "react-redux";

import LoadingIcon from "components/util/LoadingIcon";

import {renderTextField, SubmitButton} from "components/Forms";

import {useFirebase, useFirestore} from "react-redux-firebase";
import {createCompany} from "util/database/companies";
import {initializeUser} from "util/database/invites";
import {useHistory} from "react-router-dom";
import {
    defaultEmployeePermissions,
    defaultEmployeePermissionsForKudos,
    defaultSuperuserPermissions,
    defaultSuperuserPermissionsForKudos
} from "util/permission/permissions";
import i18n from "util/i18n";
import {GoogleButton, MicrosoftButton} from "./AuthButton"

const validate = (values) => {
    if (values === {}) {
        return;
    }

    const errors = {};
    const requiredFields = [["company", ["companyName"]]];

    if ("emailSignIn" in values) {
        requiredFields.push([
            "emailSignIn",
            ["email", "password", "confirmPassword"],
        ]);
        const {password, confirmPassword} = values.emailSignIn;
        if (password && password !== confirmPassword) {
            errors.emailSignIn = {};
            errors.emailSignIn.confirmPassword = i18n.passwordsDontMatch;
        }
    }

    requiredFields.forEach(([subform, fields]) => {
        fields.forEach((field) => {
            if (!values?.[subform]?.[field]) {
                if (!(subform in errors)) {
                    errors[subform] = {};
                }
                errors[subform][field] = i18n.required;
            }
        });
    });

    return errors;
};

const rejectSubmit = (_e) => {
    console.log(_e);
    switch (_e.code) {
        case "auth/invalid-email":
            throw new SubmissionError({
                emailSignIn: {email: _e.message},
            });
        case "auth/weak-password":
            throw new SubmissionError({
                emailSignIn: {password: _e.message},
            });
        case "auth/email-already-in-use":
            throw new SubmissionError({
                emailSignIn: {email: _e.message},
            });
        default:
            throw new SubmissionError({
                emailSignIn: {
                    confirmPassword: i18n.wentWrongTryAgain,
                },
            });
    }
};

const SuperuserSignupForm = ({pristine, reset, invalid, handleSubmit, redirect, source}) => {
    const user = useSelector((state) => state.firebase.auth);
    const form = useSelector((state) => state.form.superuserSignup);
    const firebase = useFirebase();
    const firestore = useFirestore();
    const dispatch = useDispatch();
    const history = useHistory();
    const [signInOption, setSignInOption] = useState("password");
    const oAuthSupplied = signInOption !== "password" && !!user;
    const emailAuthSupplied =
        signInOption === "password" && form?.values?.emailSignIn;
    const anyAuth = oAuthSupplied || emailAuthSupplied;

    useEffect(() => {
        dispatch({type: "@@reactReduxFirebase/SET_PROFILE", payload: {}});
    }, [dispatch]);

    const signInWithOAuthProvider = (provider) => {
        dispatch({type: "userinfo/unload"});
        firebase
            .login({provider, type: "popup"})
            .then(() => {
                reset();
                setSignInOption(provider);
            })
            .catch(() => {
                setSignInOption("password");
            });
    };

    const signInWithGoogle = () => signInWithOAuthProvider("google")

    const signInWithMicrosoft = () => signInWithOAuthProvider("microsoft.com")

    const onSubmit = () => {
        return submitUser(
            user,
            firebase,
            firestore,
            form.values.emailSignIn,
            form.values.company,
            signInOption,
            source
        ).then(() => history.replace({pathname: redirect || "/dashboard/home", state: {justSignedUp: true}}))
            .catch(rejectSubmit);
    };

    if (!user.isLoaded) {
        return <LoadingIcon/>;
    }


    return (
        <>
            <h1
                className={css`
          font-size: 24px;
          color: #ffbc3b;
          margin: 20px 0;
          font-family: "Roboto", serif;
          font-weight: 600;
        `}
            >
                {i18n.getStartedWith}
            </h1>

            <p
                className={css`
          color: hsl(235, 10%, 15%);
          font-size: 18px;
          margin-bottom: 30px;
        `}
            >
                {i18n.signUpMessage}
            </p>

            <form
                onSubmit={handleSubmit(onSubmit)}
                className={css`
          & input {
            border: 1px solid #ccc;
            border-radius: 3px;
            padding: 10px;
            width: 100%;
            background: #fff;
          }

          & .MuiFormControl-root {
            width: 100%;

            .MuiInputBase-root::before {
              display: none;
            }
            .MuiInputBase-root::after {
              bottom: 10px;
            }
          }
        `}
            >
                <Grid container direction="column">
                <div className="flex space-x-4">
                        <GoogleButton
                            onClick={signInWithGoogle}
                        />

                            <MicrosoftButton
                            onClick={signInWithMicrosoft}/>
                </div>
                    {!oAuthSupplied ?
                        <>
                            <Grid
                                item
                                xs
                                className={css`
              margin: 20px 0px;
            `}
                            >
                                <div
                                    className={css`
                text-align: center;
                position: relative;
                color: #ccc;
                &::before,
                &::after {
                  content: "";
                  position: absolute;
                  bottom: 50%;
                  height: 1px;
                  width: 43%;
                  background: #ccc;
                }

                &::before {
                  left: 0;
                  padding-right: 10px;
                }

                &::after {
                  right: 0;
                  padding-left: 10px;
                }
              `}
                                >
                                    {i18n.or}
                                </div>
                            </Grid>
                            <Grid container direction="column">
                                <Grid
                                    container
                                    direction="row"
                                    className={css`
                gap: 10px;
              `}
                                >
                                    <Grid item xs>
                                        <Field
                                            name="emailSignIn.firstName"
                                            placeholder={i18n.firstName}
                                            disabled={oAuthSupplied}
                                            component={renderTextField}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <Field
                                            name="emailSignIn.lastName"
                                            placeholder={i18n.lastName}
                                            disabled={oAuthSupplied}
                                            component={renderTextField}
                                        />
                                    </Grid>
                                </Grid>
                                <Field
                                    name="emailSignIn.email"
                                    placeholder={i18n.workEmailStar}
                                    disabled={oAuthSupplied}
                                    component={renderTextField}
                                />
                                <Field
                                    name="company.companyName"
                                    placeholder={i18n.companyNameStar}
                                    focused={form.active === "company.companyName" || oAuthSupplied}
                                    component={renderTextField}
                                />
                                <Field
                                    name="emailSignIn.password"
                                    placeholder={i18n.passwordStar}
                                    type="password"
                                    disabled={oAuthSupplied}
                                    component={renderTextField}
                                />
                                <Field
                                    name="emailSignIn.confirmPassword"
                                    placeholder={i18n.confirmPasswordStar}
                                    type="password"
                                    disabled={oAuthSupplied}
                                    component={renderTextField}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                className={css`
              margin-top: 20px;
            `}
                            >
                                <SubmitButton
                                    onClick={handleSubmit(onSubmit)}
                                    disabled={pristine || invalid || !anyAuth}
                                    label={i18n.signUp}
                                />
                            </Grid>
                        </> :
                        <>
                            <Field
                                name="company.companyName"
                                placeholder={i18n.companyNameStar}
                                focused={form.active === "company.companyName" || oAuthSupplied}
                                component={renderTextField}
                            />
                            <SubmitButton
                                onClick={handleSubmit(onSubmit)}
                                disabled={pristine || invalid || !anyAuth}
                                label={i18n.signUp}
                            />
                        </>
                    }
                </Grid>
            </form>
        </>
    );
};

const submitCompany = async (firestore, {companyName: name}, source) => {
    return await createCompany(firestore, {
        name,
        credits: 0,
        rolePermissions: {
            superuser: source === 'kudos' ? defaultSuperuserPermissionsForKudos : defaultSuperuserPermissions,
            employee: source === 'kudos' ? defaultEmployeePermissionsForKudos : defaultEmployeePermissions
        },
        featureList:  source === 'kudos' ? defaultSuperuserPermissionsForKudos : defaultSuperuserPermissions,
        subscription: firestore.doc('/subscriptionPlans/999'),
        timeCreated: Date.now(),
        source: source
    });
};

const submitUser = async (user, firebase, firestore, formValuesEmail, formValuesCompany, signInOption, source) => {
    let userData = user;

    let initializationData = {
        image: "https://i.imgur.com/aflwVYZ.png",
        role: "superuser",
        timeCreated: Date.now(),
    };

    switch (signInOption) {
        case "password": {
            const { firstName, lastName, email, password } = formValuesEmail;

            userData = (await firebase.auth().createUserWithEmailAndPassword(email, password)).user;

            initializationData = {
                ...initializationData,
                email,
                firstName,
                lastName,
            };

            break;
        }
        case "microsoft.com":
        case "google": {
            const [firstName, ...last] = userData.displayName.split(" ");

            initializationData = {
                ...initializationData,
                email: userData.email,
                image: userData.photoURL,
                firstName,
                lastName: last.join(""),
            };

            break;
        }
        default: {
            throw new Error("Unknown sign in provider");
        }
    }

    return await submitCompany(firestore, formValuesCompany, source).then(async (companyInfo) => {
        await initializeUser(firestore, userData, {
            ...initializationData,
            inviteCode: companyInfo.inviteCode,
            company: companyInfo.companyRef,
        });
    });
};

export default reduxForm({form: "superuserSignup", validate})(
    SuperuserSignupForm
);
