import firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid';

const handleFileUpload = async (file, directory, generateID = true) => {
    const storageRef = firebase.storage().ref();

    let result;
    if (generateID) {
        const id = uuidv4();
        const ext = file.name.split('.').pop();
        result = await storageRef.child(`${directory}/${id}.${ext}`).put(file);
    } else {
        result = await storageRef.child(`${directory}/${file.name}`).put(file);
    }

    const url = await result.ref.getDownloadURL();
    return url;
}

// Get an array of the file names in the current directory
const getFileNamesAt = async directory => {    
    const storageRef = firebase.storage().ref(directory);
    const fileRefs = await storageRef.listAll();
    const fileNames = fileRefs.items.map(file => file.name);
    return fileNames;
}

const deleteItem = filePath => {
    const storageRef = firebase.storage().ref();
    var fileRef = storageRef.child(filePath);
    fileRef.delete();
}

export { handleFileUpload, getFileNamesAt, deleteItem };
