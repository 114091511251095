import {useEffect} from "react";
import {useSelector} from "react-redux";

const ExternalRedirectToKudosAdd = () => {
    const userData = useSelector((state) => state.userinfo);
    useEffect(()=>{
        if(userData){
            const botId = process.env.REACT_APP_BUILD_MODE === "production" ? `2003043558818.2126946347671` : `2003043558818.2477424615205`;
            window.location = `https://slack.com/oauth/v2/authorize?client_id=${botId}&scope=chat:write,commands,users:read,users:read.email,channels:read,chat:write.public&state=${userData.company.id}`;
        }
    },[userData])
    return null;
}

const ExternalRedirectToShameAdd = () => {
    const userData = useSelector((state) => state.userinfo);
    useEffect(()=>{
        if(userData){
            window.location = `https://slack.com/oauth/v2/authorize?client_id=2003043558818.3309676577730&scope=channels:read,commands,users:read,channels:manage,chat:write,chat:write.public,users:read.email&user_scope=&state=${userData.company.id}`;
        }
    },[userData])
    return null;
}

export {ExternalRedirectToKudosAdd, ExternalRedirectToShameAdd};
