import { SlackButtonDark } from "components/Forms/Auth/AuthButton";
import { Button } from "./button";
import { redirectToBookDemo } from "./pageRedirects";

export const Pricing = () => {
    return (
        <div className="flex flex-col items-center p-16 space-y-16">
            <div className="text-center">
                <span className="font-bold text-4xl text-dark-grey">Pricing</span>
            </div>

            <div className="flex flex-wrap space-y-8  lg:space-x-20 lg:space-y-0 ">
                <div className="flex flex-col w-96 shadow-md border border-gray-100 p-4 bg-white space-y-6">
                    <div className="flex justify-center py-6">
                        <img className="w-24" src="/static/images/free-pricing.png" alt="Free Pricing" />
                    </div>

                    <div className="flex bg-kudos-orange-bg w-full p-6 justify-center">
                        <div className="flex space-x-16 items-center">
                            <span className="text-4xl font-light text-kudos-orange">Free</span>
                            <div className="flex flex-col items-center">
                                <div className="space-x-1">
                                    <span className="text text-lg align-top font-bold text-kudos-dark-purple">$</span>
                                    <span className="text-6xl text-kudos-dark-purple font-bold">0</span>
                                </div>
                                <span className="text-kudos-dark-purple text-sm font-light">/month</span>
                            </div>
                        </div>
                    </div>

                    <div className="w-full">
                        <div className="flex border-b border-gray-100 space-x-4 py-2 px-1">
                            <span className="text-kudos-green">✔️</span>
                            <span className="text-gray-600">Easy Slack installation</span>
                        </div>
                        <div className="flex border-b border-gray-100 space-x-4 py-2 px-1">
                            <span className="text-kudos-green">✔️</span>
                            <span className="text-gray-600">Acknowledge your teammates publicly with a message of appreciation</span>
                        </div>
                        <div className="flex border-b border-gray-100 space-x-4 py-2 px-1">
                            <span className="text-kudos-green">✔️</span>
                            <span className="text-gray-600">Weekly summary of the Kudos given in your organization</span>
                        </div>
                    </div>

                    <div className="flex justify-center py-6">
                        <SlackButtonDark />
                    </div>
                </div>

                <div className="flex flex-col w-96 shadow-md border border-gray-100 p-4 bg-white space-y-6">
                    <div className="flex justify-center py-6">
                        <img className="w-24" src="/static/images/paid-pricing.png" alt="Paid Pricing" />
                    </div>

                    <div className="flex bg-kudos-orange-bg w-full p-6 justify-center">
                        <div className="flex space-x-16 items-center">
                            <span className="text-4xl font-light text-kudos-orange">Premium</span>
                            <div className="flex flex-col items-center">
                                <div className="space-x-1">
                                    <span className="text align-top text-lg font-bold text-kudos-dark-purple">$</span>
                                    <span className="text-6xl text-kudos-dark-purple font-bold">2</span>
                                    <span className="text-xl text-kudos-dark-purple ">.99</span>
                                </div>
                                <span className="text-kudos-dark-purple text-sm font-light">/person/month</span>
                            </div>
                        </div>
                    </div>

                    <div className="w-full">
                        <div className="flex border-b border-gray-100 space-x-4 py-2 px-1">
                            <span className="text-kudos-green">✔️</span>
                            <span className="text-gray-600">Easy Slack installation</span>
                        </div>
                        <div className="flex border-b border-gray-100 space-x-4 py-2 px-1">
                            <span className="text-kudos-green">✔️</span>
                            <span className="text-gray-600">Acknowledge your teammates publicly with a message of appreciation</span>
                        </div>
                        <div className="flex border-b border-gray-100 space-x-4 py-2 px-1">
                            <span className="text-kudos-green">✔️</span>
                            <span className="text-gray-600">Weekly summary of the Kudos given in your organization</span>
                        </div>
                        <div className="flex border-b border-gray-100 space-x-4 py-2 px-1">
                            <span className="text-kudos-green">✔️</span>
                            <span className="text-gray-600">Send your teammates gift cards for being awesome</span>
                        </div>
                    </div>

                    <div className="flex justify-center py-6">
                        <Button onClick={redirectToBookDemo} shape="rounded">Book a demo</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
