import { css } from "@emotion/css";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import { renderTextField, SubmitButton } from "components/Forms";
import { default as DietaryRestrictions } from "components/Forms/sections/DietaryRestrictions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { Field, reduxForm } from "redux-form";
import { handleFileUpload } from "util/storage";
import LoadingIcon from "../../components/util/LoadingIcon";
import FormSection from 'components/Forms/FormSection';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import ShippingInfo from "components/Forms/sections/ShippingInfo";
import { default as ShirtSize } from "components/Forms/sections/ShirtSize";
import WorkStartDate from "components/Forms/sections/WorkStartDate";
import Birthday from "components/Forms/sections/Birthday";
import {renderTimeZoneField} from '../../util/forms.js';

const ProfilePicturePicker = ({ image, input }) => {
  const [imagePreview, setImagePreview] = useState(image);
  const [loadingImage, setLoadingImage] = useState(false);

  const onChange = async (e) => {
    const {
      target: {
        files: [upload],
      },
    } = e;
    if (!upload) {
      return;
    }
    setLoadingImage(true);
    const url = await handleFileUpload(upload, 'profile');
    setImagePreview(url);
    setLoadingImage(false);
    return input.onChange(url);
  };

  return (
    <>
      {
        loadingImage ?
          <div
            className={css`
              display: flex;
              justify-content: center;
            `}
          >
            <LoadingIcon />
          </div>
          : <label
            htmlFor="image-upload"
            className={css`
            cursor: pointer;
            opacity: 0.3;
            transition: opacity 0.2s ease-in-out;

            &:hover {
              opacity: 1;
            }
          `}
          >
            <div
              className={css`
                border-radius: 100%;
                height: 100px;
                width: 100px;
                background: no-repeat center/cover url("${imagePreview}");
                border: 4px solid white;
                box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.45);
                margin: 0 auto;
                margin-bottom: 20px;
                position: relative;
                overflow: hidden;
                &:hover div {
                  display: block;
                }
              `}
              title={"Select a new profile picture"}
            >
              <div className={css`
                text-align: center;
                background-color: #c0c0c0a6;
                position: absolute;
                bottom: 0;
                width: 100%;
                display: none;
              `}>
                <AddAPhotoIcon />
              </div>
            </div>
          </label>
      }


      <input
        type="file"
        id="image-upload"
        accept="image/*"
        onChange={onChange}
        className={css`
            display: none;
          `}
      />{" "}
    </>
  );
};

  const UserProfileForm = reduxForm({ form: "userSettingsForm" })(
    ({ image, firstName, lastName, address, apartment, signupTime }) => {
    const formattedSinceJoined = new Date(signupTime).toLocaleDateString(
      "en-CA",
      {
        month: "long",
        day: "numeric",
        year: "numeric",
      }
    );

// inside your render() method
   return (
      <form className={css`width: 100%;`}>
        <Grid item xs justifyContent="center">
          <Field
            name="image"
            component={ProfilePicturePicker}
            image={image}
          />
        </Grid>
        <FormSection
          label="Basic Info"
          columns={1}>
          <Grid container>
            <Field name="firstName" placeholder="First name" component={renderTextField} />
            <div className={css`
              margin: 0 1em;
            `} />
            <Field name="lastName" placeholder="Last name" component={renderTextField} />
          </Grid>
          <Grid item>
            <span>Joined {formattedSinceJoined}</span>
          </Grid>
        </FormSection>
        <FormSection
          label="Timezone"
          columns={1}>
          <Grid item>
              <span>Please Make a Selection</span>
              <div className='select-wrapper'>
                <Field name="timeZone" component={renderTimeZoneField}/>
              </div>
          </Grid>
        </FormSection>
        <FormSection columns={2} label={"Important Dates"}>
        <Grid item>
          <WorkStartDate/>
        </Grid>
        <Grid item>
          <Birthday/>
        </Grid>
        </FormSection>
        <Grid item>
          <ShippingInfo />
        </Grid>
        <Grid item>
          <ShirtSize/>
        </Grid>
        <Grid item>
          <DietaryRestrictions />
        </Grid>
      </form>
    );
  }
);

const UserSettings = () => {
  const userData = useSelector((state) => state.userinfo);
  const form = useSelector((state) => state.form.userSettingsForm);
  const [toastOpen, toggleToast] = useState(false);
  const dispatch = useDispatch();
  const firestore = useFirestore();

  async function handleSubmit() {
    const ref = firestore.collection("userinfo").doc(userData.userId);
    if (!ref) {
      throw new Error("Settings submission failed.");
    }
    const info = {};

    // This was the first-ever Redux Form validator I wrote.
    // You may want to refactor this into something less
    // spaghetti-looking.

    Object.entries(form.values).forEach(([fld, val]) => {
      console.log(fld)
      if (typeof val === "string") {
        info[fld] = val;
      } else if (fld ==='timeZone'){
          info.timeZone = val.value;
      } else if (fld === "address") {
        if (val.address) {
          info[fld] = val.address
        }
      } else if(Object.prototype.toString.call(val) === '[object Date]') {
        info[fld] = val.toDateString().substring(4);
      } else {
        info[fld] = { ...userData[fld], ...val };
      }
    });

    await ref.update(info);

    // optimistically update the user's info
    dispatch({ type: "userinfo/load", payload: {...info, userId: ref.id} });
    toggleToast(true);
    return true;
  }

  // You'll also want to update this.
  // Redux-form has an `initialize` prop that will
  // probably be useful in that regard.
  useEffect(
    () =>
      userData &&
      form &&
      !form.values &&
      Object.keys(form.registeredFields).forEach((field) => {
        dispatch({
          type: "@@redux-form/CHANGE",
          meta: { form: "userSettingsForm", field },
          payload: field === "address" ? { address: userData[field] } : userData[field],
        });
      }),
    [userData, form, dispatch]
  );

  return (
    <div
      className={css`
        margin-top: calc(85px - 1em);
        padding: 1em;
      `}
    >
      <div className={css`width: 50%; text-align: center;margin: auto;`}>
        <h1>Profile Settings</h1>
      </div>
      <Grid
        container
        direction="column"
        xs={12}
        alignItems="center"
        justifyContent="space-evenly"
        className={css`width: 50%; margin: auto; margin-bottom: 25px;`}
      >
        {userData && <UserProfileForm {...userData} />}
        <Grid container>
          <SubmitButton onClick={() => handleSubmit()} />
        </Grid>
        <Snackbar
          open={toastOpen}
          autoHideDuration={4000}
          onClose={() => toggleToast(false)}
          message="User data saved successfully."
        />
      </Grid>
    </div>
  );
};

export default UserSettings;
