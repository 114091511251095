import { UpgradeToPremium } from "./UpgradeToPremium";

export const InsightsUpsell = () => {
    return (
        <div className="bg-purple-bg w-full max-w-full rounded-lg relative">
            <div className="">
                <img
                    className="object-contain"
                    src="/static/images/team-participation-placeholder.png"
                    alt="Team participation graph"
                />
            </div>
            <div className="absolute inset-0">
                <div className="flex flex-col items-center justify-center space-y-3 p-4">
                    <div className="flex-auto">
                        <img src="/static/images/trophy-image.png" alt="Trophy" />
                    </div>

                    <span className="font-bold text-lg">Interested in team Analytics?</span>

                    <UpgradeToPremium />
                </div>
            </div>
        </div>
    );
};
