async function loadQuestions(firestore) {
    const giftPackages = await firestore.collection('questions').get();
    return giftPackages.docs.map(doc => doc.data());
}

async function addQuestion(firestore, entry) {
    const newRef = await firestore.collection('questions').doc();
    return newRef.set({...entry, id: newRef.id});
}

async function updateQuestion(firestore, entry) {
    await firestore.collection('questions').doc(entry.id).update(entry);
}

async function deleteQuestion(firestore, entry) {
    await firestore.collection('questions').doc(entry.id).delete(entry);
}

async function loadQuestionsOfEvent(firestore, eventId) {
    const questionIds = [];
    return await firestore.collection('events').doc(eventId).get().then(eventDoc => {
        const promises = [];
        const eventData = eventDoc.data();
        eventData.questions.forEach(qid => {
            questionIds.push(qid);
            promises.push(firestore.collection('questions').doc(qid).get());
        });
        return Promise.all(promises);
    }).then(questionsDoc => {
        return questionsDoc.map(q => {
            return {...q.data(), id: q.id};
        });
    });
}

async function loadUserAnswers(firestore, questionIdList, userId) {
    return (await firestore.collection("answers").where("questionId", 'in', questionIdList).where("userId", '==', userId).get())
        .docs.map(doc => {
            return {...doc.data(), id: doc.id};
        });
}

async function saveAnswers(firestore, answers, userId) {
    const promises = [];
    Object.keys(answers).forEach(question => {

        firestore.collection('answers').where('questionId', '==', question).where('userId', '==', userId).get().then(result => {
            let ansRef;
            if (result.empty) {
                ansRef = firestore.collection('answers').doc();
            } else {
                ansRef = result.docs[0].ref;
            }
            promises.push(ansRef.set({questionId: question, answer: answers[question], userId: userId, date: new Date()}));
        })
    });
    await Promise.all(promises);
}

async function loadQuestionByID(firestore, id) {
    const collection = await firestore.collection("questions")
        .where("id", "==", id)
        .get()
    return collection.docs[0].data()
};

export {
    loadQuestions,
    addQuestion,
    updateQuestion,
    deleteQuestion,
    loadQuestionsOfEvent,
    loadUserAnswers,
    saveAnswers,
    loadQuestionByID
};
