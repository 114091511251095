import React from 'react';
import { css } from '@emotion/css';
import { useHistory } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch } from 'react-redux';
import Mixpanel from "util/analytics/mixpanel";
import { mixpanelEvents } from 'util/constants/mixpanel';
import i18n from "util/i18n";
import {useFirebase} from "react-redux-firebase";

function SignOut() {
    const firebase = useFirebase();
    const history = useHistory();
    const dispatch = useDispatch();

    const signOut = async () => {
        Mixpanel.track(mixpanelEvents.SIGN_OUT);
        await firebase.auth().signOut();
        dispatch({'type': 'userinfo/unload'});
        history.replace({pathname: '/'});
    };

    return <MenuItem onClick={signOut} className={css`color:red;`}>{i18n.signOut}</MenuItem>
}

export default SignOut;
