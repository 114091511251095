import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/auth';

import firebaseConfig from 'config/firebase';

import { createStore } from 'redux';
import { Provider } from 'react-redux';

import rrfConfig from 'config/react-redux-firebase';
import rootReducer from './reducers';
import { createFirestoreInstance } from 'redux-firestore';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';

import Dashboard from 'routes/Dashboard';
import SignupPortal from 'routes/Signup/SignupPortal';
import ForgotPortal from 'routes/ForgotPortal/ForgotPortal';
import Landing from 'routes/Landing';
import BookingPage from 'routes/BookingPage';
import { hotjar } from 'react-hotjar';
import PrivacyCenter from 'routes/PrivacyCenter/PrivacyCenter';
import { VerifyGameSession } from 'util/api/game';
import {TrackNavigation} from "./components/TrackNavigation";
import GiftingPage from "routes/GiftingPage";
import i18n from "./util/i18n";
import QuestionPage from "./routes/QuestionPage";
import LoginPage from "./routes/Login/LoginPage";
import {ExternalRedirectToKudosAdd, ExternalRedirectToShameAdd} from "./components/util/externalRedirect";

import {FallEventsPage} from "routes/LandingPages/FallEvents";
import { createTheme } from '@material-ui/core/styles'

import { IntercomUserInitializer } from "./util/intercom/IntercomUserInitializer";

import { IntercomProvider } from 'react-use-intercom';
import { getIntercomAppID } from "util/intercom";
import { stage } from 'util/stage';
import { initializeModals } from 'util/modals';
import { KudosSignupPage } from 'routes/kudos/KudosSignupPage';
import HiddenFirst100KudosCompany from "./routes/HiddenSysadmin/First100KudosCompany";
import {ShameSignupPage} from "./routes/shame/KudosSignupPage";


if(stage.isProduction()){
    // Observability
    hotjar.initialize(2256463, 6);
}

if(!stage.isTesting()){
    initializeModals();
}

firebase.initializeApp(firebaseConfig);
firebase.firestore();
firebase.auth();

const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance
}

const theme = createTheme({
    palette: {
        primary: {
            light: 'hsl(230, 100%, 80%)',
            main: 'hsl(230, 100%, 40%)',
            dark: 'hsl(230, 100%, 20%)',
            contrastText: '#fff',
        },
        secondary : {
            light: 'hsl(230, 100%, 80%)',
            main: 'hsl(230, 100%, 40%)',
            dark: 'hsl(230, 100%, 20%)',
            contrastText: '#fff',
        },
    },
});

const INTERCOM_APP_ID = getIntercomAppID();

function App() {
    const params = new URLSearchParams(window.location.search);
    const urlLang = params.get("lang");
    if (urlLang) {
        i18n.setLanguage(urlLang);
    }
    return <div className='App' id="App">
        <IntercomProvider appId={INTERCOM_APP_ID}>
            <Provider store={store}>
                <IntercomUserInitializer>
                    <ReactReduxFirebaseProvider {...rrfProps}>
                        <BrowserRouter>
                            <TrackNavigation>
                                <ThemeProvider theme={theme}>
                                    <StylesProvider injectFirst>
                                        <Switch>
                                            <Route exact path="/dashboard/events/fall"><FallEventsPage /></Route>
                                            <Route path='/privacy-center'><PrivacyCenter /></Route>
                                            <Route path='/book'><BookingPage /></Route>
                                            <Route exact path='/kudos'><KudosSignupPage/></Route>
                                            <Route exact path='/shame'><ShameSignupPage/></Route>
                                            <Route path='/kudos-signup'><KudosSignupPage/></Route>
                                            <Route path='/kudos-beta'><KudosSignupPage/></Route>
                                            <Route exact path='/dashboard'><Redirect to='/dashboard/home' /></Route>
                                            <Route path='/dashboard'><Dashboard /></Route>
                                            <Route path='/gifting'><GiftingPage /></Route>
                                            <Route path='/question'><QuestionPage /></Route>
                                            <Route path='/signup'><SignupPortal /></Route>
                                            <Route path='/forgot'><ForgotPortal /></Route>
                                            <Route path='/hidden/kudosCompany'><HiddenFirst100KudosCompany /></Route>
                                            <Route path='/redirectToAddKudos' >
                                                <ExternalRedirectToKudosAdd />
                                            </Route>
                                            <Route path='/redirectToAddShame' >
                                                <ExternalRedirectToShameAdd />
                                            </Route>
                                            <Route exact path='/login'><LoginPage/></Route>
                                            <Route exact path='/game'><VerifyGameSession /></Route>
                                            <Landing />
                                        </Switch>
                                    </StylesProvider>
                                </ThemeProvider>
                            </TrackNavigation>
                        </BrowserRouter>
                    </ReactReduxFirebaseProvider>
                </IntercomUserInitializer>
            </Provider>
        </IntercomProvider>
    </div>;
}

export default App;
