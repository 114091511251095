import LocalizedStrings from 'react-localization';

const i18n = new LocalizedStrings({
    en: {
        localeString: 'en-CA',

        localeTimeZone: 'America/Detroit',
        home: "Home",
        congrats: "Congrats on taking another step towards keeping\n        your distributed team engaged! ",
        weAreHereForYou: "We’re here for you.",
        getStarted: "Get Started",
        notSpecified: "Not Specified",
        signUp: "Sign Up",
        signIn: "Sign In",
        manageCompanyMembers: "Manage Company Members",
        kudos: "Kudos",
        companyProfile: "Company Profile",
        yourColleaguesAt: "Your Colleagues at",
        member: 'member',
        registered: 'registered',
        receipts: "Receipts",
        settings: "Settings",
        backToLogin: "Back To Login",
        trialExpiredIn: "Your free trial expires in {0}.",
        migrationFailed: "Migration failed",
        successBambooHR: "Successfully imported addresses from BambooHR",
        successUserBambooHR: "Successfully imported users from BambooHR",
        successUserCompanyChange: "Successfully changed user company",
        failUserCompanyChange: "Failed to change user company",
        accError: "There is a problem with your account, please sign up again with the invitation link in your email inbox!",
        errorR: "Error",
        addLinkZoom: "Please add a link, or check the Generate Zoom checkbox.",
        notImplemented: "Not Implemented",
        roleName: "Please enter a role name",
        roleNameAdded: "Role added successfully",
        teamNameRequired: "Team name is required",
        teamNameCantBeNone: "Team name cannot be 'none'",
        teamNameAlreadyExists: "Team name already exists",
        teamNameAdded: "Team added successfully",
        passwordResetEmailSent: "Password reset email sent",
        planSavedSuccessfully: "Plan saved successfully",
        hello: "Hello",
        yourTeamAt: "Your Team at",
        events: "Events",
        gifting: "Gifting",
        classes: "Classes",
        activities: "Activities",
        analytics: "Bookings",
        seeMore: "See More",
        featured: 'Featured',
        exploreEvents: "Explore Events",
        exploreGifting: "Explore Gifting",
        exploreActivities: "Explore Activities",
        gameInstructions: {
            crowdparty: {
                title: "Notes:",
                shareRoomPin: {
                    emoji: "🔢",
                    text: "The host of the game must share the room pin with the other players before starting the game."
                },
                shareScreen: {
                    emoji: "💻",
                    text: "The game host will have to share their screen."
                },
                hostPlayer: {
                    emoji: "🎮",
                    text: "If you are hosting the game and would like to play, please open up the game on another screen and enter the pin."
                }
            }
        },
        hostAGame: "Host a Game",
        joinAGame: "Join a Game",
        joinRemotely: "Join Remotely",
        people: "people",
        credit: "credit",
        group: "group",
        startingAt: "Starting at",
        free: 'Free',
        unlimited: "Unlimited",
        atLeast: "At least",
        upTo: "Up to",
        mins: "mins",
        searchEvents: "Search Events...",
        search: "Search",
        startBuilding: "Start Building",
        pollYourTeam: "Poll your Team",
        signOut: "Sign Out",
        alreadyHaveAnAccount: "Already have an account?",
        passwordsDontMatch: "Passwords don't match",
        required: "Required",
        getStartedWith: "Get started with WFHomie",
        fewClicksAway: "You're just a few clicks away from joining your team at {0}.",
        or: "or",
        users: "Users",
        company: "Company",
        firstName: "First name",
        lastName: "Last name",
        workEmailStar: "Work email*",
        workEmail: "Work email",
        emailAddress: "Email Address",
        passwordStar: "Password*",
        password: "Password",
        confirmPasswordStar: "Confirm password*",
        wentWrongTryAgain: "Something went wrong. Please try again.",
        signUpMessage: "Sign up to start boosting your team’s culture with virtual experiences,\neasy deliveries and more!",
        hiComma: "hi,",
        signUpWithGoogle: "Sign up with Google",
        companyNameStar: "Company name*",
        companyName: "Company name",
        enterValidEmail: "Please enter a valid email.",
        incorrectEmailOrPass: "Incorrect email or password.",
        dontHaveAnAccount: "Don't have an account?",
        activityDetails: "Activity details",
        minutes: "minutes",
        includePhysicalKit: "Includes physical event kit delivery",
        virtualOnly: "Virtual-only. This activity does not include a physical kit delivery",
        reviews: "Reviews",
        whatYoullDo: "What You’ll Do",
        howItWorks: "How it works",
        whatYoullNeed: "What You'll Need",
        similarExperiences: "Similar Experiences",
        useOneCredit: "Use 1 credit (up to 30 participants), or",
        startingAtPerPerson: "Starting at {0}",
        useCredits: {
            free: "Use {0} {1} for a free event",
            reducedPrice: `Use {0} {1} for a reduced price of \${2}`,
        },
        slashPerson: "/person",
        showingPriceFor: "Showing prices for",
        weekOf: "Week of",
        nextWeek: "Next Week",
        bookNow: "Book Now",
        bookACall: "Book a call",
        buildYourSwagPack: "Build your swag pack",
        thingsToKnow: "Things to Know",
        fullRefund: "Full refund if cancelled within 12 hours.",
        pleaseWaitWhileWeProcess: "Please wait while we are processing your request...",
        confirmAndPay: "Confirm and Pay",
        authentication: "Authentication",
        pleaseAuth: "Please sign in or sign up to continue.",
        signInWithMicrosoft: "Sign in with Microsoft",
        loginFailed: "Login failed",    
        dateAndTime: "Date & Time",
        selectUpTo3TimeSlots: "Select up to 3 time slots, in order of preference.",
        weWillConfirmDateTime: "We will confirm the date & time based on the host's availability.",
        priceDetails: "Price Details",
        customRequests: "Custom Requests (optional)",
        finishAndPay: "Finish & Pay",
        generatePrebookingLink: "Generate Pre-Booking Link",
        eventName: "Event name",
        timeZone: "Timezone",
        dates: "Dates",
        choice: "choice",
        thankYou: "Thank you.",
        opsSomethingWentWrong: "Oops! Something went wrong...",
        backToEventDashboard: "Back to event dashboard",
        numberOfParticipants: "Number of participants",
        wellBeInTouchForTimeConfirmation: "We'll be in touch shortly, with a date and time confirmation.",
        dropInToTheseClassToGrowth: "Drop in to these classes to accelerate your growth!",
        orderHistory: "Order History",
        youHaventOrderAnyGiftYetWhyNot: "You haven’t ordered any gifts yet... Why not treat your team today?",
        browse: "Browse",
        ourCollectionOf: "our collection of 20+ packages, or build your own.",
        packageName: "Package Name",
        numberOfGiftees: "Number of giftees",
        companySettingSubmissionFailed: "Company settings submission failed.",
        businessSetting: "Business Settings",
        slackIntegrations: "Slack Integrations",
        addPollSlackBotMsg: "Add {0} to your Slack workspace and let your employees vote for their preferred event with just one click!\nYou can add the bot to as many channels as you want. ",
        wfhomiePoll: "WFHomie Poll",
        slackKudosBotMsg: "Add {0} to your Slack workspace!\n Members can use the \"/kudos @recipient message\" command to send a Kudos to a coworker. Have the gifting plan? The Kudos will come with a gift voucher!",
        wfhomieKudos: "WFHomie Kudos",
        generalInfo: "General Info",
        subscriptionInfo: "Subscription Info",
        creditsInBank: "Credits in Bank",
        contactUsToUpgradeSubscription: "Contact us to upgrade your subscription plan",
        usageRestrictions: "Usage Restrictions",
        allowKudosCommandInChannel: "Choose the channels where the /kudos command can be used. If none are selected, the command will be available in all channels.",
        weAreStillGettingTheChannelList: "We’re still fetching the channel list! Check back a bit later.",
        kudosAnnouncements: "Kudos Announcements",
        publishKudosMsgToThisChannel: "Publish Kudos messages to this channel:",
        allowedUsers: "Allowed Users",
        chooseWhichUserAllowToUseBot: "Choose which users are allowed to use the /kudos command. If none are selected, then everybody has access.",
        onlyWFHomieAccountsShown: "Only users with WFHomie accounts are shown.",
        loadingUsersWait: "Loading users, just a moment...",
        creditRenewalSchedule: "Credit Renewal Schedule",
        chooseWhenToTopUpYourEmployee: "Choose when to top-up your employees’ kudos credits. Each /kudos command uses one credit.",
        creditAccumulateMsg: "Credits will accumulate until the credit limit is reached. To have credits expire at the end of each period, just set the credit limit equal to the top-up amount.",
        topUpSchedule: "Top-Up Schedule",
        topUpAmount: "Top-Up Amount",
        creditLimit: "Credit Limit",
        kudosLimit: "Kudos Limit",
        kudosLimitDescription: "Limit the number of gifts that can be received by the same person. This can be useful to spread the praise more equally. Resets at each credit top-up.",
        settingsSaved: "Settings Saved",
        addKudosBot: "Add the Kudos Bot",
        addKudosBotDescription: "To enable these settings, please add the WFHomie Kudos Bot to your Slack workspace.",
        chooseADateAndTime: "Choose a date and time",
        addAlternativeDateOption: "Add alternative date option",
        openConfirmation: "Open Confirmation",
        saveYourSpot: "Save your Spot",
        inviteTeamMembers: "Invite Team Members",
        selectMembersToInvite: "Select members to invite",
        allMembersRegistered: "All members registered",
        alreadyRegistered: "Already registered",
        thisFieldIsRequired: "This field is required",
        payWithACredit: "Pay with a credit",
        fileUploadedSuccessfully: "File uploaded successfully",
        successfulYouWillGetEmail: "Success! You and your invitees will receive an email with more details.",
        wfhomieGames: "WFHomie Games",
        fromWfhomie: "from WFHomie",
        filterByTag: "Filter by tag",
        selectTagToFilter: "Select tags to filter...",
        youCanUseArrowKeyToSetPrice: "You can also use the arrow keys to set a maximum price.",
        showResult: "Show results",
        joinYourTeamForGame: "Join your team at {0} for an exciting game of {1}!",
        titleJoinGame: "{0} with your team!",
        filters: "Filters",
        noEventFoundMsg: "No events found. Please try removing some filters to broaden your search.",
        clearAll: "Clear all",
        selectAll: "Select all",
        contains: "Contains",
        email: "Email",
        phoneNumber: "Phone Number",
        orderStatus: "Order Status",
        trackingNumber: "Tracking Number",
        trackingLink: "Tracking Link",
        dateOrdered: "Date Ordered",
        quantityOrdered: "Quantity Ordered",
        completed: "Completed",
        ongoing: "Ongoing",
        dateFulfilled: "Date Fulfilled",
        orderSummery: "Order Summary",
        status: "Status",
        inProgress: "In-Progress",
        totalCost: "Total Cost",
        finalCost: "Final Cost",
        priceString: `\${0}`,
        totalSaving: "Total Savings",
        viewInvoice: "View Invoice",
        itemsOrdered: "Items Ordered",
        trackingHistory: "Tracking History",
        viewOrderDetails: "View Order Details",
        ongoingOrders: "Ongoing Orders",
        completedOrders: "Completed Orders",
        selectTeamMember: "Select the team members you would like to gift",
        gifteesSelected: "Giftees Selected",
        shipping: "Shipping",
        serviceFee: "Service Fee",
        taxes: "Taxes",
        grandTotalEstimate: "Grand Total (Estimate)",
        purchase: "Purchase",
        loading: "Loading...",
        callUsToCompleteOrder: "Call us to Complete Your Order",
        next: "Next",
        purchaseGiftingForm: "Gifting Purchase Form",
        trigger: "Trigger",
        subscriptionNotFount: "Subscription Not Found",
        subscriptionNotFoundMsg: "We couldn't find your subscription information. Please contact us if you think this is an error.",
        noInvoicesYet: "No Invoices Yet!",
        noInvoicesYetMsg: "Your first subscription invoice should appear here shortly.",
        paymentReceived: "Payment Received",
        paymentNotReceived: "Payment Not Received",
        recurringSubscription: "Recurring Subscription",
        invoiceNumber: "Invoice #{0}",
        sentToEmail: "Sent to {0}",
        subtotal: "Subtotal",
        total: "Total",
        downloadPDF: "Download PDF",
        payNow: "Pay Now",
        sentToOnTime: "Sent to {0} on time {1}. {2}",
        noGiftsYet: "No Gifts Yet!",
        noGiftsYetMsg: "Why not send your first gift?",
        noHistoryYet: "No History Yet!",
        noHistoryYetMsg: "Why not book your first event?",
        averageAttendance: "Average Attendance",
        averageRating: "Average Rating",
        senderName: "Sender Name",
        receiverName: "Receiver Name",
        timeStamp: "Timestamp",
        companyKudosHistory: "Company Kudos History",
        sendSignUpLinkMsg: "Send this signup link to your employees (click to copy)",
        enterEmailAddressToInvite: "Enter the email addresses of users to invite (one per line):",
        emailAddresses: "Email Addresses",
        invite: "Invite",
        inviteSuccess: "Successfully invited",
        inviteError: "Error inviting",
        processing: "Processing...",
        userIsManager: "{0} is already a manager. you can't change it.",
        close: "Close",
        changeRole: "Change Role",
        selectNewRole: "Please select the new role of {0}.",
        newRole: "New Role",
        changeTeam: "Change Team",
        selectNewTeam: "Please select the new team of {0}.",
        confirmDeleteUser: "Please confirm you wish to delete the user {0}.",
        deleteUser: "Delete User",
        id: "ID",
        name: "Name",
        signUpCode: "Signup Code",
        usedOn: "Used on {0}",
        delete: "Delete",
        role: "Role",
        team: "Team",
        affiliation: "Affiliation",
        basedOnYourSearch: "Based on your search",
        result: "result",
        createPollWithSelectedEvents: "Create Poll With Selected Events",
        exitPolling: "Exit Polling",
        happeningNow: "happening now",
        happeningToday: "happening today",
        happeningTomorrow: "happening tomorrow",
        showDetails: "Show Details",
        monday: "Monday",
        tuesday: "Tuesday",
        wednesday: "Wednesday",
        thursday: "Thursday",
        friday: "Friday",
        saturday: "Saturday",
        sunday: "Sunday",
        youSignUpFor: "You signed up for",
        receiveClassSignUpEmailMsg: "You’ll receive an email with a calendar invitation in a few minutes (make sure to check your Spam)",
        makeSureJoinCall: "Make sure to join the call on",
        whichIsIn: "which is in",
        returnToClasses: "Return to Classes",
        joinClass: "Join Class",
        classHasEnded: "Class has Ended",
        thisClassListed: "This class will now be listed under Your Classes",
        yourClasses: "Your Classes",
        hostedBy: 'Hosted by',
        youHavntSignUpAnyClass: "You haven't signed up for any upcoming classes! Why not do so now?",
        thisWeek: "This Week",
        happeningLater: "Happening Later",
        confirmDelete: "Confirm Delete",
        confirmDeleteMsg: "Please confirm you wish to delete the following {0}:",
        settingsSavedWithEmoj: "🙌 Settings Saved 🙌",
        welcomeToWfhomie: "Welcome to WFHomie! ",
        addTheseInfoToProfile: " Please add the following information to your profile. ",
        addInfoLaterMsg: "You can also add this later via the Settings tab in the profile dropdown.",
        attendees: "Attendees",
        inviteTheWholeTeam: "Invite the whole team",
        scheduleGame: "Schedule Game",
        createPassword: "Create Password",
        confirmPassword: "Confirm Password",
        signInWithGoogle: "Sign in with Google Account",
        googleAccountRegistered: "Google account registered.",
        signInWithGoogleBtn: "Sign In with Google",
        signInWithSlack: "Sign in with Slack",
        emailRequired: "Email is required",
        properEmailRequired: "Please enter a proper email address",
        passwordRequired: "Password is required",
        passwordLength: "Password must be at least 8 characters",
        loginSuccess: "Login successful",
        signInOption: "Sign-in Option",
        signInOptionMsg: "Choose how you want to sign in.",
        forgotPassword: "Forgot Password?",
        recoverPassword: "Recover Password",
        googleAccount: "Google Account",
        usernameAndPassowrd: "Username & Password",
        birthDay: "🎂 Birthday",
        birthLabel: '🎂',
        birthDayMsg: "Set your birthday",
        bubbleTea: "Bubble Tea",
        bubbleTeaDesc: "Select your favourite Bubble Tea flavour.",
        bubbleTeaBrownSugar: "🐻 Brown Sugar",
        bubbleTeaClassic: "🐄 Classic Milk Tea",
        bubbleTeaEarlGrey: "🌫️ Earl Grey",
        bubbleTeaGrape: "🍊 Grapefruit",
        bubbleTeaHoneyGreen: "🍯 Honey Green Tea",
        bubbleTeaLychee: "❤️ Lychee",
        bubbleTeaMango: "🥭 Mango",
        bubbleTeaPassionFruit: "💛 Passion Fruit",
        bubbleTeaRoastedOolong: "☕ Roasted Oolong",
        bubbleTeaStrawBerry: "🍓 Strawberry",
        bubbleTeaTaro: "💜 Taro",
        bubbleTeaThai: "🧡 Thai",
        bubbleTeaWhitePeach: "🍑 White Peach",
        bubbleTeaWinterMelon: "🍈 Wintermelon",
        dietaryRestrictions: "🍲 Dietary Restrictions",
        dietaryRestrictionsDesc: "Select your allergies or dietary restrictions, if any.",
        drVegan: "Vegan",
        drVegetarian: "Vegetarian",
        drLactose: "Lactose Intolerant",
        drNut: "Nut Allergy",
        drSoy: "Soy Allergy",
        drGluten: "Gluten Free",
        drKosher: "Kosher",
        drHalal: "Halal",
        promoCode: "Promo Code",
        planFreeTrialMsg: "All plans come with a 1-month free trial.Cancel anytime.",
        chooseAPlan: "Choose a Plan",
        profile: "Profile",
        shippingInfo: "📦 Shipping Information",
        shippingInfoMsg: "We need this information to send you care packages and other goodies.",
        shippingAddress: "Shipping Address",
        unitNumber: "Unit # (Optional)",
        deliveryInstructions: "Delivery Instructions (Optional)",
        shirtXs: "Extra Small",
        shirtS: "Small",
        shirtM: "Medium",
        shirtL: "Large",
        shirtXl: "Extra Large",
        shirtXxl: "XX-Large",
        shirtSize: "👕 Shirt Size",
        shirtSizeDesc: "Select your shirt size (unisex)",
        workStartDate: "📅 Work Start Date",
        workLabel: '📅',
        workStartDateDesc: "Set your work start date",
        joined: "Joined ",
        address: "Address",
        apartmentEtc: "Apartment, etc.",
        postalCode: "Postal Code",
        businessInternetDomainWithExample: "Business Internet Domain Ex. wfhomie.com",
        uploadANewLogo: "Upload a new logo",
        preview: "(preview)",
        specifyATimeZone: "Specify a Timezone",
        addToSlack: "Add to Slack",
        installed: "Installed",
        confirmKudosUninstallation: {
            title: "Are you sure?",
            message: "This will uninstall WFHomie Kudos from your Slack workspace."
        },
        uninstall: "Uninstall",
        cancel: "Cancel",
        sendToSlackBot: "Send to Kudos Bot for Slack",
        thisFieldRequired: "This field is required",
        selectASlackChannel: "Select a Slack channel",
        slackIntegrationInfoMsg: "Team managers can add new Slack integrations from the business settings.",
        inviteTeamToVote: "Invite team to vote",
        successGetYourTeamVoting: " Success! Get your team voting!",
        saveAndClose: "Save and close",
        submitReferrer: "Submit Referrer",
        refConfirm: "You were referred by {0}! Welcome aboard!",
        youWillRecieveEmailForGift: "Please check your email in a few minutes to receive the instruction on how to claim your gift.",
        balance: "balance",
        enterReferrer: "Did someone send you here? Enter your referrer here!",
        questionsFor: "Questions For",
        questionsDescription: "Your manager is organizing an event for the team! Please fill out the questions below ASAP so WFHomie can start preparing!",
        aFantasticVitualExp: "A fantastic virtual experience is on its way!",
        inviteUsers: "Invite users",
        inviteUsersNotInList: "Invite users not in list",
        importUsersBambooHR: "Import users from BambooHR",
        importAddressesBambooHR: "Import addresses from BambooHR",
        pendingInvite: "Pending Invite",
        registrationCost: "Registration Cost",
        person: "person",
        ordinals: ["th", "st", "nd", "rd"],
        mandatory: "mandatory",
        optional: "optional",
        participants: "Participants",
        employee: "employee",
        selectParticipantsRange: "Select between {0} and {1} {2} who will be attending this event. The list is sorted by last name.",
        selectParticipantsMinOnly: "Select at least {0} {1} who will be attending this event. The list is sorted by last name.",
        selectParticipantsMaxOnly: "Select at most {0} {1} who will be attending this event. The list is sorted by last name.",
        selectParticipantsFixed: "Select {0} {1} who will be attending this event. The list is sorted by last name.",
        minParticipantBoundWarning: "Please select at least {0} participants.",
        maxParticipantBoundWarning: "Please select at most {0} participants.",
        message: 'message',
        unknownUser: 'Unknown User',
        nobodySentKudos: "Looks like nobody sent a Kudos yet.",
        kudosSettingsSaved: "Settings saved successfully! Try to send your Kudos now!",
        beFirstOne: "Why not be the first one?",
        clearFilters: "Clear Filters",
        filterByPricePerPerson: "Filter by Price per Person",

        joinExistingCompany: 'Join Existing Company?',
        alreadyCompanyUsingEmail: 'There’s already a company using the email ',
        joinThemInstead: 'Do you want to join them instead?',
        join: 'Join',
        dontAskAgain: 'Don’t ask again',

        pendingApproval: 'You have limited access until a company manager approves your request to join.',
        adminEmailAddress: 'Admin Email (for join request notifications)',

        emailAlreadyInUse: 'Email is already in use',
        wrongPassword: 'Wrong password',
        popupBlocked: 'Popup blocked',

        day: 'day',
        hour: 'hour',
        minute: 'minute',

        onlyManagerCanUseCredit: 'Only managers can use credits!',

        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        booking: 'Booking',
        paid: 'Paid',
        cancelled: 'Cancelled',
        backupDates: 'Backup Dates',

        start: 'Start',
        end: 'End',
        classLength: 'Class Length',
        allAvailableClasses: 'All Available Classes',
        klass: 'Class',
        booked: 'Booked',
        bookedPlural: 'Booked',
        creditsUsed: 'Credits Used',

        onDemandRecordings: 'On-Demand Recordings',
        viewFullPlaylist: 'View full playlist',
    },
    fr: {
        localeString: 'fr-CA',

        home: "Accueil",
        congrats: "Félicitations pour avoir pris un autre pas vers\n l'engagement de votre équipe à distance! ",
        weAreHereForYou: "Nous sommes là pour vous.",
        getStarted: "Pour commencer",
        notSpecified: "Non Spécifié",
        signUp: "Inscription",
        signIn: "Connexion",
        manageCompanyMembers: "Gérer les membres de la compagnie",
        kudos: "Bravo",
        companyProfile: "Profil de l'organisation",
        yourColleaguesAt: "Vos collègues à",
        member: 'membre',
        registered: 'enregistré',
        receipts: "Reçus",
        settings: "Paramètres",
        trialExpiredIn: "Votre période d'essai gratuite expire dans {0}",
        hello: "Bonjour",
        yourTeamAt: "Votre équipe à",
        events: "Événements",
        gifting: "Cadeaux",
        classes: "Cours",
        activities: "Activités",
        analytics: "Réservations",
        seeMore: "En voir plus",
        featured: 'En vedette',
        exploreEvents: "Découvrir les événements",
        exploreGifting: "Découvrir les cadeaux",
        exploreActivities: "Découvrir les activités",
        gameInstructions: {
            crowdparty: {
                title: "Notes :",
                shareRoomPin: {
                    emoji: "🔢",
                    text: "L'hôte du jeu doit partager le pin de la salle avec les autres joueurs avant de commencer le jeu."
                },
                shareScreen: {
                    emoji: "💻",
                    text: "L'hôte du jeu devra partager son écran."
                },
                hostPlayer: {
                    emoji: "🎮",
                    text: "Si vous êtes l'hôte du jeu et que vous souhaitez jouer, veuillez ouvrir le jeu sur un autre écran et saisir le code pin."
                }
            }
        },
        hostAGame: "Créer une partie",
        joinAGame: "Rejoindre une partie",
        joinRemotely: "Rejoindre à distance",
        people: "personnes",
        credit: "crédit",
        group: "groupe",
        startingAt: "À partir de",
        free: 'Gratuit',
        unlimited: "Illimité",
        atLeast: "Au moins",
        upTo: "Jusqu'à",
        mins: "mins",
        searchEvents: "Parcourir les événements..",
        search: "Recherche",
        pollYourTeam: "Créer un sondage",
        signOut: "Se déconnecter",
        alreadyHaveAnAccount: "Vous avez déjà un compte?",
        passwordsDontMatch: "Les mots de passe ne sont pas identiques",
        required: "Obligatoire",
        getStartedWith: "Où commencer avec WFHomie",
        fewClicksAway: "Vous n'êtes plus qu'à quelques clics de rejoindre votre équipe à {0}.",
        or: "ou",
        firstName: "Prénom",
        lastName: "Nom de famille",
        workEmailStar: "Adresse courriel du travail*",
        workEmail: "Adresse courriel du travail",
        emailAddress: "Adresse courriel",
        passwordStar: "Mot de passe*",
        password: "Mot de passe",
        confirmPasswordStar: "Confirmer votre mot de passe*",
        wentWrongTryAgain: "Quelque chose s'est mal passé. Essayez encore.",
        signUpMessage: "Inscrivez-vous pour renforcer votre culture d'équipe avec\\n des événements virtuels, des livraisons rapides, et beaucoup plus!",
        hiComma: "Bonjour,",
        signUpWithGoogle: "S'inscrire avec Google",
        forgotPassword: "Mot de passe oublié?",
        recoverPassword: "Récupérer mot de passe",
        backToLogin: "Retour Connexion",
        companyNameStar: "Nom de l'organisation*",
        companyName: "Nom de l'organisation",
        enterValidEmail: "Veuillez utiliser une adresse courriel valide.",
        incorrectEmailOrPass: "Adresse courriel et/ou mot de passe incorrects.",
        dontHaveAnAccount: "Vous n'avez pas de compte?",
        activityDetails: "Détails de votre activité",
        minutes: "minutes",
        includePhysicalKit: "Comporte la livraison d'un kit d'événement à domicile",
        virtualOnly: "En ligne seulement. Cette activité ne comporte pas la livraison d'un kit d'événement à domicile",
        reviews: "Évaluations",
        whatYoullDo: "Ce que vous allez faire",
        howItWorks: "Comment ça marche",
        whatYoullNeed: "Ce dont vous aurez besoin",
        similarExperiences: "Événements similaires",
        useOneCredit: "Utilisez 1 crédit (jusqu'à 30 participants), ou",
        startingAtPerPerson: "À partir de {0}",
        useCredits: {
            free: "Utilisez {0} {1} pour un événement gratuit",
            reducedPrice: `Utilisez {0} {1} pour un prix réduit de {2}$`,
        },
        slashPerson: "/personne",
        showingPriceFor: "Voici les prix pour",
        migrationFailed: "Échec de la migration",
        successBambooHR: "Succès de la migration de BambooHR",
        successUserBambooHR: "Succès de la migration de BambooHR pour l'utilisateur",
        accError: "Il y a un problème avec votre compte, merci de vous réinscrire avec le lien d'invitation dans votre boîte mail !",
        addLinkZoom: "Veuillez ajouter un lien ou cocher la case Générer un Zoom",
        notImplemented: "Non implémenté",
        roleName: "Veuillez entrer un nom de rôle",
        roleNameAdded: "Rôle ajouté avec succès",
        teamNameRequired: "Le nom de l'équipe est requis",
        teamNameCantBeNone: "Le nom de l'équipe ne peut pas être 'aucun'",
        teamNameAlreadyExists: "Le nom de l'équipe existe déjà",
        teamNameAdded: "Équipe ajoutée avec succès",
        passwordResetEmailSent: "E-mail de mot de passe envoyé",
        planSavedSuccessfully: "Plan enregistré avec succès",
        weekOf: "La semaine du",
        nextWeek: "La semaine prochaine",
        bookNow: "Réservez maintenant",
        bookACall: "Réservez un appel",
        buildYourSwagPack: "Créez un sac cadeau",
        thingsToKnow: "Ce qu'il faut savoir",
        fullRefund: "Remboursement complet lorsque annulé en moins de 12 heures. ",
        pleaseWaitWhileWeProcess: "Veuillez attendre alors que nous traitons votre demande",
        confirmAndPay: "Confirmer et payer",
        authentication: "Authentification",
        pleaseAuth: "Veuillez vous connecter ou vous inscrire pour continuer.",
        emailRequired: "L'adresse courriel est requise",
        properEmailRequired: "Veuillez entrer une adresse courriel valide",
        passwordRequired: "Le mot de passe est requise",
        passwordLength: "Le mot de passe doit contenir au moins 8 caractères",
        signInWithMicrosoft: "Connectez-vous avec Microsoft",
        signInWithSlack: "Connectez-vous avec Slack",
        loginSuccess: "Connexion réussie",
        loginFailed: "Échec de la connexion",
        dateAndTime: "Date & heure",
        selectUpTo3TimeSlots: "Sélectionnez jusqu'à trois créneaux horaires, par ordre de préférence.",
        weWillConfirmDateTime: "Nous confirmerons la date & l'heure dépendant de la disponibilité de votre hôte.",
        priceDetails: "Détails des prix",
        customRequests: "Demandes spéciales (facultatif)",
        finishAndPay: "Terminer & payer",
        generatePrebookingLink: "Générer un lien de préréservation",
        eventName: "Nom de l'événement",
        timeZone: "Zone horaire",
        dates: "Dates",
        choice: "choix",
        thankYou: "Merci beaucoup.",
        fileUploadedSuccessfully: "Fichier téléchargé avec succès",
        opsSomethingWentWrong: "Oups! Quelque chose s'est mal passé...",
        backToEventDashboard: "Retour au tableau de bord des événements",
        numberOfParticipants: "Nombre de participants",
        wellBeInTouhForTimeConfirmation: "Nous vous contacterons prochainement pour confirmer la date et l'heure.",
        dropInToTheseClassToGrowth: "Rejoignez ces cours pour accélérer votre développement personnel!",
        orderHistory: "Historique de vos commandes",
        youHaventOrderAnyGiftYetWhyNot: "Vous n'avez pas encore commandé de cadeau... Pourquoi pas en offrir un à votre équipe aujourd'hui?",
        browse: "Parcourir",
        ourCollectionOf: "notre collection de plus de 20 paquets, ou bien créez le vôtre",
        packageName: "Nom du paquet ",
        numberOfGiftees: "Nombre de récipiendaires",
        companySettingSubmissionFailed: "Votre soumission pour changer les paramètres de votre companie a échoué",
        businessSetting: "Paramètres d'entreprise",
        slackIntegrations: "Intégrations Slack",
        addPollSlackBotMsg: "Ajoutez {0} à votre espace de travail Slack et laissez vos employés voter pour leur événement préféré \navec juste un clic! Vous pouvez ajouter cet assistant virtuel à autant de sites web que désiré. ",
        wfhomiePoll: "WFHomie Poll",
        slackKudosBotMsg: "Ajouter {0} à votre espace de travail Slack!\nLes utilisateurs peuvent utiliser la commande \"/kudos @destinataire message\" pour envoyer un Kudos à un(e) collègue. Vous avez un plan Cadeaux? Le Kudos viendra avec un certificat cadeau!",
        wfhomieKudos: "Kudos par WFHomie",
        generalInfo: "Info générale",
        subscriptionInfo: "Info de souscription",
        creditsInBank: "Crédits en banque",
        contactUsToUpgradeSubscription: "Contactez-nous pour améliorer votre plan de souscription",
        usageRestrictions: "Restrictions d'usage",
        allowKudosCommandInChannel: "Choisissez les canaux où la commande /kudos peut être utilisée. Si aucun n'est sélectionné, la commande sera disponible dans tous les canaux.",
        weAreStillGettingTheChannelList: "Nous sommes toujours en train de rechercher la liste de plateformes! Revenez un peu plus tard.",
        kudosAnnouncements: "Annonces - Bravo",
        publishKudosMsgToThisChannel: "Publier des messages Bravo sur cette plateforme:",
        allowedUsers: "Utilisateurs permis",
        chooseWhichUserAllowToUseBot: "Choisissez quels utilisateurs ont le droit d'utiliser la commande /kudos. Si aucun n'est sélectionné, alors tout le monde a accès.",
        onlyWFHomieAccountsShown: "Seuls les utilisateurs avec des comptes WFHomie sont affichés.",
        loadingUsersWait: "Chargement des utilisateurs, juste un moment...",
        creditRenewalSchedule: "Horaire de renouvellement de crédit",
        chooseWhenToTopUpYourEmployee: "Choisissez quand recharger les crédits Bravo de vos employés. Chaque commande /kudos utilise un crédit.",
        creditAccumulateMsg: "Les crédits accumuleront jusqu'à ce que la limite de crédits soit atteinte. Pour que les crédits expirent à la fin de chaque période, assurez-vous que la limite de crédits soit égale à la quantité de recharge complémentaire.",
        topUpSchedule: "Calendrier de recharge complémentaire",
        topUpAmount: "Quantité de recharge complémentaire",
        creditLimit: "Limite de crédit",
        kudosLimit: "Limite de Bravo",
        kudosLimitDescription: "Limitez le nombre de cadeaux qui peuvent être reçus par la même personne. Ceci peut être utile pour distribuer les éloges plus équitablement. Ceci réinitialise à chaque recahrge de crédit complémentaire.",
        settingsSaved: "Paramètres sauvegardés",
        addKudosBot: "Ajouter l'assistant virtuel Bravo",
        addKudosBotDescription: "Pour permettre ces paramètres, veuillez ajouter l'assistant virtuel Bravo de WFHomie à votre espace de travail sur Slack.",
        kudosSettingsSaved: "Paramètres enregistrés avec succès! Essayez d'envoyer votre Kudos maintenant!",
        chooseADateAndTime: "Choisir une date et une heure",
        addAlternativeDateOption: "Ajouter une option de date alternative",
        thisFieldIsRequired: "Ce champ est obligatoire",
        payWithACredit: "Payez avec un crédit",
        successfulYouWillGetEmail: "Succès! Vous et vos invités recevrez un courriel avec des détails supplémentaires.",
        wfhomieGames: "Jeux WFHomie",
        fromWfhomie: "de WFHomie",
        filterByTag: "Filtrer par étiquette",
        selectTagToFilter: "Sélectionner les étiquettes à filtrer...",
        youCanUseArrowKeyToSetPrice: "Vous pouvez aussi utiliser les touches de flèches sur votre clavier pour fixer un prix maximum.",
        showResult: "Montrer les résultats",
        joinYourTeamForGame: "Rejoindre votre équipe à {0} pour une partie exaltante de {1}!",
        titleJoinGame: "{0} avec votre équipe!",
        filters: "Filtres",
        noEventFoundMsg: "Aucun événement retrouvé. Veuillez supprimer certains des filtres pour élargir votre champ de recherche.",
        clearAll: "Tout effacer",
        selectAll: "Tout sélectionner",
        contains: "Contient",
        email: "Adresse Courriel",
        phoneNumber: "Numéro de téléphone",
        orderStatus: "État de votre commande",
        trackingNumber: "Numéro de suivi",
        trackingLink: "Lien de suivi",
        dateOrdered: "Date de la commande",
        quantityOrdered: "Quantité commandée",
        completed: "Complété",
        ongoing: "En cours",
        dateFulfilled: "Date réalisée",
        orderSummery: "Récapitulatif de la commande",
        status: "Statut",
        inProgress: "En cours",
        totalCost: "Coût total",
        totalSaving: "Économies totales",
        viewInvoice: "Voir la facture",
        itemsOrdered: "Articles commandés",
        trackingHistory: "Historique de suivi",
        viewOrderDetails: "Voir les détails de votre commande",
        ongoingOrders: "Commandes en cours",
        completedOrders: "Commandes complétées",
        selectTeamMember: "Sélectionnez le nombre de coéquipiers auxquels vous aimeriez envoyer un cadeau",
        gifteesSelected: "Récipiendaires sélectionnés",
        shipping: "Livraison",
        serviceFee: "Frais de service",
        taxes: "Impôts",
        grandTotalEstimate: "Total (Estimé)",
        purchase: "Commande",
        callUsToCompleteOrder: "Appelez-nous pour finaliser votre commande",
        next: "Suivant",
        purchaseGiftingForm: "Formulaire d'achat cadeau",
        trigger: "Déclencheur",
        subscriptionNotFount: "Abonnement introuvable",
        subscriptionNotFoundMsg: "Nous n'avons pas pu trouver vos informations de souscription. S'il-vous-pkaît contactez-nous si vous croyez que ceci est une erreur.",
        noInvoicesYet: "Pas de reçu pour l'instant!",
        noInvoicesYetMsg: "Votre premier reçu de souscription devrait apparaître ici sous peu.",
        paymentReceived: "Paiement reçu",
        paymentNotReceived: "Paiement non reçu",
        recurringSubscription: "Abonnement récurrent",
        invoiceNumber: "Facture #{0}",
        sentToEmail: "Envoyé à {0}",
        subtotal: "Sous-total",
        total: "Total",
        downloadPDF: "Télécharger le PDF",
        payNow: "Payez maintenant",
        sentToOnTime: "Envoyé à {0} à temps {1}. {2}",
        noGiftsYet: "Pas de cadeaux encore!",
        noGiftsYetMsg: "Pourquoi pas envoyer votre premier cadeau?",
        noHistoryYet: "Pas d'historique encore!",
        noHistoryYetMsg: "Pourquoi pas réserver votre premier événement?",
        averageAttendance: "Participation moyenne",
        averageRating: "Évaluation moyenne",
        senderName: "Nom de l'expéditeur",
        receiverName: "Nom du récipiendaire",
        timeStamp: "Horodatage",
        companyKudosHistory: "Historique de Bravo de la compagnie",
        sendSignUpLinkMsg: "Envoyez ce lien d'inscription à vos employés (cliquez pour copier)",
        enterEmailAddressToInvite: "Entrez les adresses courriel des utilisateurs pour les inviter ( un par ligne):",
        emailAddresses: "Adresses courriel",
        invite: "Inviter",
        inviteSuccess: "Invité avec succès",
        errorR: "Erreur",
        inviteError: "Erreur d'invitation",
        processing: "En traitement...",
        userIsManager: "{0} a déjà un statut d'administrateur(rice), vous ne pouvez pas le changer.",
        close: "Fermer",
        changeRole: "Changer le rôle",
        selectNewRole: "Veuillez sélectionner le nouveau rôle de {0}.",
        newRole: "Nouveau rôle",
        changeTeam: "Changer d'équipe",
        selectNewTeam: "Veuillez sélectionner l'équipe de  {0}.",
        confirmDeleteUser: "Veuillez confirmer que vous souhaitez supprimer l'utilisateur {0}.",
        deleteUser: "Supprimer l'utilisateur",
        id: "Identifiant",
        name: "Nom",
        signUpCode: "Code d'inscription",
        usedOn: "Utilisé le {0}.",
        delete: "Supprimer",
        role: "Rôle",
        team: "Équipe",
        affiliation: "Affiliation",
        basedOnYourSearch: "Basé sur votre recherche",
        result: "résultat",
        createPollWithSelectedEvents: "Créer un sondage avec les événements sélectionnés",
        exitPolling: "Quitter le sondage",
        happeningNow: "En cours",
        happeningToday: "Prévu aujourd'hui",
        happeningTomorrow: "Prévu demain",
        showDetails: "Afficher les détails",
        monday: "Lundi",
        tuesday: "Mardi",
        wednesday: "Mercredi",
        thursday: "Jeudi",
        friday: "Vendredi",
        saturday: "Samedi",
        sunday: "Dimanche",
        youSignUpFor: "Vous vous êtes inscrit pour",
        receiveClassSignUpEmailMsg: "Vous recevrez un courriel avec une invitation par calendrier dans quelques minutes (assurez-vous de vérifier votre fichier Spam)",
        makeSureJoinCall: "Assurez-vous de rejoindre l'appel le ",
        whichIsIn: "c'est-à-dire dans ",
        returnToClasses: "Retour aux cours",
        joinClass: "Rejoindre le cours",
        classHasEnded: "Ce cours est terminé",
        thisClassListed: "Ce cours sera maintenant affiché sous Vos cours",
        yourClasses: "Vos cours",
        hostedBy: 'Organisé par',
        openConfirmation: "Ouvrir Confirmation",
        saveYourSpot: "Réservez votre place",
        inviteTeamMembers: "Inviter les membres de l'équipe",
        selectMembersToInvite: "Sélectionnez les membres à inviter",
        allMembersRegistered: "Tous les membres sont inscrits",
        alreadyRegistered: "Déjà enregistré",
        youHavntSignUpAnyClass: "Vous ne vous êtes pas inscrits à aucun des prochains cours! Pourquoi  pas le faire maintenant?",
        thisWeek: "Cette semaine",
        happeningLater: "Prévu bientôt",
        confirmDelete: "Confirmer et supprimer",
        confirmDeleteMsg: "Veuillez confirmer que vous souhaitez supprimer ce qui suit {0}:",
        settingsSavedWithEmoj: "🙌  Paramètres sauvegardés 🙌",
        welcomeToWfhomie: "Bienvenue à WFHomie!",
        addTheseInfoToProfile: "Veuillez s'il-vous-plaît ajouter les informations suivantes à votre profil.",
        addInfoLaterMsg: "Vous pouvez aussi ajouter ceci plus tard grâce à la section Paramètres dans votre menu de profil.",
        attendees: "Participants",
        inviteTheWholeTeam: "Inviter toute l'équipe",
        scheduleGame: "Planifier un jeu",
        createPassword: "Créer un mot de passe",
        confirmPassword: "Confirmer votre mot de passe",
        signInWithGoogle: "Connectez-vous avec un compte Google",
        googleAccountRegistered: "Compte Google enregistré",
        signInWithGoogleBtn: "Connectez-vous avec Google",
        signInOption: "Option de connexion",
        signInOptionMsg: "Choisissez comment vous connecter",
        googleAccount: "Compte Google",
        usernameAndPassowrd: "Nom d'utilisateur & mot de passe",
        birthDay: "🎂  Date de fête",
        birthLabel: "🎂",
        birthDayMsg: "Ajouter votre date de fête",
        bubbleTea: "Thé aux perles",
        bubbleTeaDesc: "Sélectionnez votre saveur de thé aux perles",
        bubbleTeaBrownSugar: "🐻 Cassonade",
        bubbleTeaClassic: "🐄 Thé au lait classique",
        bubbleTeaEarlGrey: "🌫️ Earl Grey",
        bubbleTeaGrape: "🍊 Pamplemousse",
        bubbleTeaHoneyGreen: "🍯 Thé vert au miel",
        bubbleTeaLychee: "❤️ Litchi",
        bubbleTeaMango: "🥭 Mangue",
        bubbleTeaPassionFruit: "💛 Fruit de la passion",
        bubbleTeaRoastedOolong: "☕ Oolong torréfié",
        bubbleTeaStrawBerry: "🍓 Fraise",
        bubbleTeaTaro: "💜 Taro",
        bubbleTeaThai: "🧡 Thaïlandais",
        bubbleTeaWhitePeach: "🍑 Pêche blanche",
        bubbleTeaWinterMelon: "🍈 Melon d'hiver",
        dietaryRestrictions: "🍲 Restrictions alimentaires",
        dietaryRestrictionsDesc: "Sélectionner vos allergies et restrictions alimentaires, si nécessaire",
        drVegan: "Végétalien(ne)",
        drVegetarian: "Végétarien(ne)",
        drLactose: "Intolérance au lactose",
        drNut: "Allergie aux noix",
        drSoy: "Allergie au soja",
        drGluten: "Sans gluten",
        drKosher: "Kosher",
        drHalal: "Halal",
        promoCode: "Code Promo",
        planFreeTrialMsg: "Tous les plans incluent une période d'essai gratuite d'un mois. Annulez à n'importe quel moment.",
        chooseAPlan: "Choisissez un forfait",
        profile: "Profil",
        shippingInfo: "📦  Informations de livraison",
        shippingInfoMsg: "Nous avons besoin de ces informations pour vous envoyer vos paquets de soins et autres cadeaux",
        shippingAddress: "Adresse de livraison",
        unitNumber: "# du lot (Optionnel)",
        deliveryInstructions: "Instructions de livraison (Optionnel)",
        shirtXs: "Très petit",
        shirtS: "Petit",
        shirtM: "Moyen",
        shirtL: "Large",
        shirtXl: "Très large",
        shirtXxl: "XX-large",
        shirtSize: "👕 Taille de chandail",
        shirtSizeDesc: "Sélectionnez votre taille de chandail (unisexe)",
        workStartDate: "📅  Première journée de travail",
        workLabel: '📅',
        workStartDateDesc: "Sélectionnez votre première journée de travail",
        joined: "A rejoint",
        address: "Adresse",
        apartmentEtc: "Appartement, etc.",
        postalCode: "Code postal",
        businessInternetDomainWithExample: "Adresse du domaine de la compagnie en ligne Ex. wfhomie.com",
        uploadANewLogo: "Télécharger un nouveau logo",
        preview: "(aperçu)",
        specifyATimeZone: "Sélectionnez une zone horaire",
        addToSlack: "Ajouter à Slack",
        installed: "Installé",
        confirmKudosUninstallation: {
            title: "Êtes-vous certain?",
            message: "Cela va désinstaller WFHomie Kudos de votre espace de travail Slack."
        },
        uninstall: "Désinstaller",
        cancel: "Annuler",
        sendToSlackBot: "Envoyez à l'assistant virtuel Slack",
        thisFieldRequired: "Ce champ est obligatoire",
        selectASlackChannel: "Sélectionnez un réseau Slack",
        slackIntegrationInfoMsg: "Les gérants d'équipe peuvent ajouter de nouvelles intégrations Slack avc les paramètres de compagnie.",
        inviteTeamToVote: "Inviter l'équipe à voter",
        successGetYourTeamVoting: "Succès! Encouragez votre équipe à voter!",
        saveAndClose: "Soumettre et fermer",
        youWillRecieveEmailForGift: "Veuillez vérifier votre adresse courriel dans quelques minutes afin de recevoir les instructions pour réclamer votre cadeau.",
        balance: "solde",
        enterReferrer: "Quelqu'un t'a envoyé ici? Entrez votre référent!",
        questionsFor: "Questions pour",
        inviteUsers: "Inviter des utilisateurs",
        inviteUsersNotInList: "Inviter d'autres utilisateurs",
        importUsersBambooHR: "Importer des utilisateurs de BambooHR",
        importAddressesBambooHR: "Importer des adresses de BambooHR",
        pendingInvite: "Invitation En Attente",
        registrationCost: "Frais d'Inscription",
        person: "personne",
        ordinals: ["e", "er", "e", "e"],
        mandatory: "obligatoire",
        optional: "optionnel",
        participants: "Participants",
        employee: "employé",
        selectParticipantsRange: "Sélectionnez entre {0} et {1} {2} qui participeront à cet événement. La liste est triée par nom de famille.",
        selectParticipantsMinOnly: "Sélectionnez au moins {0} {1} qui participeront à cet événement. La liste est triée par nom de famille.",
        selectParticipantsMaxOnly: "Sélectionnez un maximum de {0} {1} qui participeront à cet événement. La liste est triée par nom de famille.",
        selectParticipantsFixed: "Sélectionnez {0} {1} qui participeront à cet événement. La liste est triée par nom de famille.",
        minParticipantBoundWarning: "Sélectionnez au moins {0} participants.",
        maxParticipantBoundWarning: "Sélectionnez un maximum de {0} participants.",
        message: 'message',
        unknownUser: 'Utilisateur Inconnu',
        nobodySentKudos: "Personne n’a encore envoyé de Bravo.",
        beFirstOne: "Pourquoi ne pas être le premier?",

        joinExistingCompany: 'Rejoindre une Entreprise Existante',
        alreadyCompanyUsingEmail: 'Il y a déjà une enterprise qui utilise l’email ',
        joinThemInstead: 'Voulez-vous les rejoindre?',
        join: 'Rejoindre',
        dontAskAgain: 'Ne demandez plus',

        pendingApproval: 'Vous avez un accès limité jusqu’à ce qu’un responsable de l’entreprise approuve votre demande.',
        adminEmailAddress: 'Courriel de l’Administrateur',

        emailAlreadyInUse: 'Le courriel est déjà utilisé',
        wrongPassword: 'Mot de passe incorrect',
        popupBlocked: 'Popup bloquée',

        day: 'jour',
        hour: 'heure',
        minute: 'minute',

        onlyManagerCanUseCredit: 'Seuls les administrateurs peuvent utiliser des crédits!',

        months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'décembre'],
        booking: 'Réservation',
        paid: 'Payé',
        cancelled: 'Annulé',
        backupDates: 'Dates Alternatives',

        start: 'Début',
        end: 'Fin',
        classLength: 'Durée du Cours',
        allAvailableClasses: 'Cours Disponibles',
        klass: 'Cours',
        booked: 'Réservé',
        bookedPlural: 'Réservés',
        creditsUsed: 'Crédits utilisés',
        finalCost: "Coût final",
        priceString: "{0}$",

        onDemandRecordings: 'Classes Enregistrées',
        viewFullPlaylist: 'Voir tout',
    }
});

export default i18n;
