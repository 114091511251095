import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from "@material-ui/core";

import { css } from '@emotion/css';
import 'url-search-params-polyfill';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PeopleIcon from '@material-ui/icons/People';
import ComputerIcon from '@material-ui/icons/Computer';
import Checkbox from '@material-ui/core/Checkbox';
import { generateTimeString, generateShortAudienceSize } from "./util/EventInfo"
import { generatePriceStrings, getStartingPrice } from 'util/pricing/events';
import i18n from "util/i18n";
import { eventThemes } from 'util/constants/events';
import { withGameModal } from 'components/hocs/withGameModal';
import { gameInstructions } from './util/gameInstructions';

const CardContainer = ({ title, image, onClick, onCheckChange, children, allowPolling, checked, tag }) => (
    <div className={css`
      background-color: white;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 1px 1px 4px hsla(230, 10%, 10%, 0.3);
      display: flex;
      flex-direction: column;
      margin-right: 30px;
      width: 100%;
      text-align: center;
      cursor: pointer;
      position: relative;
    `}
        onClick={onClick}>
        <img src={image} alt={"event " + title}
            className={css`
        height: 200px;
        width: 100%;
        object-fit: cover;
      `} />
        {allowPolling &&
            <Checkbox
                onClick={onCheckChange}
                checked={checked}
                value={title}
                inputProps={{ 'aria-label': `Checkbox ${title}` }}
                className={css`
         position: absolute;
         background-color: white;
         z-index: 100;
         border-radius: 5px 0 5px;
         &:hover {
           background-color: white !important;
         }
       `}
            />}
        {tag?.includes('one-credit') && <div className={css`
        position: absolute;
        right: 0;
        background-color: hsl(100, 40%, 100%);
        border: 2px solid #1A38D1;
        font-weight: 600;
        color: #1A38D1;
        padding: 4px 10px;
        border-radius: 15px;
        font-size: 14px;
        `}>
            One Credit
        </div>}

        <div className={css`
        display: flex;
        flex-direction: column;
        padding: 15px 15px;
      `}>
            <span className={css`
          font-size: 18px;
          font-weight: bold;
          color: #333333;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        `}>
                {title}
            </span>
            {children}
        </div>
    </div>
);

function CardInfo({ Icon, infoString }) {
    return <div className={css`
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;`
    }>
        <Icon color="primary" />
        <div className={css`
            margin-top: 4px;
            flex: 1;
            display: flex;
            align-items: center;`
        }>
            {infoString}
        </div>
    </div>;
}

function EventCard({ entry, id, title, description, themes, duration, tags, pricePerPerson,
    coverImageUrl, link, suggestedAudienceSize, allowPolling, addEventToPoll,
    checked, handleEventEdit, handleDeleteEvent, sysadmin = false, openGameModal }) {
    const history = useHistory();
    const companyInfo = useSelector(state => state.userinfo?.company);

    // Get starting price of event
    const startingPriceInfo = getStartingPrice(companyInfo?.credits, pricePerPerson, tags);

    // Generate starting price string to display
    const { numerator: startingPrice, denominator: priceDenominator } = generatePriceStrings({
        cashPrice: startingPriceInfo.cashPrice,
        creditPrice: startingPriceInfo.creditPrice,
        priceDenominator: startingPriceInfo.denominator,
        hideReducedPriceDenominator: true
    });

    const durationInfo = <CardInfo Icon={AccessTimeIcon} infoString={generateTimeString(duration)} />
    const participantCountInfo = <CardInfo Icon={PeopleIcon} infoString={generateShortAudienceSize(suggestedAudienceSize, themes)} />
    const joinMethod = <CardInfo Icon={ComputerIcon} infoString={i18n.joinRemotely} />

    const onClick = () => {
        if (!allowPolling) {
            if (link && themes.includes(eventThemes.CROWDPARTY_GAMES)) {
                // For CrowdParty games, users can host or join a game
                openGameModal({
                    eventId: id,
                    gameTitle: title,
                    gameDescription: description,
                    gameInstructions: gameInstructions.crowdParty(),
                    hostGameLink: link,
                    joinGameLink: "https://wfmhomie.crowdparty.app/join"
                })
            } else if (link) {
                // For other games, we redirect directly to the game page
                return window.open(link, "_blank", "noreferrer");
            } else {
                // No link, so we redirect to the event page
                history.push(`/dashboard/event/${id}`)
            }
        }
    }

    return (
        <>
            <CardContainer
                title={title}
                image={coverImageUrl}
                onClick={onClick}
                allowPolling={allowPolling}
                onCheckChange={() => addEventToPoll({ id, title })}
                checked={checked}
                tag={tags}
            >
                <div className={css`display: flex; flex-direction: column;`}>
                    <div className={css`width: 100%;
                            font-size: 13px;
                            color: hsl(230, 30%, 40%);
                `}>
                        {!startingPriceInfo.isFixedPrice && `${i18n.startingAt} `}
                        {startingPrice}
                        <span className={css`color: hsl(200, 10%, 50%);`}>
                            {priceDenominator}
                        </span>
                    </div>
                    <div className={css`display: flex;
                justify-content: space-around;
                width:100%;
                font-size:12px;
                padding-top: 8px;
                color: hsl(230, 30%, 40%);
                `}>
                        {durationInfo}
                        {participantCountInfo}
                        {joinMethod}
                    </div>
                </div>
            </CardContainer>
            {sysadmin &&
                <div className={css`display: flex; flex-direction: column; width: 100%;`}>
                    <Button className={css`background-color: navy;
                                    color: white;
                                    width: 100%;
                                    margin-top: 20px;
                                    :hover {
                                            background-color: rgb(120, 14, 14);
                                    }`}
                        variant='contained' onClick={() => handleEventEdit(entry)}>Edit</Button>
                    <Button className={css`background-color: rgb(186, 24, 24);
                                    color: white;
                                    width: 100%;
                                    margin-top: 20px;
                                    :hover {
                                            background-color: rgb(120, 14, 14);
                                    }`}
                        variant='contained' onClick={() => handleDeleteEvent(entry)}>Delete</Button>
                </div>}
        </>
    );
}

export default withGameModal(EventCard);
export { CardContainer };
