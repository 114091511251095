import {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {Field} from 'redux-form';

import {css} from '@emotion/css';
import pluralize from 'pluralize';
import i18n from "util/i18n";
import {calculateMaxCredits, computeTotalPrice} from "util/pricing/events";
import MemberInvite from "../Dashboard/Events/MemberInvite";
import {hasAccess} from "../../util/permission/haspermissions";
import {permissions} from "../../util/permission/permissions";
import {loadMembers} from "../../util/database/users";
import {useFirestore} from "react-redux-firebase";

function Money({cashPrice, numCredits, bold, strikethrough}) {
    return <div className={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: ${bold ? 'bold' : 'normal'};
        font-size: 28px;
        color: hsl(230, 10%, ${strikethrough ? 70 : 10}%);
        text-decoration: ${strikethrough ? 'line-through' : 'none'};
    `}>
        {numCredits > 0 && <>
            <span>{numCredits} credit</span>
            {cashPrice > 0 && <span>&nbsp;+&nbsp;</span>}
        </>
        }
        {(cashPrice > 0) && <>
            <span className={css`
                    font-size: 20px;
                    color: hsl(230, 10%, ${strikethrough ? 70 : 20}%);
                    position: relative;
                    bottom: 4px;
                    right: 2px;
                `}>$</span>
            <span>
                {cashPrice.toLocaleString()}
            </span>
        </>
        }
    </div>;
}

function CostComponent({
                           name,
                           description,
                           value,
                       }) {
    const disabled = value === 0;
    return <div
        className={css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            padding: 15px 0;
            border-bottom: 1px solid hsl(230, 10%, 80%);

            position: relative;
        `}
    >
        <div className={css`
            display: flex;
            flex-direction: column;
        `}>
            <span className={css`
                font-size: 18px;
                color: hsl(230, 10%, ${disabled ? 70 : 20}%);
            `}>{name}</span>
            <span className={css`
                font-size: 14px;
                color: hsl(230, 10%, ${disabled ? 80 : 40}%);
            `}>{description}</span>
        </div>
        <Money cashPrice={value} strikethrough={disabled}/>
    </div>
}

function Total({value, numCredits}) {
    return <div className={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        padding: 15px 0;
    `}>
        <span className={css`
            font-size: 24px;
            color: hsl(230, 20%, 15%);
            font-weight: 400;
        `}>
            Total
        </span>
        <Money cashPrice={value} bold numCredits={numCredits}/>
    </div>
}

const Invoice = ({eventInfo, setFinalCost, setPreCreditCost, companyInfo, participantCountBounds}) => {
    // TODO: call a function in src/util/database/booking.js to check if credit has value changed since first page load
    const firestore = useFirestore();
    const userData = useSelector(state => state.userinfo);
    const [members, setMembers] = useState(null);
    const creditAmount = companyInfo?.credits;
    const canUseCredit = creditAmount > 0 && hasAccess(permissions.USE_CREDIT, userData);
    const showManagerCanUseCredit = creditAmount > 0 && !canUseCredit &&
        userData.company?.rolePermissions['superuser']?.includes(permissions.USE_CREDIT);

    // Get current payWithCredit and numParticipants values from redux form
    const form = useSelector(state => state.form.eventBooking);
    const creditsUsed = form?.values?.creditsUsed;
    const numParticipants = form?.values?.membersInvited?.length || 0;

    // Get cash price to pay given the number of participants and available credits
    const {cashPrice: registrationCost} = computeTotalPrice({
        eventTags: eventInfo.tags,
        availableCredits: creditAmount,
        creditsUsed: creditsUsed,
        pricePerPerson: eventInfo.pricePerPerson,
        numParticipants: numParticipants
    });

    // TODO display credit discount ($400/credit) as cost component instead of applying it to registration cost

    // Use array to make invoice more scalable with additional event costs
    const costComponents = [
        {
            name: i18n.registrationCost,
            description: `${pluralize(i18n.person, numParticipants, true)} × $${eventInfo.pricePerPerson}/${i18n.person}`,
            value: registrationCost,
        },
    ];

    // Get total cost by summing up cost components' values
    let totalCost = 0;
    for (let entry of costComponents) {
        const {value} = entry;
        totalCost += value;
    }

    const preCreditCost = eventInfo.pricePerPerson * numParticipants;
    const maxCredits = calculateMaxCredits(preCreditCost, creditAmount, eventInfo.tags);

    useEffect(() => {
        setFinalCost(totalCost);
        setPreCreditCost(preCreditCost);
    }, [totalCost, preCreditCost, setFinalCost, setPreCreditCost])

    useEffect(() => {
        if(companyInfo) {
            loadMembers(firestore, companyInfo).then(setMembers);
            console.log("members loaded");
        }
    }, [firestore, companyInfo])

    const participantCountValidator = useMemo(() => (selectedMembers) => {
        const {min, max} = participantCountBounds;
        if (selectedMembers?.length < min) {
            return i18n.formatString(i18n.minParticipantBoundWarning, min);
        }
        if (selectedMembers?.length > max) {
            return i18n.formatString(i18n.maxParticipantBoundWarning, max);
        }
        return undefined;
    }, [participantCountBounds]);

    return (
        <div>
            <Field
                name="membersInvited"
                component={MemberInviteField}
                members={members}
                validate={participantCountValidator}
            />
            {canUseCredit &&
            <>
                <div className={css`
                    font-style: normal;
                    font-weight: bold;
                    font-size: 20px;`}>
                    {i18n.payWithACredit} ({i18n.balance}: {creditAmount}): &nbsp;
                    <Field
                        name='creditsUsed'
                        label={`Credits`}
                        component="input"
                        type="number"
                        min={0}
                        max={maxCredits}
                        value={0}
                        className={css`
                        width: 50px;
                        font-size: inherit;
                        border-color: rgba(0, 0, 0, 0.54);
                        border-radius: 2px;
                        padding:3px;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 20px;
                        color: blue;
                    `}
                    />
                </div>

            </>
            }
            {showManagerCanUseCredit && <p><b>{i18n.onlyManagerCanUseCredit}</b></p>}
            {costComponents.map(({name, description, value}) =>
                <CostComponent
                    key={name}
                    name={name}
                    description={description}
                    value={value}
                />)}
            <Total value={totalCost} numCredits={creditsUsed}/>
        </div>
    )
}

const MemberInviteField = (field) => {
    const [selectedMembers, setSelectedMembers] = useState([]);

    useEffect(() => {
        field.input.onChange(selectedMembers.map((user) => user.email));
    }, [field.input, selectedMembers, field.members]);

    return <MemberInvite
            invited={selectedMembers}
            setInvited={setSelectedMembers}
            allowCustomUsers
            memberList={field.members}
            errorMsg={field.meta?.error}
        />;
}

export default Invoice;
