import React from 'react';
import { css } from '@emotion/css';

import { useTable } from 'react-table';


function Table({columns, data, customRowProps}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({columns, data});

    customRowProps = customRowProps || (() => {return {};});

    return <table {...getTableProps()} className={css`
        background-color: white;
        border-collapse: collapse;

        border-radius: 8px;
        overflow: hidden;

        box-shadow: 1px 1px 4px hsla(230, 10%, 10%, 0.3);
    `}>
        <thead className={css`
            background-color: hsl(230, 20%, 90%);
            color: hsl(230, 10%, 40%);
            font-weight: 500;

            text-transform: uppercase;
            font-size: 12px;
            letter-spacing: 0.09em;
            height: 30px;
        `}>{headerGroups.map(headerGroup => 
        <tr {...headerGroup.getHeaderGroupProps()}>{
            headerGroup.headers.map(column =>
                <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                </th>
            )
            }</tr>
        )}</thead>
        <tbody {...getTableBodyProps()}>{
            rows.map(row => {
                prepareRow(row);
                const {getRowProps, cells} = row;
                return <tr {...getRowProps()} {...customRowProps(cells)}>{cells.map(cell =>
                    <td {...cell.getCellProps()} className={css`
                        padding: 10px 20px;
                    `}>{
                        cell.render('Cell')
                        }</td>
                )}</tr>;
            })
            }</tbody>
    </table>;
}

export default Table;
