import React, { useState, useEffect, useMemo } from "react";
import { css } from "@emotion/css";
import { useFirestore } from "react-redux-firebase";
import { useHistory, useLocation } from "react-router";

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import { CardContainer as EventCard } from "components/Dashboard/Events/EventCard";
import ScheduleGameModal from "components/Dashboard/Events/modals/ScheduleGameModal";
import i18n from "util/i18n";

const GameList = () => {
    const firestore = useFirestore();
    const history = useHistory();
    const location = useLocation();

    const [selectedGame, selectGame] = useState(undefined);
    const [virtualPlatforms, setPlatforms] = useState([]);
    const [alertContent, setAlert] = useState(null);

    const queryParams = useMemo(
        () => new URLSearchParams(location.search),
        [location]
    );

    const onAlertClose = () => {
      setAlert(null);
    };

    const clickHandler = (gpid) => (e) => {
        e.preventDefault();
        queryParams.set('game', gpid);
        history.replace({search: `?${queryParams.toString()}`});
    }

    // Load all game providers and store
    // them separately from events.
    // Once we have a better idea of what the game-booking
    // flow looks like, this will likely go elsewhere.
    // See `loadUsers` and similar util functions.
    useEffect(() => {
      firestore
        .collection("gameProviders")
        .get()
        .then((res) => res.docs)
        .then((docs) => {
          const platforms = Array.from(docs).map((doc) => ({
            gpid: doc.id,
            ...doc.data(),
          }));
          setPlatforms(platforms);
        });
    }, [firestore]);

    // If a game platform is specified in state,
    // select it and open the booking modal
    useEffect(() => {
      if (virtualPlatforms.length && queryParams.has("game")) {
        const id = queryParams.get("game");
        const ev = virtualPlatforms.find(({ gpid }) => gpid === id);
        selectGame(ev);
      }
    }, [virtualPlatforms, queryParams]);

    const onModalClose = () => {
        selectGame(undefined);
        queryParams.delete("game");
        history.replace({search: `?${queryParams.toString()}`});
    };

    const onScheduleSuccess = () => {
      setAlert(i18n.successfulYouWillGetEmail);
    };

    return (
      <>
        <ScheduleGameModal game={selectedGame} onSuccess={onScheduleSuccess} onClose={onModalClose}/>
        <Snackbar open={alertContent !== null} autoHideDuration={6000} onClose={onAlertClose}>
          <Alert onClose={onAlertClose} severity="success">
            {alertContent}
          </Alert>
        </Snackbar>
        <h1
          className={css`
            color: hsl(235, 10%, 15%);
            margin: 0;
            margin-top: 48px;
            text-transform: capitalize;
          `}
        >
            {i18n.wfhomieGames}
        </h1>
        <div
          className={css`
            display: grid;
            grid-template-columns: repeat(auto-fit, 270px);
            grid-gap: 20px;
            margin: 30px 0;
          `}
        >
          {virtualPlatforms.map(({ name, image, gpid }, i) => (
            <EventCard
              key={gpid + "-" + i}
              title={name}
              image={image || ("https://source.unsplash.com/250x200/?game&sig=" + i)}
              onClick={clickHandler(gpid)}
            >
              <span
                className={css`
                  font-size: 12px;
                  color: hsl(230, 30%, 40%);
                `}
              >
                  {i18n.fromWfhomie}
              </span>
            </EventCard>
          ))}
        </div>
      </>
    );
}

export default GameList;
