import React, { useEffect, useState } from "react";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import { css } from '@emotion/css';
import Button from '@material-ui/core/Button';
import { default as ShippingInfo } from "../Forms/sections/ShippingInfo"
import { default as DietaryRestrictions } from "../Forms/sections/DietaryRestrictions";
import WorkStartDate from "../Forms/sections/WorkStartDate";
import Birthday from "../Forms/sections/Birthday";
import TimeZoneSelect from "../Forms/sections/TimeZoneSelect";
import { reduxForm } from "redux-form";
import Snackbar from "@material-ui/core/Snackbar";
import { SubmitButton } from "../Forms";
import { useDispatch, useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";

import i18n from "util/i18n";

const WelcomeForm = reduxForm({ form: "welcomeForm" })(
    () => {
      return (
          <>
            <Grid item>
                <ShippingInfo/>
                <TimeZoneSelect/>
                <WorkStartDate/>
                <Birthday/>
                <DietaryRestrictions/>
            </Grid>
      </>
      );
    }
  );

const ShowWelcomeModal = () => {
    const [open, setOpen] = useState(true);
    const [toastOpen, toggleToast] = useState(false);
    const dispatch = useDispatch();
    const firestore = useFirestore();
    const user = useSelector((state) => state.firebase.auth);
    const userinfo = useSelector((state) => state.userinfo);
    const form = useSelector((state) => state.form.welcomeForm);
    const history = useHistory();

    const handleClose = () => {
      setOpen(false);
    };

    async function handleSubmit() {
        const ref = firestore.collection("userinfo").doc(user.uid);
        if (!ref) {
          throw new Error("Welcome Modal submission failed.");
        }
        const info = {};

        // This was the first-ever Redux Form validator I wrote.
        // You may want to refactor this into something less
        // spaghetti-looking.
        Object.entries(form.values).forEach(([fld, val]) => {
          if (typeof val === "string") {
            info[fld] = val;
          } else if (fld === 'timeZone'){
              info[fld] = val.value;
          } else if (fld === "address") {
            if (val.address) {
              info[fld] = val.address
            }
          } else if(Object.prototype.toString.call(val) === '[object Date]') {
              info[fld] = val.toDateString().substring(4);
          } else {
            info[fld] = { ...userinfo[fld], ...val };
          }
        });

        await ref.update(info);

        // optimistically update the user's info
        dispatch({ type: "userinfo/load", payload: {...info, userId: ref.id} });
        history.replace({state: {justSignedUp: false}}) // TODO Remove once page reload issue is fixed
        toggleToast(true);
        setOpen(false);
        return true;
      }

    // You'll also want to update this.
    // Redux-form has an `initialize` prop that will
    // probably be useful in that regard.
    useEffect(
        () =>
        userinfo &&
        form &&
        !form.values &&
        Object.keys(form.registeredFields).forEach((field) => {
                dispatch({
                type: "@@redux-form/CHANGE",
                meta: { form: "welcomeForm", field },
                payload: field === "address" ? { address: userinfo[field] } : userinfo[field],
            });
        }),
        [userinfo, form, dispatch]
    );

    let disableSubmit = form && form.values && form.values.address && form.values.address.address !== undefined &&
                        form.values.address.address !== "" && form.values.phone && form.values.phone.length >= 10 &&
                        form.values.postalCode && form.values.postalCode.length >= 5 && // zip codes are 5 digits long, some international postal codes have more than 6 characters
                        form.values.workStartDate !== undefined && form.values.birthday !== undefined;

    return (
        <>
            <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={toastOpen}
            autoHideDuration={4000}
            onClose={() => toggleToast(false)}
            message={i18n.settingsSavedWithEmoj}
            />
            <Dialog open={open} maxWidth={"lg"}>
                <DialogTitle>{i18n.welcomeToWfhomie}👋</DialogTitle>
                <DialogContent>
                    <p>{i18n.addTheseInfoToProfile}</p>
                    <p>{i18n.addInfoLaterMsg}</p>
                    <WelcomeForm/>
                    <div className={css`
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        > button {
                            margin: 0px 10px;
                        }
                    `}>
                        <SubmitButton
                            onClick={handleSubmit}
                            label={i18n.saveAndClose}
                            disabled={!disableSubmit}
                        />
                        <Button variant="contained"
                                className={css`background-color: hsl(0, 84%, 61%);
                                            color: white;

                                            :hover {
                                                background-color: hsla(0, 84%, 41%, 1);
                                            }
                            `} onClick={handleClose}>
                            {i18n.close}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ShowWelcomeModal
