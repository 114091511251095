import { UpgradeToPremium } from "./UpgradeToPremium";

export const GiftCardUpsell = () => {
    return (
        <div className="bg-purple-bg w-full max-w-full p-6 rounded-lg">
            <div className="flex flex-col space-y-8">
                <span className="font-bold uppercase">Add Digital Gift Cards</span>

                <div className="flex space-x-6">
                    <div className="flex-auto flex items-end justify-center">
                        <img src="/static/images/gift-image.png" alt="Gifts" />
                    </div>

                    <div className="flex-auto flex flex-col space-y-4 max-w-sm">
                        <p>Take your peer-to-peer appreciation to the next level by sharing the perfect gift!</p>
                        <p>
                            Attach digital gift cards from dozens of great brands like Starbucks, Sephora, Nike and
                            Uber.
                        </p>
                        <p>
                            Let the recipient choose, you set the budget. Finally, all your gifting needs in one spot.
                        </p>
                    </div>
                    <UpgradeToPremium/>
                </div>
            </div>
        </div>
    );
};
