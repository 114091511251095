import React, {useState} from 'react';
import {useFirestore} from 'react-redux-firebase';
import {loadKudosCompany} from 'util/database/companies'
import {useSelector} from "react-redux";

const KudosCompany = ({company}) => {
    const date = new Date(company.timeCreated);
    return <div className="bg-white border rounded justify-items-center">
        <div className="text-base font-light text-center">
            <p>{company.name}</p>
            <p>{date.toDateString()}</p>
            <p>{date.toTimeString()}</p>
        </div>
    </div>;
};

const HiddenFirst100KudosCompany = () => {
    const firestore = useFirestore();
    const userData = useSelector((state) => state.userinfo);
    const [companies, setCompanies] = useState(null);

    if (companies === null && userData && userData.role === 'sysadmin') {
        loadKudosCompany(firestore).then(setCompanies);
    }

    return <>
        <h1 className="text-2xl font-medium	m-0">First 100 Kudos Companies</h1>
        {companies === null ? 'Loading...' :
            <div className="grid grid-cols-1 gap-4 mt-8">
                {companies.map(company =>
                    <KudosCompany
                        key={company.id}
                        company={company}
                    />
                )}
            </div>
        }
    </>
};

export default HiddenFirst100KudosCompany;
