import i18n from "../../util/i18n";

// i dont know how exactly this works but https://stackoverflow.com/questions/48788200/split-array-of-objects-into-groups-by-week
export function groupDatesByWeek(dates) {
    const groupedByWeek = Object.values(dates.reduce((acc, val) => {
        let dateTimeVal = val.dateTime;
        if (!dateTimeVal) {
            dateTimeVal = "01-15-2017 22:00:00"; // default date, ideally doesnt come to this
        } else {
            dateTimeVal = dateTimeVal.replace("T", " ");
        }
        var dateparts = dateTimeVal.split(/ |-|:/g);
        var date = new Date(dateparts[0], dateparts[1] - 1, dateparts[2], dateparts[3], dateparts[4], "00");
        var weekNo = getISO8601WeekNo(date);
        if (!acc[weekNo]) acc[weekNo] = [];
        acc[weekNo].push(val);
        return acc;
    }, {}));

    const orderedGroups = groupedByWeek.sort((g1, g2) => new Date(g1[0].dateTime) - new Date(g2[0].dateTime));

    return orderedGroups;
}

function getISO8601WeekNo(date) {
    var startDate = new Date(date.getFullYear(), 0);
    var endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    while (endDate.getDay() < 6) endDate.setDate(endDate.getDate() + 1);
    endDate = endDate.getTime();
    var weekNo = 0;
    while (startDate.getTime() < endDate) {
        if (startDate.getDay() === 4) weekNo++;
        startDate.setDate(startDate.getDate() + 1);
    }
    return weekNo;
}

export function isFutureDate(date, offsetMins) {
    let now = new Date();
    let offsetConvert = 60000 * offsetMins;
    return now - new Date(Date.parse(date)) < offsetConvert;
}

//https://stackoverflow.com/questions/36787908/how-to-check-if-date-is-in-this-week-in-javascript
export function isDateInThisWeek(date) {
    const todayObj = new Date();
    const firstDayOfWeek = new Date(todayObj.setDate(todayObj.getDate() - todayObj.getDay()));
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);
    return date >= firstDayOfWeek && date <= lastDayOfWeek;
}

export function isDateNextWeek(date) {
    const prevWeekDate = new Date(date - 7 * 24 * 60 * 60 * 1000);
    return isDateInThisWeek(prevWeekDate);
}

export function generateWeek(date) {
    const todayObj = new Date(date);
    todayObj.setHours(0, 0, 0, 0);
    const firstDayOfWeek = new Date(todayObj.setDate(todayObj.getDate() - todayObj.getDay()));
    let weekArray = [];

    for (let i = 0; i < 5; i++) {
        weekArray.push(new Date((firstDayOfWeek).setDate(firstDayOfWeek.getDate() + 1)));
    }
    return weekArray;
}

export function getWeekday(num) {
    return [i18n.monday, i18n.tuesday, i18n.wednesday, i18n.thursday, i18n.friday, i18n.saturday, i18n.sunday][num - 1];
}

export function isToday(dateTime) {
    const minutesLeft = (dateTime - Date.now()) / (60 * 1000);
    const hoursLeft = minutesLeft / 60;
    const daysLeft = hoursLeft / 24;

    return daysLeft <= 1 && minutesLeft > 0 && (new Date().getDate() === new Date(dateTime).getDate());
}

export function isTomorrow(dateTime) {
    const minutesLeft = (dateTime - Date.now()) / (60 * 1000);
    const hoursLeft = minutesLeft / 60;
    const daysLeft = hoursLeft / 24;

    return daysLeft <= 2 && minutesLeft > 0 && (new Date().getDate() + 1 === new Date(dateTime).getDate());
}
