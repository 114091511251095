import { useState, useEffect, useMemo } from "react";
import { loadEvents } from "util/database/events";
import { useHistory } from "react-router-dom";
import { css } from "@emotion/css";

import EventDashboard from "components/Dashboard/Events/EventDashboard";
import EventTagFilter from "components/Dashboard/Events/EventTagFilter";
import FilteredEventList from "components/Dashboard/Events/FilteredEventList";
import GameList from "components/Dashboard/Events/games/GameList";
import PageHeader from "components/Dashboard/PageHeader";
import LandingPageHeader, { isLandingPage } from "components/Dashboard/LandingPageHeader";
import { mq } from "components/util/Breakpoints";
import VoteInviteButton from "./Voting/VoteInviteButton";
import { useFirestore } from "react-redux-firebase";
import { Link } from "react-router-dom";
import { eventThemes } from "util/constants/events";

const fillDashboardCategories = (type) => {
    switch (type) {
        case "events":
            return [
                eventThemes.MOST_POPULAR,
                eventThemes.SEASONAL_EVENTS,
                eventThemes.HEAD_TO_HEAD_GAMES,
                eventThemes.TRAVEL,
                eventThemes.ARTS_CULTURE,
                eventThemes.HEALTH_WELLNESS,
                eventThemes.LEARN_GROW,
                eventThemes.FOOD_DRINK,
                eventThemes.TEAM_PARTIES
            ];
        case "fall-events":
            return [
                eventThemes.FALL,
                eventThemes.HALLOWEEN
            ];
        case "activities":
            return [eventThemes.VIRTUAL_ACTIVITIES, eventThemes.VIRTUAL_GAMES];
        default:
            return [];
    }
};

// Effectively, checks that the value is not one of the following "logically falsy"
// values, which JS annoyingly treats as truthy/conflates with a valid value:
// []
// {}
// NaN (since !0 === 1)
const exists = (value) => (typeof value === "number" ? !isNaN(value) : Object.keys(value).length);


function CardPage({ type, showFilters, showCustomGames, pageTitle, hasPageImage, pageImage, showVoteButton }) {
    const history = useHistory();
    const firestore = useFirestore();

    const [events, setEvents] = useState([]);
    const [allowPolling, setAllowPolling] = useState(false);
    const [selectedEvents, setSelectedEvents] = useState([]); // Array of {title: string, id: string} for each selected event

    useEffect(() => {
        (() => {
            loadEvents(firestore).then(setEvents);
        })();

        return () => setEvents([]);
    }, [firestore]);

    const qs = useMemo(() => new URLSearchParams(history.location.search), [history.location.search]);

    const [filters, setFilter] = useState({
        tag: qs.get("tag")?.split(",") ?? [],
        price: Number(qs.get("price") ?? NaN),
        title: qs.get("title") ?? "",
    });

    const currentThemes = fillDashboardCategories(type);

    let activeFilters = filters.tag.length !== 0 || !isNaN(filters.price) || filters.title !== ""; // 1 means has filters, 0 means doesnt

    useEffect(() => {
        // Iterate through all filter categories (as of writing, tag and price).
        // If the field no longer exists, remove it from query params.
        // Otherwise, add it and reflect the query in the URL.
        // TLDR two-way binding btwn. local state and query params
        Object.entries(filters).forEach(([key, value]) => {
            if (!exists(value)) {
                qs.delete(key);
            } else {
                qs.set(key, value);
            }
        });

        const currentPathname = history.location.pathname;

        history.replace(currentPathname + "?" + qs.toString());
    }, [history, qs, filters]);

    const addEventToPoll = (selectedEvent) => {
        if (selectedEvents.some(({ id }) => id === selectedEvent.id)) {
            setSelectedEvents(selectedEvents.filter(({ id }) => id !== selectedEvent.id));
        } else {
            setSelectedEvents((selectedEvents) => [...selectedEvents, selectedEvent]);
        }
    };

    let landingPageContentWrapperCSS = ``;

    if (isLandingPage(type)) {
        landingPageContentWrapperCSS = `
        margin:0 120px;


        ${mq[2]} {
            margin:0 40px;
        }

        `;
    }

    return (
        <>
            {isLandingPage(type) ?
                <LandingPageHeader type={type} />
                : <PageHeader title={pageTitle} hasImage={hasPageImage} image={pageImage} />}

            <div className={css(landingPageContentWrapperCSS)}
            >
                {showFilters && (
                    <div
                        className={css`
                        display: flex;
                        justify-content: space-between;

                        // We need to do proper media queries at some point
                        @media only screen and (max-width: 900px) {
                            flex-direction: column;
                            margin-bottom: 20px;
                        }

                        @media only screen and (min-width: 901px) {
                            margin-bottom: ${activeFilters ? "40px" : "0px"};
                        }

                        > div {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                        }


                        ${mq[2]} {
                            display:none;
                        }

                    `}
                    >
                        <div>
                            <EventTagFilter filters={filters} setFilter={setFilter} />
                        </div>
                    </div>
                )}

                {showVoteButton && (
                    <div
                        className={css`
                        display: flex;
                        margin-top: 20px;
                    `}
                    >
                        <VoteInviteButton
                            setPolling={setAllowPolling}
                            eventsToPoll={selectedEvents}
                            setEventsToPoll={setSelectedEvents}
                        />
                    </div>
                )}

                {showCustomGames && <GameList />}
                {activeFilters ? (
                    <FilteredEventList
                        exists={exists}
                        filters={filters}
                        events={events}
                        currentThemes={currentThemes}
                        addEventToPoll={addEventToPoll}
                        allowPolling={allowPolling}
                        currentEventsToPoll={selectedEvents}
                    />
                ) : (
                    <EventDashboard
                        isHomePage={false}
                        currentThemes={currentThemes}
                        addEventToPoll={addEventToPoll}
                        allowPolling={allowPolling}
                        currentEventsToPoll={selectedEvents}
                    />
                )}

                {isLandingPage(type) && <div
                    className={css`
                display: flex;
                justify-content: center;
                margin: 80px 0;


                ${mq[2]} {
                    margin: 40px 0;
                }

             `}
                >
                    <Link to="/dashboard/events" className={css`
            display: block;
            max-width:fit-content;
            padding: 10px 30px;
            font-weight:bold;
            border-radius: 28px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            background-color: #fff;
            color: #2636ff;
            margin-top:40px;
            cursor:pointer;
            text-decoration:none;
            font-size:25px;
            `}>Explore all events</Link></div>
                }
            </div>
        </>
    );
}

export default CardPage;
