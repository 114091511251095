import React from 'react';

import {css} from '@emotion/css'

import {useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EventIcon from '@material-ui/icons/Event';
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined';
import CardGiftcardOutlinedIcon from '@material-ui/icons/CardGiftcardOutlined';
import ExtensionOutlinedIcon from '@material-ui/icons/ExtensionOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import TimelineIcon from '@material-ui/icons/Timeline';

import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import {hasAccess} from 'util/permission/haspermissions';
import {permissions} from 'util/permission/permissions';
import {getReplaceClassWithHome} from 'util/localization';
import {mq} from '../util/Breakpoints'
import i18n from "util/i18n";

/* Styles for our Drawer */
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        flexShrink: 0,
        whiteSpace: 'nowrap',
        backgroundColor: "hsl(235, 10%, 97%)"
    },
    toolbar: {
        marginTop: 70,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    typeography : {
        fontFamily: 'Seravek',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '20px',
        textAlign: 'center',
        color: '#B3B3B3'
    },
}));


const entries = (userInfo) => {
    let buildSideBar = [];

    if (hasAccess(permissions.HOME, userInfo) && !getReplaceClassWithHome(userInfo)) {
        buildSideBar.push([i18n.home, <HomeOutlinedIcon fontSize='inherit'/>, 'home']);
    }

    if (hasAccess(permissions.EVENTS, userInfo)) {
        buildSideBar.push([i18n.events, <EventIcon fontSize='inherit'/>, 'events']);
    }

    if (hasAccess(permissions.GIFTING, userInfo)) {
        buildSideBar.push([i18n.gifting, <CardGiftcardOutlinedIcon fontSize='inherit'/>, 'gifting']);
    }

    if (hasAccess(permissions.CLASSES, userInfo)) {
        let routeClass = !getReplaceClassWithHome(userInfo) ? "classes" : "home";
        buildSideBar.push([i18n.classes, <ClassOutlinedIcon fontSize='inherit'/>, routeClass]);
    }

    if (hasAccess(permissions.ACTIVITIES, userInfo)) {
        buildSideBar.push([i18n.activities, <ExtensionOutlinedIcon fontSize='inherit'/>, 'activities']);
    }

    if (hasAccess(permissions.ANALYTICS, userInfo)) {
        buildSideBar.push([i18n.analytics, <TimelineIcon fontSize='inherit'/>, 'summary']);
    }

    return buildSideBar;

}

function SidebarItem({name, icon, url, selected}) {

    const to = url.startsWith('/') ? url : `/dashboard/${url}`;
    return <Link to={to} className={css`
        text-decoration: none; color: blue; flex: 1;
        border-radius: 10px;
        font-weight: bold;
    `}>
        <ListItem button key={name} selected={selected}
                  className={css`padding: 20px 0px 14px 0px; display: flex; flex-direction: column;`}>
            <ListItemIcon className={css`font-size: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #0022CC;
                font-family: Seravek;
                font-style: normal;
                font-weight: 500;
                font-size: 32px;
                line-height: 32px;

                `}>{icon}</ListItemIcon>
            <ListItemText primary={<Typography style={{fontWeight: "bold"}}>{name}</Typography>}
                className={css`
                color: #0022CC;
                font-family: Seravek;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;      
                `}/>
        </ListItem>
    </Link>;
}

function Sidebar() {
    const classes = useStyles();
    // const theme = useTheme();

    const loc = useLocation();
    const part = loc.pathname.split('/').pop();
    const user = useSelector(state => state.firebase.auth);
    const userinfo = useSelector(state => state.userinfo);

    const items = entries(userinfo);


    return <>
        {items && userinfo && !user.isEmpty && <div className={classes.root}>
            <CssBaseline/>
            <Drawer
                className={css`
          ${mq[2]} {
            .MuiDrawer-paper {
              height: auto;
              position: relative;
            }

            width: 100%;
          }

          ${mq[3]} {
            .MuiDrawer-paper {
              height: 100%;
              width: auto;
              position: fixed;
            }

            .MuiDrawer-docked {
              width: auto;
            }
          }
        `}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
            >
                <Divider/>
                <List disablePadding={true} className={css`

          ${mq[2]} {
            display:flex;
            justify-content: space-evenly;
            position: relative;
            margin-top: 80px;
          }

          ${mq[3]} {
            width: 120px;
            display: block;
            margin-top: 100px;
          }
        `}>
                    {items.map(([name, icon, url]) =>
                        <SidebarItem
                            key={name}
                            name={name}
                            icon={icon}
                            url={url}
                            selected={part === url}
                        />
                    )}
                </List>
            </Drawer>
        </div>}
    </>;
}

export default Sidebar;
