import React, { useState, useEffect } from "react";
import { css } from "@emotion/css";

import { makeStyles, createTheme, ThemeProvider } from "@material-ui/core/styles";
import StarRateIcon from "@material-ui/icons/StarRate";
import Typography from "@material-ui/core/Typography";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import Overview from "./util/Overview";
import BookingInfo from "./util/BookingInfo";
import Event from "./EventCard";

import LoadingIcon from "components/util/LoadingIcon";
import Tags from "components/util/Tags";
import { loadSimilarEvents } from "util/database/events";

import { Helmet } from "react-helmet";
import i18n from "util/i18n";
import { useFirestore } from "react-redux-firebase";

import { mq } from "../../util/Breakpoints";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

const theme = createTheme();
theme.typography.h3 = {
    color: "#333333",
    fontSize: "1.2rem",
    "@media (min-width:600px)": {
        fontSize: "1.5rem",
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "2rem",
    },
};

function ImageOverview({ images }) {
    return (
        <div
            className={css`
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                grid-gap: 10px;

                max-height: 410px;
                grid-template-rows: 200px 200px;
                overflow: hidden;
                margin-bottom: 20px;
            `}
        >
            {images.map(({ alt, src }, i) => (
                <img
                    key={alt + " " + i}
                    alt={alt + " " + i}
                    src={src}
                    className={css`
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 8px;

                        grid-row: ${i === 0 ? "span 2" : "initial"};
                        grid-column: ${i === 0 ? "span 3" : "initial"};
                    `}
                />
            ))}
        </div>
    );
}

function Placeholder({ header, bodyText }) {
    return (
        <>
            <h2
                className={css`
                    margin: 0;
                    margin-top: 20px;
                    font-weight: bold;
                    font-size: 20px;
                    color: hsl(230, 10%, 15%);
                    text-align: left;
                `}
            >
                {header}
            </h2>
            <br />
            <div
                className={css`
                    color: #333333;
                    line-height: 1.5;
                    font-weight: 500;
                    text-align: left;
                `}
            >
                {" "}
                {bodyText}{" "}
            </div>
        </>
    );
}

function Whatneed({ header, bodyText }) {
    const listItems = bodyText.map((item) => (
        <div
            className={css`
                display: flex;
                flex-direction: row;
                align-items: center;
            `}
            key={"checkboxdiv " + item}
        >
            {
                <CheckBoxOutlinedIcon
                    style={{
                        color: "#808080",
                        fontSize: 35,
                        marginRight: 10,
                    }}
                />
            }
            <div>{item}</div>
        </div>
    ));

    return (
        <>
            <h2
                className={css`
                    margin: 20px 0px;
                    font-weight: bold;
                    font-size: 20px;
                    color: hsl(230, 10%, 15%);
                    text-align: left;
                `}
            >
                {header}
            </h2>
            <span
                className={css`
                    color: #333333;
                    font-size: 18px;
                    line-height: 2.5;
                    font-weight: 500;
                    text-align: left;
                    vertical-align: middle;
                    align-items: center;
                `}
            >
                {" "}
                {listItems}{" "}
            </span>
        </>
    );
}

function ReviewsSection({ header, reviews, classes }) {
    let revCount = reviews.length;
    let avgRating = (reviews.reduce((revSum, rev) => revSum + rev.rating, 0) / revCount).toFixed(1);

    if (avgRating === "NaN") {
        return <></>;
    }

    return (
        <Grid item sm={9}>
            <Paper className={classes.paper}>
                <h2
                    className={css`
                        margin: 0;
                        margin-top: 20px;
                        margin-bottom: 20px;
                        font-weight: bold;
                        font-size: 20px;
                        color: hsl(230, 10%, 15%);
                        text-align: left;
                    `}
                >
                    {header}
                </h2>
                <h3
                    className={css`
                        text-align: left;
                    `}
                >
                    Average Rating: {avgRating}
                </h3>
                <Grid container spacing={3}>
                    {reviews.map((review) => {
                        return (
                            <>
                                <div
                                    className={css`
                                        margin: 20px;
                                        text-align: left;
                                        font-size: 16px;
                                        display: flex;
                                        justfiy-content: space-around;
                                        align-items: center;

                                        > div {
                                            margin: 0px 10px;
                                        }
                                    `}
                                >
                                    <StarRateIcon fontSize="large" color="primary" />
                                    <div
                                        className={css`
                                            color: #333333;
                                            font-weight: 500;
                                            font-size: 16px;
                                        `}
                                    >
                                        {" "}
                                        {review.rating}
                                    </div>
                                    <div>{review.text}</div>
                                </div>
                            </>
                        );
                    })}
                </Grid>
            </Paper>
        </Grid>
    );
}

// Returns 'numEvents' event cards of random events in the same category
function SimilarEvents(id, numEvents) {
    const [similarEvents, setSimilarEvents] = useState(null);
    const firestore = useFirestore();

    useEffect(() => {
        loadSimilarEvents(firestore, id, numEvents).then(setSimilarEvents);
    }, [id, numEvents, firestore]);

    return (
        <>
            {similarEvents ? (
                <div
                    className={css`
                        display: grid;
                        grid-template-columns: repeat(auto-fit, 270px);
                        grid-gap: 20px;
                        margin: 30px 0;
                    `}
                >
                    {similarEvents.map((entry, i) => (
                        <Event key={"sim events " + i} {...entry} />
                    ))}
                </div>
            ) : (
                <LoadingIcon />
            )}
        </>
    );
}

function EventPage({ data }) {
    const images = [
        { src: data.imageUrls[0], alt: data.title },
        { src: data.imageUrls[1] ?? data.imageUrls[0], alt: data.title },
        { src: data.imageUrls[2] ?? data.imageUrls[0], alt: data.title },
    ];
    const tags = data.tags;
    const classes = useStyles();
    const notEvent = !(data.themes.includes("Perks") && data.themes.includes("Team Parties"));

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>WFHomie | {data.title ? data.title : "Events"}</title>
            </Helmet>
            <div className={classes.root}>
                <Grid
                    container
                    spacing={3}
                    className={css`
                        width: calc(100% + 6px);
                        margin: -12px;
                    `}
                >
                    <Grid item sm={9}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <ImageOverview images={images} />
                                <ThemeProvider theme={theme}>
                                    <Typography variant="h3">{data.title}</Typography>
                                </ThemeProvider>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div
                                        className={css`
                                            width: 200px;
                                            margin: 10px 0px;
                                        `}
                                    >
                                        <Tags tags={tags} />
                                    </div>
                                </Grid>
                                {data.header !== "" ? (
                                    <h1
                                        className={css`
                                            font-size: 16px;
                                        `}
                                    >
                                        {data.seoHeader}
                                    </h1>
                                ) : (
                                    <h3
                                        className={css`
                                            font-size: 16px;
                                        `}
                                    >
                                        Theme(s): {data.themes.join(", ")}
                                    </h3>
                                )}
                                <p>{data.description}</p>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Paper className={classes.paper + ' ' + css`

                            position: unset;
                            margin-right: 0;

                            ${mq[1]}{
                                position: fixed;
                                margin-right: 30px;
                            }
                        `}>
                            <BookingInfo data={data} />
                        </Paper>
                    </Grid>
                    {notEvent && (
                        <Grid item sm={9}>
                            <Paper className={classes.paper}>
                                <Overview data={data} />
                            </Paper>
                        </Grid>
                    )}
                    {data.reviews && <ReviewsSection classes={classes} header="Reviews" reviews={data.reviews} />}
                    <Grid item sm={9}>
                        <Paper className={classes.paper}>
                            <Placeholder
                                header={notEvent ? i18n.whatYoullDo : i18n.howItWorks}
                                bodyText={data.whatDo}
                            />
                        </Paper>
                    </Grid>
                    {notEvent && (
                        <Grid item sm={9}>
                            <Paper className={classes.paper}>
                                <Whatneed header={i18n.whatYoullNeed} bodyText={data.whatNeed} />
                            </Paper>
                        </Grid>
                    )}
                    <Grid item sm={9}>
                        <Paper className={classes.paper}>
                            <Placeholder
                                header={i18n.similarExperiences}
                                bodyText={SimilarEvents(data.id, 3)} // Set max number of recommendations here
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default EventPage;
