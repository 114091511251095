const logos = [
    {
        name: "Hooli",
        imageUrl: "/static/images/logos/hooli.png",
    },
    {
        name: "Momcorp",
        imageUrl: "/static/images/logos/momcorp.png",
    },
    {
        name: "Dunder_Mifflin",
        imageUrl: "/static/images/logos/Dunder_Mifflin_Inc.png",
    },
    {
        name: "monster",
        imageUrl: "/static/images/logos/monster.png",
    },
    {
        name: "acme",
        imageUrl: "/static/images/logos/acme.png",
    },
    {
        name: "stark_ind",
        imageUrl: "/static/images/logos/stark_ind.png",
    },
];

const CustomerLogo = ({ name, imageUrl }) => (
    <div className="flex items-center justify-center">
        <img src={imageUrl} alt={name} />
    </div>
);

export const Logos = () => {
    return (
        <>
            <div>
                <span className="text-kudos-grey font-bold">Teams at these companies trust WFHomie's Shame bot</span>
            </div>

            <div className="grid grid-cols-6 gap-4 gap-y-6 w-full">
                {logos.map((l) => (
                    <CustomerLogo {...l} key={l.imageUrl} />
                ))}
            </div>
        </>
    );
};
