import React from 'react';
import { css } from '@emotion/css';

function Company({id, name, logo, onClick}) {
  
    return <div className={css`
        background-color: white;
        box-shadow: 1px 1px 4px hsla(235, 10%, 10%, 0.3);

        display: flex;
        flex-direction: column;
        justify-content: center;

        border-radius: 8px;
        cursor: pointer;

        overflow: hidden;
    `} onClick={onClick}>
        <img src={logo} alt={`${name} logo`} className={css`
            height: 150px;
            object-fit: contain;
            box-sizing: border-box;
            padding: 20px;
        `}/>
        <div className={css`
            padding: 10px 20px;
            background-color: hsl(235, 10%, 95%);
        `}>
        <p className={css`
            font-size: 16px;
            font-weight: 300;
            color: hsl(235, 10%, 30%);

            text-align: center;
            margin: 0;
        `}>{name}</p>
        </div>
    </div>;
}

export default Company;
