import CenteredLoadingIcon from "../../../components/util/CenteredLoadingIcon";


export const InsightsComingSoon = () => {
    return (
        <div className="bg-purple-bg w-full max-w-full p-6 rounded-lg">
            <div className="flex flex-col space-y-8">
                <span className="font-bold uppercase">Insights Processing</span>

                <div className="flex flex-col space-y-8">
                    <div className="flex flex-col items-center space-y-2 text-base">
                        <p>We're collecting data on your team's Kudos Bot usage.</p>
                        <p>We'll email you once your insights are available.</p>
                    </div>

                    <div>
                        <CenteredLoadingIcon color="#6200db" size="40" thickness="4"/>
                    </div>
                </div>
            </div>
        </div>
    );
};
