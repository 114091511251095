import React, {useState, useEffect} from "react";
import { css } from '@emotion/css';

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { Box, Grid } from "@material-ui/core";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';

// Modified From https://material-ui.com/components/selects/#multiple-select
function DynamicDropdown({input, placeholder, menuItems, fromAutofill}) {
    const [values, setValues] = useState([placeholder]);
    const [hasAutofilled, setHasAutofilled] = useState(false);

    useEffect(() => {
        if (!hasAutofilled && fromAutofill) {
            if(input.value && input.value !== placeholder) {
                setValues(input.value);
            }
            setHasAutofilled(true);
        }
    }, [hasAutofilled, fromAutofill, input, placeholder, menuItems]);

    useEffect(() => {
        if(!fromAutofill || (fromAutofill && hasAutofilled)) {
            input.onChange(values);
        }
    }, [input, values, hasAutofilled, fromAutofill]);

    const handleValueChange = (e) => {
        let newValues = e.target.value;
        if(e.target.value[0] === placeholder) {
            newValues.shift()
        }
        setValues(newValues);
    };

    return <>
            <Select
                className={css`display: flex; flex-wrap: wrap;`}
                id="demo-mutiple-chip"
                multiple
                value={values}
                onChange={handleValueChange}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return <>{placeholder}</>;
                    }
                    return <div className={css`display: flex; flex-wrap: wrap;`}>
                    {selected.map((value) => (
                        <Chip key={value} label={value}/>
                    ))}
                    </div>
                }}
                >
                <MenuItem disabled value="">
                    {placeholder}
                </MenuItem>
                {menuItems.map((item) => (
                    <MenuItem key={item} value={item}>
                        {item}
                    </MenuItem>
                ))}
                </Select>
    </>
}

// Modified from https://stackoverflow.com/questions/65549331/dynamic-input-fields-in-react-using-functional-component
function DynamicTextField({input, descriptor, limit, minimum, fromAutofill}) {
    const [values, setValues] = useState(Array(minimum ?? 0).fill(""));
    const [hasAutofilled, setHasAutofilled] = useState(false);
    const inputLimit = limit ?? 1000;

    useEffect(() => {
        if(!hasAutofilled && fromAutofill) {
            if(input.value) {
                // checks if array of objects then connects keys,
                // ideally we have some kind of multiple entry component in the future
                // https://stackoverflow.com/questions/8511281/check-if-a-value-is-an-object-in-javascript
                if(typeof input.value[0] === 'object' && input.value !== null) {
                    let parsedVals = input.value.map((val) => Object.keys(val).map(key => val[key]).join(","));
                    setValues(parsedVals)
                } else {
                    setValues(input.value);
                }
                setHasAutofilled(true);
            }
        }
    }, [input.value, hasAutofilled, fromAutofill])

    const addValue = () => {
        setValues([...values, ""]);
    };

    const handleValueChange = (index, e) => {
        if(!fromAutofill || (hasAutofilled && fromAutofill)) {
            const updatedValues = values.map((value, i) => i === index ? e.target.value : value);
            setValues(updatedValues);
            input.onChange(updatedValues);
        }
    };

    const deleteValue = (index) => {
        setValues(values => values.filter((fValue, fIndex) => index !== fIndex));
    };

    return <>
            {values.map((val, index) => (
                <Box key={"jump" + descriptor + index}>
                <Grid container spacing={1} alignItems="flex-end">
                    <Grid item xs={10}>
                    <TextField
                        autoFocus
                        margin="dense"
                        label={descriptor + " " + (index + 1)}
                        value={val || ""}
                        onChange={(e) => handleValueChange(index, e)}
                        fullWidth
                    />
                    </Grid>
                    <Grid item xs={2}>
                    <div
                        className="font-icon-wrapper"
                        onClick={() => deleteValue(index)}
                    >
                        <IconButton aria-label="delete">
                        <DeleteIcon />
                        </IconButton>
                    </div>
                    </Grid>
                </Grid>
                </Box>
            ))}
            {values.length < inputLimit &&
                <Button onClick={addValue} color="primary">
                    Add {descriptor}
                </Button>
            }
    </>
}

export {DynamicTextField, DynamicDropdown};
