import { css } from "@emotion/css";
import TuneIcon from "@material-ui/icons/Tune";
import Badge from "@material-ui/core/Badge";
import { useState } from "react";
import EventFilterModal from "./modals/EventFilterModal";
import i18n from "util/i18n";
import { eventTags } from "util/constants/events";

const TagFilterButton = ({ tag, onClick, active }) => (
    <div
        onClick={onClick}
        className={css`
            background: #f2f2f2;
            border: ${active ? "1px solid gray" : "1px solid #f2f2f2"};
            font-weight: ${active ? "bold" : "normal"};
            padding: 0.5em 1em;
            border-radius: 300px;
            cursor: pointer;
            transition: all 0.2s ease;
            user-select: none;
        `}
    >
        {tag}
    </div>
);

const EventTagFilter = ({ filters, setFilter }) => {
    const tagButtons = [
        eventTags.ONE_CREDIT,
        eventTags.SOCIAL,
        eventTags.GAME,
        eventTags.MASTERCLASS,
        eventTags.FUN,
        eventTags.DELIVERY,
        eventTags.QUARTERLY,
    ];

    const [modalOpen, toggleModal] = useState(false);

    const open = (e) => {
        e.stopPropagation();
        toggleModal(true);
    };

    const close = (e) => {
        // This e is required for a (monumentally stupid)
        // reason: because react-modal uses Portals to render
        // content, any click events utilized inside the modal
        // (e.g., clicking on the overlay to close it)
        // end up propagating to the app itself, including the
        // currently-active component... which, by definition,
        // is the button that triggers opening the modal.
        // So, basically, closing the modal causes the modal
        // to reopen. Brilliant.
        e.stopPropagation();
        toggleModal(false);
    };

    const toggleTag = (option) => () => {
        let { tag } = filters;
        if (filters.tag.includes(option)) {
            tag = tag.filter((tg) => tg !== option);
        } else {
            tag.push(option);
        }
        setFilter({ ...filters, tag });
    };

    const clearTags = () => {
        setFilter({ ...filters, tag: [] });
    };

    const setPrice = (price) => {
        setFilter((prev) => ({
            ...prev,
            price,
        }));
    };

    return (
        <div
            className={css`
                display: flex;
                flex-wrap: wrap;
                align-items: baseline;
                & > * {
                    margin-right: 12px;
                    margin-bottom: 10px;
                }
            `}
        >
            <Badge color="primary" badgeContent={filters.tag.length || null}>
                <div
                    onClick={open}
                    className={css`
                        background: white;
                        padding: 0.5em 1em;
                        border-radius: 300px;
                        border: 1px solid grey;
                        cursor: pointer;
                        transition: all 0.2s ease;
                        user-select: none;
                        display: flex;
                        align-items: center;
                    `}
                >
                    <TuneIcon fontSize="inherit" />
                    <span
                        className={css`
                            margin-left: 5px;
                        `}
                    >
                        {i18n.filters}
                    </span>
                    <EventFilterModal
                        isOpen={modalOpen}
                        onClose={close}
                        filters={filters}
                        toggleTag={toggleTag}
                        setPrice={setPrice}
                        clear={clearTags}
                    />
                </div>
            </Badge>
            {tagButtons.map((tag) => (
                <TagFilterButton tag={tag} onClick={toggleTag(tag)} active={filters.tag.includes(tag)} key={tag} />
            ))}
        </div>
    );
};

export default EventTagFilter;
