const exampleData = [
    {
      packageName: "Basic Pack",
      numPackages: 70,
      dateOrdered: "04/21/2021",
      dateFulfilled: "09/10/2021",
      isFulfilled: false,
      totalCost: "$4130.00",
      totalSavings: "$560.00",
      invoiceLink: "http://dummyimage.com/136x100.png/dddddd/000000",
      trackingHistory: [
        {
          firstName: "Kaz",
          lastName: "Brekker",
          email: "kaz@ketter.com",
          phoneNumber: "(123) 456-7890",
          orderStatus: "Delivered",
          trackingNumber: "111111",
          trackingLink: "wfhomie.com",
        },
        {
          firstName: "Inej",
          lastName: "Ghafa",
          email: "inej@ketter.com",
          phoneNumber: "(123) 456-7890",
          orderStatus: "Delivered",
          trackingNumber: "111111",
          trackingLink: "wfhomie.com",
        },
        {
          firstName: "Nina",
          lastName: "Zenik",
          email: "nina@ketter.com",
          phoneNumber: "(123) 456-7890",
          orderStatus: "Delivered",
          trackingNumber: "111111",
          trackingLink: "wfhomie.com",
        },
      ],
      managerID: "670e51f5-194e-4aa0-b37c-ca9e9ca63526",
      itemsOrdered: [{name: "Black T-Shirt"}, {name: "Baseball Cap"}],
    },
    {
      packageName: "Let's Eat! Pack",
      numPackages: 26,
      dateOrdered: "04/17/2021",
      dateFulfilled: "01/19/2021",
      isFulfilled: false,
      totalCost: "$1073.80",
      totalSavings: "$130.00",
      invoiceLink: "http://dummyimage.com/150x100.png/dddddd/000000",
      trackingHistory: [
        {
          firstName: "Inej",
          lastName: "Ghafa",
          email: "inej@ketter.com",
          phoneNumber: "(123) 456-7890",
          orderStatus: "Delivered",
          trackingNumber: "111111",
          trackingLink: "wfhomie.com",
        },
      ],
      managerID: "02a8904f-6c11-4f14-bd06-9533bd4f128b",
      itemsOrdered: [{name: "Personalized Tin and Jelly Beans"}],
    },
    {
        packageName: "VIP Pack",
        numPackages: 22,
        dateOrdered: "04/10/2021",
        dateFulfilled: "05/19/2021",
        isFulfilled: true,
        totalCost: "$3894.00",
        totalSavings: "$110.00",
        invoiceLink: "http://dummyimage.com/150x100.png/dddddd/000000",
        trackingHistory: [
          {
            firstName: "Inej",
            lastName: "Ghafa",
            email: "inej@ketter.com",
            phoneNumber: "(123) 456-7890",
            orderStatus: "Delivered",
            trackingNumber: "111111",
            trackingLink: "wfhomie.com",
          },
          {
            firstName: "Nina",
            lastName: "Zenik",
            email: "nina@ketter.com",
            phoneNumber: "(123) 456-7890",
            orderStatus: "Delivered",
            trackingNumber: "111111",
            trackingLink: "wfhomie.com",
          },
        ],
        managerID: "02a8904f-6c11-4f14-bd06-9533bd4f128b",
        itemsOrdered: [{name: "Black 1/2 Zip Sweater"}, {name: "Wine Bottle"}, {name: "Branded Succulent"},],
      },
  ];
  
  const dummyStats = [
    { title: "Packages Purchased", value: 118, units: "" },
    { title: "Total Savings", value: 800, units: "$" },
    { title: "Happy Employees", value: 22, units: "" },
  ];

  
  export {dummyStats, exampleData}