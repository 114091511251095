import { generateInvite } from "util/database/invites";

async function inviteUsers(firebase, firestore, userInfo, addresses) {
    const { company } = userInfo;

    const code = await generateInvite(firestore, company.id, "employee");
    const invitationLink = `${window.location.origin}/signup?code=${code}&company=${encodeURIComponent(company.name)}`;

    await firebase.functions().httpsCallable("sendInvitationEmail")({
        emails: addresses,
        companyName: company.name,
        invitationLink: invitationLink,
    });

    for (let address of addresses) {
        await firestore.collection("invitedUsers").add({
            company: company,
            email: address,
            code: code,
        });
    }
}

export { inviteUsers };
