import { mergeClasses } from "util/classes";

export const StandardButton = (props) => {
    const { className, children, theme, ...restProps } = props;

    return (
        <button className={mergeClasses("px-4 py-2 text-base font-bold border", className)} {...restProps}>
            {children}
        </button>
    );
};


export const Button = (props) => {
    const { theme, shape, className, ...restProps } = props;

    let classes = className;

    if (shape === "rounded") {
        classes = mergeClasses(classes, "rounded-md");
    } else {
        classes = mergeClasses(classes, "rounded-full");
    }

    if (theme === "light") {
        classes = mergeClasses(classes, "text-primary bg-transparent border-primary");
    } else if (theme === "white") {
        classes = mergeClasses(classes, "text-primary bg-white");
    } else {
        classes = mergeClasses(classes, "text-white bg-primary");
    }

    return <StandardButton {...restProps} className={classes} />;
};
