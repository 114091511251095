import React, { useState, useEffect } from 'react';
import { css } from '@emotion/css';

import { useParams } from 'react-router-dom';
import { useFirestore } from 'react-redux-firebase';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { loadCompany } from 'util/database/companies';

import LoadingIcon from 'components/util/LoadingIcon';

import CompanySettings from 'routes/Settings/CompanySettings';
import CompanyMembers from 'routes/Dashboard/CompanyMembers';
import Summary from 'routes/Dashboard/Summary';
import GiftDB from './GiftDB';
import CompanyPermissionManager from './CompanyPermissionManager';
import TeamManagement from './TeamManagement';
import Localization from './Localization';

const TABS = ['profile', 'members', 'bookings', 'gift', 'permission','team', "localization"];

function CompanyManager() {
    const {id} = useParams();
    const firestore = useFirestore();

    const [company, setCompany] = useState(null);
    const [tab, setTab] = useState(0);

    useEffect(() => {if (company === null) {
        loadCompany(firestore, id).then(setCompany);
    }}, [firestore, id, company]);

    if (company === null) {return <LoadingIcon/>;}

    let content = null;
    switch(TABS[tab]) {
        case 'profile':
            content = <CompanySettings companyInfo={company} sysadmin/>;
            break;

        case 'members':
            content = <CompanyMembers companyInfo={company}/>;
            break;

        case 'bookings':
            content = <Summary companyInfo={company}/>;
            break;

        case 'gift':
            content = <GiftDB companyInfo={company}/>;
            break;

        case 'permission':
            content = <CompanyPermissionManager companyInfo={company}/>
            break;

        case 'team':
            content = <TeamManagement companyInfo={company}/>
            break;

        case "localization":
            content = <Localization companyInfo={company}/>
            break;

        default:
            content = 'tab machine broke';
            break;
    }

    return <>
        <h1 className={css`
            font-size: 24px;
            font-weight: 500;
            color: hsl(235, 10%, 15%);
            margin: 0;

        `}>Manage {company.name}</h1>
        <div className={css`
            width: 100%;
            margin: 30px auto;
        `}>
            <Paper square>
            <Tabs
                value={tab}
                onChange={(e, v) => setTab(v)}
                variant='fullWidth'
            >
                {TABS.map(
                    (name) => <Tab key={name} label={name}/>
                )}
            </Tabs>
            </Paper>
            {content}
        </div>
    </>;
}

export default CompanyManager;
