import React, { useState, useEffect } from 'react';
import { css } from '@emotion/css';

import { useFirebase, useFirestore } from 'react-redux-firebase';

import { loadClasses, addEntry, updateEntry } from 'util/database/classes';
import { createZoomMeeting } from 'util/api/zoom';
import { change } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';

import Checkbox from '@material-ui/core/Checkbox';
import LoadingIcon from 'components/util/LoadingIcon';
import Classes from 'components/Dashboard/Classes';
import { ClassAutofillForm, ClassEntryForm } from 'components/Dashboard/Sysadmin/Forms/ClassEntryForms';
import { withToastNotification } from 'components/hocs/withToastNotification';
import i18n from 'util/i18n';

function ClassDB({ toastNotification }) {
    const firebase = useFirebase();
    const firestore = useFirestore();
    const [classes, setClasses] = useState([]);
    const [loading, setLoading] = useState(true);
    const numericalFields = ['duration', "price"];

    const dispatch = useDispatch();
    const autofillForm = useSelector((state) => state.form.autofillForm);
    const classesForm = useSelector((state) => state.form.classDBForm);
    const [isEdit, setIsEdit] = useState(false);
    const [generateLink, setGenerateLink] = useState(true);

    useEffect(() => {
        setLoading(false);
        loadClasses(firestore).then(setClasses);
        return () => { setClasses([]); }
    }, [firestore]);


    const saveEntry = async (firestore, entry) => {
        if (generateLink) {
            entry.link = await createZoomMeeting(firebase, entry);
        }
        await addEntry(firestore, entry);
    }

    const handleAutofill = async () => {
        const id = autofillForm.values.class.split(' · ')[1];
        setIsEdit(false);
        await classAutoFill(id, false);
    };

    const classAutoFill = (id, fillLink) => {
        const info = classes.find(c => (c.id === id));

        for (let [key, value] of Object.entries(info)) {
            if ((key !== "link" || fillLink) && key !== "googleCalendarEventInfo") {
                dispatch(change('classDBForm', key, value));
            }
        }
    };

    const normalize = (entries) => {
        const output = { ...entries };
        for (let field of numericalFields) {
            if (output[field]) {
                output[field] = Number(output[field]);
            }
        }
        return output;
    }

    const handleSubmit = async () => {
        const info = normalize(classesForm.values);

        info.dateTime = new Date(info.dateTime.getTime() - (info.dateTime.getTimezoneOffset() * 60000)).toISOString().substring(0, 16);

        info.tags = (info.tags && !Array.isArray(info.tags)) ? info.tags.split(',') : [];

        if (!info.image) {
            info["image"] = "";
        }

        if (!generateLink && info.link === "") {
            toastNotification(i18n.addLinkZoom, 'error');
        } else {
            if (isEdit) {
                await updateEntry(firestore, info);
            } else {
                await saveEntry(firestore, info);
            }
            window.location.reload();
        }
    };

    const handleClassEdit = async (entryID) => {
        setIsEdit(true);
        setGenerateLink(false)
        window.scrollTo(0, 0);
        await classAutoFill(entryID, true);
    }

    const toggleGenerateLink = () => {
        setGenerateLink(generateLink => !generateLink);
    }

    const checkboxComponent = <div className={css`display: flex; align-items: center;`}>
        <Checkbox checked={generateLink && !isEdit} onClick={toggleGenerateLink} disabled={isEdit} />
        <div>Enable Zoom Link Generation (uncheck to manually enter link)</div>
    </div>


    return loading ? <LoadingIcon /> :
        <>
            <div className={css`width: 480px; margin: auto`}>
                <ClassAutofillForm classes={classes} onSubmit={handleAutofill} />
                <ClassEntryForm isEdit={isEdit} onSubmit={handleSubmit} generateLink={generateLink} checkboxComponent={checkboxComponent} />
            </div>
            <Classes classes={classes} onEdit={handleClassEdit} sysadmin />
        </>
}

export default withToastNotification(ClassDB);
