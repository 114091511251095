import React, {} from "react";
import {css} from "@emotion/css";

import {connect} from 'react-redux';
import {Field, formValueSelector, reduxForm} from 'redux-form';


import FormSection from 'components/Forms/FormSection';
import {
    renderDropdown, renderMultiAutocomplete,
    renderNumberField,
} from "components/Forms";

import LoadingIcon from 'components/util/LoadingIcon';
import i18n from "util/i18n";


let SettingsForm = ({ channels, users, isPremium }) => {
    if (!users) {
        return <LoadingIcon />;
    }

    return (
        <>
           {isPremium &&  <FormSection columns={2} label={i18n.usageRestrictions} description={i18n.allowKudosCommandInChannel}>
                {channels ? null : i18n.weAreStillGettingTheChannelList}
                <Field
                    name="allowedChannels"
                    component={renderMultiAutocomplete}
                    getOptionLabel={c => c.name}
                    options={channels}
                    multiple={true}
                    labels={'channels'}
                />
            </FormSection>}
           <FormSection
                columns={1}
                label={i18n.kudosAnnouncements}
                description={
                    <>
                        <img
                            src="/static/images/kudos-message.png"
                            className={css`
                                width: 100%;
                                max-width: 400px;
                                margin-bottom: 16px;
                            `}
                            alt="kudos announcement"
                        />
                        <span>{i18n.publishKudosMsgToThisChannel}</span>
                    </>
                }
            >
                <Field
                    name="announcementChannel"
                    component={renderDropdown}
                    menuItems={Object.fromEntries([
                        ["same", "Same channel as /kudos command"],
                        ...channels.map((data) => [data.id, `#${data.name}`]),
                    ])}
                />
            </FormSection>

            {isPremium &&  <FormSection
                columns={1}
                label={i18n.allowedUsers}
                description={
                    <>
                        <span>{i18n.chooseWhichUserAllowToUseBot}</span>
                    </>
                }
            >
                <Field
                    name="allowedUsers"
                    component={renderMultiAutocomplete}
                    getOptionLabel={u => u.profile?.email || u.name}
                    options={users}
                    multiple={true}
                    labels={'users'}
                />
            </FormSection> }

            {isPremium &&
            <FormSection columns={1} label={i18n.kudosLimit} description={i18n.kudosLimitDescription}>
                <Field
                    name="kudosLimit"
                    component={renderNumberField}
                    label={i18n.kudosLimit}
                    unit="gifts received"
                    min={1}
                />
            </FormSection>}
        </>
    );
};

SettingsForm = reduxForm({
    form: "kudosSettingsForm",
})(SettingsForm);

const selector = formValueSelector("kudosSettingsForm");

export const KudosSettingsForm = connect((state) => {
    const planValue = selector(state, "plan");
    return {
        planValue,
    };
})(SettingsForm);
