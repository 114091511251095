// Add voting form to Firestore
async function addVotingForm(firestore, inviterName, user, companyID, data) {
    // Get company ref
    const companyRef = firestore.collection('companies').doc(companyID);

    // Add voting form to Firestore
    await firestore.collection('voteInviteForms').add({
        // Add form metadata
        inviterName: inviterName,
        inviterUID: user.uid,
        email: user.email,
        timeCreated: Date.now(),
        company: companyRef,

        // Input data
        ...data,
    });
}

export { addVotingForm };
