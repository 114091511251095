const checkTexts = ["Free Forever", "Unlimited Seats", "No Credit Card Required", "Easy Setup"];

const Checkmark = ({ text }) => (
    <div className="flex space-x-1 items-center mr-2">
        <span>✔️</span>
        <span>{text}</span>
    </div>
);

export const Checkmarks = () => {
    return (
        <div className="flex flex-wrap">
            {checkTexts.map((text) => (
                <Checkmark text={text} key={text} />
            ))}
        </div>
    );
};
