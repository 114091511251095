import React from 'react';
import {css} from '@emotion/css';
import {Button, Grid} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom'
import Header from 'components/Header';
import i18n from "../util/i18n";

function Landing() {
    const userinfo = useSelector(state => state.userinfo);

    if (userinfo) {
        return <Redirect to='/dashboard'/>
    }

    return <main className={css`background: #fff; min-height: calc(100vh - 15px);`}><Header/>
        <Grid container item justifyContent="center" alignItems="center">
            <img alt='landing group' src='../static/images/group.jpg'
                 className={css`width: 50%; max-width: 630px; margin-top: 120px;`}/>
        </Grid>
        <Grid container item xs={12} justifyContent="center" alignItems="center">
            <img src='/static/images/logo.png'
                 className={css`height: 30px; margin: 40px; margin-bottom: 20px;`}
                 style={{cursor: "pointer"}}
                 alt='wfhomie logo'
            />
        </Grid>
        <Grid container item xs={12} justifyContent="center" alignItems="center">
            <h1 className={css`
            color: hsl(235, 10%, 20%);
            font-size: 24px;
            text-align: center;
            max-width: 600px;
        `}>{i18n.congrats}</h1>

            <Grid container item xs={12} justifyContent="center" alignItems="center">
                <p className={css`font-size: 16px;
                                   color: #777777;`}>
                    {i18n.weAreHereForYou}
                </p>
            </Grid>
            <p className={css`
                            color: hsl(235, 10%, 30%);
                            font-size: 30px;
                            margin: 0;
                            margin-top: 20px;
        `}>
                <Button variant="contained"
                        href="/signup"
                        className={css`
                                font-size: 20px;
                                font-weight: bold;
                                width: 230px;
                                height: 56px;
                                border-radius: 28px;
                                color: white;
                                background-color: #052aff;`}>
                    {i18n.getStarted}
                </Button>
            </p>
        </Grid></main>;
}

export default Landing;
