import Mixpanel from 'util/analytics/mixpanel';
import {eventProperties, mixpanelEvents} from 'util/constants/mixpanel';
import {v4 as uuidv4} from 'uuid';
import {generateInvite} from './invites';

// Load data from all companies. (Requires sysadmin auth)
async function loadCompanies(firestore) {
    console.log('Getting company list.');

    const collection = await firestore.collection('companies').get();

    const companies = {};
    for (let company of collection.docs) {
        companies[company.id] = company.data();
    }

    return companies;
}

async function loadCompany(firestore, companyID) {
    const doc = await firestore.collection('companies').doc(companyID).get();
    const data = doc.data();
    data.id = doc.id;
    return data;
}


async function createCompany(firestore, data) {
    console.log('Creating company:', data.name);

    const companyId = uuidv4();

    // Create invite (must be done first!)
    const inviteCode = await generateInvite(firestore, companyId, 'superuser');

    // Create company
    const companyRef = firestore.collection('companies').doc(companyId);
    await companyRef.set(data);

    Mixpanel.track(
        mixpanelEvents.COMPANY_CREATED,
        {
            [eventProperties.COMPANY_ID]: companyId,
            [eventProperties.COMPANY_NAME]: data?.name
        }
    );  

    return {
        inviteCode,
        companyRef,
    };
}

async function loadKudosCompany(firestore) {
    const result = [];
    const docs = await firestore.collection('companies').where('source', '==', 'kudos')
        .orderBy('timeCreated','asc').limit(100).get();
    docs.forEach(doc => {
        const data = doc.data();
        data.id = doc.id;
        result.push(data);
    });
    console.log(result);
    return result;
}

// Load list of all subscription plans.
async function loadSubscriptions(firestore) {
    console.log('Loading subscription plans.');

    const collection = await firestore.collection('subscriptionPlans').get();

    const subscriptions = {};
    for (let entry of collection.docs) {
        subscriptions[entry.id] = entry.data();
    }

    return subscriptions;
}

export {loadCompanies, loadCompany, createCompany, loadKudosCompany, loadSubscriptions};
