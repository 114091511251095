import React from 'react';

import { Field } from 'redux-form';

import { renderDatePicker } from 'components/Forms';
import FormSection from 'components/Forms/FormSection';
import i18n from "util/i18n";

const WorkStartDate = () => (
    <FormSection
        label={i18n.workStartDate}
        description={i18n.workStartDateDesc}
    >
        <Field
            label={i18n.workLabel}
            name='workStartDate'
            component={renderDatePicker}
        />
    </FormSection>
);

export default WorkStartDate;
