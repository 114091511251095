const logos = [
    {
        name: "ClearCo",
        imageUrl: "/static/images/logos/clearco.png",
    },
    {
        name: "Facebook",
        imageUrl: "/static/images/logos/facebook.png",
    },
    {
        name: "Google",
        imageUrl: "/static/images/logos/google.png",
    },
    {
        name: "Lane",
        imageUrl: "/static/images/logos/lane.png",
    },
    {
        name: "TD Bank",
        imageUrl: "/static/images/logos/tdbank.png",
    },
    {
        name: "BCG",
        imageUrl: "/static/images/logos/bcg.png",
    },
];

const CustomerLogo = ({ name, imageUrl }) => (
    <div className="flex items-center justify-center">
        <img src={imageUrl} alt={name} />
    </div>
);

export const Logos = () => {
    return (
        <>
            <div>
                <span className="text-kudos-grey font-bold">Teams at these companies trust WFHomie</span>
            </div>

            <div className="grid grid-cols-3 gap-4 gap-y-6 w-full">
                {logos.map((l) => (
                    <CustomerLogo {...l} key={l.imageUrl} />
                ))}
            </div>
        </>
    );
};
