import { useState } from 'react';
import { css } from "@emotion/css";
import UploadIcon from "@material-ui/icons/PhotoLibraryOutlined";
import Grid from "@material-ui/core/Grid";
import { handleFileUpload } from "util/storage";
import i18n from "../../../util/i18n";


const UploadCompanyLogo = ({ logo, directory, input }) => {
    const [logoPreview, setLogoPreview] = useState(logo);

    const onChange = async (e) => {
        const {
            target: {
                files: [upload],
            },
        } = e;
        if (!upload) {
            return;
        }
        const url = await handleFileUpload(upload, directory);
        setLogoPreview(url);
        return input.onChange(url);
    };

    return (
        <Grid
            container
            direction="column"
            className={css`
          box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.45);
          padding: 1em;
          margin: 0 auto;
          margin-bottom: 28px;
        `}
        >
            <Grid
                item
                className={css`
            height: 10vw;
            width: 100%;
            background: no-repeat center/contain url("${logoPreview}");
          `}
            />
            <Grid item container justifyContent="flex-end" alignItems="baseline">
                <label
                    htmlFor="image-upload"
                    className={css`
              cursor: pointer;
              opacity: 0.3;
              transition: opacity 0.2s ease-in-out;

              &:hover {
                opacity: 1;
              }
            `}
                >
                    <UploadIcon />
                </label>
                <input
                    type="file"
                    id="image-upload"
                    accept="image/*"
                    onChange={onChange}
                    className={css`
              display: none;
            `}
                />{" "}
                <span>{logo === logoPreview ? i18n.uploadANewLogo : i18n.preview}</span>
            </Grid>
        </Grid>
    );
};

export default UploadCompanyLogo;
