async function loadAnswers(firestore) {
    const collection = await firestore.collection('answers')
        .orderBy("date", "desc")
        .get()   
    const answers = {};

    for (let event of collection.docs) {
        answers[event.id] = event.data();
    }
    return answers
};

export {loadAnswers}