import { reduxForm, Field } from "redux-form";
import { add, formatISO } from "date-fns";
import FormSection from "components/Forms/FormSection";
import Grid from "@material-ui/core/Grid";
import { renderDateTimePicker, SubmitButton } from "..";
import { useSelector } from "react-redux";
import AddToCalendarButton from "components/util/AddToCalendarButton";
import { css } from "@emotion/css";
import UserSearch from "components/Forms/UserSearch";
import { useEffect, useState } from "react";
import { loadMembers } from "util/database/users";
import { useFirestore } from "react-redux-firebase";
import i18n from "../../../util/i18n";

// get a Date representing the current time,
// optionally plus a given interval.
const now = (to_add) => {
  if (to_add === undefined) {
    return new Date();
  }
  return add(new Date(), to_add);
};

// formatter for date picker
const iformat = (dt) => formatISO(dt).substring(0, 16);

const ScheduleGameForm = ({
  event,
  onSubmit,
  handleSubmit,
  submitting,
  submitSucceeded,
  initialize,
}) => {
  const firestore = useFirestore();
  const userInfo = useSelector((state) => state.userinfo);
  const form = useSelector((state) => state.form.scheduleGame);

  const [team, setTeam] = useState(null);

  useEffect(() => {
    if (team === null && userInfo) {
      loadMembers(firestore, userInfo.company).then((team) => {
        const filteredTeam = Object.entries(team).filter(
          ([_, member]) => member.email !== userInfo.email
        );
        setTeam(Object.fromEntries(filteredTeam));
      });
    }
  }, [team, firestore, userInfo]);

  const { values } = form ?? {};

  useEffect(() => {
    if (!values) {
      // redux REALLY doesn't like
      // non-string datatypes, so this
      // is the best way to handle it.
      initialize({ date: now(), invited: [] });
    }
  }, [values, initialize]);

  const startTime = values?.date ? new Date(values.date) : now();

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={css`
        overflow-x: hidden;
      `}
    >
      <FormSection label={i18n.dateAndTime} columns={1}>
        <Field
          name="date"
          component={renderDateTimePicker}
          inputProps={{
            value: iformat(startTime),
          }}
        />
      </FormSection>
      <FormSection label={i18n.attendees} columns={1}>
        <Field
          name="invited"
          component={UserSearch}
          label={i18n.inviteTheWholeTeam}
          data={team}
          value={values?.invited || []}
          disabled={submitSucceeded}
        />
      </FormSection>
      <Grid
        container
        className={css`
          margin-bottom: 15px;
        `}
      >
        <Grid item xs>
          {submitSucceeded && event ? (
            <AddToCalendarButton event={event} />
          ) : (
            <SubmitButton label={i18n.scheduleGame} disabled={submitting} />
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default reduxForm({ form: "scheduleGame" })(ScheduleGameForm);
