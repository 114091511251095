import React from 'react';
import { css } from '@emotion/css';

import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import PlatformIcon from '@material-ui/icons/PersonalVideo';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { generateTimeString, generateLongAudienceSize } from './EventInfo';
import i18n from "util/i18n";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'left',
      color: theme.palette.text.secondary,
      marginLeft: 15,
    },
  }));



function Entry({icon, label, value}) {
    const IconComponent = icon;

    return <div className={css`
        display: flex;
        flex-direction: row;
        align-items: center;
    `}>
        <IconComponent style={{
            color: '#052aff', fontSize: 40
        }}/>
        <div className={css`
            margin-left: 20px;
            font-size: 12px;
            letter-spacing: 0.02em;
            color: hsl(230, 10%, 40%);
        `}>
            {label}
            <p className={css`
                color: hsl(230, 10%, 20%);
                font-size: 16px;
                font-weight: 500;
                margin: 0;
                text-align: left;
            `}>{value}</p>
        </div>
    </div>;
}

function FormRow({data}) {
    const classes = useStyles();
    return (

    <React.Fragment>
        <Grid item sm={6}>
            <div className={classes.paper}>
                <Entry
                    icon={ScheduleOutlinedIcon}
                    value = {generateTimeString(data.duration)}
                />
            </div>
        </Grid>
        <Grid item sm={6}>
            <div className={classes.paper}>
                    <Entry
                        icon={PeopleAltOutlinedIcon}
                        value={generateLongAudienceSize(data.suggestedAudienceSize, data.themes)}
                    />
            </div>
        </Grid>
        <Grid item sm={6}>
            <div className={classes.paper}>
                    <Entry
                        icon={PlatformIcon}
                        value={i18n.joinRemotely}
                    />
            </div>
        </Grid>
        <Grid item sm={6}>
            <div className={classes.paper}>
                <Entry
                    icon={data.physicalPackage ? CardGiftcardIcon : LaptopMacIcon}
                    value={data.physicalPackage ? i18n.includePhysicalKit: i18n.virtualOnly}
                />
            </div>
        </Grid>


    </React.Fragment>
    );
}

function Overview({data}) {
    const classes = useStyles();

    return <div>
        <Grid item xs={12}>
            <h2 className={css`
                margin: 10;
                font-weight: bold;
                font-size: 20px;
                color: hsl(230, 10%, 15%);
                text-align:left;
                box-shadow:none;
            `}>
                {i18n.activityDetails}
            </h2>
        </Grid>

        <div className={classes.root}>
        <Grid container spacing={1}>
            <Grid container item xs={12} spacing={3}>
                <FormRow data={data}/>
            </Grid>
        </Grid>
        </div>
    </div>
}

export default Overview;
