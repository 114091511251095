import React from "react";
import { css } from "@emotion/css";

import { Switch, Route, Redirect } from "react-router-dom";

import { useSelector } from "react-redux";

import { mq } from "components/util/Breakpoints";
import { hasAccess, trialCheck } from "util/permission/haspermissions";
import { managerPermissions, permissions, specialRoles } from "util/permission/permissions";

import Companies from "./Companies";
import CompanyManager from "./CompanyManager";
import Users from "./Users";
import CompanyMembers from "./CompanyMembers";
import Event from "./Event";
import EventPolls from "./EventPolling";
import ClassDB from "./ClassDB";
import Summary from "./Summary";
import Invoices from "./Invoices";

import UserSettings from "routes/Settings/UserSettings";
import CompanySettings from "routes/Settings/CompanySettings";

import Home from "routes/Dashboard/SidebarLinks/Home";
import Classes from "routes/Dashboard/SidebarLinks/Classes";
import Gifting from "routes/Dashboard/SidebarLinks/Gifting";
import Activities from "routes/Dashboard/SidebarLinks/Activities";
import Events from "routes/Dashboard/SidebarLinks/Events";

import Header from "components/Header";
import Sidebar from "components/Sidebar";
import TrialBanner from "components/Header/TrialBanner";
import Announcement from "components/Header/Announcement";
import KudosSettings from "routes/Settings/KudosSettings/KudosSettings";
import GiftPackagesDB from "routes/Dashboard/GiftPackagesDB";
import QuestionsDB from "routes/Dashboard/QuestionsDB";
import AnswersDB from "routes/Dashboard/AnswersDB";
import EventDB from "./EventDB";
import CenteredLoadingIcon from "components/util/CenteredLoadingIcon";

import { getReplaceClassWithHome } from "util/localization";
import i18n from "util/i18n";

function PageWrapper({ children }) {
    return (
        <>
            <Header hideSignIn />
            <div
                className={css`
                    display: flex;
                    height: 100vh;
                    ${mq[2]} {
                        flex-direction: column;
                    }
                    ${mq[3]} {
                        flex-direction: row;
                    }
                `}
            >
                {children}
            </div>
        </>
    );
}

function Dashboard() {
    const authData = useSelector(state => state.firebase.auth);
    const userinfo = useSelector((state) => state.userinfo);

    const { isTrial, trialDaysLeft } = trialCheck(userinfo);
    // Get role (special role for unauthed users)
    const notLoggedIn = authData?.isLoaded && authData?.isEmpty;

    function AuthorizedAccess({ isAllowed, children }) {
        if (isAllowed) {
            return children;
        }

        if (notLoggedIn) {
            console.log("redirect");
            return <Redirect to={`/login?redirect=${window.location.pathname}`} />;
        }

        if (!userinfo) {
            return children;
        }

        return <Redirect to="/dashboard/home" />;
    }

    // if authData is undefined, we haven't loaded it yet; show loading indicator to prevent weird looking rerender,
    // if authData is defined (user is logged in), show loading icon while fetching user info
    return authData.isEmpty && !notLoggedIn ? (
        <PageWrapper>
            <CenteredLoadingIcon />
        </PageWrapper>
    ) : (
        <PageWrapper>
            <Sidebar />
            <main
                className={css`
                    box-sizing: border-box;
                    flex-grow: 9;

                    ${mq[3]} {
                        margin: 80px 0px auto 120px;
                    }
                `}
            >
                {isTrial ? <TrialBanner daysLeft={trialDaysLeft} /> : null}
                {userinfo?.role === specialRoles.PENDING ? <Announcement>{i18n.pendingApproval}</Announcement> : null}
                <div
                    className={css`
                        margin: 30px;
                        margin-left: 30px;
                        ${mq[2]} {
                            margin: 20px;
                            margin-top: 100px;
                        }
                    `}
                >
                    {
                        <>
                            <Switch>
                                <Route exact path="/dashboard/settings">
                                    <UserSettings />
                                </Route>

                                <Route exact path="/dashboard/gifting">
                                    <AuthorizedAccess isAllowed={hasAccess(permissions.GIFTING, userinfo)}>
                                        <Gifting />
                                    </AuthorizedAccess>
                                </Route>

                                <Route exact path="/dashboard/events">
                                    <AuthorizedAccess isAllowed={hasAccess(permissions.EVENTS, userinfo)}>
                                        <Events />
                                    </AuthorizedAccess>
                                </Route>

                                <Route exact path="/dashboard/classes">
                                    <AuthorizedAccess isAllowed={true}>
                                        <Classes />
                                    </AuthorizedAccess>
                                </Route>

                                <Route exact path="/dashboard/activities">
                                    <AuthorizedAccess isAllowed={hasAccess(permissions.ACTIVITIES, userinfo)}>
                                        <Activities />
                                    </AuthorizedAccess>
                                </Route>

                                <Route exact path="/dashboard/settings/company">
                                    <AuthorizedAccess isAllowed={hasAccess(permissions.COMPANY_PROFILE, userinfo)}>
                                        <CompanySettings />
                                    </AuthorizedAccess>
                                </Route>

                                <Route exact path="/dashboard/event/:id">
                                    <AuthorizedAccess isAllowed={hasAccess(permissions.EVENTS, userinfo)}>
                                        <Event />
                                    </AuthorizedAccess>
                                </Route>

                                <Route exact path="/dashboard/companies">
                                    <AuthorizedAccess
                                        isAllowed={hasAccess(managerPermissions.MANAGE_COMPANIES, userinfo)}
                                    >
                                        <Companies />
                                    </AuthorizedAccess>
                                </Route>

                                <Route exact path="/dashboard/companies/:id">
                                    <AuthorizedAccess isAllowed={hasAccess(permissions.MANAGE_TEAM, userinfo)}>
                                        <CompanyManager />
                                    </AuthorizedAccess>
                                </Route>

                                <Route exact path="/dashboard/event-polling">
                                    <AuthorizedAccess isAllowed={hasAccess(managerPermissions.MANAGE_USERS, userinfo)}>
                                        <EventPolls />
                                    </AuthorizedAccess>
                                </Route>

                                <Route exact path="/dashboard/users">
                                    <AuthorizedAccess isAllowed={hasAccess(managerPermissions.MANAGE_USERS, userinfo)}>
                                        <Users />
                                    </AuthorizedAccess>
                                </Route>

                                <Route exact path="/dashboard/members">
                                    <AuthorizedAccess isAllowed={hasAccess(permissions.MANAGE_TEAM, userinfo)}>
                                        <CompanyMembers />
                                    </AuthorizedAccess>
                                </Route>

                                <Route exact path="/dashboard/gift-pack-database">
                                    <AuthorizedAccess isAllowed={hasAccess(permissions.CLIENT_EVENT_DB, userinfo)}>
                                        <GiftPackagesDB />
                                    </AuthorizedAccess>
                                </Route>

                                <Route exact path="/dashboard/class-database">
                                    <AuthorizedAccess isAllowed={hasAccess(permissions.CLIENT_EVENT_DB, userinfo)}>
                                        <ClassDB />
                                    </AuthorizedAccess>
                                </Route>

                                <Route exact path="/dashboard/questions-database">
                                    <AuthorizedAccess isAllowed={hasAccess(permissions.CLIENT_EVENT_DB, userinfo)}>
                                        <QuestionsDB />
                                    </AuthorizedAccess>
                                </Route>

                                <Route exact path="/dashboard/answers-database">
                                    <AuthorizedAccess isAllowed={hasAccess(permissions.CLIENT_EVENT_DB, userinfo)}>
                                        <AnswersDB />
                                    </AuthorizedAccess>
                                </Route>

                                <Route exact path="/dashboard/event-database">
                                    <AuthorizedAccess isAllowed={hasAccess(permissions.CLIENT_EVENT_DB, userinfo)}>
                                        <EventDB />
                                    </AuthorizedAccess>
                                </Route>

                                <Route exact path="/dashboard/summary">
                                    <AuthorizedAccess isAllowed={hasAccess(permissions.ANALYTICS, userinfo)}>
                                        <Summary />
                                    </AuthorizedAccess>
                                </Route>

                                <Route exact path="/dashboard/receipts">
                                    <AuthorizedAccess isAllowed={hasAccess(permissions.COMPANY_PROFILE, userinfo)}>
                                        <Invoices />
                                    </AuthorizedAccess>
                                </Route>

                                <Route exact path="/dashboard/kudos-settings">
                                    <AuthorizedAccess isAllowed={hasAccess(permissions.KUDOS_BOT, userinfo)}>
                                        <KudosSettings />
                                    </AuthorizedAccess>
                                </Route>

                                <Route exact path="/dashboard/games">
                                    <Home />
                                </Route>

                                {getReplaceClassWithHome(userinfo) ? (
                                    <Route path="/dashboard">
                                        <Classes />
                                    </Route>
                                ) : (
                                    <Route path="/dashboard">
                                        <AuthorizedAccess isAllowed={hasAccess(permissions.HOME, userinfo)}>
                                            <Home />
                                        </AuthorizedAccess>
                                    </Route>
                                )}
                            </Switch>
                        </>
                    }
                </div>
            </main>
        </PageWrapper>
    );
}

export default Dashboard;
