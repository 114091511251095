const FilledButton = ({ disabled = false, children, onClick }) => (
    <div
        className="bg-primary text-white text-base font-bold flex text-center justify-center rounded-2xl cursor-pointer w-full p-3 hover:bg-primary-hover"
        disabled={disabled}
        onClick={onClick}
    >
        {children}
    </div>
);

export default FilledButton;
