import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PrivacyCenter = () => {
    const location = useLocation();
    const privacyCenterSubpath = location.pathname.substring(15); // Get fullpath after /privacycenter

    useEffect(() => {
        document.title = "Privacy Center"
    });

    return <iframe
        title="Privacy Center"
        style={{
            position: "fixed",
            border: "none",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            width: "100%",
            height: "100%"
        }}
        src={`https://app.dataships.io/privacycenter/i-TxTN7qbnkhirzjmICbqpe2At5ZxExC${privacyCenterSubpath}`}
    />;
}

export default PrivacyCenter
