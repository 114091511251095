import { mergeClasses } from "util/classes";
import { stage } from "util/stage";
import i18n from "util/i18n";

const LoginButton = (props) => {
    const { className, children, ...restProps } = props;
    return (
        <button
            {...restProps}
            className={mergeClasses(
                "border border-microsoft-grey-border px-4 py-2 flex items-center space-x-4",
                className
            )}
        >
            {children}
        </button>
    );
};

export const MicrosoftButton = (props) => {
    const { children, className, ...restProps } = props;

    return (
        <LoginButton {...restProps} className={mergeClasses("rounded-md border-slack-grey-border", className)}>
            <img src="/static/images/microsoft.svg" alt="Sign in with Microsoft" />
            <span className="text-microsoft-grey">{i18n.signInWithMicrosoft}</span>
        </LoginButton>
    );
};

export const GoogleButton = (props) => {
    const { children, className, ...restProps } = props;

    return (
        <LoginButton {...restProps} className={mergeClasses("rounded-md border-slack-grey-border", className)}>
            <img src="/static/images/google.svg" alt="Sign in with Google" />
            <span className="text-microsoft-grey">{i18n.signInWithGoogleBtn}</span>
        </LoginButton>
    );
};

export const SlackButton = (props) => {
    const { children,className, ...restProps } = props;

    return (
        <a href={buildSlackLoginUrl()}>
            <LoginButton {...restProps} className={mergeClasses("rounded-md border-slack-grey-border", className)}>
                <img src="/static/svg/slack-small.svg" alt="Sign in with Slack" />
                <span className="text-microsoft-grey">{i18n.signInWithSlack}</span>
            </LoginButton>
        </a>
    );
};

const getBotId = () => stage.isProduction() ? '2003043558818.2126946347671' : '2003043558818.2477424615205';

const getSlackRedirectURL = () => stage.isProduction() ?  'https://kudosbot-lkls3azcmq-uc.a.run.app/oauthRedirect' : 'https://kudosbot-vkmnp7z35a-uc.a.run.app/oauthRedirect';

const buildAddToSlackUrl = () =>  {
    const botId = getBotId();
    return `https://slack.com/oauth/v2/authorize?client_id=${botId}&state=new-company&scope=chat:write,commands,users:read,users:read.email,channels:read,chat:write.public,channels:manage`;
}

const buildSlackLoginUrl = () =>  {
    const botId = getBotId();
    const uriSafeRedirectURL = encodeURIComponent(getSlackRedirectURL())
    return `https://slack.com/openid/connect/authorize?scope=openid%20email%20profile&response_type=code&redirect_uri=${uriSafeRedirectURL}&client_id=${botId}`;
}



export const SlackButtonDark = (props) => {
    const { className, children, ...restProps } = props;

    return (
        <a href={buildAddToSlackUrl()}>
            <LoginButton {...restProps} className="rounded-md bg-slack-purple border-none h-12">
                <img src="/static/svg/slack-small.svg" alt="Sign in with Slack" />
                <span className="text-white font-bold">Add to Slack - free!</span>
            </LoginButton>
        </a>
    );
};

export const ShameButtonDark = (props) => {
    const { className, children, ...restProps } = props;

    return (
        <a href={'https://slack.com/oauth/v2/authorize?client_id=2003043558818.3309676577730&scope=channels:read,commands,users:read,channels:manage,chat:write,chat:write.public,users:read.email&user_scope=&state=none'}>
            <LoginButton {...restProps} className="rounded-md bg-slack-purple border-none h-12">
                <img src="/static/svg/slack-small.svg" alt="Sign in with Slack" />
                <span className="text-white font-bold">Add to Slack - free!</span>
            </LoginButton>
        </a>
    );
};
