import React, { useState } from 'react';
import { css } from '@emotion/css';

function EventPoll({company, poll, onClick}) {

    const events = []
    const [companyName, setCompanyName] = useState(null)
    const utcSeconds = poll.date?.seconds;
    const date = new Date(0); // The 0 there is the key, which sets the date to the epoch
    date.setUTCSeconds(utcSeconds)
    const simpleDate = date.toUTCString().split(' ').slice(0, 4).join(' ')

    Object.keys(poll.eventVotes).forEach(function(key) {
        events.push(poll.eventVotes[key].title + ' ---> ' + poll?.eventVotes[key].voters?.length.toString() + ' vote(s).') 
    });

    if (companyName === null) {
        company.get().then(companyData => {
            setCompanyName(companyData.data()?.name)
        })
    }
  
    return <div className={css`
        background-color: white;
        box-shadow: 10px 10px 5px hsla(235, 10%, 10%, 0.3);

        display: flex;
        flex-direction: column;
        justify-content: center;

        border-radius: 30px;
        cursor: pointer;
        overflow: hidden;
    `} onClick={onClick}>
        <div className={css`
            padding: 10px 20px;
            background-color: hsl(235, 10%, 95%);
        `}>
        <p className={css`
            font-size: 25px;
            font-weight: 300;
            color: hsl(235, 10%, 30%);

            text-align: center;
            margin-top: 10;
        `}><h4>{companyName}</h4></p>
        <p className={css`
            font-size: 15px;
            font-weight: 300;
            color: hsl(235, 10%, 30%);

            text-align: center;
            margin: 0;
        `}>{simpleDate || poll.date}</p>
        </div>
        <p className={css`
            font-size: 16px;
            font-weight: 300;
            color: hsl(235, 10%, 30%);

            text-align: center;
            margin-top: 0;
        `}>
            {events.map((title, index) => (
                <li key={index}>
                    { title }
                </li>
            ))}
             </p>
    </div>;
}

export default EventPoll;




