import React, { useState, useRef, useEffect } from "react";
import { css } from "@emotion/css";
import CodeIcon from "@material-ui/icons/Code";
import FileCopyIcon from "@material-ui/icons/FileCopy";

// Allows to upload new csv files and display/remove current ones
const CompanyMembersExport = ({ members }) => {
    // request a weekday along with a long date
    let options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
    let exportTime = new Date().toLocaleString("en-US", options);

    const [CSVName, setCSVName] = useState("WFHomie Data exported " + exportTime + ".csv");
    const [JSONName, setJSONName] = useState("WFHomie Data exported " + exportTime + ".json");

    let csvContent = "data:text/csv;charset=utf-8,%EF%BB%BF";
    let jsonContent = "data:text/json;charset=utf-8,";

    let nonCircularMembers = members;

    useEffect(() => {
        if (members?.length) {
            setCSVName(
                `WFHomie data for: ${Object.values(nonCircularMembers)[0].companyName} exported ${exportTime}.csv`
            );
            setJSONName(
                `WFHomie data for: ${Object.values(nonCircularMembers)[0].companyName} exported ${exportTime}.json`
            );
        }
    }, [nonCircularMembers, exportTime, members?.length]);

    //https://stackoverflow.com/questions/8847766/how-to-convert-json-to-csv-format-and-store-in-a-variable
    const linkDownloadRef = useRef(null);

    if (!members?.length) {
        return <div>No members to export</div>;
    }

    // i know there has to be a better way to do this but we're replacing FS soon anyway
    for (let key of Object.keys(nonCircularMembers)) {
        delete nonCircularMembers[key]["company"];
    }

    function replacer(key, value) {
        if (!value) return "";

        if (typeof value === "object" && value !== null) {
            // Allergies are stored in an object so make these easier to read in a CSV
            return Object.keys(value)
                .sort()
                .filter((item) => value[item])
                .toString();
        }

        return value;
    }

    let headerKeys = [];

    if (Object.keys(nonCircularMembers).length > 0) {
        headerKeys = Object.keys(nonCircularMembers[Object.keys(nonCircularMembers)[0]]).sort();
    } else if (Object.keys(nonCircularMembers).length === 0) {
        headerKeys = [];
    }

    // this is a messy "filter" but organizes the CSV columns in the order we currently need them
    headerKeys = [
        "firstName",
        "lastName",
        "email",
        "phone",
        "address",
        "apartment",
        "deliveryInstructions",
        "shirtSize",
        "birthday",
        "workStartDate",
        "companyName",
        ...headerKeys.filter(
            (item) =>
                ![
                    "firstName",
                    "lastName",
                    "email",
                    "dietaryRestrictions",
                    "phone",
                    "address",
                    "apartment",
                    "deliveryInstructions",
                    "shirtSize",
                    "companyName",
                    "birthday",
                    "workStartDate",
                ].includes(item)
        ),
    ];

    const csv = [
        headerKeys.join(","),
        ...Object.values(nonCircularMembers).map((row) =>
            headerKeys.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(",")
        ),
    ].join("\r\n");

    let encodedUriCSV = csvContent + encodeURIComponent(csv);
    let encodedUriJSON = encodeURI(jsonContent + JSON.stringify(nonCircularMembers, null, 4));

    return (
        <>
            <div
                className={css`
                    display: flex;
                    justify-content: space-between;
                `}
            >
                <a
                    ref={linkDownloadRef}
                    href={encodedUriCSV}
                    download={CSVName}
                    className={css`
                        text-decoration: none;
                    `}
                >
                    <div
                        className={css`
                            color: hsl(230, 10%, 40%);
                            font-size: 16px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;

                            width: 180px;
                            border-radius: 8px;
                            padding: 10px;
                            cursor: pointer;

                            :hover {
                                background-color: hsl(200, 10%, 90%);
                            }
                        `}
                        onClick={linkDownloadRef.click}
                    >
                        <FileCopyIcon />
                        Download CSV
                    </div>
                </a>
                {/* Display list of current files under upload the button */}

                <a
                    ref={linkDownloadRef}
                    href={encodedUriJSON}
                    download={JSONName}
                    className={css`
                        text-decoration: none;
                    `}
                >
                    <div
                        className={css`
                            color: hsl(230, 10%, 40%);
                            font-size: 16px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                            width: 180px;
                            border-radius: 8px;
                            padding: 10px;
                            cursor: pointer;

                            :hover {
                                background-color: hsl(200, 10%, 90%);
                            }
                        `}
                        onClick={linkDownloadRef.click}
                    >
                        <CodeIcon />
                        Download JSON
                    </div>
                </a>
            </div>
        </>
    );
};

export default CompanyMembersExport;
