import { css } from '@emotion/css';

import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import i18n from 'util/i18n';


function SignIn() {
    const history = useHistory();
    const lang = i18n.getLanguage();
    return <>
        <Button
            onClick={() => history.push('/login?lang=' + lang)}
            className={css`
            padding: 8px 15px;
            margin-right: 10px;
        `}>{i18n.signIn}</Button>
        <Button
            onClick={() => history.push('/signup?lang=' + lang)}
            className={css`
            background-color: hsl(235, 100%, 57%);
            padding: 8px 15px;

            :hover {
                background-color: hsl(235, 100%, 50%);
            }
        `}>
            <span className={css`
            font-size: 14px;
            font-weight: 500;
            color: white;
        `}>{i18n.signUp}</span>
        </Button>
    </>;
}

export default SignIn;
