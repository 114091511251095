import Grid from "@material-ui/core/Grid";
import { css } from "@emotion/css";

/**
 * A dumb container that formats auth pages (login & signup)
 * appropriately, with images and responsive CSS.
 */
const AuthPortalContainer = ({ children }) => {
  return (
    <main
      className={css`
        box-sizing: border-box;
        background: #fff url("/static/svg/signup_bg.svg") no-repeat right bottom;
        background-size: cover;
        min-height: 100vh;
        padding: 15px;
        padding-top: 15px;

        @media screen and (min-width: 1280px) {
          padding: 0 30px;
          padding-top: 100px;
          background-size: contain;
        }

        & input {
          border: 1px solid #ccc;
          border-radius: 3px;
          padding: 10px;
          width: 100%;
          margin: 10px 0;
          background: #fff;
        }

        & .MuiFormControl-root {
          width: 100%;

          .MuiInputBase-root::before {
            display: none;
          }
          .MuiInputBase-root::after {
            bottom: 10px;
          }
        }
      `}
    >
      <Grid container>
        <Grid container item xs={12} lg={6}>
          <div
            className={css`
              margin: 0 auto;
              flex-basis: 60ch;
              flex-grow: 0;
              flex-shrink: 1;
            `}
          >
            <img
              src="/static/images/logo.png"
              alt="wfhomie logo"
              className={css`
                height: 24px;
              `}
            />
            {children}
          </div>
        </Grid>
        <Grid container item xs={12} lg={6}>
          <img
            src="/static/images/signin.png"
            alt="wfhomie sign in"
            className={css`
              height: auto;
              max-height: 600px;
              align-self: center;
              margin: 0 auto;

              @media screen and (max-width: 1280px) {
                & {
                  display: none;
                }
              }
            `}
          />
        </Grid>
      </Grid>
    </main>
  );
};

export default AuthPortalContainer;
