// Returns the kudos history for this company
async function loadKudosHistory(integrationRef) {
    const history = (await integrationRef.collection('kudosTransactions').get()).docs;

    const output = {};
    for (let entry of history) {
        output[entry.id] = entry.data();
    }

    return output;
}


// Load list of Slack channels.
async function loadChannels(integrationRef) {
    const channels = (await integrationRef.collection('channels').orderBy("name").get()).docs;
    const output = [];
    for (let channel of channels) {
        output.push({id: channel.id, ...channel.data()});
    }
    return output;
}

async function loadTeam(integrationRef) {
    const team = (await integrationRef.collection("users").orderBy("real_name").get()).docs;
    const output = [];
    for (let user of team) {
        output.push({id:user.id, ...user.data()});
    }
    return output;
}

/**
 * Save integration settings.
 * @param firestore
 * @param companyID the companyId
 * @param settings the kudos settings in this format
 * {
 *      creditLimit: integer
 *      renewalSchedule: <<Integer indicating day of the week or day of the month> <monthly or weekly>>
 *      announcementChannel
 * }
 * @returns {Promise<void>}
 */
async function saveSettings(firestore, companyID, settings) {
    const companyRef = firestore.collection('companies').doc(companyID);
    const currentSettings = (await companyRef.get()).data().kudosIntegrationInfo;
    if (!currentSettings) {
        settings.plan = {type: 'free'};
    } else if(currentSettings.plan) {
        settings.plan = currentSettings.plan;
    }
    await companyRef.set({
        kudosIntegrationInfo: settings
    }, {merge: true});
}

/**
 * upgrade the kudos bot plan
 * @param firebase firebase reference
 * @param stripe stripe reference
 * @param companyId
 * @param userId
 * @param upgradeSettings in this format
 * {
 * plan : {
 *          type: <free/premium>
 *          denomination: <gift card amount for premium>
 *          currency: <USD/CAD>
 *          allowedUsers: an array of user IDs (from slackIntegration.users)
 *      }
 * }
 * @returns {Promise<void>}
 */
async function upgradePlan(firebase, stripe, companyId,userId, upgradeSettings) {
    console.log("upgrading,",companyId,userId,upgradeSettings);
    firebase.functions().httpsCallable('kudosPurchase')(
        {
            data: {
                companyId,
                userId,
                settings: upgradeSettings
            },
            origin: window.location.origin
        })
        .then((result) => {
            stripe.redirectToCheckout({sessionId: result.data.stripeSessionId})
        })
        .catch((err) => {
            console.error("error upgrading plan : )", err)
        });
}

export {loadKudosHistory, loadChannels, loadTeam, saveSettings, upgradePlan};
