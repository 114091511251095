import React, {useState} from 'react';
import { css } from '@emotion/css';
import { Card, CardContent, Grid, Button, ListItemText, Dialog, DialogContent,
        ListItem, List, IconButton } from '@material-ui/core';
import { mq } from "../../util/Breakpoints";
import { useTable, useSortBy } from 'react-table'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import RemoveIcon from '@material-ui/icons/Remove';
import CloseIcon from '@material-ui/icons/Close';
import i18n from "../../../util/i18n";

function Table({ columns, data }) {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable(
      {
        columns,
        data,
        initialState: {
            sortBy: [
                {
                    id: 'firstName',
                    desc: false
                }
            ]
        }
      },
      useSortBy
    )

    // We don't want to render all 2000 rows for this example, so cap
    // it at 20 for this use case
    const firstPageRows = rows.slice(0, 20)

    return (
      <>
        <table {...getTableProps()}
              className={css`width: 100%; text-align: left; max-width:100%; margin-top: 10px;
                              white-space:nowrap; background-color: #F2F2F2; padding: 6px; border-spacing: 0px;`}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} className={css`border-bottom: 1px solid #909090;`}>
                      {<div className={css`display: flex;`}>
                        {column.render('Header')}
                        {column.isSorted
                        ? column.isSortedDesc
                          ? <ArrowDropDownIcon/>
                          : <ArrowDropUpIcon/>
                        : <RemoveIcon/>}
                        </div>}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {firstPageRows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className={css`cursor:pointer; :hover {background-color: lightgrey} `}>
                    {row.cells.map(cell => {
                      return (
                        <td {...cell.getCellProps()} className={css`padding-bottom: 3px;`}>{cell.render('Cell')}</td>
                      )
                    })}
                  </tr>
                )}
            )}
          </tbody>
        </table>
        <br />
      </>
    )
  }

function GenerateCard(elements, completed, openAndSetModalContent) {
        // Needs to be fixed but works temp
        // https://stackoverflow.com/questions/3859239/sort-json-by-date
        function dateSort(a, b) {
            return new Date(a.dateOrdered).getTime() - new Date(b.dateOrdered).getTime();
        }

        const columns = React.useMemo(
            () => [
                  {
                    Header: i18n.firstName,
                    accessor: 'firstName',
                  },
                  {
                    Header: i18n.lastName,
                    accessor: 'lastName',
                  },
                  {
                    Header: i18n.email,
                    accessor: 'email',
                  },
                  {
                    Header: i18n.phoneNumber,
                    accessor: 'phoneNumber',
                  },
                  {
                    Header: i18n.orderStatus,
                    accessor: 'orderStatus',
                  },
                  {
                    Header: i18n.trackingNumber,
                    accessor: 'trackingNumber',
                  },
                  {
                    Header: i18n.trackingLink,
                    accessor: 'trackingLink'
                  }
            ],[])

        return elements.sort(dateSort).map((value, index) =>
        <>
            {completed === value.isFulfilled &&
            <div className={css`background-color: white;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding: 10px 20px;
                                border-bottom: 2px solid #909090;
                                font-style: normal;
                                font-weight: bold;
                                font-size: 20px;
                                line-height: 25px;
                                text-align: center;
                                color: #2300F8;
                                ${mq[4]} {
                                    flex-direction: column;
                                }

                                ${mq[5]} {
                                    flex-direction: row;
                                }

                                `} key={"cw" + value.packageName}>
                <div>
                        <div>
                        <ListItemText className={css`
                                font-style: normal;
                                font-weight: bold;`} key={"dateorderedGOWR"} primary={`${value.packageName}`} secondary={`${i18n.dateOrdered}: ${value.dateOrdered}`}></ListItemText>
                        <span className={css`color:#0022CC; font-weight: 600;`}>{i18n.quantityOrdered}: {value.numPackages}</span>
                    </div>
                </div>
                <div className={css`display: flex; flex-direction: column; justify-content: space-evenly;`}>
                    {
                        value.isFulfilled ?
                            <Button variant="contained" disableElevation
                                    className={css`margin: 5px; background-color: #00B11C; color: white; pointer-events: none; height: 30px; border-radius: 30px;
                                    font-style: normal;
                                    font-weight: bold;`}>
                                {i18n.completed} {value.dateFulfilled}
                            </Button> :
                            <Button variant="contained" disableElevation
                                    className={css`margin: 5px; background-color: #E98458; color: white; pointer-events: none; height: 30px; border-radius: 30px;
                                    
                                    font-style: normal;
                                    font-weight: bold;`}>
                                {i18n.ongoing}
                            </Button>
                    }
                    <Button variant="contained" color="primary" disableElevation
                            className={css`height: 50px; margin: 5px; padding: 7px; box-sizing: content-box; border-radius: 20px; 
                            font-style: normal;
                            font-weight: bold;`}
                            onClick={(e) => openAndSetModalContent(e,
                            <div className={css`margin-top: -40px;`}>
                                    <span className={css`font-size: 36px;font-weight: bold;`}>{value.packageName}</span>
                                        <List className={css`margin-bottom: 16px; color: #0022CC;`}>
                                            <span key="datesGOWR">
                                              <strong>{i18n.dateOrdered}: </strong>{value.dateOrdered} | <strong> {i18n.dateFulfilled}: </strong>{value.dateFulfilled}
                                            </span>
                                        </List>
                                    <hr/>

                                    <span className={css`font-size: 28px; font-weight: bold;`}>{i18n.orderSummery}</span>
                                    <div className={css`display: flex; justify-content: space-between; width: 75%;`}>
                                        <List disablePadding>
                                            <ListItem disableGutters key="statusL">
                                                <span className={css`font-weight: bold; 
                                                                      font-style: normal;
                                                                      font-weight: bold;`}>{i18n.status}:&nbsp;</span>
                                                {completed ? <span className={css`color: #00B11C;`}>{i18n.completed}</span> : <span className={css`color: #E98458;`}>{i18n.inProgress}</span>}
                                            </ListItem>
                                            <ListItem disableGutters key="totalCostL">
                                                <span className={css`font-weight: bold;`}>{i18n.totalCost}:&nbsp;</span>{value.totalCost}
                                            </ListItem>
                                            <ListItem disableGutters key="totalSavingsL">
                                                <span className={css`font-weight: bold;`}>{i18n.totalSaving}:&nbsp;</span>{value.totalSavings}
                                            </ListItem>
                                            <ListItem disableGutters key="invoiceL">
                                                <a className={css`font-weight: bold;`} href={value.invoiceLink}>{i18n.viewInvoice}</a>
                                            </ListItem>
                                        </List>
                                        <div>
                                            <div className={css`font-weight: bold;`}>{i18n.itemsOrdered}</div>
                                            <ul className={css`
                                              margin-top: 0;
                                              column-width: 200px;
                                              `
                                              }>
                                                {value.itemsOrdered.map(item => <li key={"liordered" + item.name}>{item.name}</li>)}
                                            </ul>
                                        </div>
                                    </div>
                                    <hr/>

                                    <span className={css`font-size: 28px; font-weight: bold;`}>{i18n.trackingHistory}</span>
                                    <Table columns={columns} data={value.trackingHistory} />
                            </div>)}>
                        {i18n.viewOrderDetails}
                    </Button>
                </div>
            </div>}
        </>
    );
}

function CardWidget ({exampleData, completed, openAndSetModalContent, title}){
    return <>
        <Card className={css`flex: 1; padding: 10px; border-radius: 30px; border: 2.5px solid #D8D5F0;
              overflow: hidden;
              box-shadow: 1px 1px 4px hsla(230, 10%, 10%, 0.3);`}>
            <CardContent>
                <h2 className={css`font-family: Seravek;
                                    font-style: normal;
                                    font-weight: bold;
                                    font-size: 20px;`}>{title}</h2>
                <Grid item className={css`border: 2px solid #909090; height: 400px; overflow-y: scroll; background-color: #E6E6E6;`}>
                        {GenerateCard(exampleData, completed, openAndSetModalContent)}
                </Grid>
            </CardContent>
        </Card>
    </>;
}

function CardWidgetRow({exampleData}) {
    const [modalOpen, toggleModal] = useState(false);
    const [modalContent, setModalContent] = useState(<></>);

    const openSetModal = (e, modalContent) => {
        e.stopPropagation();
        setModalContent(modalContent);
        toggleModal(true);
    }

    const closeModal = (e) => {
        e.stopPropagation();
        toggleModal(false);
    };

    return <>
        <Dialog open={modalOpen} onClose={closeModal} fullWidth={true} maxWidth={"lg"}>
          <DialogContent className={css`font-size: 16px;`}>
              {modalOpen &&
              <IconButton aria-label="close" onClick={closeModal}
                          className={css`position: relative; margin-left: 95%; `}>
                <CloseIcon />
              </IconButton>}
            {modalContent}
            </DialogContent>
        </Dialog>

        <div className={css`width: 100%;
            display: flex;
            justify-content: space-evenly;
            

            ${mq[2]} {
            flex-direction: column;

                > * + * {
                    margin-top: 30px;
                }
            }

            ${mq[3]} {
                > * + * {
                    margin-left: 30px;
                }
            }
        `}>
        <CardWidget exampleData={exampleData} completed={false}
                    openAndSetModalContent={openSetModal} title={i18n.ongoingOrders}></CardWidget>
        <CardWidget exampleData={exampleData} completed={true}
                    openAndSetModalContent={openSetModal} title={i18n.completedOrders}></CardWidget>
    </div>
    </>;
}

export default CardWidgetRow;
