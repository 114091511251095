import React from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useFirebase, useFirestore} from 'react-redux-firebase';
import { useHistory } from "react-router-dom";
import LoadingIcon from 'components/util/LoadingIcon';
import i18n from "util/i18n";
import { withToastNotification } from 'components/hocs/withToastNotification';


function InfoGetter({ loadingIcon, toastNotification }) {
    const dispatch = useDispatch();
    const firestore = useFirestore();
    const firebase = useFirebase();
    const history = useHistory();
    const user = useSelector(state => state.firebase.auth);

    console.log('Getting user info.');
    firestore.collection('userinfo').doc(user.uid).get().then(
        (doc) => {
            const userData = doc.data();
            if (userData === undefined) { //the case that authentication is exist, but there is no userInfo attached to it
                if(firebase.auth().currentUser) {
                    firebase.auth().currentUser.delete();
                    history.push('/');
                    toastNotification(i18n.accError, "error");
                }
                return;
            }
            if(userData.lang) {
                i18n.setLanguage(userData.lang);
            }
            userData.company.get().then(
                // Get company data
                companyDoc => {
                    const companyData = companyDoc.data();
                    // Function to dispatch user data once subscription info has been gathered
                    function dispatchUserData(subscriptionData) {
                        dispatch({
                            type: 'userinfo/load',
                            payload: {
                                ...userData,
                                userId: doc.id,
                                company: {
                                    id: companyDoc.id,
                                    ...companyData,
                                    subscription: subscriptionData
                                }
                            }
                        });
                    }

                    if (companyData.subscription) {
                        // Get company's subscription plan data
                        companyData.subscription.get().then(
                            subscriptionDoc => dispatchUserData({ id: subscriptionDoc.id, ...subscriptionDoc.data() })
                        );
                    } else {
                        // Lazy update company info with pay-as-you-go plan
                        const basicPlanRef = firestore.collection('subscriptionPlans').doc('0');
                        basicPlanRef.get().then(basicPlanDoc => {
                            userData.company.update({
                                subscription: basicPlanRef
                            }).then(() => dispatchUserData({ id: basicPlanDoc.id, ...basicPlanDoc.data() }));
                        });
                    }
                }
            );
        }
    );

    return <>{loadingIcon && <LoadingIcon size={40} thickness={4} />}
            </>;
}

export default withToastNotification(InfoGetter);
