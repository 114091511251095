import React from 'react';
import { css } from '@emotion/css';

import { useHistory } from 'react-router-dom';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { SubmitButton } from 'components/Forms';
import i18n from "util/i18n";

// Get n's ordinal number (1st, 2nd, 3rd, 4th etc.)
function getOrdinal(n) {
    const s = ["th", "st", "nd", "rd"],
        v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

// formatter for date picker
const dateFormat = (dateStr, dateTzFormat) => new Date(dateStr).toLocaleString([], { timeZone: dateTzFormat, weekday: 'short', day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' });

export const OrderSummary = ({ orderSummary }) => {
    return (
        <div className={css`
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        grid-gap: 15px;
        padding: 25px;
        border-radius: 8px;
        border: 1px solid hsl(230, 10%, 80%);
        background-color: white;
        margin: 9px 0px 25px;
        width: auto;
        overflow: hidden;
        & div span:first-child {
            color: hsl(0deg 0% 21%);
        }`
        }>
            <h2 className={css`margin: 0px;`}> Order Summary</h2>

            <div>
                <span>{i18n.eventName}:&nbsp;</span>
                <span>{orderSummary.name}</span>
            </div>

            <div>
                <span>{i18n.numberOfParticipants}:&nbsp;</span>
                <span>{orderSummary.numParticipants}</span>
            </div>

            <div>
                <span>{i18n.timeZone}:&nbsp;</span>
                <span>{orderSummary.timezone}</span>
            </div>

            <div className={css`display: flex; flex-direction: column; grid-gap: 10px;`}>
                <span>{i18n.dates}:</span>
                {Object.entries(orderSummary.dates).map(([idx, dateStr]) =>
                    <div key={idx} className={css`display: flex;`}>
                        <span className={css`width: 108px`}> &nbsp;&nbsp;&nbsp;{`${getOrdinal(parseInt(idx) + 1)} ${i18n.choice}:`}</span>
                        <span> {dateFormat(dateStr, orderSummary.dateTzFormat)}</span>
                    </div>)
                }
            </div>
        </div >
    )
}


const BookingResult = ({ success, description, orderSummary }) => {
    const history = useHistory();

    if (success && !orderSummary) {
        const params = new URLSearchParams(window.location.search);
        const encodedDates = params.get('dates');
        const numParticipants = params.get('count');
        const eventName = params.get('title');
        const timezone = params.get('timezone');

        const datesMs = JSON.parse(encodedDates);
        const dates = Object.entries(datesMs).map(([_, msString]) => parseInt(msString)); // Parse string to int

        orderSummary = {
            name: eventName,
            numParticipants: numParticipants,
            dates: dates,
            timezone: timezone,
            dateTzFormat: "GMT" // Dates from query params passed as GMT Epoch
        }
    }

    return (
        <div className={css`
            height: calc(100vh - 130px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        `}>
            {success ?
                <CheckCircleIcon className={css`height: 100px; width: 100px; color: hsl(130, 100%, 25%);`} />
                : <ErrorIcon className={css`height: 100px; width: 100px; color: hsl(0deg 100% 36%);`} />
            }
            <h1>{success ? i18n.thankYou : i18n.opsSomethingWentWrong}</h1>

            <p>{description}</p>
            {orderSummary &&
                <OrderSummary orderSummary={orderSummary} className={css`margin-bottom: 50px;`} />
            }
            <SubmitButton
                label={i18n.backToEventDashboard}
                onClick={() => history.push('/dashboard/home')}
            />
        </div>
    )
}

export default BookingResult
