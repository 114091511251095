import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useFirestore } from 'react-redux-firebase';

import { loadEvent } from "util/database/events";

import LoadingIcon from 'components/util/LoadingIcon';

import EventPage from 'components/Dashboard/Events/EventPage';


function Event() {
    const {id} = useParams();
    const [info, setInfo] = useState(null);
    const firestore = useFirestore();

    useEffect(() => {
        setInfo(null); // Display loadingIcon on recommendation click
        window.scrollTo(0, 0);
        loadEvent(firestore, id).then(setInfo);
    }, [id, firestore]);

    if (info === null) {
        return <LoadingIcon/>;
    }

    return <EventPage data={info}/>;
}

export default Event;
