import {permissions, specialRoles} from './permissions';

const notLoggedInAccess = [permissions.EVENTS, permissions.CLASSES];

export function trialCheck(userInfo) {
    const companyInfo = userInfo?.company;
    const subscriptionInfo = companyInfo?.subscription;

    // Compute trial info
    const isTrial = (subscriptionInfo?.type === 'Trial');
    const daysSinceCreation = Math.floor((+new Date() - companyInfo?.timeCreated) / 86400e3);
    const trialDaysLeft = subscriptionInfo?.trialDuration - daysSinceCreation;
    const trialExpired = isTrial && (trialDaysLeft <= 0);

    // Deny access if trial expired
    return {isTrial, trialExpired, trialDaysLeft};
}

export function hasAccess(feature, userInfo) {
    if (!userInfo) { //NotLoggedIn
        return notLoggedInAccess.includes(feature);
    }
    const {trialExpired} = trialCheck(userInfo);

    if (trialExpired) {
        return notLoggedInAccess.includes(feature);
    }

    const userRole = userInfo.role.toLowerCase();
    if (userRole === specialRoles.SYSADMIN) {
        return true;
    }
    if (!companyHasAccess(userInfo.company, feature)) {
        return false;
    }
    if (userRole === specialRoles.SUPERUSER) {
        return true;
    }

    if (userRole === specialRoles.PENDING) {
        return notLoggedInAccess.includes(feature);
    }
    return userInfo.company.rolePermissions[userRole].includes(feature);
}

const companyHasAccess = (companyInfo, feature) => {
    if (!companyInfo) {
        return false;
    }
    return companyInfo.featureList.includes(feature);
};
