import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Mixpanel from "util/analytics/mixpanel";
import { mixpanelEvents, eventProperties } from "util/constants/mixpanel";

/// Higher order component tracks page views in Mixpanel.
export const TrackNavigation = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        Mixpanel.track(mixpanelEvents.PAGE_VIEW, { [eventProperties.URL]: location.pathname + location.search });
    }, [location]);

    return children;
}
