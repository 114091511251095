import { changeUserLang } from "util/database/users";
import { useFirestore } from "react-redux-firebase";
import React, { useState } from "react";
import i18n from "util/i18n";
import { useSelector } from "react-redux";
import { MenuItem, Select } from "@mui/material";

const LanguageChanger = () => {
    const firestore = useFirestore();
    const userData = useSelector((state) => state.userinfo);
    const [language, setLanguage] = useState("en");
    if (language !== i18n.getLanguage()) {
        setLanguage(i18n.getLanguage());
    }
    const handleChangeLanguage = (event) => {
        setLanguage(event.target.value);
        if (userData) {
            changeUserLang(firestore, userData.userId, event.target.value);
        } else {
            const url = new URL(window.location);
            url.searchParams.set("lang", event.target.value);
            window.location = url;
        }
    };

    return (
        <div>
            <Select
                sx={{
                    display: "flex",
                    position: "relative",
                    marginRight: "35px",
                    borderRadius: "80px",
                    height: "30px",
                    width: "70px",
                    background: "transparent",
                }}
                variant="outlined"
                value={language}
                onChange={handleChangeLanguage}
            >
                <MenuItem value={"en"} divider={true}>
                    EN
                </MenuItem>
                <MenuItem value={"fr"} divider={true}>
                    FR
                </MenuItem>
            </Select>
        </div>
    );
};

export default LanguageChanger;
