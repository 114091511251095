import React from 'react';
import { css } from '@emotion/css';

import pluralize from 'pluralize';

import HistoryEntry from './HistoryEntry';
import i18n from "../../../util/i18n";


function Summary({history}) {
    if (!Object.keys(history).length) {
        // TODO insert some clipart
        return <div className={css`width: 480px; margin: auto;`}>
            <h1>{i18n.noHistoryYet}</h1>
            <p>{i18n.noHistoryYetMsg}</p>
        </div>;
    }

    // Sort by date
    const sortedEntries = Object.entries(history).sort(
        (a, b) => Date.parse(b[1].dates[0]) - Date.parse(a[1].dates[0])
    );

    return <div className={css`width: 550px; margin: auto`}>
        <h1>{pluralize(i18n.booking, Object.keys(history).length, true)}</h1>
        {sortedEntries.map(([id, entry]) => <HistoryEntry id={id} {...entry}/>)}
    </div>;
}

export default Summary;
