import React from 'react';
import { css } from '@emotion/css';

function LongGiftingWidget({stats}) {
    return <> 
        <div className={css`
            background-color: white;
            border-radius: 20px;
            overflow: hidden;
            box-shadow: 1px 1px 4px hsla(230, 10%, 10%, 0.3);
            border: 3px solid #D8D5F0;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            margin-right: 30px;
            width: 100%;
            text-align: center;
            margin-bottom: 30px;
            font-weight: bold;
            padding: 15px;
            font-family: Seravek;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            `}>
            {stats.map((item) => {
                return <div key={item.title}>
                    <div className={css`display: flex; flex-direction: column;`}>
                        <div className={css`font-size: 28px;`}>
                            {item.units}{item.value}
                        </div>
                        <div className={css`color: #0022CC; font-size: 18px;`}>
                            {item.title}
                        </div>
                    </div>
                </div>
            })}
        </div>
    </>;
}

export default LongGiftingWidget