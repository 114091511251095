import {stage} from '../stage'

export const getIntercomAppID = () => stage.isProduction() ? 'vddk6or4' : 'ybloxsqz';

const toUnixTimeSeconds = (unixTimeMilliseconds) => Math.round(unixTimeMilliseconds / 1000);

export const parseUserInfo = (userInfo) => {
    const {
        email,
        role,
        phone,
        firstName,
        lastName,
        timeCreated,
        image,
        company: companyData,
        birthday,
        workStartDate,
    } = userInfo;

    let company, avatar;

    if (companyData) {
        company = {
            companyId: companyData.id,
            name: companyData.name,
            createdAt: toUnixTimeSeconds(companyData.timeCreated),
        };
    }

    if (image) {
        avatar = {
            type: "avatar",
            imageUrl: image,
        };
    }

    return {
        email,
        name: `${firstName} ${lastName}`,
        phone,
        createdAt: toUnixTimeSeconds(timeCreated),
        ...(avatar && { avatar }),
        ...(company && { company }),
        customAttributes: { role, birthday, startDate: workStartDate },
        hideDefaultLauncher: true,
    };
};
