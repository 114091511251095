import React, { useState} from 'react';
import { css } from '@emotion/css';

import pluralize from 'pluralize';
import { useFirestore } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';

import { loadEventPolls } from 'util/database/eventPolls';
import EventPoll from 'components/Dashboard/EventPoll';


// Admin user view.
function EventPolls() {
    const firestore = useFirestore();
    const history = useHistory();
    const [slackPolls, setSlackPolls] = useState(null);

    if (slackPolls === null) {
        loadEventPolls(firestore).then(setSlackPolls)
    }

    return <>
        <h1 className={css`
            font-size: 24px;
            font-weight: 500;
            color: hsl(235, 10%, 15%);
            margin: 0;

        `}>Manage Slack Polls</h1>
        {slackPolls && <p className={css`   
            margin: 0;
            color: hsl(200, 10%, 30%);
            font-size: 16px;
            margin-top: 20px;
            margin-bottom: 50px;
            
        `}>{pluralize('company', Object.keys(slackPolls).length, true)} Slack Polls</p>}
        {slackPolls === null ? 'Loading...' :
            <div className={css`
            display: grid;
            grid-template-columns: repeat(auto-fit, 550px);
            grid-gap: 50px;
        `}>
            {Object.entries(slackPolls).map(([id, poll]) =>
            <EventPoll
                key={id}
                id={id}
                company = {poll.company}
                poll = {poll}
                onClick={() => history.push(`/dashboard/companies/${poll.company.id}`)}
            />
            )}
            </div>}
    </>;
}

export default EventPolls;
