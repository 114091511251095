import React from "react";

import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import VoteInviteForm from "../../Forms/VoteInviteForm";
import { css } from "@emotion/css";
import { List, ListItem, ListItemIcon } from "@material-ui/core";
import StarRateIcon from "@material-ui/icons/StarRate";
import i18n from "util/i18n";
 

const VoteInviteModal = ({ eventsToPoll, modalIsOpen, closeModal, toastNotification }) => {
    return (
        <Dialog open={modalIsOpen} onClose={closeModal}>
            <DialogTitle>{i18n.createPollWithSelectedEvents}</DialogTitle>
            <DialogContent>
                {eventsToPoll.length > 0 && (
                    <List
                        className={css`
                            margin-bottom: 16px;
                        `}
                    >
                        {eventsToPoll.map(({ title, id }) => (
                            <ListItem
                                className={css`
                                    font-size: 16px;
                                `}
                                key={id}
                            >
                                <ListItemIcon>
                                    <StarRateIcon />
                                </ListItemIcon>
                                <span>{title}</span>
                            </ListItem>
                        ))}
                    </List>
                )}
                <VoteInviteForm eventsToPoll={eventsToPoll} closeModal={closeModal} toastNotification={toastNotification}/>
            </DialogContent>
        </Dialog>
    );
};

export default VoteInviteModal;
