import React, {useEffect, useState} from 'react';
import {css} from '@emotion/css';

import {useHistory} from 'react-router-dom';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import {SubmitButton} from 'components/Forms';
import {useFirestore} from "react-redux-firebase";
import LoadingIcon from "components/util/LoadingIcon";
import i18n from "util/i18n";
import {getGiftOrder} from "../../util/database/gifts";
import {useSelector} from "react-redux";


export const OrderSummary = ({order}) => {
    return (
        <div className={css`
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        grid-gap: 15px;
        padding: 25px;
        border-radius: 8px;
        border: 1px solid hsl(230, 10%, 80%);
        background-color: white;
        margin: 9px 0px 25px;
        width: auto;
        overflow: hidden;
        & div span:first-child {
            color: hsl(0deg 0% 21%);
        }`
        }>
            {order?.payment_status === 'credit' ?
                <div>
                    <span>{i18n.youWillRecieveEmailForGift}</span>
                </div>
                : (<>
                    <h2 className={css`margin: 0px;`}> Gift Order Summary</h2>
                    <div>
                        <span>{i18n.packageName}:&nbsp;</span>
                        <span>{order.package.name}</span>
                    </div>

                    <div>
                        <span>{i18n.numberOfGiftees}:&nbsp;</span>
                        <span>{order.giftees.length}</span>
                    </div>
                </>)
            }
        </div>
    )
}


const GiftOrderingResult = ({success}) => {
    const history = useHistory();
    const params = new URLSearchParams(window.location.search);
    const orderId = params.get("orderId");
    const firestore = useFirestore();
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const userInfo = useSelector((state) => state.userinfo);
    useEffect(() => {
        if (success && loading && userInfo) {
            setLoading(false)
            getGiftOrder(firestore, orderId).then(setOrder);
        }
    }, [firestore, userInfo, loading, orderId, success]);

    return (!order && success) ? <LoadingIcon/> : (
        <div className={css`
            height: calc(100vh - 130px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        `}>
            {success ?
                <CheckCircleIcon className={css`height: 100px; width: 100px; color: hsl(130, 100%, 25%);`}/>
                : <ErrorIcon className={css`height: 100px; width: 100px; color: hsl(0deg 100% 36%);`}/>
            }
            <h1>{success ? i18n.thankYou : i18n.opsSomethingWentWrong}</h1>
            {order && order.package &&
            <OrderSummary order={order} className={css`margin-bottom: 50px;`}/>
            }
            <SubmitButton
                label={i18n.backToEventDashboard}
                onClick={
                    () => history.push('/dashboard/home') 
                }
                />
        </div>
    );
}

export default GiftOrderingResult
