export const eventThemes = {
    MOST_POPULAR: "Most Popular",
    HEAD_TO_HEAD_GAMES: "Head-to-Head Games",
    TRAVEL: "Travel the World",
    ARTS_CULTURE: "Arts & Culture",
    HEALTH_WELLNESS: "Health & Wellness",
    LEARN_GROW: "Learn & Grow",
    FOOD_DRINK: "Food & Drink",
    VIRTUAL_GAMES: "Virtual Games",
    TEAM_PARTIES: "Team Parties",
    VIRTUAL_ACTIVITIES: "Virtual Activities",
    FALL: "Fall",
    HALLOWEEN: "Halloween",
    SEASONAL_EVENTS: "Seasonal Events",
    CROWDPARTY_GAMES: "WFHomie x CrowdParty"
};

export const eventTags = {
    HOLIDAY: "holiday",
    DELIVERY: "delivery",
    EATS: "eats",
    FUN: "fun",
    GAME: "game",
    GROWTH: "growth",
    MASTERCLASS: "masterclass",
    OFFSITE: "offsite",
    PERKS: "perks",
    QUARTERLY: "quarterly",
    REWARDS: "rewards",
    SOCIAL: "social",
    SWAG: "swag",
    WELLNESS: "wellness",
    ONE_CREDIT: "one-credit"
};
