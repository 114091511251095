// Name of events tracked by Mixpanel
export const mixpanelEvents = {
    PAGE_VIEW: 'Page View',
    SIGN_UP: 'Sign Up',
    SIGN_IN: 'Sign In',
    SIGN_OUT: 'Sign Out',
    COMPANY_CREATED: 'Company Created',
    GROUP_CLASS_REGISTRATION: 'Group Class Registration',
    CROWDPARTY_HOST_GAME_CLICK: 'CrowdParty "Host a Game" Button Clicked',
    CROWDPARTY_JOIN_GAME_CLICK: 'CrowdParty "Join a Game" Button Clicked',
}

// Name of event properties
export const eventProperties = {
    TYPE: 'Type',
    URL: 'URL',
    USER_ROLE: 'User Role',
    SIGNUP_TIME: 'Signup Time',
    ROLE:  'Role',
    EMAIL: 'Email',
    COMPANY_ID: 'Company ID',
    COMPANY_NAME: 'Company name',
    SUBSCRIPTION_ID: 'Subscription ID',
    SUBSCRIPTION_TYPE: 'Subscription Type',
    SUBSCRIPTION_NAME: 'Subscription Name',
    CLASS_ID: 'Class ID',
    NUM_INVITEES: 'Number of Invitees',
    INVITED_USERS: 'Invited Users',
    EVENT_ID: 'Event ID',
    EVENT_TITLE: 'Event Title'
}
