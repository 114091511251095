import React, { useState } from 'react';
import { css } from '@emotion/css';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AddressAutocomplete from './AddressAutocomplete';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import DateFnsUtils from '@date-io/date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


import { withToastNotification  } from 'components/hocs/withToastNotification';
import i18n from 'util/i18n';

const renderTextField = ({
    label,
    input,
    meta: {touched, invalid, error},
    ...custom
}) => <TextField
    autoComplete="off"
    label={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
/>;

const renderPhoneField = ({
    label,
    input,
    meta: {touched, invalid, error},
    ...custom
}) => <TextField
    autoComplete="off"
    label={label}
    type="tel"
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
/>;

const renderAddressAutocomplete = ({
    label,
    input,
    meta: {touched, invalid, error},
    ...custom
}) => <AddressAutocomplete
    label={label}
    error={invalid}
    helperText={error}
    {...input}
    {...custom}
/>;

const renderCheckbox = ({
    input, label, ...custom
}) => <FormControlLabel
    label={label}
    control={
        <Checkbox
            checked={input.value ? true : false}
            onChange={input.onChange}
        />
    }
    {...custom}
/>;

const renderCheckboxArray = ({
    input, labels
}) => Object.entries(labels).map(([key, label]) =>
    <FormControlLabel
        key={key}
        label={label}
        className={css`
            font-size: 14px;
            color: hsl(230, 10%, 15%);
            margin: -10px 0;
        `}
        control={
            <Checkbox
                checked={input.value[key] ? true : false}
                onChange={v => {
                    const newValues = {...input.value};
                    newValues[key] = v.target.checked;
                    input.onChange(newValues);
                }}
            />
        }
    />
);

const renderRadiobuttonArray = ({
    input, labels
}) =>
    <RadioGroup value={input.value || ""}
            onChange={v => {
                input.onChange(v.target.value);
            }}
            >
        {Object.entries(labels).map(([key, label]) =>
            <FormControlLabel
                value={key}
                key={key}
                label={label}
                className={css`
                    font-size: 14px;
                    color: hsl(230, 10%, 15%);
                `}
                control={
                    <Radio/>
                }
            />)}
</RadioGroup>;

const renderNumberField = ({
    label,
    input,
    defaultVal,
    meta: { touched, invalid, error },
    disabled,

    min,
    unit
}) => {
    const inputProps = {
        inputProps: {min: min || 0},
        endAdornment: unit ? <InputAdornment position='end'>{unit}</InputAdornment> : undefined,
    }
    return <TextField
        variant="outlined"
        label={label}
        type='number'
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        disabled={disabled}
        value={defaultVal || input.value}

        InputProps={inputProps}
    />
};

const renderDropdown = ({
    menuItems,
    input,
    label,
    disabled,
    defaultVal,

    customCSS,
}) => (
    <FormControl variant="outlined" className={css`height: 56px; width: 100%;${customCSS}`}>
        <InputLabel id="dropdown-label">{label}</InputLabel>
        <Select
            labelId="dropdown-label"
            label={label} // Required so that label does not get struck through by outline
            {...input}
            disabled={disabled}
            value={defaultVal || input.value}
        >
            {Object.entries(menuItems).map(
                ([key, label]) =>
                    <MenuItem key={key} value={key}>{label}</MenuItem>
                )
            }
        </Select>
    </FormControl>
);

const renderValueDropdown = ({
    menuItems,
    label,
    disabled,
    input,
}) => {
    return <FormControl variant="outlined" className={css`height: 56px; width: 100%;`}>
        <InputLabel id="dropdown-label">{label}</InputLabel>
        <Select
            labelId="dropdown-label"
            label={label} // Required so that label does not get struck through by outline
            disabled={disabled}
            {...input}
        >
            {Object.entries(menuItems).map(([key, label]) =>
                 <MenuItem key={key} value={label}>{label}</MenuItem>
            )}
        </Select>
    </FormControl>
};


// Custom DateTimePicker implementation
const renderDateTimePicker = ({ meta, displayError, input, label, defaultDate, ...custom }) => {
    if (!input.value && defaultDate) { input.onChange(defaultDate) }

    return <LocalizationProvider dateAdapter={AdapterDateFns} utils={DateFnsUtils}>
    <DateTimePicker
        id="datetime-local"
        label={label}
        type="datetime-local"
        inputFormat='yyyy-MM-dd HH:mm'
        disablePast={true}
        value={input.value}
        {...custom}
        minutesStep={5}
        error={displayError && meta.error && meta.touched}
        onChange={(newTime) =>{input.onChange(newTime )}}
        renderInput={(params) => <TextField {...params}/>}
    />
    </LocalizationProvider>
}

const renderDatePicker = ({ meta, displayError, input, label, defaultDate, ...custom }) => {
    if (!input.value && defaultDate) { input.onChange(defaultDate) }
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
            id={"date-picker-inline for" + label}
            openTo="year"
            views={['year', 'month', 'day']}
            disableFuture={true}
            minDate={new Date(1920, 1, 1)}
            toolbarPlaceholder={label}
            inputFormat="yyyy/MM/dd"
            label={label}
            value={input.value}
            onChange={(newValue) => {
            input.onChange(newValue)}}
            {...custom}
            renderInput={(params) => <TextField {...params}/>}
            error={displayError && meta.error && meta.touched}
            />
  </LocalizationProvider>
  )

}


const renderGenericAutocomplete = ({
    label,
    input,
    meta: {touched, invalid, error},
    options,
    getOptionLabel,
    ...custom
}) => <Autocomplete
    options={options}
    getOptionLabel={getOptionLabel}
    renderInput={params => <TextField
        autoComplete="off"
        label={label}
        error={touched && invalid}
        helperText={touched && error}
        {...params}
        {...custom}
        {...input}
    />}
/>;

const renderMultiAutocomplete = ({
                                       label,
                                       input,
                                       meta: {touched, invalid, error},
                                       options,
                                       getOptionLabel,
                                       ...custom
                                   }) => <Autocomplete
    options={options}
    multiple={true}
    onChange={(event, newValue) =>{
        input.onChange(newValue);
    }}
    filterSelectedOptions={true}
    value={input.value || []}
    getOptionLabel={getOptionLabel}
    renderInput={params => <TextField
        autoComplete="off"
        label={label}
        error={touched && invalid}
        helperText={touched && error}
        {...params}
        {...custom}
        type={input.type}
    />}
/>;

const SubmitButton = withToastNotification(({onClick = () => undefined, disabled, label, style, toastNotification}) =>{
    const [submitting, setSubmitting] = useState(false);

    return <Button
        type="submit"
        onClick={async () => {
            setSubmitting(true);
            try {
                await onClick();
            } catch (error) {
                toastNotification(i18n.errorR, "error")
            }
            setSubmitting(false);
        }}
        disabled={disabled || submitting}
        style={style || {}}
        className={css`
        background-color: hsl(230, 100%, 35%);
        color: white;
        width: 100%;

        :hover:not(:disabled) {
            background-color: hsl(230, 100%, 20%);
        }

        :disabled {
            background-color: hsl(230, 10%, 80%);
        }
    `}>{submitting ? 'Processing...' : (label || 'Submit')}
    </Button>;
}
)


export {
    SubmitButton,
    renderTextField, renderPhoneField, renderAddressAutocomplete,
    renderCheckbox, renderCheckboxArray, renderRadiobuttonArray, renderNumberField,
    renderDropdown, renderDateTimePicker, renderDatePicker,
    renderGenericAutocomplete, renderValueDropdown, renderMultiAutocomplete
};
