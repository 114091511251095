import React from 'react';
import { css } from '@emotion/css';

import pluralize from 'pluralize';

import LoadingIcon from 'components/util/LoadingIcon';
import i18n from "../../../util/i18n";

const UNKNOWN = {
    firstName: 'Unknown',
    lastName: 'Employee',
}

function GiftEntry({
    gift,
    image,
    price,
    dateTime,
    recipient,
    employeeList,
}) {
    console.log(employeeList, recipient);
    const recipientInfo = employeeList[recipient] || UNKNOWN;
    return <div className={css`
        border-radius: 8px;
        background-color: white;
        box-shadow: 1px 1px 4px hsla(235, 10%, 10%, 0.1);
        margin-bottom: 30px;
    `}>
        <img src={image} className={css`
            width: 100%;
            height: 300px;
            object-fit: cover;
            border-radius: 8px 8px 0 0;
            background-color: hsl(235, 30%, 95%);
        `}
            alt=''
        />
        <div className={css`
            padding: 32px;
            padding-top: 16px;

            display: flex;
            align-items: center;
        `}>
            <div className={css`flex-grow: 1;`}>
                <h2 className={css`
                    margin: 0;
                    color: hsl(235, 10%, 15%);
                `}>{gift}</h2>
                <p className={css`
                    color: hsl(235, 15%, 40%);
                    margin: 0;
                    margin-top: 10px;
                `}>{i18n.formatString(i18n.sentToOnTime, <b>{recipientInfo.firstName} {recipientInfo.lastName}</b>, dateTime, price)}</p>
            </div>
            <img src={recipientInfo.image} className={css`
                border-radius: 50%;
                height: 48px;
                width: 48px;
                background-color: hsl(235, 30%, 95%);
            `} alt={recipientInfo.firstName + " on gift list"}/>
        </div>
    </div>;
}


function GiftList({giftList, employeeList}) {
    if (!Object.entries(employeeList).length) {
        return <LoadingIcon/>;
    }

    if (!giftList.length) {
        // TODO insert some clipart
        return <div className={css`width: 480px; margin: auto;`}>
            <h1>{i18n.noGiftsYet}</h1>
            <p>{i18n.noGiftsYetMsg}</p>
        </div>;
    }

    return <div className={css`width: 550px; margin: auto`}>
        <h1>{pluralize('Gift', giftList.length, true)} Sent</h1>
        {giftList.map(entry => <GiftEntry {...entry} employeeList={employeeList}/>)}
    </div>;
}

export default GiftList;
