import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { loadBambooHRUsers } from 'util/api/bamboohr';
import i18n from 'util/i18n';
import { useFirebase, useFirestore} from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import LoadingIcon from 'components/util/LoadingIcon';
import { withToastNotification } from 'components/hocs/withToastNotification';

const ImportUsersBambooHR = ({companyInfo, data, toastNotification}) => {
    const firebase = useFirebase();
    const firestore = useFirestore();
    const userInfo = useSelector(state => state.userinfo);
    const [loading, setLoading] = useState(false);

    return (
        (typeof companyInfo?.BambooHRIntegrationInfo?.CompanyDomain !== "undefined" && typeof companyInfo?.BambooHRIntegrationInfo?.APIKey !== "undefined") ? 
            loading? <LoadingIcon/> : <div className="text-base text-gray-600 flex flex-row justify-center items-center mt-3 rounded-lg hover:bg-gray-200 cursor-pointer"
                onClick={() => {
                    setLoading(true);
                    loadBambooHRUsers(firestore, firebase,data, userInfo, companyInfo)
                    setLoading(false);
                    toastNotification(i18n.successUserBambooHR, "success");
                } }
            >
                <IconButton> <AddCircleIcon/> </IconButton>
                {i18n.importUsersBambooHR}
            </div>
        : null     
    );
}

export default withToastNotification(ImportUsersBambooHR);
