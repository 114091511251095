import firebase from "firebase";
import { useState } from "react";
import AuthPortalContainerV2 from "../../components/AuthPortal/AuthPortalContainerV2";
// import * as yup from "yup";
// import {useFormik} from "formik";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import "url-search-params-polyfill";
import i18n from "../../util/i18n";
import { withToastNotification } from "components/hocs/withToastNotification";

const ForgotPortal = ({ toastNotification }) => {
    const [email, setEmail] = useState(null);

    const params = new URLSearchParams(window.location.search);
    const redirectString = `/login?${decodeURIComponent(params.toString())}`;

    function resetUserPassword(params) {
        firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {
                // Password reset email sent!
                toastNotification(i18n.passwordResetEmailSent, "success");
            })
            .catch((error) => {
                // Email formatting error !
                toastNotification(error.message, "error");
            });
    }

    return (
        <AuthPortalContainerV2>
            <TextField
                fullWidth
                id="recoveryEmail"
                name="email"
                label="Enter Your Login Email"
                variant="outlined"
                onChange={(event) => setEmail(event.target?.value)}
            />
            <Button
                color="primary"
                variant="contained"
                fullWidth
                size="large"
                type="submit"
                onClick={resetUserPassword}
            >
                {i18n.recoverPassword}
            </Button>
            <p>
                <Link to={redirectString}>{i18n.backToLogin}</Link>
            </p>
        </AuthPortalContainerV2>
    );
};

export default withToastNotification(ForgotPortal);
