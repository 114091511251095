import React from 'react'
import { css } from '@emotion/css';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from "react-router";

import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from 'util/api/stripe';

import Header from 'components/Header';
import BookingForm from './BookingForm';
import BookingResult from './BookingResult';

import InfoGetter from 'components/Header/InfoGetter';
import i18n from "util/i18n";
import PreOrder from "./PreOrder";

const BookingPage = ({ location }) => {
    return <>
        <InfoGetter />
        <Header />
        <main className={css`
            padding: 100px 30px 30px;
            box-sizing: border-box;
            min-height: 100vh;
        `}>
            <div className={css`width: 610px; margin: auto;`}>
                <Switch>
                    <Route path='/book/success'>
                        <BookingResult
                            success
                            description={i18n.wellBeInTouchForTimeConfirmation}
                            orderSummary={location.state?.orderSummary}
                        />
                    </Route>
                    <Route path='/book/fail'>
                        <BookingResult
                            success={false}
                            description={location.state?.errorMsg}
                        />
                    </Route>
                    <Route path='/book/pre'>
                        <Elements stripe={stripePromise}>
                            <PreOrder/>
                        </Elements>
                    </Route>
                    <Route path='/book'>
                        <Elements stripe={stripePromise}>
                            <BookingForm/>
                        </Elements>
                    </Route>
                </Switch>
            </div>
        </main>
    </>;
}

export default withRouter(BookingPage);
