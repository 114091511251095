import i18n from "util/i18n";

const gameInstructions = {
    crowdParty: () => (
        <div className="text-gray-600 space-y-1.5">
            <div className="flex flex-col space-y-1.5">
                <div className="flex flex-row space-x-1.5">
                    <span>{i18n.gameInstructions.crowdparty.shareRoomPin.emoji}</span>
                    <span className="italic">{i18n.gameInstructions.crowdparty.shareRoomPin.text}</span>
                </div>
                <div className="flex flex-row space-x-1.5">
                    <span>{i18n.gameInstructions.crowdparty.shareScreen.emoji}</span>
                    <span className="italic">{i18n.gameInstructions.crowdparty.shareScreen.text}</span>
                </div>
                <div className="flex flex-row space-x-1.5">
                    <span>{i18n.gameInstructions.crowdparty.hostPlayer.emoji}</span>
                    <span className="italic">{i18n.gameInstructions.crowdparty.hostPlayer.text}</span>
                </div>
            </div>
        </div>
    )
}

export { gameInstructions };
