import React, { useState } from 'react';
import { css } from '@emotion/css';

export const CopiableText = ({ text }) => {
    const [copied, setCopied] = useState(false);

    const handleClick = () => {
        navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => setCopied(false), 800);
    };

    return <div className={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    `}>
        <div className={css`
            background-color: hsl(230, 30%, 95%);
            font-size: 11px;
            padding: 8px 15px;
            border-radius: 8px;
            cursor: pointer;
        `}
            onClick={handleClick}
        >
            <code>
                {text}
            </code>
        </div>
        <div className={css`
            opacity: ${copied ? 1 : 0};

            background-color: hsla(230, 10%, 30%, 0.95);
            font-size: 11px;
            color: white;
            text-transform: uppercase;
            letter-spacing: 0.09em;

            position: absolute;
            top: ${copied ? -30 : -10}px;

            padding: 5px 8px;
            border-radius: 8px;

            transition: opacity 0.3s ease-out,
                top 0.3s ease-in-out;
        `}>Copied!</div>
    </div>;
}
