import React from 'react';
import { css } from '@emotion/css';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import { generateTimeString } from 'components/Dashboard/Events/util/EventInfo';

const EventSummary = ({ eventInfo }) => {
    return (
        <div className={css`
            display: flex;
            border-radius: 8px;
            border: 1px solid hsl(230, 10%, 80%);
            background-color: white;
            margin-top: 36px;
            width: auto;
            overflow: hidden;`
        }
        >
            <img src={eventInfo.coverImageUrl} alt={"image for " + eventInfo.title} className={css`
                    width: 200px;
                    height: 300px;
                    object-fit: cover;
                    border-radius: 8px 0 0 8px;
                `} />
            <div className={css`
                padding: 25px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            `}>
                <p className={css`
                    color: hsl(230, 10%, 20%);
                    font-size: 20px;
                    font-weight: 500;
                    margin: 0;`
                }>{eventInfo.title}</p>
                {/* {eventInfo['host/Vendor'] !== "" && <p className={css`
                    color: hsl(230, 10%, 40%);
                    font-size: 16px;
                    margin: 0;
                    margin-top: 5px;
                    word-break: break-word;
                `}>
                    <span className={css`font-weight: bold;`}>{i18n.hostedBy}:</span><br />
                    {eventInfo['host/Vendor']}
                </p>} */}
                {eventInfo.description && <p className={css`
                    color: hsl(230, 10%, 40%);
                    font-size: 16px;
                    margin: 0;
                    margin-top: 5px;
                    word-break: break-word;
                `}>
                    {eventInfo.description}
                </p>}
                <div className={css`
                    display: flex;
                    align-items: center;
                `}>
                    <ScheduleOutlinedIcon className={css`color: #052aff; font-size: 35px;`} />
                    <span className={css`
                        margin-left: 20px;
                        color: hsl(230, 10%, 20%);
                        font-size: 16px;
                        font-weight: 500;
                        text-align: left;
                    `}>{generateTimeString(eventInfo.duration)}</span>
                </div>
            </div>

        </div>
    )
}

export default EventSummary
