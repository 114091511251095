import React, { useState } from 'react';
import { css } from '@emotion/css';

import { useFirestore } from 'react-redux-firebase';

import pluralize from 'pluralize';

import CalendarIcon from '@material-ui/icons/Event';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import XIcon from '@material-ui/icons/Cancel';

import { loadEvent } from 'util/database/events';

import i18n from "util/i18n";



function MainDate({date}) {
    const dateObj = new Date(date);
    const dateOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };
    const timeOptions = {
        hour: '2-digit',
        minute: '2-digit',
    };
    return <div className={css`display: flex; align-items: center;`}>
        <span className={css`
            font-size: 40px;
            color: hsl(236, 80%, 37%);
        `}><CalendarIcon fontSize='inherit'/></span>
        <div className={css`
            margin-left: 12px;
            font-size: 14px;
        `}>
            <p className={css`color: hsl(236, 10%, 10%);`}>{dateObj.toLocaleString(i18n.localeString, dateOptions)}</p>
            <p className={css`color: hsl(236, 10%, 40%);`}>{dateObj.toLocaleString(i18n.localeString, timeOptions)}</p>
        </div>
    </div>;
}

function DateSelection({date, index}) {
    const dateObj = new Date(date);
    const dateOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    };
    return <div className={css`display: flex; align-items: center; margin: 8px 0;`}>
        <div className={css`
            width: 24px;
            height: 24px;
            border-radius: 50%;

            background-color: hsl(235, 80%, 37%);
            color: white;
            font-size: 12px;
            font-weight: bold;

            margin-right: 8px;

            display: flex;
            align-items: center;
            justify-content: center;
        `}>{index}</div>
        {dateObj.toLocaleString(i18n.localeString, dateOptions)}
    </div>;
}


function HistoryEntry({
    eventID,
    eventName, finalCost,
    dates,
    membersInvited,
    company,
    paid,
}) {
    const firestore = useFirestore()

    const [eventInfo, setEventInfo] = useState(null);
    const [loading, setLoading] = useState(true);

    if (!eventInfo && loading) {
        loadEvent(firestore, eventID).then(setEventInfo).then(
            () => setLoading(false)
        );
    }

    const numMembers = membersInvited ? membersInvited.length : 0;

    return <div className={css`
        background-color: white;
        border-radius: 8px;
        box-shadow: 1px 1px 4px hsla(200, 10%, 10%, 0.1);
        margin: 20px auto;

        display: flex;
        flex-direction: row;
    `}>
        {company && <p className={css`margin-top: 0; margin-bottom: 4px;`}>{company.name}</p>}
        {eventInfo ? <img alt={eventName} src={eventInfo.coverImageUrl} className={css`width: 200px; height: auto; border-radius: 8px 0 0 8px; object-fit: cover;`}/> : null}
        <div className={css`
            padding: 32px;
        `}>
            <h2 className={css`
            margin: 0;
            font-size: 18px;
            font-weight: 700;
            color: hsl(230, 10%, 20%);
        `}>{eventName}</h2>
            <div className={css`
            font-size: 14px;
            display: flex;
            align-items: center;
            margin-top: 2px;
            margin-bottom: 12px;
        `}>
                <div className={css`
                    color: ${paid ? 'hsl(130, 100%, 20%)' : 'hsl(0, 100%, 30%)'};
                    display: flex;
                    align-items: center;
                    margin-left: -2px;
                    margin-right: 12px;
                `}>
                    {paid ? <CheckCircleIcon fontSize='small'/> : 
                    <XIcon fontSize='small'/>}
                    <span className={css`margin-left: 4px`}>{paid ? i18n.paid : i18n.cancelled}</span>
                </div>
                <span className={css`color: hsl(200, 10%, 30%);`}>${finalCost}</span>
            </div>

            <MainDate date={dates[0]}/>

            {dates.length > 1 ? 
                <>
                    <p className={css`
            font-size: 12px;
            color: hsl(200, 10%, 40%);
            font-weight: 900;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            margin-top: 12px;
        `}>{i18n.backupDates}:</p>
                    {dates.slice(1).map((date, i) => <DateSelection id={date} index={i+1} date={date}/>)}
                </>
                : null}

            {numMembers > 0 ?
                <>
                    <p className={css`
            font-size: 12px;
            color: hsl(200, 10%, 40%);
            font-weight: 900;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            margin-top: 24px;
            margin-bottom: 4px;
        `}>{pluralize('Participant', numMembers, true)}:</p>
                    <div className={css`
                        font-size: 14px;
                        color: hsl(200, 10%, 15%);
                    `}>
                        {membersInvited.slice(0, 8).map((member) => <p>{member}</p>)}
                        {numMembers> 8 ? <p className={css`color: hsl(200, 10%, 40%); font-size: 12px;`}>
                            + {numMembers - 8} more
                        </p>: null}
                    </div>
                </> : null}
        </div>
    </div>;
}

export default HistoryEntry;
