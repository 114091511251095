import React from "react";
import { css } from '@emotion/css';

import { renderDateTimePicker } from 'components/Forms';
import { add, formatISO } from "date-fns";
import { Field } from "redux-form";

import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import i18n from "util/i18n";

// Get n's ordinal number (1st, 2nd, 3rd, 4th etc.)
function getOrdinal(n) {
    const s = i18n.ordinals;
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

// formatter for date picker
const iformat = (dt) => formatISO(dt).substring(0, 16); // required format is yyyy-MM-ddThh:mm

// return current time with input time added to it
const now = (toAdd) => {
    if (toAdd === undefined) {
        return new Date();
    }
    return add(new Date(), toAdd);
};

const required = value => value ? undefined : 'Required';

const renderDateSelector = ({ defaultDate, fields, submitFailed, dirty }) => (
    <>
        {
            //Maps each date to a datepicker component
            fields.map((date, index) => (
                <React.Fragment key={index}>
                    <div className={css`
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 490px;
                    `}
                    >
                        <span>
                            {getOrdinal(index + 1) + ` ${i18n.choice} (` + ((index + 1) === 1 ? `${i18n.mandatory}):` : `${i18n.optional}):`)}
                        </span>
                        <div>
                            <Field
                                name={date}
                                component={renderDateTimePicker}
                                label={i18n.chooseADateAndTime}
                                inputProps={{
                                    min: iformat(now({ hours: 1 })),
                                }}
                                validate={required}
                                displayError
                            />
                            <IconButton
                                key={index}
                                onClick={() => fields.remove(index)}
                                className={css`
                                    margin-top: 10px;
                                    padding: 10px;
                                    visibility: ${fields.length === 1 ? 'hidden': 'visible'};
                                `}
                            >
                                <RemoveCircleOutlineIcon />
                            </IconButton>

                        </div>
                    </div>
                </React.Fragment>
            ))
        }

        <div className={css`
            display: inline-block;
            color: hsl(230, 10%, 40%);
            font-size: 16px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            border-radius: 8px;
            margin-top: 10px;
            cursor: pointer;

            :hover {
                background-color: hsl(200, 10%, 90%);
            }`}
            onClick={() => fields.push()}
        >
            <IconButton><AddCircleIcon /></IconButton>
            {i18n.addAlternativeDateOption}
        </div>
    </>
);

export default renderDateSelector;
