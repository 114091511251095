import React from 'react';
import { css } from '@emotion/css';
import { Field, reduxForm } from 'redux-form';
import {
    renderTextField,
    SubmitButton,
    renderNumberField, renderCheckbox
} from 'components/Forms';
import FormSection from 'components/Forms/FormSection';

const numericalFields = ["quantity", "price"];
const validate = (values) => {
    const requiredFields = [
        'name',
        'description',
        'price',
        'image',
    ];

    const errors = {};

    for (let field of requiredFields) {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    }

    for (let field of numericalFields) {
        if (isNaN(Number(values[field]))) {
            errors[field] = 'Must be a number';
        }
    }

    return errors;
}

const GiftPackageEntryForm = reduxForm({ form: 'giftPackageDBForm', validate })(
    ({invalid, onSubmit, isEdit}) => {
        return <>
        {isEdit && <h2 className={css`color: red; text-align: center;`}>You are currently editing this package. Your changes here will override the previous version.</h2>}
        <form className={css`width: 100%`}>
            <FormSection label={isEdit? 'Edit New Gift Package Entry' : 'Add New Gift Package Entry'} columns={1}>
                <Field name='name' label='Package Name' component={renderTextField}/>
                <Field name='description' label='Description' component={renderTextField}/>
                <Field name='price' label='Price ($)' component={renderNumberField}/>
                <Field name='image' label='Image URL (Optional)' component={renderTextField}/>
                <Field name='shipping' label='Shipping' component={renderCheckbox}/>
                <Field name='tax' label='Tax' component={renderCheckbox}/>
                <Field name='serviceFeeFixed' label='Service Fee Fixed' component={renderNumberField}/>
                <Field name='serviceFeePercentage' label='Service Fee Percentage' component={renderNumberField}/>
                <Field name='image' label='Image URL (Optional)' component={renderTextField}/>
            </FormSection>

            <FormSection label={isEdit? 'Edit New Gift Package Entry' : 'Add New Gift Package Entry'} columns={7}>
                {
                    // there has to be a better way to do this but trust it looked way worse before :')
                    [1,2,3,4].map((index) => {
                        return <>
                            <Field name={`item_name${index}`} label={`Item (${index}) Name`} component={renderTextField}/>
                            <Field name={`item_image${index}`} label={`Item (${index}) Image`} component={renderTextField}/>
                            <Field name={`item_quantity${index}`} label={`Item (${index}) Quantity`} component={renderNumberField}/>
                            <Field name={`item_customOptions${index}`} label={`Options (seperated by ,)`} component={renderTextField}/>
                            <Field name={`item_API_campaignID${index}`} label={`API campaign ID`} component={renderTextField}/>
                            <Field name={`item_API_denomination${index}`} label={`API denomination`} component={renderNumberField}/>
                            <Field name={`item_API_currency${index}`} label={`API currency`} component={renderTextField}/>
                        </>
                    })
                }
            </FormSection>
        </form>
        <SubmitButton onClick={onSubmit} disabled={invalid}/>
        </>;
    }
);

export default GiftPackageEntryForm;
