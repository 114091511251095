import i18n from 'util/i18n';
import pluralize from 'pluralize';

function calculateRemainingCost(totalCost, credits) {
    return Math.max(0, parseInt(totalCost) - (400 * parseInt(credits ?? 0)));
}
function calculateMaxCredits(totalCost, creditAmount, eventTags) {
    // One-credit events can be paid with a single credit, with no limit on the number of participants and total price
    if (creditAmount > 0 && !isPremium(eventTags)) {
        return 1;
    }
    return Math.min(Math.ceil(totalCost / 400), creditAmount);
}
// Returns true in the event is premium
function isPremium(tags) {
    return !tags?.includes('one-credit');
}
/* Returns the cash price and number of credits to pay given the booking information
    Output:
        {
            cashPrice: number,
            creditPrice: number,
            denominator: string,
            isFixedPrice: boolean // true if the price does not vary based on the number of participants
        };
*/
function computeTotalPrice({
    eventTags,
    availableCredits,
    creditsUsed,
    useMaxCredits, // Use as many credits as possible
    pricePerPerson,
    numParticipants,
    isStartingPrice,
}) {

    
    // Free if price per person is 0
    if (pricePerPerson === 0) {
        return {
            cashPrice: 0,
            creditPrice: 0,
            denominator: '',
            isFixedPrice: true
        };;
    }

    // Event cost 1 credit if company has at least 1 credit in bank and event is not premium
    if (availableCredits > 0 && !isPremium(eventTags) && (creditsUsed > 0 || useMaxCredits)) {
        return {
            cashPrice: 0,
            creditPrice: 1,
            denominator: i18n.group,
            isFixedPrice: true
        };
    }

    // Return regular price per person ($) as the starting price
    if (isStartingPrice) {
        return {
            cashPrice: pricePerPerson,
            creditPrice: 0,
            denominator: i18n.person,
            isFixedPrice: false
        };
    }

    // Else compute cash price to pay (discounted if credits are used)
    const totalPrice = pricePerPerson * numParticipants; // Cash price without credit discount
    const creditsToPay = isStartingPrice || useMaxCredits ? calculateMaxCredits(totalPrice, availableCredits) : creditsUsed;
    let cashToPay = calculateRemainingCost(totalPrice, creditsToPay);

    if (isNaN(cashToPay)) {
        cashToPay = totalPrice
    }

    return {
        cashPrice: cashToPay,
        creditPrice: creditsToPay,
        denominator: i18n.group,
        isFixedPrice: false
    };
}

// Returns the starting price of an event (in terms of cash and credits) and its denominator, if any
function getStartingPrice(availableCredits, pricePerPerson, eventTags) {

    return computeTotalPrice({
        eventTags: eventTags,
        availableCredits: availableCredits,
        useMaxCredits: true,
        pricePerPerson: pricePerPerson,
        numParticipants: 1,
        isStartingPrice: true
    });
}

function generatePriceStrings({
    cashPrice,
    creditPrice,
    priceDenominator,
    hideReducedPriceDenominator
}) {
    const isReducedPrice = creditPrice > 0 && cashPrice > 0; // reduced price if user pays with both credits and cash
    const showDenominator = priceDenominator && !(isReducedPrice && hideReducedPriceDenominator);

    if (cashPrice === 0 && creditPrice === 0) {
        return { numerator: i18n.free, denominator: '' };
    }

    let numeratorString = '';
    if (creditPrice > 0) numeratorString += `${creditPrice} ${pluralize(i18n.credit, creditPrice)}`;
    if (isReducedPrice) numeratorString += ' + ';
    if (cashPrice > 0) numeratorString += `$${cashPrice.toLocaleString()}`;

    return {
        numerator: numeratorString,
        denominator: showDenominator ? `/${priceDenominator}` : ''
    };
}

export {
    calculateRemainingCost,
    calculateMaxCredits,
    isPremium,
    computeTotalPrice,
    getStartingPrice,
    generatePriceStrings
}
