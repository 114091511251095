import React, {useEffect, useState} from 'react';
import { css } from '@emotion/css';

import { useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import LoadingIcon from 'components/util/LoadingIcon';

import { loadMembers } from 'util/database/users';
import i18n from "../../../util/i18n";

import AddUserModal from 'components/Dashboard/UserTable/modals/AddUser.js'

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
  }));


function Member({member, selected, onClick, disabled, disabledMsg}) {
    const classes = useStyles();

    return <div className={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 15px 10px;
        margin: 16px 8px;
        background-color: ${disabled ? 'hsl(0, 0%, 83%)' : `hsl(230, 100%, ${selected ? 93 : 100}%)`};
        cursor: ${disabled ? 'default' : 'pointer'};
        border: 1px solid hsl(230, 10%, 90%);
        border-radius: 8px;
        box-shadow: 1px 1px 4px hsla(230, 10%, 10%, 0.1);
        :hover {
            background-color: ${disabled ? 'hsl(0, 0%, 83%)' : `hsl(230, 100%, ${selected ? 93 : 97}%)`};
        }
    `} onClick={() => disabled ? {} : onClick()}>

    <div className={classes.root}>
            <div className={css`display: flex;`}>
                <div className={css`margin: auto 0px`}>
                    <Checkbox checked={disabled || selected} disabled={disabled}/>
                </div>
                <div className={css`margin: auto 0px auto 20px;`}>
                    <img src={member.image} alt={member.firstName + " invite"} className={css`
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                    `}/>
                </div>
                <div className={css`
                    display: flex;
                    flex-direction: column;
                    margin-left: 15px;
                `}>
                    {disabled && disabledMsg && <span className={css`
                        font-size: 14px;
                        font-style: italic;
                    `}>{disabledMsg}</span>}
                    <span className={css`
                        font-size: 16px;
                    `}>{member.firstName} {member.lastName}</span>
                    <span className={css`
                        font-size: 14px;
                        color: hsl(230, 10%, 30%);
                        margin-top: 2px;
                    `}>{member.email}</span>
                </div>
            </div>
        </div>
    </div>;
}


function MemberInvite({invited, setInvited, allowCustomUsers, allByDefault, disabledUsers, disabledMsg, memberList, errorMsg}) {
    const firestore = useFirestore();
    const userInfo = useSelector(state => state.userinfo);

    const [modalOpen, setModalOpen] = useState(false);
    const [customMembers, setCustomMembers] = useState([]); // Array of emails of invited members

    const [allInvited, setAllInvited] = useState(allByDefault);
    const [combinedMembers,setCombinedMembers] = useState(null);
    const [memberListEntry,setMemberListEntry] = useState(null);
    useEffect(() =>{
        const shouldLoadMembers = !memberList;
        if (shouldLoadMembers &&  userInfo?.company) {
            loadMembers(firestore, userInfo.company).then(setMemberListEntry);
        } else  {
            setMemberListEntry(memberList);
        }
    },[memberList,firestore,userInfo]);

    useEffect(() => {
        if(memberListEntry) {
            const teamEntries = Object.values(memberListEntry).sort((a, b) =>
                a.email.localeCompare(b.email)
            );

            const members  = {...teamEntries};

            if (allByDefault) {
                setInvited(Object.values(teamEntries));
            }
            if(members) {

                // Combine existing members + write-in invites
                // if(sortedMembers == null)
                const sortedMembers = Object.entries(members).sort(
                    (a, b) => (a[1].lastName?.toLowerCase() > b[1].lastName?.toLowerCase())
                );
                // if(combinedMembers == null)
                setCombinedMembers([...sortedMembers, ...customMembers.map(email => [email, decorateUser(email)])]);
            }
        }
    },[memberListEntry, allByDefault, customMembers, setInvited]);

    useEffect(() => {
        if(combinedMembers)
            setAllInvited(invited.length === combinedMembers.length);
    },[invited, combinedMembers])

    // Map from email to placeholder user
    const decorateUser = (email) => {return {
        email,
        firstName: i18n.pendingInvite,
        lastName: '',
        image: 'https://i.imgur.com/aflwVYZ.png',
    };};

    const toggleInviteAll = () => {
        if (!allInvited) {
            return setInvited([...Object.values(memberListEntry), ...customMembers.map(decorateUser)]);
        }
        return setInvited([]);
      };

    if (memberListEntry === null &&  userInfo !== null) {
        return <div className={css`
            display: flex;
            align-items: center;
            justify-content: center;
        `}>
            <LoadingIcon />
        </div>
    }
    return <>
        <div className={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
        `}>
            <div>
                <Checkbox
                    checked={allInvited}
                    onClick={toggleInviteAll}
                />
                {allInvited ? i18n.clearAll : i18n.selectAll}
            </div>
            {errorMsg && <span className={css`color: red;`}>*{errorMsg}</span>}
        </div>
        <div className={css`
            background-color: white;
            border-radius: 8px;
            margin-top: 8px;
            max-height: 500px;
            overflow-y: scroll;
        `}>
            {combinedMembers && combinedMembers.map(([uid, member]) => (
                <Member
                    key={uid}
                    member={member}
                    selected={invited.some(e => e.email === member.email)}
                    disabled={disabledUsers?.some(e => e === member.email)}
                    disabledMsg={disabledMsg}
                    onClick={() => {
                        if (invited.some(e => e.email === member.email)) {
                            setInvited(invited.filter(memberI => memberI.email !== member.email));
                        } else {
                            setInvited(invited => [member, ...invited]);
                        }
                    }}
                />
            ))}
        </div> 
        {allowCustomUsers ?
        <div className={css`
                color: hsl(230, 10%, 40%);
                font-size: 18px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                color: blue;
                cursor: pointer;
            `}
            onClick={() => setModalOpen(true)}
        >
            <IconButton><AddCircleIcon/></IconButton>
            {i18n.inviteUsersNotInList}
        </div> : null}
        <AddUserModal
            isOpen={modalOpen}
            onClose={(users) => {
                setModalOpen(false);

                // If users were selected, add them
                if (!users.nativeEvent) {
                    setCustomMembers([...customMembers, ...users]);
                    setInvited([...invited, ...users.map(decorateUser)]);
                }
            }}
            companyInfo={userInfo?.company}
            hideDirectLink
        />
    </>;
}

export default MemberInvite;
