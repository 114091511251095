import AuthPortalContainer from "components/AuthPortal/AuthPortalContainer";
import EmployeeSignup from "components/Forms/Auth/EmployeeSignup";
import SuperuserSignup from "components/Forms/Auth/SuperuserSignup";
import { Link } from "react-router-dom";
import "url-search-params-polyfill";
import i18n from "../../util/i18n";

const SignupPortal = () => {
  const params = new URLSearchParams(window.location.search);
  // Is the user logging in from the homepage, or from an invite link?
  const isInvited = params.has("code") && params.has("company");
  const redirect = params.get("redirect");
  const source = params.get("source");
  const redirectString = `/login?${decodeURIComponent(params.toString())}`;


  return (
    <AuthPortalContainer>
      {isInvited ? (
        <EmployeeSignup redirect={redirect} />
      ) : (
        <SuperuserSignup redirect={redirect} source={source} />
      )}
      <p>
        {i18n.alreadyHaveAnAccount} <Link to={redirectString}>{i18n.signIn}</Link>
      </p>
    </AuthPortalContainer>
    
  );
};

export default SignupPortal;
