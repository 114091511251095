import React from 'react';
import { css } from '@emotion/css';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import LoadingIcon from 'components/util/LoadingIcon';
import { Avatar } from '@material-ui/core';
import i18n from "util/i18n";


function TeamMember({member}) {
    if(member.firstName && member.lastName) {
        const displayName = member.firstName + " " + member.lastName;
        member.lastName = (member.lastName) ? member.lastName : member.firstName;
        const initials = member.firstName.charAt(0).toUpperCase() + member.lastName.charAt(0).toUpperCase();
        return (
            <Avatar
                src={member.image}
                alt={displayName}
                title={displayName}
                className={css`margin-right: 15px;`}
                >{initials}</Avatar>
        )
    }

    return <></>;
}


function TeamWidget({team}) {
    const history = useHistory();
    const userInfo = useSelector(state => state.userinfo);

    if (team === null || userInfo === null) {
        return <LoadingIcon size={50} thickness={4}/>;
    }

    const limit = 10;
    const length = Object.entries(team).length;

    // Compute number of entries not shown.
    // Choose a font size appropriate for the number of digits in the "+xxx" bubble
    const difference = Math.min(length - limit, 999);
    const digits = Math.floor(Math.log10(Math.max(difference, 1))) + 1;
    const differenceFont = [20, 18, 15][digits - 1];

    return <>
        <h2 className={css`
            font-size: 20px;
            font-weight: 400;
            color: hsl(235, 10%, 15%);
            margin: 0;

        `}>{i18n.yourTeamAt} {userInfo.company.name}</h2>
        <div className={css`
            display: flex;
            flex-flow: row wrap;
            align-items: center;

            margin: 15px 0;
        `}>
            {Object.entries(team).slice(0, limit).map(
                ([id, member]) => <TeamMember key={id} member={member}/>
            )}
            {length > limit ? <Avatar style={{fontSize: differenceFont}}>+{difference}</Avatar> : <></>}
            {userInfo.role.length > 0 ?
                <IconButton onClick={() => history.push('/dashboard/members')}><EditIcon/></IconButton>
                : null}
        </div>
    </>;
}

export default TeamWidget;
