import React, { useState, useEffect } from 'react';
import { css } from '@emotion/css';

import { useFirebase, useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';


import Modal from 'react-modal';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Separator from 'components/util/Separator';

import { validateAddress } from 'util/email/validate';
import { inviteUsers } from 'util/email/invite';
import { getInviteLink } from 'util/database/invites';
import i18n from "../../../../util/i18n";
import { CopiableText } from '../../../util/CopiableText';
import { withToastNotification } from 'components/hocs/withToastNotification';

// If passed in (e.g. from sysadmin panel), companyInfo
// supercedes company entry in userInfo
function AddUserModal({ isOpen, onClose, companyInfo, hideDirectLink, toastNotification }) {
    const firestore = useFirestore();
    const firebase = useFirebase();
    const userInfo = useSelector(state => state.userinfo);

    const [loading, setLoading] = useState(false);
    const [addresses, setAddresses] = useState('');
    const [error, setError] = useState(undefined);

    const [link, setLink] = useState(null);

    const company = companyInfo || userInfo?.company;

    const generateLink = () => {
        if (link === null) {
            if (userInfo === null) {
                setTimeout(generateLink, 500);
            } else {
                getInviteLink(firestore, company).then(setLink);
            }
        }
    };
    useEffect(generateLink);

    async function handleClick() {
        setLoading(true);

        const addressList = addresses.split(/\r?\n/);
        const validated = [];

        for (let entry of addressList) {
            const trimmed = entry.trim();
            if (trimmed.length === 0) { continue; }

            if (!validateAddress(trimmed)) {
                setError(`Invalid email: ${trimmed}`);
                setLoading(false);
                return;
            }

            validated.push(trimmed);
        }

        if (validated.length === 0) {
            setError('At least one entry is required');
            setLoading(false);
            return;
        }

        const userInfoPatched = { ...userInfo, company };

        await inviteUsers(firebase, firestore, userInfoPatched, validated).then(() => {
        setLoading(false)
        onClose(validated)
        toastNotification(i18n.inviteSuccess, 'success')
        }).catch(error => {toastNotification(i18n.inviteError, 'error')})
    };

    const ready = (!loading) && (userInfo !== null);

    return <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
    >
        <Typography variant='h5'>Add Users</Typography>
        {hideDirectLink ? null : <>
            <p className={css`
            color: hsl(230, 10%, 25%);
            font-size: 14px;
        `}>{i18n.sendSignUpLinkMsg}:</p>
            <CopiableText text={link} />
            <Separator marginTop={20} />
        </>}
        <p className={css`
            color: hsl(230, 10%, 25%);
            font-size: 14px;
        `}>{i18n.enterEmailAddressToInvite}</p>
        <TextField
            value={addresses}
            onChange={e => { setAddresses(e.target.value); setError(null); }}

            label={i18n.emailAddresses}
            multiline
            rows={5}
            variant='outlined'

            error={error}
            helperText={error}
        />
        <Button onClick={handleClick}
            style={{
                marginTop: 30,
                backgroundColor: 'hsl(230, 100%, 30%)',
                color: 'white',
            }}
            disabled={!ready}
        >{ready ? i18n.invite : i18n.processing}</Button>
    </Modal>;
}

export default withToastNotification(AddUserModal);

