import React, { useMemo, useState} from 'react';
import {css} from '@emotion/css';

import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import AddUserModal from './modals/AddUser';
import DeleteUserModal from './modals/DeleteUser';
import ChangeUserRole from './modals/ChangeUserRole';
import ChangeUserTeam from './modals/ChangeUserTeam';
import Table from '../Table';
import CompanyMembersFileSelector from './CompanyMembersFileSelector';
import CompanyMembersExport from './CompanyMembersExport';
import ImportUsersBambooHR from './ImportUsersBambooHR';
import ImportAddressesBambooHR from './ImportAddressesBambooHR';

import {changeCompany, changeRole, changeTeam, del, inviteCode, name} from './columns';
import {useFirestore} from "react-redux-firebase";

import {loadCompanies} from 'util/database/companies';
import {changeUserCompany} from "util/database/users";

import {specialRoles} from 'util/permission/permissions';
import i18n from 'util/i18n';
import { withToastNotification } from 'components/hocs/withToastNotification';



function UserTable({
                       users, variant,
                       onAddUser,
                       companyFolder,
                       storedFiles,
                       setStoredFiles,
                       companyInfo,
                       userRole,
                       toastNotification,
                   }) {
    const [modalOpen, setModalOpen] = useState(false);
    const [deleteInfo, setDeleteInfo] = useState(null);
    const [changeRoleInfo, setChangeRoleInfo] = useState(null);
    const [changeTeamInfo, setChangeTeamInfo] = useState(null);
    const [availableCompanies, setAvailableCompanies] = useState(null);
    const [availableCompaniesLoaded, setAvailableCompaniesLoaded] = useState(false);
    const firestore = useFirestore();
    variant = variant || 'admin';
    if (variant === 'admin' && !availableCompaniesLoaded) {
        setAvailableCompaniesLoaded(true)
        loadCompanies(firestore).then((data) => {
            setAvailableCompanies(data);
        });
    } else if(!availableCompanies) {
        setAvailableCompanies([]);
        setAvailableCompaniesLoaded(true);
    }
    const sortUser = (a, b) => {
        if (a.role === specialRoles.PENDING) {return false;}
        if (b.role === specialRoles.PENDING) {return true;}
        if (a.role === b.role) {
            return a.firstName.toLowerCase() > b.firstName.toLowerCase();
        }
        return a.role.toLowerCase() > b.role.toLowerCase();
    };

    const data = useMemo(() => Object.entries(users).sort(
        (a, b) => sortUser(a[1], b[1])
    ).map(
        ([id, user]) => {
            return {
                id,
                profile: {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    image: user.image,
                    email: user.email,
                },
                changeCompany: {
                    id,
                    user,
                    availableCompanies
                },
                inviteCode: {
                    code: user.inviteCode,
                    timestamp: user.signupTime
                },
                deleteInfo: {
                    id,
                    name: user.firstName,
                    company: user.company,
                },
                changeRoleInfo: {
                    id,
                    name: user.firstName,
                    role: user.role,
                    availableRoles: user.company?.rolePermissions ?
                        Object.keys(user.company.rolePermissions) : []
                },
                changeTeamInfo: {
                    id,
                    name: user.firstName,
                    team: user.team,
                    role: user.role,
                    availableTeams: user.company?.teams
                },
            };
        }
    ), [users, availableCompanies]);


    const columns = useMemo(() => {
        const onChangeCompany = (id, companyId) => {
            changeUserCompany(firestore, id, companyId).then(() => toastNotification(i18n.successUserCompanyChange, "success")).catch(() => toastNotification(i18n.failUserCompanyChange, "error"));
        };
        return ({
            admin: [name, changeCompany(onChangeCompany), inviteCode, changeRole(setChangeRoleInfo), changeTeam(setChangeTeamInfo), del(setDeleteInfo)],
            superuser: [name, changeRole(setChangeRoleInfo), changeTeam(setChangeTeamInfo), del(setDeleteInfo)],
        });
    }, [firestore, toastNotification]);

    return <>
        <AddUserModal
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
            companyInfo={companyInfo}
        />
        <ChangeUserRole
            isOpen={changeRoleInfo !== null}
            onClose={() => setChangeRoleInfo(null)}
            info={changeRoleInfo}
            currentUserRole={userRole}
        />
        <ChangeUserTeam
            isOpen={changeTeamInfo !== null}
            onClose={() => setChangeTeamInfo(null)}
            info={changeTeamInfo}
        />
        <DeleteUserModal
            isOpen={deleteInfo !== null}
            onClose={() => setDeleteInfo(null)}
            info={deleteInfo}
        />
        <div className={css`display: inline-block;`}>
            {availableCompaniesLoaded && (<Table
                columns={columns[variant]}
                data={data}
                customRowProps={cells => {
                    const cell = cells.find(c => c.column.Header === i18n.role);
                    if (!cell || cell.value.role !== specialRoles.PENDING) {return {};}
                    return {
                        style: {
                            backgroundColor: 'hsl(60, 100%, 93%)',
                            borderLeft: '5px solid hsl(60, 100%, 40%)',
                        }
                    };
                }}
            />)}
            {(variant === 'superuser') &&
            <>
                <div
                    className={css`
                        color: hsl(230, 10%, 40%);
                        font-size: 16px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;

                        border-radius: 8px;
                        margin-top: 10px;
                        cursor: pointer;

                        :hover {
                            background-color: hsl(200, 10%, 90%);
                        }
                    `}
                    onClick={() => setModalOpen(true)}
                >
                    <IconButton> <AddCircleIcon/> </IconButton>
                    {i18n.inviteUsers}
                </div>
                {userRole === "sysadmin" && <ImportUsersBambooHR companyInfo={companyInfo} data={data}></ImportUsersBambooHR>}
                {userRole === "sysadmin" && <ImportAddressesBambooHR companyInfo={companyInfo} data={data}></ImportAddressesBambooHR>}
                <CompanyMembersFileSelector
                    companyFolder={companyFolder}
                    storedFiles={storedFiles}
                    setStoredFiles={setStoredFiles}
                />
                {userRole === "sysadmin" && <CompanyMembersExport members={users}/>}
            </>
            }
        </div>
    </>;
}

export default withToastNotification(UserTable);
