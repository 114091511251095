import React from 'react';
import { Field } from 'redux-form';
import { renderTimeZoneField } from '../../../util/forms.js';
import { FormLabel } from 'components/Forms/FormSection';
import i18n from "util/i18n";

const TimeZoneSelect = () => (
    <div>
        <FormLabel label={i18n.specifyATimeZone} />
        <Field name="timeZone" component={renderTimeZoneField} />
    </div>
);

export default TimeZoneSelect;
