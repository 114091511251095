import React from 'react';
import { css } from '@emotion/css';

import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import ShowWelcomeModal from "components/Dashboard/ShowWelcomeModal";
import MigrationModal from "components/Dashboard/MigrationModal";


export function Header({title}) {
    return <Helmet>
        <meta charSet="utf-8" />
        <title>WFHomie | {title ? title : ""}</title>
    </Helmet>
}

function PageHeader({title, description, hasImage, image, leftChild, showWelcomePopUp, isBlank=false}) {
    const history = useHistory();
    const showWelcomeModal = showWelcomePopUp && history.location.state?.justSignedUp;

    let defaultImageStyle = {
        width: "250px",
        marginLeft: "100px"
    };

    return <>
        {showWelcomeModal && <ShowWelcomeModal/>}
        <MigrationModal/>
        <Header title={title}/>
        {!isBlank &&
            <div className={css`display: flex; flex-direction: row; margin-top: 30px;`}>
                <div className={css`margin-right: 50px; min-width: 280px`}>
                    <span className={css`font-size: 32px;font-weight: 700;`}>{title}</span>
                    <p className={css`font-size: 20px;`}>{description}</p>
                    {leftChild}
                </div>
                <div>
                    {hasImage && <img style={defaultImageStyle} src={image} alt={title.split(" ")[1] + " image"}/>}
                </div>
        </div>}
    </>;
}

export default PageHeader;
