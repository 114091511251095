import React from 'react'
import { css } from '@emotion/css';

import Button from '@material-ui/core/Button';

import { mq } from "../util/Breakpoints";
import Announcement from './Announcement'

import i18n from 'util/i18n';

const conversionURL = "https://calendly.com/reza_/30-min";

function TrialBanner({daysLeft}) {
    const conversionAction = () => {window.open(conversionURL, '_blank');};

    return <Announcement>
        <span>
            {daysLeft > 0 ? <>{i18n.formatString(i18n.trialExpiredIn, <b>{daysLeft} days</b>)} </> : <>Your trial has <b>expired</b>. </>}
            Please <b onClick={conversionAction}>book a call</b> to upgrade your plan.
        </span>
        <Button
            onClick={conversionAction}
            variant='outlined'
            className={css`
                border: 1.5px solid hsl(40, 50%, 30%);
                margin-left: 32px;

                font-weight: 700;
                color: hsl(40, 50%, 30%);
                min-width: 120px;

                ${mq[0]} {
                    margin-left: 8px;
                }
            `}
        >{i18n.bookACall}</Button>
    </Announcement>;
}

export default TrialBanner;
