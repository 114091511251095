import mixpanel from 'mixpanel-browser';
import { mixpanelEvents, eventProperties } from 'util/constants/mixpanel';
import { stage } from 'util/stage';

const mixpanelAccountToken = stage.isProduction() ? '2bf8b1213dd86a09ae609a1357f4315c' : '0591bf221e8930296ec7e54549c26b48';

mixpanel.init(mixpanelAccountToken, { debug: stage.isTesting() });

// Extract properties we want to keep track of on Mixpanel from a userinfo object
function extractUserProperties(user) {
    const { email, role, signupTime, company } = user;

    const mixpanelUserData = {
        ...(signupTime && { [eventProperties.SIGNUP_TIME]: new Date(signupTime) }),
        ...(role && { [eventProperties.ROLE]: role }),
        ...(email && { [eventProperties.EMAIL]: email }),
        ...(company?.id && { [eventProperties.COMPANY_ID]: company.id }),
        ...(company?.name && { [eventProperties.COMPANY_NAME]: company.name }),
        ...(company?.subscription?.id && { [eventProperties.SUBSCRIPTION_ID]: company.subscription.id }),
        ...(company?.subscription?.type && { [eventProperties.SUBSCRIPTION_TYPE]: company.subscription.type }),
        ...(company?.subscription?.name && { [eventProperties.SUBSCRIPTION_NAME]: company.subscription.name })
    };

    return mixpanelUserData;
}

const mixpanelActions = {
    // Time an event by including the time between this call and a later 'track' call for the same event in the properties sent with the event.
    // Note that each call to time_event for the same event name restarts the start timer.
    timeEvent: (event_name) => {
        mixpanel.time_event(event_name);
    },

    // Track an event. This is the most important and frequently used Mixpanel function.
    track: (event_name, properties) => {
        mixpanel.track(event_name, properties);
    },

    // Track user sign up event
    trackSignUp: (user) => {
        const { role } = user;

        mixpanel.track(mixpanelEvents.SIGN_UP, {
            ...(role && { [eventProperties.USER_ROLE]: role })
        })
    },

    // Register a set of super properties, which are included with all events. This will overwrite previous super property values.
    register: (props) => {
        mixpanel.register(props);
    },

    // Identify a user to track user activity across devices and tie a user to their events
    identifyUser: (email) => {
        mixpanel.identify(email);
    },

    // Merge provided user properties with existing ones (on Mixpanel user profile)
    setUserProperties: (user) => {
        mixpanel.people.set(extractUserProperties(user));
    },

    // Clears super properties and generates a new random distinct_id for this instance. Useful for clearing data when a user logs out.
    resetUser: () => {
        mixpanel.reset();
    }
}

export default mixpanelActions;
