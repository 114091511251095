import { css } from "@emotion/css";
import Paper from "@material-ui/core/Paper";
import LoadingIcon from "components/util/LoadingIcon";
import Checkbox from "@material-ui/core/Checkbox";
import { Badge } from "@material-ui/core";
import i18n from "util/i18n";

// Card for a single team member
const TeamMember = ({ member, selected, onClick, disabled }) => {
  return (
    <div>
      <Paper
        className={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 10px;
          margin: 5px 0px;
          overflow: auto;
        `}
      >
        <div
          className={css`
            display: flex;
          `}
        >
          <Checkbox checked={selected} onClick={onClick} disabled={disabled} />
          <img
            src={member.image}
            alt={`${member.firstName} ${member.lastName} on user search`}
            className={css`
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin-left: 20px;
            `}
          />
          <div
            className={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              margin-left: 15px;
            `}
          >
            <span
              className={css`
                font-size: 16px;
              `}
            >
              {member.firstName} {member.lastName}
            </span>
            <span
              className={css`
                font-size: 14px;
                color: hsl(230, 10%, 30%);
              `}
            >
              {member.email}
            </span>
          </div>
        </div>
      </Paper>
    </div>
  );
};

/**
 * A component, formatted as a Redux Form field,
 * that allows for the controlled selection of multiple
 * user instances (i.e., for voting or inviting).
 */
const UserSearch = ({
  data: team = null,
  disabled: fieldDisabled,
  input: { value: invited, onChange: setInvited },
}) => {
  if(invited === undefined) {
    invited = [];
  }

  if (team === null) {
    return (
      <div className={css`margin: auto;`}>
        <LoadingIcon />
      </div>
    );
  }

  const allInvited = invited.length === Object.keys(team)?.length;

  const invitedEmails = invited.map(({ email }) => email);

  const toggleInviteAll = () => {
    if (!allInvited) {
      const all_emails = Object.values(
        team
      ).map(({ email, firstName: name }) => ({ email, name }));
      return setInvited(all_emails);
    }
    return setInvited([]);
  };

  // Finally, the actual processed data we'll be using.
  // sorted alphabetically by email, for now
  const teamEntries = Object.entries(team).sort(([_, a], [__, b]) =>
    a.email.localeCompare(b.email)
  );

  return (
    <div
      className={css`
        height: 300px;
        overflow: auto;
        overflow-y: overlay;
      `}
    >
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 90%;
        `}
      >
        <span>
          <Checkbox
            checked={allInvited}
            onClick={toggleInviteAll}
            disabled={fieldDisabled}
          />
          {allInvited ? i18n.clearAll : i18n.inviteTheWholeTeam}
        </span>
        <Badge color="primary" badgeContent={invited.length} />
      </div>
      {teamEntries.map(([uid, member]) => (
        <TeamMember
          key={uid}
          member={member}
          selected={invitedEmails.includes(member.email)}
          disabled={fieldDisabled}
          onClick={({ target: { checked } }) => {
            // if this click checked a currently-unchecked box
            if (checked) {
              return setInvited(
                invited.concat({
                  email: member.email,
                  name: member.firstName,
                })
              );
            }
            // otherwise
            return setInvited(
              invited.filter(({ email }) => email !== member.email)
            );
          }}
        />
      ))}
    </div>
  );
};

export default UserSearch;
