// this code should not be duplicated
const PanelLeft = ({ title, subtitle, description, imageUrl }) => {
    return (
        <div className="grid grid-flow-row gap-y-16 lg:grid-cols-2 lg:gap-x-16">
            <div>
                <img src={imageUrl} alt="Slack Demo" />
            </div>
            <div className="flex flex-col space-y-8">
                {title && <span className="text-kudos-green uppercase font-bold">{title}</span>}

                <span className="text-kudos-dark-blue font-bold leading-relaxed text-xl lg:text-3xl">{subtitle}</span>

                <span className="text-kudos-dark-blue leading-relaxed whitespace-pre-wrap">{description}</span>
            </div>
        </div>
    );
};

const PanelRight = ({ title, subtitle, description, imageUrl }) => {
    return (
        <div className="grid grid-flow-row gap-y-16 lg:grid-cols-2 lg:gap-x-16">
            <div className="flex flex-col space-y-8">
                {title && <span className="text-kudos-green uppercase font-bold">{title}</span>}

                <span className="text-kudos-dark-blue font-bold leading-relaxed text-xl lg:text-3xl">{subtitle}</span>

                <span className="text-kudos-dark-blue leading-relaxed whitespace-pre-wrap">{description}</span>
            </div>

            <div>
                <img src={imageUrl} alt="Slack Demo" />
            </div>
        </div>
    );
};

const panels = [
    {
        title: "TEAM SHOUTOUTS IN SLACK WITH A TWIST",
        subtitle: "Level up your employee engagement!",
        description:
            `
Our shiny new Kudos Bot for Slack allows employees to receive recognition from their team. Building trust and relationships in distributed teams is hard, but it’s never been easier with the latest Kudos Bot from WFHomie.

Kudos has been designed and optimized from the bottom up to help encourage employees in a distributed workforce.
`,
        imageUrl: "/static/images/kudos-slack-1.png",
    },
    {
        subtitle: "Put your P2P tools where they count. At your team’s finger tips.",
        description:
            `
Peer-to-peer recognition is about giving employees the chance to show their friends and their colleagues that they count and are counted on.

Kudos Bot gives your team the gift of giving. Team members can quickly and easily gift each other in the moment. When it's needed. When it matters.
`,
        imageUrl: "/static/images/kudos-slack-2.png",
    },
    {
        subtitle: "Team managers can’t hype everyone all the time, but Kudos Bot can!",
        description:
            `
There’s only so far a team manager can go with a top-down approach. Kudos is one of WFHomie's newest team building tools that gets to your team where they live..Slack!

Your team can reward and hype each other with electronic gift cards including UberEats. They’re no headaches for management because this is the only really hands-off HR Software on the market for peer to peer appreciation.
`,
        imageUrl: "/static/images/kudos-slack-3.png",
    },
];

export const Panels = () => {
    return (
        <div className="flex flex-col p-8 lg:p-16 space-y-32">
            <PanelRight {...panels[0]} />
            <PanelLeft {...panels[1]} />
            <PanelRight {...panels[2]} />
        </div>
    );
};
