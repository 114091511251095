import { Helmet } from "react-helmet"

export const LandingPageMetaTags = ({title, description, image, url}) => {
    const prefixedTitle = `WFHomie | ${title}`

    return <Helmet>
        <title>{prefixedTitle}</title>
        <meta name="title" content={prefixedTitle}/>
        <meta name="description" content={description}/>

        <meta property="og:type" content="website"/>
        <meta property="og:url" content={url}/>
        <meta property="og:title" content={prefixedTitle}/>
        <meta property="og:description" content={description}/>
        <meta property="og:image" content={image}/>

        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content={url}/>
        <meta property="twitter:title" content={prefixedTitle}/>
        <meta property="twitter:description" content={description}/>
        <meta property="twitter:image" content={image}/>
    </Helmet>
}
