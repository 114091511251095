async function loadEvents(firestore) {
    const event = await firestore.collection('events').get();
    return event.docs.map(doc => {
        return {id: doc.id, ...doc.data()}
    });
}

async function loadEventsByTheme(firestore, themeName, limit) {
    let event;
    if(limit === -1) {
        event = await firestore.collection('events').where("themes", "array-contains", themeName).get();
    } else {
        event = await firestore.collection('events').where("themes", "array-contains", themeName).limit(limit).get();
    }

    return event.docs.map(doc => {
        return {id: doc.id, ...doc.data()}
    });
}


async function loadEvent(firestore, eventID) {
    const event = await firestore.collection('events').doc(eventID).get();
    const data = event.data();
    return {id: eventID, ...data};
}

async function addEvent(firestore, entry) {
    return await firestore.collection('events').add({...entry});
}

async function updateEvent(firestore, entry) {
    await firestore.collection('events').doc(entry.id).update(entry);
}

async function deleteEvent(firestore, entry) {
    await firestore.collection('events').doc(entry.id).delete(entry);
}

async function loadSimilarEvents(firestore, id, numEvents) {
    const similarEvents = [];
    const thisEvent = await loadEvent(firestore, id);
    let eventCategory = thisEvent.themes[Math.floor(Math.random() * thisEvent.themes.length)];

    if (!eventCategory) throw new Error(`Event ${id} not found!`);

    let events = await loadEventsByTheme(firestore, eventCategory, numEvents + 1);
    // To get a different list of suggestions on every page load
    let shuffledIndices = [...Array(events.length).keys()].sort(() => 0.5 - Math.random());

    for (let idx of shuffledIndices) {
        let entry = events[idx]
        if (entry.themes.includes(eventCategory) && entry.id !== id) {
            similarEvents.push(entry);
            if (similarEvents.length === numEvents) break;
        }
    }

    return similarEvents;

}

export {
    loadEvents,
    loadEvent,
    addEvent,
    updateEvent,
    deleteEvent,
    loadSimilarEvents,
    loadEventsByTheme
};
