import React from 'react';
import { css } from '@emotion/css';

import pluralize from 'pluralize';

import Invoice from './Invoice';
import i18n from "util/i18n";


function Invoices({invoices}) {
    if (invoices === undefined) {
        // TODO insert some clipart
        return <div className={css`width: 480px; margin: auto;`}>
            <h1>{i18n.subscriptionNotFount}</h1>
            <p>{i18n.subscriptionNotFoundMsg}</p>
        </div>;
    }

    if (invoices.length === 0) {
        // TODO insert some clipart
        return <div className={css`width: 480px; margin: auto;`}>
            <h1>{i18n.noInvoicesYet}</h1>
            <p>{i18n.noInvoicesYetMsg}</p>
        </div>;
    }

    return <div className={css`width: 550px; margin: auto`}>
        <h1>{pluralize('Invoice', invoices.length, true)}</h1>
        {invoices.map(invoice => <Invoice invoice={invoice}/>)}
    </div>;
}

export default Invoices;
