export const TwoPanelLandingPage = ({ leftPanel, rightPanel }) => {
    return (
        <div className="h-content w-full grid grid-cols-1 lg:grid-cols-2">
            <div className="w-full h-full">
                <div className="flex flex-col max-w-full">
                    <div className="flex flex-col space-y-8 m-10 lg:m-10 lg:mr-14 lg:mr-14 max-w-full">{leftPanel}</div>
                </div>
            </div>
            <div className="w-full h-full bg-kudos-pink lg:flex hidden flex ">
                <div className="items-center p-8 flex">{rightPanel}</div>
            </div>
        </div>
    );
};
