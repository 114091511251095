import React, { useState, useEffect, useMemo } from "react";
import { css } from "@emotion/css";
import { useFirestore } from "react-redux-firebase";

import Mixpanel from "util/analytics/mixpanel";
import { eventProperties, mixpanelEvents } from "util/constants/mixpanel";

import pluralize from "pluralize";

import {
    Button,
    Dialog, DialogTitle, DialogContent, TextField
} from "@material-ui/core/";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import LoadingIcon from "components/util/LoadingIcon";
import { SubmitButton } from "components/Forms";

import {
    groupDatesByWeek, isFutureDate, isDateInThisWeek, isDateNextWeek,
    generateWeek, getWeekday,
} from "components/util/DateUtils";
import { addReferral, checkReferral, loadCompanyRegistrationsForClasses } from "util/database/classes"
import i18n from "util/i18n";
import { mq } from "components/util/Breakpoints";
import MemberInvite from "../Events/MemberInvite";

import ClassEntry from "./entry.js";
import FilterSelect from "./filter.js";
import { withToastNotification } from "components/hocs/withToastNotification";


const DATE_FORMAT = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName:'short',
}

function timeLeftStr(dateTime){
    // Compute time left into a nice string
    const minutesLeft = (Date.parse(dateTime) - Date.now()) / (60 * 1000);
    const hoursLeft = minutesLeft / 60;
    const daysLeft = hoursLeft / 24;

    let status = "in progress";
    if (hoursLeft > 24) {
        status = pluralize(i18n.day, Math.floor(daysLeft), true);
    } else if (minutesLeft > 60) {
        status = pluralize(i18n.hour, Math.floor(hoursLeft), true);
    } else if (minutesLeft > 0) {
        status = pluralize(i18n.minute, Math.floor(minutesLeft), true);
    }
    return status;
}

function Classes({classes, userRegistrations, onRegister, sysadmin, onEdit, userData, toastNotification}) {
    userRegistrations = userRegistrations || [];
    classes.sort((a, b) => (Date.parse(a.dateTime) - Date.parse(b.dateTime)));
    const firestore = useFirestore();

    const registeredClasses = classes.filter(element => userRegistrations.includes(element.id));
    const futureClasses = useMemo(() => classes.filter(element => isFutureDate(element.dateTime, 15)), [classes]);
    const futureRegisteredClasses = futureClasses.filter(element => registeredClasses.includes(element))
    const futureRegisteredClassesThisWeek = futureRegisteredClasses.filter(element =>
        isDateInThisWeek(Date.parse(element.dateTime)));
    const futureRegisteredClassesOther = futureRegisteredClasses.filter(element =>
        !isDateInThisWeek(Date.parse(element.dateTime)));

    const isManager = userData?.role.toLowerCase() === "superuser";

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [isRefOpen, setIsRefOpen] = useState(false);
    const [hasRefer, setHasRefer] = useState(false);
    const [referrer, setReferrer] = useState(null);
    const [currentClassEntry, setCurrentClassEntry] = useState({});
    const [filters, setFilters] = useState([]);

    const groupedByWeek = groupDatesByWeek(futureClasses.filter(
        ({tags}) => (filters.length === 0 || filters.every(tag => tags.includes(tag)))
    ));
    const allTagSet = new Set([].concat(...futureClasses.map(({tags}) => tags)));
    const allTags = [...allTagSet].sort();

    // Invite modal state
    const [isInviteOpen, setIsInviteOpen] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [companyRegistrations, setCompanyRegistrations] = useState(undefined);
    const [currentClassRegistrations, setCurrentClassRegistrations] = useState(undefined);
    const [inviteLoading, setInviteLoading] = useState(false);
    const [numMembers, setNumMembers] = useState(-1);

    useEffect(() => {
        // If user is a manager, get all registrations from company members for all future classes
        if (isManager) {
            loadCompanyRegistrationsForClasses(firestore, futureClasses, userData.company).then( ({classRegistrations, numMembers}) => {
                setCompanyRegistrations(classRegistrations);
                setNumMembers(numMembers);
            });
        } else {
            setCompanyRegistrations(null);
        }
    }, [firestore, futureClasses, isManager, userData?.company]);

    const handleRefOpen = () => {
        setIsRefOpen(true);
    }

    const handleRefClose = () => {
        setIsRefOpen(false);
    }

    const openInvite = (classEntry) => {
        setCurrentClassRegistrations(companyRegistrations[classEntry.id].registeredUsers);
        setCurrentClassEntry(classEntry);
        setIsInviteOpen(true);
    }

    const handleInviteClose = () => {
        setIsInviteOpen(false);
        setCurrentClassRegistrations(undefined);
    }

    const updateRegistrations = (classId, emails) => {
        if (isManager && companyRegistrations !== null) {
            // Update state with new class registrations
            const newRegisteredUsers = [...companyRegistrations[classId].registeredUsers, ...emails];
            const newRegistrations = {
                ...companyRegistrations, [classId]: {
                    registeredUsers: newRegisteredUsers,
                    allUsersRegistered: newRegisteredUsers.length === numMembers
                }
            };
            setCompanyRegistrations(newRegistrations);
            setCurrentClassRegistrations(newRegisteredUsers)
        }
    }

    const handleUserRegistration = (classId) => {
        onRegister(classId, [userData.userId], [userData.email]);
        updateRegistrations(classId, [userData.email])
    }

    async function handleInviteSubmit() {
        setInviteLoading(true);

        // Invite users that haven't registered yet
        const membersToInvite = selectedMembers.filter(u => !currentClassRegistrations.includes(u.email));

        const memberIds = membersToInvite.map(u => u.uid);
        const memberEmails = membersToInvite.map(u => u.email);
        onRegister(currentClassEntry.id, memberIds, memberEmails);

        // Update state with new class registrations
        updateRegistrations(currentClassEntry.id, memberEmails);

        // Close invite modal
        handleInviteClose();
        setInviteLoading(false);

        // Open confirmation modal
        setIsConfirmationOpen(true);

        Mixpanel.track(
            mixpanelEvents.GROUP_CLASS_REGISTRATION,
            {
                [eventProperties.CLASS_ID]: currentClassEntry.id,
                [eventProperties.NUM_INVITEES]: memberIds.length,
                [eventProperties.INVITED_USERS]: memberEmails
            }
        );
    }

    const handleClose = () => {
        setIsConfirmationOpen(false);
        setHasRefer(false);
        setReferrer(null);
    };

    const handleRefChange = (data) => {
        setReferrer(data.target.value);
    }

    function handleConfirmationOpen(data){
        checkReferral(firestore, data, userData).then((res) => {
            setReferrer(res);

            if(res !== null) {
                setHasRefer(true);
            }
        });
        setCurrentClassEntry(data);
        setIsConfirmationOpen(true);
    };

    async function handleRefSubmit(){
        await addReferral(firestore, currentClassEntry, userData, referrer);
        toastNotification(`You have submitted that you were referred by: ${referrer}. Enjoy your class! 🎉`, 'success');
        setHasRefer(true);
        handleRefClose()
    }

    let timeZone = userData?.timeZone
    if (timeZone == null){
        timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    const showClassEntry = (entry) => <ClassEntry
        entry={entry}
        key={entry.id}
        filters={filters}
        {...entry}
        timeZone={timeZone}
        classId={entry.id}
        registered={userRegistrations.includes(entry.id)}
        onRegister={() => handleUserRegistration(entry.id)}
        openConfirm={() => handleConfirmationOpen(entry)}
        sysadmin={sysadmin}
        onEdit={onEdit}
        firestore={firestore}
        isManager={isManager}
        openInvite={() => openInvite(entry)}
        allUsersRegistered={companyRegistrations?.[entry.id]?.allUsersRegistered}
        companyId={userData?.company?.id}
    />;

    return <>
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={isConfirmationOpen} maxWidth={"md"}>
            <div className={css`padding: 30px; width: 700px; font-size: 14px;`}>
                <div className={css`text-align: center;`}>
                    <span className={css`font-size: 26px; font-weight: bold; color: black;`}>
                        🎉 {i18n.youSignUpFor} <span className={css`color: #0022CC;`}> {currentClassEntry.name}!</span>
                    </span>
                </div>
                <div className={css`display: flex; margin-top: 30px; justify-content: space-between;`}>
                    <div className={css`height: 250px; flex: 1;`}>
                        <img className={css`height: 100%; width: auto;`} src="/static/svg/undrawCalendar.svg" alt='class calendar confirm'/>
                    </div>
                    <div className={css`flex: 0.8;`}>
                        <span>
                            {i18n.receiveClassSignUpEmailMsg}
                        </span>
                        <p>
                            {i18n.makeSureJoinCall}
                            <strong>
                                {" " + (new Date(Date.parse(currentClassEntry.dateTime, DATE_FORMAT))).toLocaleString('en-CA', DATE_FORMAT)}
                            </strong>, {i18n.whichIsIn}
                            <strong>
                                {" " + timeLeftStr(currentClassEntry.dateTime)}
                            </strong>!
                        </p>
                        {!hasRefer ?
                            <Button className={css`margin: 0px 0px;`} fullWidth color='primary'
                                variant='contained' onClick={handleRefOpen}>{i18n.enterReferrer}</Button> :
                                    <>{i18n.formatString(i18n.refConfirm, referrer)}</>
                        }
                        <Button className={css`margin: 20px 0px;`} fullWidth color='primary'
                            variant='contained' onClick={handleClose}>{i18n.returnToClasses}</Button>
                        <div className={css`font-size: 12px; color: #909090; text-align: center;`}>
                            <em>{i18n.thisClassListed}</em>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>

        <Dialog onClose={handleRefClose} aria-labelledby="simple-dialog-title" open={isRefOpen} maxWidth={"md"}>
            <DialogTitle onClose={handleRefClose}>
                Submit Referrer
            </DialogTitle>
            <DialogContent dividers>
                <TextField id="standard-basic" label="Referrer" className={css`margin-bottom: 10px; width: 100%;`} onChange={handleRefChange}/>
                <SubmitButton
                    disabled={!referrer && referrer !== ""}
                    onClick={handleRefSubmit}
                    label={i18n.submitReferrer}
                />
            </DialogContent>
        </Dialog>

        <Dialog onClose={handleInviteClose} aria-labelledby="simple-dialog-title" open={isInviteOpen} maxWidth={"md"}>
            <DialogTitle onClose={handleInviteClose}>
                {i18n.selectMembersToInvite}
            </DialogTitle>
            <DialogContent dividers>
                {currentClassRegistrations ?
                    <>
                        <MemberInvite
                            invited={selectedMembers}
                            setInvited={setSelectedMembers}
                            disabledUsers={currentClassRegistrations}
                            disabledMsg={i18n.alreadyRegistered} //TODO
                            allByDefault
                        />
                        {inviteLoading ?
                            <div className={css`
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            `}>
                                <LoadingIcon size={36.5} thickness={4} />
                            </div> :
                            <SubmitButton
                                disabled={selectedMembers.length === 0}
                                onClick={handleInviteSubmit}
                                label={i18n.invite}
                            />}
                    </> :
                        <div className={css`
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    `}>
                            <LoadingIcon />
                        </div>
                }
            </DialogContent>
        </Dialog>

        {/* ******************************************* */}
        {!sysadmin &&
            <>
                {/* YOUR CLASSES START (ONLY SHOWN ON CLASS PAGE)*/}
                <h2 className={css`
                            margin-top: 48px;
                            margin-bottom: 36px;
                            margin-left: 24px;
                            font-size: 24px;
                            color: hsl(235, 100%, 30%);
                        `}>
                    {pluralize(i18n.klass, futureRegisteredClasses.length, true)} {futureRegisteredClasses.length === 1 ? i18n.booked : i18n.bookedPlural}
                </h2>
                {
                    futureRegisteredClassesThisWeek.length === 0 && futureRegisteredClassesOther.length === 0 &&
                        <div className={css`
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    background-color: hsl(235, 80%, 95%);
                                    border-radius: 24px;
                                    padding: 60px 60px;

                                    max-width: 800px;
                                    margin: 0 auto;

                                    color: hsl(235, 30%, 25%);
                                    font-size: 18px;
                                `}>
                            <h3>
                                {i18n.youHavntSignUpAnyClass}
                            </h3>
                        </div>
                }

                {futureRegisteredClassesThisWeek.length > 0 &&
                    <ClassWeek classes={futureRegisteredClassesThisWeek} index={-2} showClassEntry={showClassEntry} hideHeader/>
                }

                {futureRegisteredClassesOther.length > 0 &&
                    <ClassWeek classes={futureRegisteredClassesOther} index={-1} showClassEntry={showClassEntry} hideHeader/>
                }
            </>
        }

        <Recordings/>

        <h2 className={css`
            margin-top: 72px;
            margin-bottom: 0;
            margin-left: 24px;
            font-size: 24px;
            color: hsl(235, 100%, 30%);
        `}>
            {i18n.allAvailableClasses}
        </h2>
        <FilterSelect filters={filters} setFilters={setFilters} allTags={allTags}/>
        {groupedByWeek.map((classesInGroup, groupIndex) =>
                ((classesInGroup.some((entry) => futureClasses.includes(entry))) ?
                    <ClassWeek classes={classesInGroup} index={groupIndex} showClassEntry={showClassEntry}/>
                    : null
                )
        )}
    </>;
}


// Eventually move this into a DB,
// if we want to embed all recordings on our site.
// For now we're showing a few and linking to external playlist.
const RECORDINGS = [
    'MVeiTJxFPfQ',
    'OiNv8bLAOyA',
    'G1NsWcOXGtY',
    '5tJIZmpDoAs',
    'AA5NlpJ22q4',
    'yUsWEF0aiUE',
    'CgYmrTCKa-4',
    'jCUkdCo6HuA',
    'h8to6EJBCFU',
    'BRzULnEVQXM',
    'MZMK823Rw-o',
    'd05EoAxbIN0',
    't6UhUFMmTgQ',
    'bJh2vkFJiOg',
    'mcV-P1KZXdo',
    '0VeyaXpNrOk',
    'Ha-VgrTvsT0',
    '9d96d1kOB5I',
    'QdHYyHGSWBw',
    'qYqYUcIbfYQ',
    'm7dt9zv1_2s',
    'DQq0msqvAkc',
    'gg7TPBMlvbc',
    'fVRo0clznYk',
    'ek-Sp0nNuKY',
    '-L2S1rSGXdw',
    'eM219epHM6M',
    'LpvsaZ2LNh4',
    'Ahyo3mhslHw',
    'SXcLDMai_3k',
];

const Recordings = () => {
    const embed = (id) => <iframe
        className={css`
            width: 430px;
            height: 250px;

            ${mq[0]} {
                width: 350px;
            }
        `}
        src={`https://www.youtube.com/embed/${id}`}
        frameborder="0"
        title={id}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
    ></iframe>;

    const displayedRecordings = RECORDINGS.slice(0, 6);

    return <>
        <h2 className={css`
            margin-top: 72px;
            margin-bottom: 36px;
            margin-left: 24px;
            font-size: 24px;
            color: hsl(235, 100%, 30%);
        `}>
            {i18n.onDemandRecordings}
        </h2>
        <div className={css`
            display: grid;
            grid-template-columns: repeat(auto-fit, 430px);
            grid-gap: 24px;
            justify-content: center;

            ${mq[0]} {
                grid-template-columns: 350px;
            }

            ${mq[11]} {
                background-color: grid-template-columns: repeat(3, 450px);
            }
        `}>
            {displayedRecordings.map(embed)}
        </div>
        <a
            href='https://www.youtube.com/watch?v=MVeiTJxFPfQ&list=PLKtcIYTGYxO9I48El2TLVNomIKp5KWxwn&index=1'
            target='_blank'
            rel='noreferrer'
            className={css`
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                margin: 24px auto;
                font-size: 16px;
            `}>
            <Button>
            <OpenInNewIcon/><span className={css`
            margin-left: 8px;
        `}>{i18n.viewFullPlaylist}</span>
            </Button>
        </a>
    </>;

};

const ClassWeek = ({
    classes,
    index,
    showClassEntry,
    hideHeader
}) => {
    const firstClassDate = classes[0].dateTime;
    if (firstClassDate === undefined) {
        console.log("Error, invalid date time");
        return <></>;
    }

    return <>
        {hideHeader ? null :
        <div className={css`
                position: sticky;
                margin-top: 36px;
                margin-bottom: 36px;
                top: 150px;
                width: 180px;
                background-color: hsl(235, 30%, 95%);
                border: 1px solid hsl(235, 30%, 90%);
                border-radius: 8px;
                padding: 16px 24px;

                ${mq[8]} {
                    top: 180px;
                }

                ${mq[2]} {
                    top: 220px;
                }
            `}>
            <div className={css`
                    color: hsl(235, 10%, 40%);
                    font-size: 12px;
                    text-transform: uppercase;
                    letter-spacing: 0.1em;
                    font-weight: 700;
                `}>{isDateInThisWeek(Date.parse(firstClassDate))
                    ? i18n.thisWeek
                    : (isDateNextWeek(Date.parse(firstClassDate))
                        ? i18n.nextWeek
                        : i18n.weekOf)}
            </div>
            <div className={css`
                    color: hsl(200, 10%, 20%);
                    font-size: 16px;
                    font-weight
                `}>
                {generateWeek(firstClassDate)[0].toString().split(' ').slice(1, 3).join(" ")}{" — "}
                {generateWeek(firstClassDate)[4].toString().split(' ').slice(1, 3).join(" ")}
            </div>
        </div>}
        {generateWeek(firstClassDate).map((day) => {
            const dayClasses = classes.filter((classXD) => new Date(classXD.dateTime).setHours(0, 0, 0, 0) === new Date(day).setHours(0, 0, 0, 0));
            if (dayClasses.length === 0) {return null;}

            const fullDate = day.toLocaleString(i18n.localeString, {month: 'long', day: 'numeric'});

            return <div key={`${index}${day}`} className={css`
                display: flex;
                flex-direction: column;

                ${mq[3]} {
                    flex-direction: row;
                }
            `}>
                <div className={css`
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-bottom: auto;
                        margin-right: 20px;

                        min-width: ${hideHeader ? 200 : 150}px;

                        padding: 24px;
                        border-radius: 20px;
                        color: hsl(235, 10%, 40%);
                    `}>
                    <div className={css`
                            font-size: 12px;
                            text-transform: uppercase;
                            font-weight: 700;
                            letter-spacing: 0.1em;
                        `}>{getWeekday(day.getDay())}</div>
                    <div className={css`
                        font-size: ${hideHeader ? 24 : 48}px;
                        font-weight: 700;
                        color: hsl(235, 100%, 30%);
                        line-height: 1;
                        margin-top: 16px;
                    `}>{hideHeader ? fullDate : day.getDate()}</div>
                </div>
                <div>
                    {dayClasses.map(showClassEntry)}
                </div>
            </div>
        })}
        {hideHeader ? null : <hr className={css``} key={"line for classgroupindex " + index}/>}
    </>
};

export default withToastNotification(Classes);
